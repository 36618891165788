import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const Investors = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames("section", styles.investors)}>
      <div className={classNames("center", styles.investors__center)}>
        <h2 className={classNames("h3 text-center", styles.investors__title)}>
          {t('Investors')}
        </h2>

        <div className={styles.investors__info}>
          {t('Sorry, no fancy logos')}
        </div>

        <div className={styles.investors__text}>
          {t('We believe that  Investhub  should be owned by the future of web3 Investors and Issuers.')}
        </div>
      </div>
    </div>
  )
}

export default Investors
