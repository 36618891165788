import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import { useQuery } from '@apollo/react-hooks'
import MediaSiteLogo from '../../media/images/investhub-logo-v1.svg'
import { PUBLIC_SETTINGS } from '../../queriesAndMutations'
import '../../styles/styles.scss'

// OPTIMIZITATION STYLESHEET
// import '../../styles/index-output.css'

const GuestAppBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const mobileMenuSetter = (state) => () => setMobileMenuOpen(state)
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const maybeRenderNavigation = () => (
    <div className="site-header__sidebar h-full min-w-[320px] py-12">
      <nav className="site-header__sidebar-navigation mx-6 flex flex-wrap flex-col justify-center items-center">
        <span className="mb-2">
          <NavLink to="/" className="uppercase text-base leading-[1.4] py-2 text-black hover:text-brand">Home</NavLink>
        </span>
        <span className="mb-2">
          <NavLink to="/explore" className="uppercase text-base leading-[1.4] py-2 text-black hover:text-brand">Explore</NavLink>
        </span>
        <span className="mb-2">
          <NavLink to="/dashboard" className="uppercase text-base leading-[1.4] py-2 text-black hover:text-brand">My Account</NavLink>
        </span>
      </nav>
    </div>
  )
  return (
    <>
      <Drawer
        anchor="left"
        open={mobileMenuOpen}
        onClose={mobileMenuSetter(false)}
      >
        {maybeRenderNavigation(true)}
      </Drawer>

      <header className="site-header py-4 lg:py-2 z-50 relative">
        <div className="site-header__layer absolute left-0 top-0 w-full h-full bg-white bg-opacity-20">&nbsp;</div>
        <div className="container mx-auto z-10 relative flex justify-start items-center pl-3 pr-3 md:pr-3 md:pl-3 lg:pl-6 lg:pr-6">
          <div className="site-header__logo max-w-full xl:max-w-[160px] mr-12">
            <NavLink to="/" className="flex items-center">
              {
                publicSettings && publicSettings.siteLogo ? (
                  <img
                    src={`${publicSettings.siteLogo}`}
                    alt="front"
                  />
                )
                  : (
                    <img src={MediaSiteLogo} alt="Investhub" className="w-full" />

                  )
              }
            </NavLink>
          </div>

          <nav className="site-header__navigation flex-1 hidden lg:block">
            <NavLink to="/" className="inline-block py-6 px-3 mr-9 text-base text-grey-pure transition font-medium hover:text-brand">Home</NavLink>
            <NavLink to="/explore" className="inline-block py-6 px-3 mr-9 text-base text-grey-pure transition font-medium hover:text-brand">Explore</NavLink>
            <NavLink to="/dashboard" className="inline-block py-6 px-3 mr-9 text-base text-grey-pure transition font-medium hover:text-brand">My Account</NavLink>
          </nav>

          <div className="site-header__cta hidden lg:flex lg:items-center">
            <NavLink to="/onboarding" className="btn-investhub__primary mr-3 py-2">Create</NavLink>
            <NavLink to="/connect-wallet" className="btn-investhub__primary-line py-2">Connect wallet</NavLink>
          </div>

          <div
            className="site-header__hamburger flex-1 lg:hidden"
            role="button"
            tabIndex="0"
            onClick={mobileMenuSetter(true)}
          >
            <div className="toggle-icon__wrapper relative w-[32px] h-[32px] transition cursor-pointer ml-auto">
              <div className="toggle-icon relative w-[32px] top-[12px]">&nbsp;</div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default GuestAppBar
