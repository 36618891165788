import React, { useState } from 'react'
import propTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from '@material-ui/core'
import { DISABLE_2FA } from '../../../queriesAndMutations'

const DisableTwoFactorAuth = ({
  onClose,
  onComplete,
}) => {
  const { t } = useTranslation()
  const [twoFaToken, setTwoFaToken] = useState('')
  const [step, setStep] = useState(0)
  const [disable2Fa, disable2FaStatus] = useMutation(DISABLE_2FA)

  const onSubmitToken = async (event) => {
    event.preventDefault()
    try {
      await disable2Fa({ variables: { token: twoFaToken } })
      setStep(2)
    } catch (error) {}
  }

  const renderFirstStep = () => (
    <>
      <form onSubmit={onSubmitToken} className="details__fieldset">
        <TextField
          className="investhub__field field w-full"
          label={t('To confirm disabling 2FA, please, enter 2FA token')}
          value={twoFaToken}
          onChange={({ target: { value } }) => setTwoFaToken(value)}
        />
        <div className="popup__btns">
          <button
            type="button"
            onClick={onClose}
            className="button-stroke popup__button"
          >
            Close
          </button>
          {disable2FaStatus.loading && (
            <CircularProgress />
          )}
          {!disable2FaStatus.loading && (
            <button type="submit" className="button popup__button">{t('Confirm')}</button>
          )}
        </div>
      </form>
    </>
  )

  const renderSuccess = () => (
    <>
      <div className="popup__info">
        {t('Success! 2FA was successfully disabled!')}
      </div>
      <div className="popup__btns">
        <button type="button" onClick={onComplete} className="button js-popup-close popup__button">{t('Close')}</button>
      </div>
    </>
  )

  return (
    <>
      <Stepper activeStep={step} className="stepper__wrapper">
        <Step key={1} className="stepper__item">
          <StepLabel>{t('Confirmation')}</StepLabel>
        </Step>
        <Step key={2} className="stepper__item">
          <StepLabel>{t('Success')}</StepLabel>
        </Step>
      </Stepper>
      <div className="popup__info">{' '}</div>
      <>
        {step === 0 && renderFirstStep()}
        {step === 2 && renderSuccess()}
      </>
    </>
  )
}

DisableTwoFactorAuth.propTypes = {
  onClose: propTypes.func.isRequired,
  onComplete: propTypes.func,
}

export default DisableTwoFactorAuth
