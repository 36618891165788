import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  PUBLIC_SETTINGS,
} from '../../queriesAndMutations'
import { capitalize } from '../../utils'

const MediumFeedGrid = ({
  numberOfPosts,
}) => {
  const { t } = useTranslation()
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const [posts, setPosts] = useState([])

  useEffect(() => {
    if (publicSettings?.medium?.username) {
      const feedUrl = `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@${publicSettings.medium.username}`

      axios.get(feedUrl).then((response) => {
        if (response.data.status === 'ok') {
          let neededPosts
          if (numberOfPosts > 0) {
            neededPosts = response.data.items.slice(0, numberOfPosts || 3)
          } else {
            neededPosts = response.data.items
          }
          setPosts(neededPosts.map((post) => ({
            title: post.title,
            date: post.pubDate,
            url: post.link,
            category: (post.categories && post.categories[0]) || null,
            thumbnail: post.thumbnail,
          })))
        }
      })
    }
  }, [publicSettings, numberOfPosts])

  const unescapeHtml = (escapedText) => (new DOMParser().parseFromString(escapedText, 'text/html').documentElement.textContent)

  if (posts.length === 0) {
    return (
      <div className="text-center">
        <em><strong>{t('No post found.')}</strong></em>
      </div>
    )
  }
  return (
    <div className="variants__list investhub-blog__list">
      {posts.map((post) => (
        <div className="variants__item investhub-blog__item" key={post.url}>
          <div className="variants__preview investhub-blog__preview">
            <img src={post.thumbnail} alt={post.title} />

            <a
              href={post.url}
              target="_blank"
              rel="noreferrer"
              className="status-green investhub-blog__category"
            >
              {capitalize(post.category)}
            </a>
          </div>

          <h3 className="user__name investhub-blog__title">
            {unescapeHtml(post.title)}
          </h3>
          <span className="investhub-blog__date">{moment(post.date).format('ll')}</span>

          <a href={post.url} className="button-stroke variants__button">{t('View more detail')}</a>
        </div>
      ))}
    </div>
  )
}

MediumFeedGrid.propTypes = {
  numberOfPosts: PropTypes.number,
}

export default MediumFeedGrid
