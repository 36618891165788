import React, { useState } from 'react'
import {
  Dialog,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import UserTenantTable from '../components/Dashboard/UserTenantTable'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'

const Dashboard = () => {
  const { t } = useTranslation()
  const [openSetupDialog, setOpenSetupDialog] = useState(false)

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          {t('Dashboard')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 faq discover tenant">
        <div className="discover__center tenant__center center">
          <div className="faq__top tenant__top">
            <div className="faq__title tenant__title h2">{t('Tenant')}</div>
            <div className="faq__info discover__info">{t('Use our innovative investor onramp + tokenize + payment. Simply configure your tenant and start onboarding your investors within 5 minutes.')}</div>

            <button
              className="button tenant__button"
              type="button"
              onClick={() => setOpenSetupDialog(true)}
            >
              {t('Add new tenant')}
            </button>
          </div>

          <div className="faq__row tenant__row">
            <div className="faq__col tenant__col">
              <UserTenantTable />

              <Dialog
                open={openSetupDialog}
                onClose={() => setOpenSetupDialog(false)}
                className="investhub-modal investhub-modal__m"
              >
                <ModalInnerMaterialDialog
                  modalTitle="New Tenant"
                  modalOnClickClose={() => setOpenSetupDialog(false)}
                >
                  <div className="variants__list onboarding-best__list flex__row onboarding-best__list-v2">
                    <div className="variants__item onboarding-best__item text-center">
                      <div className="variants__preview">
                        <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
                      </div>

                      <div className="variants__info onboarding-best__info">
                        {t('Easy Setup')}
                        <br />
                        {t('(4 Steps - 60 seconds)')}
                      </div>

                      <a
                        href="/tenant-easy-setup"
                        type="button"
                        className="button-stroke variants__button admin-settings-page__button"
                      >
                        {t('Choose')}
                      </a>
                    </div>

                    <div className="variants__item onboarding-best__item text-center">
                      <div className="variants__preview">
                        <img src="https://via.placeholder.com/650/000000/FFFFFF/?text=investhub" alt="Onboarding 1" />
                      </div>

                      <div className="variants__info onboarding-best__info">
                        {t('Professional Setup')}
                        <br />
                        {t('(12 Steps - up to 5 minutes)')}
                      </div>

                      <a
                        href="/tenant-professional-setup"
                        type="button"
                        className="button-stroke variants__button admin-settings-page__button"
                      >
                        {t('Choose')}
                      </a>
                    </div>
                  </div>
                </ModalInnerMaterialDialog>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
