import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import TheCommunityImg from '../../../media/images/the-community.png'
import styles from './styles.module.scss'

const TheCommunity = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames('section', styles.thecommunity)}>
      <img src={TheCommunityImg} alt={t('Join the Community')} className={styles.thecommunity__img} />
      <div className={classNames('center', styles.thecommunity__center)}>
        <h2 className={classNames('h3', styles.thecommunity__title)}>
          {t('The community section')}
        </h2>
        <div className={styles.thecommunity__text}>
          {t('We are bootstrapping the future of investment communities without borders.')}
        </div>

        <a href="https://t.me/investhubDAO" className="button" target="_blank" rel="noreferrer">
          {t('Join Community')}
        </a>
      </div>
    </div>
  )
}

export default TheCommunity
