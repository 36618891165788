export const createQueryString = (data) => {
  const searchParams = new URLSearchParams(data)

  return searchParams.toString()
}

export const getTextAvatarUrl = (name, background, color) => {
  const params = {
    name,
    background: background || '0066FF',
    color: color || 'FFFFFF',
  }

  return `https://ui-avatars.com/api/?${createQueryString(params)}`
}
