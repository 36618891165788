import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const CommunityRightNow = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames("section", styles.communityrightnow)}>
      <div className={classNames('center', styles.communityrightnow__center)}>
        <h2 className={classNames('h3 text-center', styles.communityrightnow__title)}>
          {t('Whats happening in the Community right now?')}
        </h2>

        <div className={styles.table}>
          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Issuer')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Status')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Type')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Chain')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Verified')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Registered')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__header__title}>
                {t('Progress')}
              </div>
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 1')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__yellow)}>
                {t('Onboarding')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('Equity')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('Etherum')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('800')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('1400')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('USDC 650,981')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 2')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__green)}>
                {t('Completed - Token Issued')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('Utility')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('Cosmos')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('300')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('423')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('DAI 300,201')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 3')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__yellow)}>
                {t('Capital Commitment')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('SAFE (Tokenized)')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('Polygon')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('20')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('80')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('USDT 50,482')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 4')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__green)}>
                {t('Completed')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('Utility')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('Elrond')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('200')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('569')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('USDC/T 3,854,990')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 5')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__green)}>
                {t('Completed')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('Equity (Series A+B)')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('N/A')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('140')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('122')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('N/A')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 6')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__yellow)}>
                {t('Onboarding')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('SAFE')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('None')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('64')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('80')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('EURe 870,921')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 7')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__yellow)}>
                {t('Onboarding')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('Utility')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('Q Blockchain')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('10')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('15')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('USDC 50,232')}
            </div>
          </div>

          <div className={styles.table__row}>
            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Issuer')}
              </div>
              {t('Issuer 8')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Status')}
              </div>

              <div className={classNames(styles.table__status, styles.status__yellow)}>
                {t('Onboarding')}
              </div>
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Type')}
              </div>
              {t('Bond')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Chain')}
              </div>
              {t('XDC')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Verified')}
              </div>
              {t('150')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Registered')}
              </div>
              {t('700')}
            </div>

            <div className={styles.table__col}>
              <div className={styles.table__label}>
                {t('Progress')}
              </div>
              {t('USDT 55,000')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityRightNow
