import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Slider from 'react-slick'
import { pick } from 'lodash'
import { useTranslation } from 'react-i18next'

import 'slick-carousel/slick/slick.css'

import {
  GET_OPPORTUNITY_COMMITMENT,
  COMPLETE_OPPORTUNITY_COMMITMENT,
} from '../queriesAndMutations'
import Icon from '../components/Icon'

function SliderNextArrow(props) {
  const { className, onClick } = props
  return (
    <div
      className={classNames('', className)}
      role="button"
      tabIndex="0"
      onClick={onClick}
    >
      <Icon
        name="arrow-next"
        className={classNames('arrow-next')}
        size="16"
      />
    </div>
  )
}

SliderNextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

function SliderPrevArrow(props) {
  const { className, onClick } = props
  return (
    <div
      className={classNames('', className)}
      role="button"
      tabIndex="0"
      onClick={onClick}
    >
      <Icon
        name="arrow-prev"
        className={classNames('slick-prev')}
        size="16"
      />
    </div>
  )
}

SliderPrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

const settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,
  dots: false,
  speed: 500,
  responsive: [
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        infinite: true,
      },
    },
  ],
}

const OpportunitySuccess = () => {
  const { t } = useTranslation()
  const query = new URLSearchParams(window.location.search)
  const id = parseInt(query.get('commitment_id'), 10)

  const { data: { getOpportunityCommitment: data } = {}, loading } = useQuery(GET_OPPORTUNITY_COMMITMENT, {
    variables: {
      id,
    },
  })

  const [completeOpportunityCommitment] = useMutation(COMPLETE_OPPORTUNITY_COMMITMENT)

  const [commitent, setCommitent] = useState({
    id: '',
    amount: '',
    currency: '',
    total_amount: '',
  })

  useEffect(() => {
    if (data) {
      setCommitent(pick(data, Object.keys(commitent)))
      completeOpportunityCommitment({
        variables: {
          id,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  return (
    <>
      <div className="section result successfully-subscribed no-padding__bottom">
        <div className="result__center center">
          <div className="result__wrap">
            <>
              {/* Credit to https://codepen.io/Hellwinkel/pen/KKaNNKb */}
              <div className="successfully-subscribed__icon">
                <div className="successfully-subscribed__wrapper">
                  <div className="successfully-subscribed__background">
                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </div>
                <div className="successfully-subscribed__shadow">{' '}</div>
              </div>
              <div className="result__title h2">
                {t('Thank you for your Commitment')}
              </div>
              <div className="result__info">
                <p>
                  {t('Your commitment of')}
                  {` ${+commitent.amount} ${commitent.currency.toUpperCase()} `}
                  {t('has been received, thank you for supporting inspring entrepreneurs,')}
                  {' '}
                  {t('distributed networks, teams, companies, protocols and many more.')}
                </p>

                <p>
                  {t('You will receive further informations once the deal is successful.')}
                </p>

                {/* <p>
                  <strong>Please Join this Telegram Group in case you have any further Questions.</strong>
                </p> */}
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="section" style={{ display: 'none' }}>
        <div className="popular__center center opportunity-other">
          <div className="popular__top opportunity-other__top">
            <div className="popular__box">
              <h2 className="hot__title opportunity-other__title h3">
                Other Opportunities
              </h2>
            </div>

            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
            <a href="#" className="link-icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>

              View all
            </a>
          </div>

          <div className="hot__inner opportunity-other__inner">
            <Slider {...settings}>
              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Amazing digital art</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>0.001 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Ribbon Hunter</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>2.45 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Amazing digital art</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>0.001 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Ribbon Hunter</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>2.45 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Amazing digital art</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>0.001 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Ribbon Hunter</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>2.45 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Amazing digital art</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>0.001 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

              <div className="card card-explore opportunity-other__card">
                <div className="card__preview card-explore__preview">
                  <img src="https://via.placeholder.com/256x303.png" alt="No Human Series - Comeby" />

                  <div className="card__control">
                    <button className="card__favorite" type="button">
                      <Icon
                        name="heart"
                        className={classNames('heart')}
                        size="20"
                      />
                    </button>

                    <button type="button" className="button-small card__button js-popup-open">
                      <span>Place a bid</span>
                    </button>
                  </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
                <a href="#" className="card__link no-padding__bottom">
                  <div className="card__body">
                    <div className="card__line">
                      <div className="card__title">Ribbon Hunter</div>
                    </div>
                  </div>
                </a>

                <div className="card__foot">
                  <div className="card__status">
                    <Icon
                      name="candlesticks-up"
                      className={classNames('candlesticks-up')}
                      size="20"
                    />
                    Highest bid
                    <span>2.45 ETH</span>
                  </div>
                  <div className="card__bid">
                    New bid
                    <span role="img" aria-label="fire">🔥</span>
                  </div>
                </div>
              </div>

            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default OpportunitySuccess
