import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Contributor from '../../../media/images/bg-with-abstract.png'
import styles from './styles.module.scss'

const CapitalDeployment = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames('section', styles.capitaldeployment)}>
      <img src={Contributor} alt="Contributor" className={styles.capitaldeployment__image} />
      <div className={classNames('center', styles.capitaldeployment__center)}>

        <section className={styles.capitaldeployment__box}>

          <h2 className={classNames('h3', styles.capitaldeployment__title)}>
            {t('Capital Deployment')}
          </h2>

          <p className={styles.capitaldeployment__text}>
            {t('Over the past decade, I have observed a significant shift in the way entrepreneurs approach fundraising, as they have come to recognize the importance of capital allocation, deployment, and transparent reporting.')}
          </p>

          <span className={styles.capitaldeployment__extra}>
            <strong>
              {t('Equanimity AG, Marcel Füssinger, Investhub.io Contributor')}
            </strong>
          </span>
        </section>
      </div>
    </div>
  )
}

export default CapitalDeployment
