import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'

import ImageSupport from '../../../media/supports/index'

import styles from './styles.module.scss'

const ImagesSupports = [
  {
    imageLight: ImageSupport.caag,
    imageDark: ImageSupport.caag,
    name: 'Caag',
  },
  {
    imageLight: ImageSupport.calidrisColor,
    imageDark: ImageSupport.calidrisWhite,
    name: 'Calidris',
  },
  {
    imageLight: ImageSupport.cll,
    imageDark: ImageSupport.cll,
    name: 'CLL',
  },
  {
    imageLight: ImageSupport.enercom,
    imageDark: ImageSupport.enercom,
    name: 'Enercom',
  },
  {
    imageLight: ImageSupport.equanimity,
    imageDark: ImageSupport.equanimityDark,
    name: 'Equanimity',
  },
  {
    imageLight: ImageSupport.ixo,
    imageDark: ImageSupport.ixo,
    name: 'Ixo',
  },
  {
    imageLight: ImageSupport.xdcteq,
    imageDark: ImageSupport.xdcteq,
    name: 'XDCTEQ',
  },
]

const AdoptionRates = () => {
  const { t } = useTranslation()

  const data = [
    {
      name: '2000',
      internetusers: 0,
      walletusers: 0,
      forecasewalletusers: 0,
      greatwalletusers: 0,
    },
    {
      name: '2022',
      internetusers: 50,
      walletusers: 50,
      forecasewalletusers: 50,
      greatwalletusers: 50,
    },

    {
      name: '2028',
      internetusers: 50,
      walletusers: 50,
      forecasewalletusers: 50,
      greatwalletusers: 50,
    },

    {
      name: '2030',
      internetusers: 200,
      walletusers: 200,
      forecasewalletusers: 200,
      greatwalletusers: 200,
    },
    {
      name: '2035',
      internetusers: 500,
      walletusers: 300,
      forecasewalletusers: 250,
      greatwalletusers: 420,
    },
  ]

  return (
    <>
      <div className={classNames('section', styles.adoption)}>
        <div className={classNames('center', styles.adoption__center)}>

          <h2 className={classNames('h3 text-center', styles.adoption__title)}>
            {t('Adoption rate of wallets similiar to the Internet.')}
          </h2>

          <div className={classNames('adoption__graphic')}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                width={500}
                height={500}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="1 4" />
                <XAxis dataKey="name" height={60} stroke="#272B30" color="white" />
                <YAxis stroke="#272B30" color="white" />
                <Tooltip />
                <Legend />
                <Line type="monotone" name={t('Number of Internet users')} dataKey="internetusers" stroke="#FEEEDA" strokeWidth={2} />
                <Line type="monotone" name={t('Number of Blockchain Wallet Users')} dataKey="walletusers" stroke="#FFD88D" strokeWidth={2} />
                <Line type="monotone" name={t('Forecast of number of Blockchain Wallet Users')} dataKey="forecasewalletusers" stroke="#CECECE" strokeWidth={2} strokeDasharray="5 5" />
                <Line type="monotone" name={t('680bn “The great wealth transfer”')} dataKey="greatwalletusers" stroke="#3683F6" strokeWidth={2} strokeDasharray="5 5" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.goals)}>
        <div className={classNames('center', styles.goals__center)}>

          <div className={classNames(styles.goals__list)}>
            <div className={styles.goals__box}>
              <div className={styles.goals__inner}>
                <div className={classNames('h3', styles.goals__title, styles.goals__title_yellow)}>
                  {t('Our goal by 2030')}
                </div>
              </div>
            </div>

            <div className={styles.goals__box}>
              <div className={styles.goals__inner}>
                <div className={classNames('h3', styles.goals__title, styles.goals__title_yellow)}>
                  {t('2m')}
                </div>

                <div className={classNames(styles.goals__text)}>
                  {t('Wallets installed with the help of our Community.')}
                </div>
              </div>
            </div>

            <div className={styles.goals__box}>
              <div className={styles.goals__inner}>
                <div className={classNames('h3', styles.goals__title, styles.goals__title_yellow)}>
                  {t('1bn')}
                </div>

                <div className={classNames(styles.goals__text)}>
                  {t('Yearly deployable Capital by the Community.')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.card)}>
        <div className={classNames('center', styles.card__center)}>

          <div className={styles.card__list}>
            {ImagesSupports.map((x) => (
              <div className={styles.card__item} key={x.name}>
                <div className={styles.card__inner}>
                  <img src={x.imageDark} alt={t(x.name)} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default AdoptionRates
