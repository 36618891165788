import React from 'react'
import PropTypes from 'prop-types'
import { useTimer } from 'react-timer-hook'

const CountingDown = ({ expiryTimestamp }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
  } = useTimer({ expiryTimestamp })

  return (
    <div className="main__timer">
      {days && (
        <div className="main__box">
          <div className="main__number">{days}</div>
          <div className="main__time">Days</div>
        </div>
      )}

      {hours && (
        <div className="main__box">
          <div className="main__number">{hours}</div>
          <div className="main__time">Hrs</div>
        </div>
      )}

      <div className="main__box">
        <div className="main__number">{minutes}</div>
        <div className="main__time">Mins</div>
      </div>
      <div className="main__box">
        <div className="main__number">{seconds}</div>
        <div className="main__time">Secs</div>
      </div>
    </div>
  )
}

CountingDown.propTypes = {
  expiryTimestamp: PropTypes.number,
}

export default CountingDown
