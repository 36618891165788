import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import classNames from 'classnames'
import {
  Dialog,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import uuid from 'react-uuid'
import { sum, pick } from 'lodash'
import { useTranslation } from 'react-i18next'

import {
  GET_OPPORTUNITY,
} from '../queriesAndMutations'
import BreadcrumbsSimple from '../components/BreadcrumbsSimple'
import CountingDown from '../components/CountingDown'
import ModalInnerMaterialDialog from '../components/ModalInnerMaterialDialog'
import CreateCommitmentForm from '../components/Opportunity/CreateCommitmentForm'
import Icon from '../components/Icon'
// import OpportunityChart from '../components/OpportunityChart'
import { formatPrintNumber, roundNumber, getTextAvatarUrl } from '../utils'

const Opportunity = ({ match }) => {
  const slug = match.params.id
  const { t } = useTranslation()

  const { data: { getOpportunity: data } = {}, loading } = useQuery(GET_OPPORTUNITY, {
    variables: {
      id: slug,
    },
  })

  const [opportunity, setOpportunity] = useState({
    id: '',
    user_id: '',
    name: '',
    currency: '',
    slug: '',
    video_embed_url: '',
    owner: {},
    owner_id: '',
    owner_wallet: '',
    location: '',
    sector: '',
    minimum_subscription: '',
    total_expected_amount: '',
    start_date: '',
    end_date: '',
    description: '',
    tenant_url: '',
    allOpportunityCommitment: [],
  })

  const totalCommitment = opportunity.allOpportunityCommitment && opportunity.allOpportunityCommitment.length
    ? sum(opportunity.allOpportunityCommitment.map((commitment) => parseFloat(commitment.amount))) : 0
  const commitmentPercent = (totalCommitment / parseFloat(opportunity.total_expected_amount)) * 100

  useEffect(() => {
    if (data) {
      setOpportunity(pick(data, Object.keys(opportunity)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading])

  // const opportunityTabNav = ['History', 'Bids', 'Details']
  const opportunityTabNav = ['History']
  const [activeOpportunityTab, setActiveOpportunityTab] = useState(opportunityTabNav[0])

  const [openCommitmentDialog, setOpenCommitmentDialog] = useState(false)

  const renderTabContentHistory = () => (
    <div className="item__box js-tabs-item" style={{ display: 'block' }}>
      <div className="item__users">
        {opportunity.allOpportunityCommitment && opportunity.allOpportunityCommitment.length > 0
          && opportunity.allOpportunityCommitment.map((commitment) => (
            <div className="item__user investhub-item__user" key={commitment.id}>
              <div className="item__avatar">
                <img
                  src={getTextAvatarUrl(commitment.ens || commitment.name || commitment.wallet_address.slice(-2))}
                  alt={`${commitment.ens || commitment.name || commitment.wallet_address} avatar`}
                />
              </div>

              <div className="item__description">
                <div
                  className="item__name investhub-item__name"
                  title={commitment.ens || commitment.name || commitment.wallet_address}
                >
                  {commitment.ens || commitment.name || commitment.wallet_address}
                </div>
                <div className="item__action">
                  {t('Commitment placed for')}
                  {' '}
                  <span className="item__money">
                    {+commitment.amount}
                    {' '}
                    {commitment.currency.toUpperCase()}
                  </span>
                </div>
              </div>

              <div className="item__time investhub-item__time">
                {moment(commitment.commited_at).fromNow()}
              </div>
            </div>
          ))}
      </div>
    </div>
  )

  // const renderTabContentBids = () => (
  //   <div className="item__box js-tabs-item" style={{ display: 'block' }}>
  //     <div className="item__users">
  //       <div className="item__user investhub-item__user">
  //         <div className="item__avatar">
  //           <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
  //         </div>

  //         <div className="item__description">
  //           <div className="item__action">
  //             Highest bid:
  //             {' '}
  //             <span>
  //               500 USDC
  //             </span>
  //           </div>
  //           <div className="item__name investhub-item__name" title="@WeekendFund.ETH">
  //             @WeekendFund.ETH
  //           </div>
  //         </div>
  //       </div>

  //       <div className="item__user investhub-item__user">
  //         <div className="item__avatar">
  //           <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
  //         </div>

  //         <div className="item__description">
  //           <div className="item__action">
  //             Commitment placed for
  //             {' '}
  //             <span>
  //               800 USDC
  //             </span>
  //           </div>
  //           <div className="item__name investhub-item__name" title="@0xEcB438375dCAd215C3198293332B6b19de48aC79">
  //             @0xEcB438375dCAd215C3198293332B6b19de48aC79
  //           </div>
  //         </div>
  //       </div>

  //       <div className="item__user investhub-item__user">
  //         <div className="item__avatar">
  //           <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
  //         </div>

  //         <div className="item__description">
  //           <div className="item__action">
  //             Commitment placed for
  //             {' '}
  //             <span>
  //               1500 CHF
  //             </span>
  //           </div>
  //           <div className="item__name investhub-item__name" title="@nakamoto">
  //             @nakamoto
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  // const renderTabContentDetails = () => (
  //   <div className="item__box js-tabs-item" style={{ display: 'block' }}>
  //     <div className="item__users">
  //       <div className="item__user investhub-item__user">
  //         <div className="item__avatar">
  //           <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
  //         </div>

  //         <div className="item__description">
  //           <div className="item__position">
  //             Owner
  //           </div>
  //           <div className="item__name investhub-item__name" title="@WeekendFund.ETH">
  //             @WeekendFund.ETH
  //           </div>
  //         </div>
  //       </div>

  //       <div className="item__user investhub-item__user">
  //         <div className="item__avatar">
  //           <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
  //         </div>

  //         <div className="item__description">
  //           <div className="item__position">
  //             Contributor
  //           </div>
  //           <div className="item__name investhub-item__name" title="@0xEcB438375dCAd215C3198293332B6b19de48aC79">
  //             @0xEcB438375dCAd215C3198293332B6b19de48aC79
  //           </div>
  //         </div>
  //       </div>

  //       <div className="item__user investhub-item__user">
  //         <div className="item__avatar">
  //           <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
  //         </div>

  //         <div className="item__description">
  //           <div className="item__position">
  //             Creator
  //           </div>
  //           <div className="item__name investhub-item__name" title="@nakamoto">
  //             @nakamoto
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )

  return (
    <>
      <BreadcrumbsSimple>
        <li className="breadcrumbs__item">
          <NavLink to="/" className="breadcrumbs__link">{t('Home')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          <NavLink to="/opportunity" className="breadcrumbs__link">{t('Opportunity')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {opportunity.name}
        </li>
      </BreadcrumbsSimple>

      <div className="section item">
        <div className="item__center center no-padding__bottom">
          <div className="upload__head">
            <div className="upload__title h2">
              {opportunity.name}
            </div>
          </div>
        </div>

        <div className="item__center center">
          <div className="item__bg">
            <div className="item__preview item__preview-ratio">
              {/* <img src="https://via.placeholder.com/640x768.png" alt="preview" /> */}
              <iframe width="560" height="315" src={opportunity.video_embed_url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="item__preview-video" />
            </div>
          </div>

          <div className="item__details">
            <h3 className="item__title h3">{t('Descriptions')}</h3>

            <div className="item__text" style={{ marginBottom: '24px', marginTop: '24px' }}>
              {opportunity.description}
            </div>

            <div className="item__control no-margin__top" style={{ marginBottom: '48px' }}>
              <div className="item__head">
                <div className="item__avatar">
                  {
                    opportunity.owner.fullname ? (
                      <img
                        src={getTextAvatarUrl(opportunity.owner.fullname)}
                        alt={`${opportunity.owner.fullname} avatar`}
                      />
                    ) : <img src="https://via.placeholder.com/56x56.png" alt="Avatar" />
                  }
                </div>

                <div className="item__description">
                  <div className="item__info">
                    {t('Opportunity scouted by')}
                    {' '}
                    <span>
                      {opportunity.owner.fullname}
                    </span>
                  </div>
                </div>
              </div>

              <div className="item__variants no-margin__top" style={{ paddingTop: '16px' }}>
                {`@${opportunity.owner.user_name}`}
              </div>
            </div>

            <div className="item__tabs js-tabs">
              <div className="item__nav">
                {opportunityTabNav.map((x) => (
                  <button
                    type="button"
                    key={uuid()}
                    className={`item__link js-tabs-link ${x === activeOpportunityTab ? 'active' : ''}`}
                    onClick={() => setActiveOpportunityTab(x)}
                  >
                    {x}
                  </button>
                ))}
              </div>

              <div className="item__container">
                {activeOpportunityTab === opportunityTabNav[0] && renderTabContentHistory()}
                {/*
                {activeOpportunityTab === opportunityTabNav[1] && renderTabContentBids()}

                {activeOpportunityTab === opportunityTabNav[2] && renderTabContentDetails()} */}
              </div>
            </div>

            <div className="main__wrap investhub-main__wrap">
              <div className="main__info">
                {t('Minimum subscription')}
              </div>
              <div className="main__currency">
                {opportunity.minimum_subscription}
                {' '}
                {opportunity.currency.toUpperCase()}
              </div>

              <div className="main__info investhub-main__info">
                {t('Opportunity Ends in')}
              </div>

              {opportunity.end_date && <CountingDown expiryTimestamp={parseInt(opportunity.end_date, 10)} />}
            </div>

            <div className="main__btns" style={{ marginTop: '0px' }}>
              <button type="button" className="button main__button js-popup-open" onClick={() => setOpenCommitmentDialog(true)}>{t('Place commitment now')}</button>
            </div>

            <div className="sharelove" style={{ marginTop: '32px', display: 'none' }}>
              <button className="button-stroke sharelove__like" type="button">
                <Icon
                  name="heart-fill"
                  className={classNames('heart-fill')}
                  size="20"
                />
                105
              </button>

              <div className="sharelove__socials">
                <button className="button-circle-stroke sharelove__social" type="button">
                  <Icon
                    name="twitter"
                    className={classNames('twitter')}
                    size="20"
                  />
                </button>

                <button className="button-circle-stroke sharelove__social" type="button">
                  <Icon
                    name="facebook"
                    className={classNames('facebook')}
                    size="20"
                  />
                </button>

                <button className="button-circle-stroke sharelove__social" type="button">
                  <Icon
                    name="pinterest"
                    className={classNames('pinterest')}
                    size="20"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="item__center center flex-row flex-wrap" style={{ marginTop: '48px' }}>
          <div className="opportunity-table">
            <div className="opportunity-table__row tablet-hide">
              <div className="opportunity-table__col main__time">{t('Total amount committed')}</div>
              {/* <div className="opportunity-table__col main__time">Nationalities</div> */}
              <div className="opportunity-table__col main__time">{t('Investors')}</div>
              <div className="opportunity-table__col main__time">{t('Location of SPV')}</div>
              <div className="opportunity-table__col main__time">{t('Sector')}</div>
              <div className="opportunity-table__col main__time">{t('SPV Lead')}</div>
            </div>

            <div className="opportunity-table__row">
              <div className="opportunity-table__col">
                <div className="investhub-table__label">
                  {t('Total amount committed')}
                </div>
                <div className="main__number">
                  {formatPrintNumber(+totalCommitment)}
                  {' '}
                  {opportunity.currency.toUpperCase()}
                </div>
              </div>

              {/* <div className="opportunity-table__col">
                <div className="investhub-table__label">
                  Nationalities
                </div>
                <div className="main__number">
                  36
                </div>
              </div> */}

              <div className="opportunity-table__col">
                <div className="investhub-table__label">
                  {t('Investors')}
                </div>
                <div className="main__number">
                  {opportunity.allOpportunityCommitment && opportunity.allOpportunityCommitment.length}
                </div>
              </div>

              <div className="opportunity-table__col">
                <div className="investhub-table__label">
                  {t('Location of SPV')}
                </div>
                <div className="main__number">
                  {opportunity.location}
                </div>
              </div>

              <div className="opportunity-table__col">
                <div className="investhub-table__label">
                  {t('Sector')}
                </div>
                <div className="main__number">
                  {opportunity.sector}
                </div>
              </div>

              <div className="opportunity-table__col">
                <div className="investhub-table__label">
                  {t('SPV Lead')}
                </div>
                <div>
                  <div className="item__name investhub-item__name">
                    {`@${opportunity.owner.user_name} +`}
                  </div>

                  <div className="item__action" title={opportunity.owner_wallet}>
                    {opportunity.owner_wallet}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opportunity-progress flex flex-col flex-nowrap justify-center items-center">
            <div className="opportunity-progress__bar">
              <span style={{ width: `${commitmentPercent}%` }}>{' '}</span>
            </div>
            <div className="opportunity-progress__info main__time">
              {roundNumber(commitmentPercent)}
              {t('% committed')}
            </div>
          </div>
        </div>

        <div className="item__center center opportunity-content">
          {/* <h2 className="item__title h3">Description</h2>

          <img src="https://via.placeholder.com/1440x768.png" alt="description content" className="img-full img-rounded" />

          <h2 className="item__title h3">Lorem ipsum dolor sit amet</h2>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            {' '}
            Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
          </p>

          <blockquote>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              {' '}
              Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.
            </p>
          </blockquote>

          <h2 className="item__title h3">Deal</h2>

          <ol>
            <li>Type of offer: SAFE</li>
            <li>Pre money valuation: 8,000,000 USDC</li>
            <li>Post money valuation: 10,000,000 USDC</li>
            <li>Cap table: Investor 1, 15%, Investor 2, 8%, Investor 4, 12%</li>
            <li>Funding need: 750,000 USDC</li>
            <li>
              Conversion: 4 years, or when priced round happens. Once this happens,
              {' '}
              your SAFE Token will be converted into a Security and a SPV will be incorporated.
            </li>
          </ol>

          <h2 className="item__title h3">Stripe Data</h2>
          <OpportunityChart />

          <h2 className="item__title h3">Google Analytics</h2>
          <img src="https://via.placeholder.com/1007x505.png" alt="Google Analytics" />

          <h2 className="item__title h3">Repository</h2>
          <img src="https://via.placeholder.com/1119x221.png" alt="Repository" />

          <h2 className="item__title h3">Documents</h2>
          <div className="opportunity-download flex flex-row flex-wrap justify-start items-center">
            <span>
              <Icon
                name="download"
                className={classNames('download')}
                size="20"
              />
            </span>
            Filename.doc (either link from google drive or uploaded when opportunity created)
          </div> */}

          <h2 className="item__title h3">Dataroom</h2>
          {t('Click')}
          {' '}
          <a rel="noopener noreferrer" href={`${opportunity.tenant_url}/data-center`} target="_blank">{t('here')}</a>
          {' '}
          {t('to be forwarded to the Dataroom. (Registration required)')}
        </div>
      </div>

      <Dialog
        open={openCommitmentDialog}
        onClose={() => setOpenCommitmentDialog(false)}
        className="investhub-modal investhub-modal__m"
      >
        <ModalInnerMaterialDialog
          modalTitle={t("Place a Commitment")}
          modalOnClickClose={() => setOpenCommitmentDialog(false)}
        >
          <CreateCommitmentForm opportunity={opportunity} />
        </ModalInnerMaterialDialog>

      </Dialog>
    </>
  )
}

Opportunity.propTypes = {
  match: PropTypes.object,
}

export default Opportunity
