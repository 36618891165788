import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { omit } from 'lodash'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { toaster } from '../utils'
import {
  PUBLIC_SETTINGS,
  REGISTRATION,
} from '../queriesAndMutations'

const SignUpForm = ({
  onRegistrationComplete,
}) => {
  const { t } = useTranslation()
  const [values, setValues] = useState({
    email: '',
    fullname: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    phone: '',
    companyAddress: '',
    desiredUrl: '',
    agree: false,
  })

  const setField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }
  const setCheckBox = (fieldName) => ({ target: { checked } }) => {
    setValues({
      ...values,
      [fieldName]: checked,
    })
  }

  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const [register, registerStatus] = useMutation(REGISTRATION)

  const submitRegistration = async () => {
    if (values.password !== values.confirmPassword) {
      toaster.error(t('Password confirmation mismatched.'))
      return
    }

    try {
      await register({
        variables: {
          input: omit(values, ['agree', 'confirmPassword']),
        },
      })

      localStorage.setItem('desiredUrl', values.desiredUrl)
      localStorage.setItem('companyName', values.companyName)

      if (onRegistrationComplete) {
        onRegistrationComplete()
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submitRegistration()
        }}
        className="variants__list signinup__form"
      >
        <div className="variants__item signinup__inner">
          <div className="details__fieldset">
            <TextField
              required
              label={t('Email')}
              className="investhub__field field"
              placeholder={t('Enter Email Address')}
              margin="normal"
              value={values.email}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={setField('email')}
            />

            <TextField
              required
              label={t('Name')}
              className="investhub__field field"
              placeholder={t('Enter Full Name')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.fullname}
              onChange={setField('fullname')}
            />

            <TextField
              required
              type="password"
              label={t('Password')}
              className="investhub__field field"
              placeholder={t('Enter Password')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.password}
              onChange={setField('password')}
            />

            <TextField
              required
              type="password"
              label={t('Confirm password')}
              className="investhub__field field"
              placeholder={t('Enter Confirm Password')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.confirmPassword}
              onChange={setField('confirmPassword')}

            />

            <TextField
              required
              label={t('Company Name')}
              className="investhub__field field"
              placeholder={t('Enter Company Name')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.companyName}
              onChange={setField('companyName')}
            />

            <TextField
              required
              label={t('Phone Number')}
              className="investhub__field field"
              placeholder={t('Enter Phone Number')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.phone}
              onChange={setField('phone')}
            />

            <TextField
              required
              label={t('Company Address')}
              className="investhub__field field"
              placeholder={t('Enter Company Address')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.companyAddress}
              onChange={setField('companyAddress')}
            />

            <TextField
              required
              label={t('Desired Url')}
              className="investhub__field field field-has__url"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              value={values.desiredUrl}
              onChange={setField('desiredUrl')}
              InputProps={{
                endAdornment: publicSettings && publicSettings.cloudflare.baseDomain && (
                  <InputAdornment position="end">
                    {publicSettings.cloudflare.baseDomain}
                  </InputAdornment>
                ),
              }}
            />

            <div className="field__group">
              <FormControlLabel
                className="field field__full field__checkbox"
                label={(
                  <>
                    {t('I agree to InvesthubDAOs ')}
                    <NavLink to="/privacy-policy" target="_blank">{t('Privacy Policy')}</NavLink>
                    {t(' & ')}
                    <NavLink to="/tos" target="_blank">{t('Terms')}</NavLink>
                  </>
                )}
                control={(
                  <Checkbox
                    values="yes"
                    checked={values.agree}
                    onChange={setCheckBox('agree')}
                    color="primary"
                  />
                )}
              />
            </div>
          </div>

          <div className="details__btns signinup__btns">
            { registerStatus.loading
              ? (
                <CircularProgress />
              ) : (
                <button
                  className="button details__button signinup__button"
                  disabled={!values.agree}
                  type="submit"
                >
                  {t('Sign up Now')}
                </button>
              )}
          </div>

        </div>
      </form>

      <div className="variants__note signinup__note">
        {t('Do you have an account already?')}
        {' '}
        <NavLink to="/login">{t('Login now')}</NavLink>
      </div>
    </>
  )
}

SignUpForm.propTypes = {
  onRegistrationComplete: PropTypes.func,
}

export default SignUpForm
