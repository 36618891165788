import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ControlBreadcrumbs from '../components/ControlBreadcrumbs'
import MyProfileForm from '../components/MyProfile/MyProfileForm'
import TwoFactorAuth from '../components/MyProfile/TwoFactorAuth'

const MyProfile = () => {
  const { t } = useTranslation()

  return (
    <>
      <ControlBreadcrumbs>
        <li className="breadcrumbs__item">
          <NavLink to="/dashboard" className="breadcrumbs__link">{t('Dashboard')}</NavLink>
        </li>
        <li className="breadcrumbs__item">
          {t('Profile')}
        </li>
      </ControlBreadcrumbs>

      <div className="section-pt80 faq discover tenant">
        <div className="discover__center tenant__center center">
          <div className="faq__top tenant__top">
            <div className="faq__title tenant__title h2">{t('My Profile')}</div>
          </div>

          <div className="faq__row tenant__row">
            <div className="faq__col tenant__col">
              <MyProfileForm />

              <hr className="learn-content__line" />

              <TwoFactorAuth />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MyProfile
