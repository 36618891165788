import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useMutation } from '@apollo/react-hooks'
import { useCookies } from 'react-cookie'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FormControlLabel,
  Checkbox,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import AuthClient, { generateNonce } from "@walletconnect/auth-client"
import QRCodeModal from "@walletconnect/qrcode-modal"
import _ from "lodash"
import { toaster } from '../utils'

import config from '../config'
import {
  IS_2FA_NEEDED,
  LOGIN,
  WALLET_SIGN_IN,
} from '../queriesAndMutations'

const LoginForm = ({
  onSuccess,
}) => {
  const { t } = useTranslation()
  const [, setCookie] = useCookies()
  const [require2Fa, setRequire2Fa] = useState(false)
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState(null)
  const [hasInitialized, setHasInitialized] = useState(false)
  const [openQRModel, setOpenQRModel] = useState(false)
  const [walletSignIn, walletSignInStatus] = useMutation(WALLET_SIGN_IN)

  const [values, setValues] = useState({
    email: localStorage.getItem('email') || '',
    password: '',
    twoFaToken: '',
  })

  useEffect(() => {
    AuthClient.init({
      projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
      metadata: {
        name: "react-dapp-auth",
        description: "React Example Dapp for Auth",
        url: window.location.host,
        icons: ["https://dev.investhub.io/favicon.png"],
      },
    })
      .then((authClient) => {
        setClient(authClient)
        setHasInitialized(true)
      })
      // eslint-disable-next-line no-console
      .catch(console.error)
  }, [])

  const onWalletSignIn = async (params) => {
    const wallet = params.result.p.iss.split(":")[4]

    const { data } = await walletSignIn({
      variables: {
        input: {
          wallet,
          message: params.result.p.statement,
          signedMessage: params.result.s.s,
          network: 'mainnet',
        },
      },
    })

    setCookie(config.jwtCookie, data.walletSignIn.accessToken, { path: '/' })
    localStorage.setItem('email', `${wallet}@example.com`)

    onSuccess(data.walletSignIn.accessToken)
  }

  useEffect(() => {
    if (!client) return

    client.on("auth_response", ({ params }) => {
      // eslint-disable-next-line no-console
      console.log('params', params)
      if ("code" in params) {
        // eslint-disable-next-line no-console
        console.error(params)
        return
      }
      if ("error" in params) {
        // eslint-disable-next-line no-console
        console.error(params.error)
        toaster.error(params.error.message)
        return
      }
      toaster.success("Auth request successfully approved")
      QRCodeModal.close()
      setOpenQRModel(false)
      onWalletSignIn(params)
    })

    // eslint-disable-next-line
  }, [client])

  const onSignIn = () => {
    if (!client) return

    client
      .request({
        aud: window.location.href,
        domain: window.location.hostname,
        chainId: "eip155:1",
        nonce: generateNonce(),
        statement: `${(new Date()).getTime()} - Investhub.io - Sign in with wallet.`,
      })
      .then(({ uri }) => {
        setOpenQRModel(true)
        QRCodeModal.open(uri, () => {
          setOpenQRModel(false)
        })
      })
  }

  const setField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }
  const setCheckBox = (fieldName) => ({ target: { checked } }) => {
    setValues({
      ...values,
      [fieldName]: checked,
    })
  }

  const [login] = useMutation(LOGIN)

  const getIs2FaNeeded = async () => {
    const response = await axios.post(config.endpoint, {
      query: IS_2FA_NEEDED.loc.source.body,
      variables: { email: values.email },
    })

    return response.data.data.is2FaNeeded
  }

  const submitLogin = async () => {
    try {
      const { data } = await login({
        variables: {
          input: _.omit(values, ['rememberMe']),
        },
      })

      setCookie(config.jwtCookie, data.login.accessToken, { path: '/' })
      localStorage.setItem('email', values.email)

      if (values.rememberMe) {
        localStorage.setItem(config.jwtCookie, data.login.accessToken)
      }

      onSuccess(data.login.accessToken)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  if (openQRModel) {
    return ''
  }

  return (
    <>
      <form
        onSubmit={async (event) => {
          event.preventDefault()
          setLoading(true)

          const is2FaNeeded = await getIs2FaNeeded()

          if (is2FaNeeded === require2Fa) {
            try {
              await submitLogin()
            } catch (error) {}
          } else {
            setRequire2Fa(is2FaNeeded)
          }
          setLoading(false)
        }}
        className="variants__list signinup__form"
      >
        <div className="variants__item signinup__inner">
          <div className="details__fieldset">
            <TextField
              required
              value={values.email}
              onChange={setField('email')}
              label={t('Email')}
              className="investhub__field field"
              placeholder={t('Enter Email Address')}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              required
              type="password"
              label={t('Password')}
              value={values.password}
              onChange={setField('password')}
              className="investhub__field field"
              placeholder={t('Enter Password')}
              InputLabelProps={{
                shrink: true,
              }}
            />

            {require2Fa && (
              <TextField
                required
                label={t('2FA code')}
                value={values.twoFaToken}
                onChange={setField('twoFaToken')}
                className="investhub__field field"
                placeholder={t('Enter 2FA code')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}

            <div className="field__group">
              <FormControlLabel
                className="field field__checkbox"
                label={t('Remember me')}
                control={(
                  <Checkbox
                    values="yes"
                    checked={values.rememberMe}
                    onChange={setCheckBox('rememberMe')}
                    color="primary"
                  />
                )}
              />

              <div className="field field__link text-right">
                <NavLink to="/restore-password">{t('Forgot Password')}</NavLink>
              </div>
            </div>
          </div>

          <div className="details__btns signinup__btns">
            { loading || walletSignInStatus.loading
              ? (
                <CircularProgress />
              ) : (
                <button
                  className="button details__button signinup__button"
                  type="submit"
                >
                  {t('Sign in')}
                </button>
              )}
          </div>

          { hasInitialized && (
            <div className="details__btns signinup__btns">
              <button
                className="button details__button signinup__button"
                type="button"
                onClick={onSignIn}
              >
                {t('Login via WalletConnect')}
              </button>
            </div>
          )}
        </div>
      </form>

      <div className="variants__note signinup__note">
        {t('Do not have an account ?')}
        {' '}
        <NavLink to="/signup">{t('Signup')}</NavLink>
      </div>
    </>
  )
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default LoginForm
