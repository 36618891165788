import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import {
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { toaster, isCrypto } from '../../utils'
import {
  CREATE_OPPORTUNITY_COMMITMENT,
  PUBLIC_SETTINGS,
} from '../../queriesAndMutations'
import metamaskService from '../../services/metamask'
import { roundNumber } from '../../utils/number'

const CreateCommitmentForm = ({
  opportunity,
}) => {
  // const history = useHistory()
  const { t } = useTranslation()
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [createOpportunityCommitment, createOpportunityCommitmentStatus] = useMutation(CREATE_OPPORTUNITY_COMMITMENT)
  const [acceptTerm, setAcceptTerm] = useState(false)

  const [values, setValues] = useState({
    currency: '',
    amount: '',
    total_amount: '',
    email: '',
    wallet_address: '',
  })

  const checkoutViaStripe = () => {
    createOpportunityCommitment({
      variables: {
        input: {
          ...values,
          opportunity_id: opportunity.id,
        },
      },
    }).then((response) => {
      const result = response.data.createOpportunityCommitment
      if (result.success) {
        window.location.href = result.data.redirectUrl
      } else {
        toaster.error(result.message)
        setDisableSubmit(false)
      }
    }).catch(() => {
      setDisableSubmit(false)
    })
  }

  const checkoutViaCrypto = async () => {
    const message = t('Please sign this message to confirm the ownership of provided wallet')

    try {
      await metamaskService.requestAccounts()

      const signature = await metamaskService.signMessage(
        metamaskService.getCurrentAddress(),
        message,
      )
      const signerAddress = await metamaskService.getSigner(signature, message)

      if (signerAddress === values.wallet_address.toLowerCase()) {
        createOpportunityCommitment({
          variables: {
            input: {
              ...values,
              opportunity_id: opportunity.id,
            },
          },
        }).then((response) => {
          const result = response.data.createOpportunityCommitment
          if (result.success) {
            window.location.href = `/opportunity/success?commitment_id=${result.commitment.id}`
          } else {
            toaster.error(result.message)
          }
        }).catch(() => {})
      } else {
        toaster.error(t('The wallet you provided does not match with the current wallet on Metamask.'))
      }

      setDisableSubmit(false)
    } catch (error) {
      switch (error.code) {
        case 4001:
          toaster.error(t('You need to select at least the account with the provided address to connect'))
          break
        default:
          toaster.error(error.message)
          break
      }
      setDisableSubmit(false)
    }
  }

  const onSubmit = () => {
    if (!acceptTerm) {
      return toaster.error(t('You need to accept the Terms & Condition'))
    }

    setDisableSubmit(true)
    if (isCrypto(values.currency)) {
      return checkoutViaCrypto()
    }
    return checkoutViaStripe()
  }

  const onChangeCommitment = (event) => {
    const fee = roundNumber(publicSettings.others.opportunity_fee * parseFloat(event.target.value))
    setValues({
      ...values,
      amount: parseFloat(event.target.value),
      total_amount: parseFloat(event.target.value) + fee,
    })
  }

  return (
    <>
      <div className="popup__info">
        {t('You are about to place a commitment for Opportunity')}
        {' '}
        <strong>
          {opportunity.name}
        </strong>
        {' '}
        {t('by Scout')}
        {' '}
        <strong>
          {opportunity.owner.fullname}
        </strong>
        .
      </div>

      <div className="radio-button__group">
        { ['chf', 'eur', 'usdc', 'usdt', 'dai', 'eure'].map((currency) => (
          <div className="radio-button__wrap" key={currency}>
            <input
              type="radio"
              name="currency"
              value={currency}
              className="radio-button__field"
              id={currency}
              onChange={() => setValues({ ...values, currency })}
            />
            <div className="radio-button__text">
              {currency === 'eure' ? 'EURe' : currency.toUpperCase()}
            </div>
          </div>
        ))}
      </div>

      <div className="popup__table" style={{ marginTop: '32px' }}>
        <div className="popup__row">
          <div className="popup__col full-popup__col">
            <TextField
              type="text"
              required
              className="investhub__field field field-has__text"
              margin="normal"
              label={t("Your email")}
              onChange={(event) => setValues({ ...values, email: event.target.value })}
            />
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col full-popup__col">
            <TextField
              type="number"
              required
              className="investhub__field field field-has__text"
              margin="normal"
              label={t("Your commitment")}
              onChange={onChangeCommitment}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <span>
                      {values.currency.toUpperCase()}
                    </span>
                  </InputAdornment>
                ),
              }}
              helperText={`${t('Must be at least')} ${opportunity.minimum_subscription} ${opportunity.currency.toUpperCase()}`}
            />
          </div>
        </div>

        { isCrypto(values.currency) && (
          <div className="popup__row">
            <div className="popup__col full-popup__col">
              <TextField
                type="text"
                required
                className="investhub__field field field-has__text"
                margin="normal"
                label={t("Your wallet")}
                onChange={(event) => setValues({ ...values, wallet_address: event.target.value })}
              />
            </div>
          </div>
        )}

        <div className="popup__row">
          <div className="popup__col">
            <div className="field__label">
              {t('Your commitment')}
            </div>
          </div>
          <div className="popup__col">
            {`${values.amount} ${values.currency.toUpperCase()}`}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">
            <div className="field__label">
              {t('Platform fee')}
            </div>
          </div>
          <div className="popup__col">
            {values.amount ? `${roundNumber(publicSettings.others.opportunity_fee * parseFloat(values.amount))} ${values.currency.toUpperCase()}` : ''}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col">
            <div className="field__label">{t('Total commitment amount')}</div>
          </div>
          <div className="popup__col">
            {`${values.total_amount} ${values.currency.toUpperCase()}`}
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col full-popup__col text-center">
            <FormControlLabel
              className="field field__checkbox"
              onChange={() => setAcceptTerm(!acceptTerm)}
              label={(
                <>
                  {t('I agree to the')}
                  {' '}
                  <a className="link" rel="noopener noreferrer" href={`${opportunity.tenant_url}/terms`} target="_blank">{t('Terms & Condition')}</a>
                </>
              )}
              control={(
                <Checkbox
                  values="yes"
                  color="primary"
                />
              )}
            />
          </div>
        </div>

        <div className="popup__row">
          <div className="popup__col full-popup__col text-center">
            {createOpportunityCommitmentStatus.loading || disableSubmit ? (
              <CircularProgress />
            ) : (
              <Button onClick={onSubmit} variant="contained" color="primary">
                {t('Place commitment')}
              </Button>
            )}

          </div>
        </div>
      </div>

      <div className="item__time text-center" style={{ marginTop: '32px' }}>
        {t('You are required to pay your placement within 7-14 days once the deal materialise. You will get another Email for this.')}
      </div>
    </>
  )
}

CreateCommitmentForm.propTypes = {
  opportunity: PropTypes.object.isRequired,
}

export default CreateCommitmentForm
