import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const Achievement = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames("section", styles.achievement)}>
      <div className={classNames("center", styles.achievement__center)}>
        <h2 className={classNames("h3 text-center", styles.achievement__title)}>
          {t("Our Achievements")}
          {' '}
          <small>{t('(since 2016)')}</small>
        </h2>

        <div className={styles.achievement__list}>
          <div className={classNames(styles.achievement__item, styles.achievement__item_line)}>
            <div className={classNames(styles.achievement__item_inner)}>
              <h3 className={styles.achievement__item_title}>88%</h3>
              <div className={styles.achievement__item_text}>{t("Reduction of legal and compliance costs for Token Issuers.")}</div>
            </div>
          </div>

          <div className={classNames(styles.achievement__item, styles.achievement__item_line)}>
            <div className={classNames(styles.achievement__item_inner)}>
              <h3 className={styles.achievement__item_title}>{t("Reviewed")}</h3>
              <div className={styles.achievement__item_text}>{t("Our Engine has been reviewed  by Compliance people all over the world and a Top 4 accounting firm.")}</div>
            </div>
          </div>

          <div className={styles.achievement__item}>
            <div className={styles.achievement__item_inner}>
              <h3 className={styles.achievement__item_title}>{t("Pay as you go")}</h3>
              <div className={styles.achievement__item_text}>{t("We dont care if you onboard 1 or 10.000 Investors. No upfront costs. 4 ever, promised.")}</div>
            </div>
          </div>

          <div className={classNames(styles.achievement__item, styles.achievement__item_line)}>
            <div className={classNames(styles.achievement__item_inner)}>
              <h3 className={styles.achievement__item_title}>{t("Payments")}</h3>
              <div className={styles.achievement__item_text}>{t("Sign documents digitally (Signnow, Skribble) and recieve payments with auto reconsiliation.")}</div>
            </div>
          </div>

          <div className={classNames(styles.achievement__item, styles.achievement__item_line)}>
            <div className={classNames(styles.achievement__item_inner)}>
              <h3 className={styles.achievement__item_title}>{t("Tokenisation")}</h3>
              <div className={styles.achievement__item_text}>{t("Equity, Bonds, SAFEs, SPVs, you name it we did it. Issue your Tokens on multiple chains.")}</div>
            </div>
          </div>

          <div className={styles.achievement__item}>
            <div className={styles.achievement__item_inner}>
              <h3 className={styles.achievement__item_title}>{t("Learn")}</h3>
              <div className={styles.achievement__item_text}>{t("We provide most of our knowledge online, for you free of charge.")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Achievement
