import React from 'react'
import classNames from 'classnames'
import * as ReactDOMServer from 'react-dom/server'
import { useTranslation } from 'react-i18next'

import "react-tooltip/dist/react-tooltip.css"
import { Tooltip as ReactTooltip } from "react-tooltip"

import Countries from '../../../media/images/countries/countries'
import styles from './styles.module.scss'

const WeAreActive = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames("section", styles.weareactive)}>
      <div className={classNames("center", styles.weareactive__center)}>
        <h2 className={classNames("h3 text-center", styles.weareactive__title)}>
          {t("Where we are active, and will be in future.")}
        </h2>

        <div className={styles.weareactive__text}>
          {t("Through our Community approach we are able to quickly scale in future jurisdictions and adopt to local regulation change.")}
        </div>
      </div>

      <div className={styles.weareactive__dotmap}>
        <svg width="1400" height="800" viewBox="0 0 1400 800" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M696.699 403.7H689.699L686.199 397.7L689.699 391.7H696.699L700.099 397.7L696.699 403.7Z" fill="white" opacity="0.3" />
            <path d="M683.599 411.3H676.699L673.199 405.2L676.699 399.2H683.599L687.099 405.2L683.599 411.3Z" fill="white" opacity="0.3" />
            <path d="M696.699 418.8H689.699L686.199 412.8L689.699 406.7H696.699L700.099 412.8L696.699 418.8Z" fill="white" opacity="0.3" />
            <path d="M709.701 411.3H702.801L699.301 405.2L702.801 399.2H709.701L713.201 405.2L709.701 411.3Z" fill="white" opacity="0.3" />
            <path d="M709.701 396.2H702.801L699.301 390.2L702.801 384.1H709.701L713.201 390.2L709.701 396.2Z" fill="white" opacity="0.3" />
            <path d="M696.699 388.7H689.699L686.199 382.6L689.699 376.6H696.699L700.099 382.6L696.699 388.7Z" fill="white" opacity="0.3" />
            <path d="M683.599 396.2H676.699L673.199 390.2L676.699 384.1H683.599L687.099 390.2L683.599 396.2Z" fill="white" opacity="0.3" />
            <path d="M670.6 418.8H663.6L660.1 412.8L663.6 406.7H670.6L674 412.8L670.6 418.8Z" fill="white" opacity="0.3" />
            <path d="M683.599 426.3H676.699L673.199 420.3L676.699 414.3H683.599L687.099 420.3L683.599 426.3Z" fill="white" opacity="0.3" />
            <path d="M696.699 433.9H689.699L686.199 427.8L689.699 421.8H696.699L700.099 427.8L696.699 433.9Z" fill="white" opacity="0.3" />
            <path d="M709.701 426.3H702.801L699.301 420.3L702.801 414.3H709.701L713.201 420.3L709.701 426.3Z" fill="white" opacity="0.3" />
            <path d="M722.801 418.8H715.801L712.301 412.8L715.801 406.7H722.801L726.301 412.8L722.801 418.8Z" fill="white" opacity="0.3" />
            <path d="M722.801 403.7H715.801L712.301 397.7L715.801 391.7H722.801L726.301 397.7L722.801 403.7Z" fill="white" opacity="0.3" />
            <path d="M722.801 388.7H715.801L712.301 382.6L715.801 376.6H722.801L726.301 382.6L722.801 388.7Z" fill="white" opacity="0.3" />
            <path d="M709.701 381.1H702.801L699.301 375.1L702.801 369.1H709.701L713.201 375.1L709.701 381.1Z" fill="white" opacity="0.3" />
            <path d="M696.699 373.6H689.699L686.199 367.6L689.699 361.5H696.699L700.099 367.6L696.699 373.6Z" fill="white" opacity="0.3" />
            <path d="M683.599 381.1H676.699L673.199 375.1L676.699 369.1H683.599L687.099 375.1L683.599 381.1Z" fill="white" opacity="0.3" />
            <path d="M670.6 388.7H663.6L660.1 382.6L663.6 376.6H670.6L674 382.6L670.6 388.7Z" fill="white" opacity="0.3" />
            <path d="M670.6 403.7H663.6L660.1 397.7L663.6 391.7H670.6L674 397.7L670.6 403.7Z" fill="white" opacity="0.3" />
            <path d="M657.5 426.3H650.6L647.1 420.3L650.6 414.3H657.5L661 420.3L657.5 426.3Z" fill="white" opacity="0.3" />
            <path d="M670.6 433.9H663.6L660.1 427.8L663.6 421.8H670.6L674 427.8L670.6 433.9Z" fill="white" opacity="0.3" />
            <path d="M683.599 441.4H676.699L673.199 435.4L676.699 429.4H683.599L687.099 435.4L683.599 441.4Z" fill="white" opacity="0.3" />
            <path d="M696.699 448.9H689.699L686.199 442.9L689.699 436.9H696.699L700.099 442.9L696.699 448.9Z" fill="white" opacity="0.3" />
            <path d="M709.701 441.4H702.801L699.301 435.4L702.801 429.4H709.701L713.201 435.4L709.701 441.4Z" fill="white" opacity="0.3" />
            <path d="M722.801 433.9H715.801L712.301 427.8L715.801 421.8H722.801L726.301 427.8L722.801 433.9Z" fill="white" opacity="0.3" />
            <path d="M735.8 426.3H728.9L725.4 420.3L728.9 414.3H735.8L739.3 420.3L735.8 426.3Z" fill="white" opacity="0.3" />
            <path d="M735.8 411.3H728.9L725.4 405.2L728.9 399.2H735.8L739.3 405.2L735.8 411.3Z" fill="white" opacity="0.3" />
            <path d="M735.8 396.2H728.9L725.4 390.2L728.9 384.1H735.8L739.3 390.2L735.8 396.2Z" fill="white" opacity="0.3" />
            <path d="M735.8 381.1H728.9L725.4 375.1L728.9 369.1H735.8L739.3 375.1L735.8 381.1Z" fill="white" opacity="0.3" />
            <path d="M722.801 373.6H715.801L712.301 367.6L715.801 361.5H722.801L726.301 367.6L722.801 373.6Z" fill="white" opacity="0.3" />
            <path d="M709.701 366.1H702.801L699.301 360L702.801 354H709.701L713.201 360L709.701 366.1Z" fill="white" opacity="0.3" />
            <path d="M696.699 358.5H689.699L686.199 352.5L689.699 346.5H696.699L700.099 352.5L696.699 358.5Z" fill="white" opacity="0.3" />
            <path d="M683.599 366.1H676.699L673.199 360L676.699 354H683.599L687.099 360L683.599 366.1Z" fill="white" opacity="0.3" />
            <path d="M670.6 373.6H663.6L660.1 367.6L663.6 361.5H670.6L674 367.6L670.6 373.6Z" fill="white" opacity="0.3" />
            <path d="M657.5 381.1H650.6L647.1 375.1L650.6 369.1H657.5L661 375.1L657.5 381.1Z" fill="white" opacity="0.3" />
            <path d="M657.5 396.2H650.6L647.1 390.2L650.6 384.1H657.5L661 390.2L657.5 396.2Z" fill="white" opacity="0.3" />
            <path d="M657.5 411.3H650.6L647.1 405.2L650.6 399.2H657.5L661 405.2L657.5 411.3Z" fill="white" opacity="0.3" />
            <path d="M644.5 433.9H637.5L634 427.8L637.5 421.8H644.5L647.9 427.8L644.5 433.9Z" fill="white" opacity="0.3" />
            <path d="M657.5 441.4H650.6L647.1 435.4L650.6 429.4H657.5L661 435.4L657.5 441.4Z" fill="white" opacity="0.3" />
            <path d="M670.6 448.9H663.6L660.1 442.9L663.6 436.9H670.6L674 442.9L670.6 448.9Z" fill="white" opacity="0.3" />
            <path d="M683.599 456.5H676.699L673.199 450.5L676.699 444.4H683.599L687.099 450.5L683.599 456.5Z" fill="white" opacity="0.3" />
            <path d="M696.699 464H689.699L686.199 458L689.699 452H696.699L700.099 458L696.699 464Z" fill="white" opacity="0.3" />
            <path d="M709.701 456.5H702.801L699.301 450.5L702.801 444.4H709.701L713.201 450.5L709.701 456.5Z" fill="white" opacity="0.3" />
            <path d="M722.801 448.9H715.801L712.301 442.9L715.801 436.9H722.801L726.301 442.9L722.801 448.9Z" fill="white" opacity="0.3" />
            <path d="M735.8 441.4H728.9L725.4 435.4L728.9 429.4H735.8L739.3 435.4L735.8 441.4Z" fill="white" opacity="0.3" />
            <path d="M748.9 433.9H741.9L738.4 427.8L741.9 421.8H748.9L752.4 427.8L748.9 433.9Z" fill="white" opacity="0.3" />
            <path d="M748.9 418.8H741.9L738.4 412.8L741.9 406.7H748.9L752.4 412.8L748.9 418.8Z" fill="white" opacity="0.3" />
            <path d="M748.9 403.7H741.9L738.4 397.7L741.9 391.7H748.9L752.4 397.7L748.9 403.7Z" fill="white" opacity="0.3" />
            <path d="M748.9 388.7H741.9L738.4 382.6L741.9 376.6H748.9L752.4 382.6L748.9 388.7Z" fill="white" opacity="0.3" />
            <path d="M748.9 373.6H741.9L738.4 367.6L741.9 361.5H748.9L752.4 367.6L748.9 373.6Z" fill="white" opacity="0.3" />
            <path d="M735.8 366.1H728.9L725.4 360L728.9 354H735.8L739.3 360L735.8 366.1Z" fill="white" opacity="0.3" />
            <path d="M722.8 358.5H715.8L712.4 352.5L715.8 346.5H722.8L726.2 352.5L722.8 358.5Z" fill="white" opacity="0.3" />
            <path d="M709.7 350.9H702.8L699.4 345L702.8 339H709.7L713.1 345L709.7 350.9Z" fill="white" opacity="0.3" />
            <path d="M696.699 343.4H689.699L686.199 337.4L689.699 331.4H696.699L700.099 337.4L696.699 343.4Z" fill="white" opacity="0.3" />
            <path d="M683.599 351H676.699L673.199 345L676.699 338.9H683.599L687.099 345L683.599 351Z" fill="white" opacity="0.3" />
            <path d="M670.6 358.5H663.6L660.1 352.5L663.6 346.5H670.6L674 352.5L670.6 358.5Z" fill="white" opacity="0.3" />
            <path d="M657.5 366.1H650.6L647.1 360L650.6 354H657.5L661 360L657.5 366.1Z" fill="white" opacity="0.3" />
            <path d="M644.5 373.6H637.5L634 367.6L637.5 361.5H644.5L647.9 367.6L644.5 373.6Z" fill="white" opacity="0.3" />
            <path d="M644.5 388.7H637.5L634 382.6L637.5 376.6H644.5L647.9 382.6L644.5 388.7Z" fill="white" opacity="0.3" />
            <path d="M644.5 403.7H637.5L634 397.7L637.5 391.7H644.5L647.9 397.7L644.5 403.7Z" fill="white" opacity="0.3" />
            <path d="M644.5 418.8H637.5L634 412.8L637.5 406.7H644.5L647.9 412.8L644.5 418.8Z" fill="white" opacity="0.3" />
            <path d="M631.4 441.4H624.4L621 435.4L624.4 429.4H631.4L634.9 435.4L631.4 441.4Z" fill="white" opacity="0.3" />
            <path d="M644.5 448.9H637.5L634 442.9L637.5 436.9H644.5L647.9 442.9L644.5 448.9Z" fill="white" opacity="0.3" />
            <path d="M657.5 456.5H650.6L647.1 450.5L650.6 444.4H657.5L661 450.5L657.5 456.5Z" fill="white" opacity="0.3" />
            <path d="M670.6 464H663.6L660.1 458L663.6 452H670.6L674 458L670.6 464Z" fill="white" opacity="0.3" />
            <path d="M683.599 471.6H676.699L673.199 465.5L676.699 459.5H683.599L687.099 465.5L683.599 471.6Z" fill="white" opacity="0.3" />
            <path d="M696.699 479.1H689.699L686.199 473.1L689.699 467H696.699L700.099 473.1L696.699 479.1Z" fill="white" opacity="0.3" />
            <path d="M709.701 471.6H702.801L699.301 465.5L702.801 459.5H709.701L713.201 465.5L709.701 471.6Z" fill="white" opacity="0.3" />
            <path d="M722.801 464H715.801L712.301 458L715.801 452H722.801L726.301 458L722.801 464Z" fill="white" opacity="0.3" />
            <path d="M735.8 456.5H728.9L725.4 450.5L728.9 444.4H735.8L739.3 450.5L735.8 456.5Z" fill="white" opacity="0.3" />
            <path d="M748.9 448.9H741.9L738.4 442.9L741.9 436.9H748.9L752.4 442.9L748.9 448.9Z" fill="white" opacity="0.3" />
            <path d="M761.9 441.4H755L751.5 435.4L755 429.4H761.9L765.4 435.4L761.9 441.4Z" fill="white" opacity="0.3" />
            <path d="M761.9 426.3H755L751.5 420.3L755 414.3H761.9L765.4 420.3L761.9 426.3Z" fill="white" opacity="0.3" />
            <path d="M761.9 411.3H755L751.5 405.2L755 399.2H761.9L765.4 405.2L761.9 411.3Z" fill="white" opacity="0.3" />
            <path d="M761.9 396.2H755L751.5 390.2L755 384.1H761.9L765.4 390.2L761.9 396.2Z" fill="white" opacity="0.3" />
            <path d="M761.9 381.1H755L751.5 375.1L755 369.1H761.9L765.4 375.1L761.9 381.1Z" fill="white" opacity="0.3" />
            <path d="M761.9 366.1H755L751.5 360L755 354H761.9L765.4 360L761.9 366.1Z" fill="white" opacity="0.3" />
            <path d="M748.5 358H742.2L739.1 352.5L742.2 347H748.5L751.7 352.5L748.5 358Z" fill="white" opacity="0.3" />
            <path d="M707.201 331.6H705.301L704.301 329.9L705.301 328.2H707.201L708.201 329.9L707.201 331.6Z" fill="white" opacity="0.3" />
            <path d="M695.3 326.1H691L688.9 322.4L691 318.6H695.3L697.5 322.4L695.3 326.1Z" fill="white" opacity="0.3" />
            <path d="M683.5 335.7H676.8L673.4 329.9L676.8 324.1H683.5L686.8 329.9L683.5 335.7Z" fill="white" opacity="0.3" />
            <path d="M670.6 343.4H663.6L660.1 337.4L663.6 331.4H670.6L674 337.4L670.6 343.4Z" fill="white" opacity="0.3" />
            <path d="M657.5 351H650.6L647.1 345L650.6 338.9H657.5L661 345L657.5 351Z" fill="white" opacity="0.3" />
            <path d="M644.5 358.5H637.5L634 352.5L637.5 346.5H644.5L647.9 352.5L644.5 358.5Z" fill="white" opacity="0.3" />
            <path d="M631.4 366.1H624.4L621 360L624.4 354H631.4L634.9 360L631.4 366.1Z" fill="white" opacity="0.3" />
            <path d="M631.4 381.1H624.4L621 375.1L624.4 369.1H631.4L634.9 375.1L631.4 381.1Z" fill="white" opacity="0.3" />
            <path d="M631.4 396.2H624.4L621 390.2L624.4 384.1H631.4L634.9 390.2L631.4 396.2Z" fill="white" opacity="0.3" />
            <path d="M631.4 411.3H624.4L621 405.2L624.4 399.2H631.4L634.9 405.2L631.4 411.3Z" fill="white" opacity="0.3" />
            <path d="M631.4 426.3H624.4L621 420.3L624.4 414.3H631.4L634.9 420.3L631.4 426.3Z" fill="white" opacity="0.3" />
            <path d="M618.4 448.9H611.4L607.9 442.9L611.4 436.9H618.4L621.8 442.9L618.4 448.9Z" fill="white" opacity="0.3" />
            <path d="M631.4 456.5H624.4L621 450.5L624.4 444.4H631.4L634.9 450.5L631.4 456.5Z" fill="white" opacity="0.3" />
            <path d="M644.5 464H637.5L634 458L637.5 452H644.5L647.9 458L644.5 464Z" fill="white" opacity="0.3" />
            <path d="M657.5 471.6H650.6L647.1 465.5L650.6 459.5H657.5L661 465.5L657.5 471.6Z" fill="white" opacity="0.3" />
            <path
              d="M670.6 479.1H663.6L660.1 473.1L663.6 467H670.6L674 473.1L670.6 479.1Z"
              fill="#392DCA"
              id="nigeria"
              className="legend-map"
              data-tooltip-html={
                ReactDOMServer.renderToString(
                  <div className={styles.country__item}>
                    <img src={Countries.nigeria} alt="Nigeria" />
                    <span className={styles.country__title}>
                      {t('Nigeria')}
                    </span>
                  </div>,
                )
              }
            />
            <path d="M694.8 490.9H691.6L690 488.1L691.6 485.3H694.8L696.4 488.1L694.8 490.9Z" fill="white" opacity="0.3" />
            <path d="M709.701 486.6H702.801L699.301 480.6L702.801 474.6H709.701L713.201 480.6L709.701 486.6Z" fill="white" opacity="0.3" />
            <path d="M722.801 479.1H715.801L712.301 473.1L715.801 467H722.801L726.301 473.1L722.801 479.1Z" fill="white" opacity="0.3" />
            <path d="M735.8 471.6H728.9L725.4 465.5L728.9 459.5H735.8L739.3 465.5L735.8 471.6Z" fill="white" opacity="0.3" />
            <path d="M748.9 464H741.9L738.4 458L741.9 452H748.9L752.4 458L748.9 464Z" fill="white" opacity="0.3" />
            <path d="M761.9 456.5H755L751.5 450.5L755 444.4H761.9L765.4 450.5L761.9 456.5Z" fill="white" opacity="0.3" />
            <path d="M775 448.9H768L764.5 442.9L768 436.9H775L778.5 442.9L775 448.9Z" fill="white" opacity="0.3" />
            <path d="M775 433.9H768L764.5 427.8L768 421.8H775L778.5 427.8L775 433.9Z" fill="white" opacity="0.3" />
            <path d="M775 418.8H768L764.5 412.8L768 406.7H775L778.5 412.8L775 418.8Z" fill="white" opacity="0.3" />
            <path d="M775 403.7H768L764.5 397.7L768 391.7H775L778.5 397.7L775 403.7Z" fill="white" opacity="0.3" />
            <path d="M775 388.7H768L764.5 382.6L768 376.6H775L778.5 382.6L775 388.7Z" fill="white" opacity="0.3" />
            <path d="M775 373.6H768L764.5 367.6L768 361.5H775L778.5 367.6L775 373.6Z" fill="white" opacity="0.3" />
            <path d="M774.8 358.2H768.2L764.9 352.5L768.2 346.7H774.8L778.1 352.5L774.8 358.2Z" fill="white" opacity="0.3" />
            <path d="M760.001 347.7H756.901L755.301 345L756.901 342.2H760.001L761.601 345L760.001 347.7Z" fill="white" opacity="0.3" />
            <path d="M720.2 324H718.4L717.4 322.4L718.4 320.7H720.2L721.1 322.4L720.2 324Z" fill="white" opacity="0.3" />
            <path d="M695.1 310.6H691.3L689.4 307.3L691.3 304H695.1L697 307.3L695.1 310.6Z" fill="white" opacity="0.3" />
            <path d="M656.9 334.8H651.2L648.4 329.9L651.2 325H656.9L659.7 329.9L656.9 334.8Z" fill="white" opacity="0.3" />
            <path d="M644.4 343.4H637.5L634.1 337.4L637.5 331.5H644.4L647.9 337.4L644.4 343.4Z" fill="white" opacity="0.3" />
            <path d="M631.4 351H624.4L621 345L624.4 338.9H631.4L634.9 345L631.4 351Z" fill="white" opacity="0.3" />
            <path d="M616.999 356.1H612.799L610.699 352.5L612.799 348.9H616.999L618.999 352.5L616.999 356.1Z" fill="white" opacity="0.3" />
            <path d="M618.3 373.4H611.5L608.1 367.6L611.5 361.7H618.3L621.6 367.6L618.3 373.4Z" fill="white" opacity="0.3" />
            <path d="M618.4 388.7H611.4L607.9 382.6L611.4 376.6H618.4L621.8 382.6L618.4 388.7Z" fill="white" opacity="0.3" />
            <path d="M618.4 403.7H611.4L607.9 397.7L611.4 391.7H618.4L621.8 397.7L618.4 403.7Z" fill="white" opacity="0.3" />
            <path d="M618.4 418.8H611.4L607.9 412.8L611.4 406.7H618.4L621.8 412.8L618.4 418.8Z" fill="white" opacity="0.3" />
            <path d="M618.4 433.9H611.4L607.9 427.8L611.4 421.8H618.4L621.8 427.8L618.4 433.9Z" fill="white" opacity="0.3" />
            <path d="M605.3 456.5H598.3L594.9 450.5L598.3 444.4H605.3L608.8 450.5L605.3 456.5Z" fill="white" opacity="0.3" />
            <path d="M618.4 464H611.4L607.9 458L611.4 452H618.4L621.8 458L618.4 464Z" fill="white" opacity="0.3" />
            <path d="M631.4 471.6H624.4L621 465.5L624.4 459.5H631.4L634.9 465.5L631.4 471.6Z" fill="white" opacity="0.3" />
            <path d="M644.5 479.1H637.5L634 473.1L637.5 467H644.5L647.9 473.1L644.5 479.1Z" fill="white" opacity="0.3" />
            <path d="M657.1 485.9H650.9L647.9 480.6L650.9 475.3H657.1L660.2 480.6L657.1 485.9Z" fill="white" opacity="0.3" />
            <path d="M668.1 489.9H666.1L665.1 488.1L666.1 486.4H668.1L669.1 488.1L668.1 489.9Z" fill="white" opacity="0.3" />
            <path d="M694.5 505.4H691.9L690.6 503.2L691.9 501H694.5L695.8 503.2L694.5 505.4Z" fill="white" opacity="0.3" />
            <path d="M709.701 501.7H702.801L699.301 495.7L702.801 489.6H709.701L713.201 495.7L709.701 501.7Z" fill="white" opacity="0.3" />
            <path d="M722.801 494.2H715.801L712.301 488.1L715.801 482.1H722.801L726.301 488.1L722.801 494.2Z" fill="white" opacity="0.3" />
            <path d="M735.8 486.6H728.9L725.4 480.6L728.9 474.6H735.8L739.3 480.6L735.8 486.6Z" fill="white" opacity="0.3" />
            <path d="M748.9 479.1H741.9L738.4 473.1L741.9 467H748.9L752.4 473.1L748.9 479.1Z" fill="white" opacity="0.3" />
            <path d="M761.9 471.6H755L751.5 465.5L755 459.5H761.9L765.4 465.5L761.9 471.6Z" fill="white" opacity="0.3" />
            <path d="M775 464H768L764.5 458L768 452H775L778.5 458L775 464Z" fill="white" opacity="0.3" />
            <path d="M788 456.5H781.1L777.6 450.5L781.1 444.4H788L791.5 450.5L788 456.5Z" fill="white" opacity="0.3" />
            <path d="M788 441.4H781.1L777.6 435.4L781.1 429.4H788L791.5 435.4L788 441.4Z" fill="white" opacity="0.3" />
            <path d="M788 426.3H781.1L777.6 420.3L781.1 414.3H788L791.5 420.3L788 426.3Z" fill="white" opacity="0.3" />
            <path d="M788 411.3H781.1L777.6 405.2L781.1 399.2H788L791.5 405.2L788 411.3Z" fill="white" opacity="0.3" />
            <path d="M788 396.2H781.1L777.6 390.2L781.1 384.1H788L791.5 390.2L788 396.2Z" fill="white" opacity="0.3" />
            <path d="M788 381.1H781.1L777.6 375.1L781.1 369.1H788L791.5 375.1L788 381.1Z" fill="white" opacity="0.3" />
            <path d="M788 366.1H781.1L777.6 360L781.1 354H788L791.5 360L788 366.1Z" fill="white" opacity="0.3" />
            <path d="M760.6 333.7H756.3L754.1 329.9L756.3 326.1H760.6L762.8 329.9L760.6 333.7Z" fill="white" opacity="0.3" />
            <path d="M747.5 326.1H743.3L741.1 322.4L743.3 318.6H747.5L749.7 322.4L747.5 326.1Z" fill="white" opacity="0.3" />
            <path d="M720.399 309.1H718.199L717.199 307.3L718.199 305.4H720.399L721.399 307.3L720.399 309.1Z" fill="white" opacity="0.3" />
            <path d="M707.199 301.5H705.199L704.199 299.7L705.199 298H707.199L708.299 299.7L707.199 301.5Z" fill="white" opacity="0.3" />
            <path d="M695.699 296.5H690.699L688.199 292.2L690.699 287.9H695.699L698.199 292.2L695.699 296.5Z" fill="white" opacity="0.3" />
            <path d="M657.3 320.5H650.8L647.5 314.8L650.8 309.2H657.3L660.6 314.8L657.3 320.5Z" fill="white" opacity="0.3" />
            <path d="M643.599 326.9H638.299L635.699 322.4L638.299 317.8H643.599L646.299 322.4L643.599 326.9Z" fill="white" opacity="0.3" />
            <path d="M628.6 331.1H627.2L626.6 329.9L627.2 328.7H628.6L629.3 329.9L628.6 331.1Z" fill="white" opacity="0.3" />
            <path d="M603.8 378.5H599.9L597.9 375.1L599.9 371.7H603.8L605.7 375.1L603.8 378.5Z" fill="white" opacity="0.3" />
            <path d="M605.3 396.2H598.3L594.9 390.2L598.3 384.1H605.3L608.8 390.2L605.3 396.2Z" fill="white" opacity="0.3" />
            <path d="M605.3 411.3H598.3L594.9 405.2L598.3 399.2H605.3L608.8 405.2L605.3 411.3Z" fill="white" opacity="0.3" />
            <path d="M605.3 426.3H598.3L594.9 420.3L598.3 414.3H605.3L608.8 420.3L605.3 426.3Z" fill="white" opacity="0.3" />
            <path d="M605.3 441.4H598.3L594.9 435.4L598.3 429.4H605.3L608.8 435.4L605.3 441.4Z" fill="white" opacity="0.3" />
            <path d="M589.5 459.2H588.1L587.4 458L588.1 456.8H589.5L590.1 458L589.5 459.2Z" fill="white" opacity="0.3" />
            <path d="M605.3 471.5H598.4L595 465.5L598.4 459.6H605.3L608.7 465.5L605.3 471.5Z" fill="white" opacity="0.3" />
            <path d="M618.4 479.1H611.4L607.9 473.1L611.4 467H618.4L621.8 473.1L618.4 479.1Z" fill="white" opacity="0.3" />
            <path d="M631.4 486.6H624.4L621 480.6L624.4 474.6H631.4L634.9 480.6L631.4 486.6Z" fill="white" opacity="0.3" />
            <path d="M643.4 492.3H638.6L636.1 488.1L638.6 483.9H643.4L645.8 488.1L643.4 492.3Z" fill="white" opacity="0.3" />
            <path d="M696.1 523.3H690.3L687.4 518.3L690.3 513.3H696.1L699 518.3L696.1 523.3Z" fill="white" opacity="0.3" />
            <path d="M709.701 516.8H702.801L699.301 510.7L702.801 504.7H709.701L713.201 510.7L709.701 516.8Z" fill="white" opacity="0.3" />
            <path d="M722.801 509.2H715.801L712.301 503.2L715.801 497.2H722.801L726.301 503.2L722.801 509.2Z" fill="white" opacity="0.3" />
            <path d="M735.8 501.7H728.9L725.4 495.7L728.9 489.6H735.8L739.3 495.7L735.8 501.7Z" fill="white" opacity="0.3" />
            <path d="M748.9 494.2H741.9L738.4 488.1L741.9 482.1H748.9L752.4 488.1L748.9 494.2Z" fill="white" opacity="0.3" />
            <path d="M761.9 486.6H755L751.5 480.6L755 474.6H761.9L765.4 480.6L761.9 486.6Z" fill="white" opacity="0.3" />
            <path d="M775 479.1H768L764.5 473.1L768 467H775L778.5 473.1L775 479.1Z" fill="white" opacity="0.3" />
            <path d="M788 471.6H781.1L777.6 465.5L781.1 459.5H788L791.5 465.5L788 471.6Z" fill="white" opacity="0.3" />
            <path d="M801.1 464H794.1L790.6 458L794.1 452H801.1L804.6 458L801.1 464Z" fill="white" opacity="0.3" />
            <path d="M801.1 448.9H794.1L790.6 442.9L794.1 436.9H801.1L804.6 442.9L801.1 448.9Z" fill="white" opacity="0.3" />
            <path d="M801.1 433.9H794.1L790.6 427.8L794.1 421.8H801.1L804.6 427.8L801.1 433.9Z" fill="white" opacity="0.3" />
            <path d="M801.1 418.8H794.1L790.6 412.8L794.1 406.7H801.1L804.6 412.8L801.1 418.8Z" fill="white" opacity="0.3" />
            <path d="M801.1 403.7H794.1L790.6 397.7L794.1 391.7H801.1L804.6 397.7L801.1 403.7Z" fill="white" opacity="0.3" />
            <path d="M801.1 388.7H794.1L790.6 382.6L794.1 376.6H801.1L804.6 382.6L801.1 388.7Z" fill="white" opacity="0.3" />
            <path d="M801.1 373.6H794.1L790.6 367.6L794.1 361.5H801.1L804.6 367.6L801.1 373.6Z" fill="white" opacity="0.3" />
            <path d="M799.999 356.7H795.199L792.699 352.5L795.199 348.3H799.999L802.499 352.5L799.999 356.7Z" fill="white" opacity="0.3" />
            <path d="M798.2 338.4H797L796.5 337.4L797 336.4H798.2L798.7 337.4L798.2 338.4Z" fill="white" opacity="0.3" />
            <path d="M786.4 333H782.7L780.9 329.9L782.7 326.7H786.4L788.2 329.9L786.4 333Z" fill="white" opacity="0.3" />
            <path d="M774.8 328.1H768.2L764.9 322.4L768.2 316.6H774.8L778.1 322.4L774.8 328.1Z" fill="white" opacity="0.3" />
            <path d="M759.7 317H757.2L755.9 314.8L757.2 312.6H759.7L761 314.8L759.7 317Z" fill="white" opacity="0.3" />
            <path d="M748.799 313.1H741.999L738.699 307.3L741.999 301.4H748.799L752.099 307.3L748.799 313.1Z" fill="white" opacity="0.3" />
            <path d="M734.399 303.3H730.299L728.199 299.7L730.299 296.2H734.399L736.399 299.7L734.399 303.3Z" fill="white" opacity="0.3" />
            <path d="M721 295.1H717.6L715.9 292.2L717.6 289.3H721L722.6 292.2L721 295.1Z" fill="white" opacity="0.3" />
            <path d="M709.701 290.7H702.801L699.301 284.7L702.801 278.6H709.701L713.201 284.7L709.701 290.7Z" fill="white" opacity="0.3" />
            <path d="M696.699 283.2H689.699L686.199 277.1L689.699 271.1H696.699L700.099 277.1L696.699 283.2Z" fill="white" opacity="0.3" />
            <path d="M683 289.6H677.3L674.5 284.7L677.3 279.8H683L685.8 284.7L683 289.6Z" fill="white" opacity="0.3" />
            <path d="M669.7 296.7H664.5L661.9 292.2L664.5 287.7H669.7L672.2 292.2L669.7 296.7Z" fill="white" opacity="0.3" />
            <path d="M657.5 305.8H650.6L647.1 299.7L650.6 293.7H657.5L661 299.7L657.5 305.8Z" fill="white" opacity="0.3" />
            <path d="M644.5 313.3H637.5L634 307.3L637.5 301.3H644.5L647.9 307.3L644.5 313.3Z" fill="white" opacity="0.3" />
            <path d="M631.4 320.8H624.4L621 314.8L624.4 308.8H631.4L634.9 314.8L631.4 320.8Z" fill="white" opacity="0.3" />
            <path d="M590.2 400.2H587.3L585.9 397.7L587.3 395.2H590.2L591.7 397.7L590.2 400.2Z" fill="white" opacity="0.3" />
            <path d="M591.9 418.3H585.6L582.4 412.8L585.6 407.3H591.9L595.1 412.8L591.9 418.3Z" fill="white" opacity="0.3" />
            <path d="M591.9 433.3H585.6L582.4 427.8L585.6 422.4H591.9L595.1 427.8L591.9 433.3Z" fill="white" opacity="0.3" />
            <path d="M591.9 448.4H585.6L582.4 442.9L585.6 437.4H591.9L595.1 442.9L591.9 448.4Z" fill="white" opacity="0.3" />
            <path d="M602.399 481.6H601.299L600.699 480.6L601.299 479.6H602.399L602.999 480.6L602.399 481.6Z" fill="white" opacity="0.3" />
            <path d="M616.1 490.2H613.7L612.5 488.1L613.7 486H616.1L617.3 488.1L616.1 490.2Z" fill="white" opacity="0.3" />
            <path d="M709.701 531.8H702.801L699.301 525.8L702.801 519.8H709.701L713.201 525.8L709.701 531.8Z" fill="white" opacity="0.3" />
            <path d="M722.801 524.3H715.801L712.301 518.3L715.801 512.2H722.801L726.301 518.3L722.801 524.3Z" fill="white" opacity="0.3" />
            <path d="M735.8 516.8H728.9L725.4 510.7L728.9 504.7H735.8L739.3 510.7L735.8 516.8Z" fill="white" opacity="0.3" />
            <path d="M748.9 509.2H741.9L738.4 503.2L741.9 497.2H748.9L752.4 503.2L748.9 509.2Z" fill="white" opacity="0.3" />
            <path d="M761.9 501.7H755L751.5 495.7L755 489.6H761.9L765.4 495.7L761.9 501.7Z" fill="white" opacity="0.3" />
            <path d="M775 494.2H768L764.5 488.1L768 482.1H775L778.5 488.1L775 494.2Z" fill="white" opacity="0.3" />
            <path d="M788 486.6H781.1L777.6 480.6L781.1 474.6H788L791.5 480.6L788 486.6Z" fill="white" opacity="0.3" />
            <path d="M801.1 479.1H794.1L790.6 473.1L794.1 467H801.1L804.6 473.1L801.1 479.1Z" fill="white" opacity="0.3" />
            <path d="M814.099 471.6H807.199L803.699 465.5L807.199 459.5H814.099L817.599 465.5L814.099 471.6Z" fill="white" opacity="0.3" />
            <path d="M814.099 456.5H807.199L803.699 450.5L807.199 444.4H814.099L817.599 450.5L814.099 456.5Z" fill="white" opacity="0.3" />
            <path d="M814.099 441.4H807.199L803.699 435.4L807.199 429.4H814.099L817.599 435.4L814.099 441.4Z" fill="white" opacity="0.3" />
            <path d="M814.099 426.3H807.199L803.699 420.3L807.199 414.3H814.099L817.599 420.3L814.099 426.3Z" fill="white" opacity="0.3" />
            <path d="M814.101 411.1H807.201L803.801 405.2L807.201 399.3H814.101L817.501 405.2L814.101 411.1Z" fill="white" opacity="0.3" />
            <path d="M811.8 392.1H809.5L808.4 390.2L809.5 388.2H811.8L812.9 390.2L811.8 392.1Z" fill="white" opacity="0.3" />
            <path d="M814.099 381.1H807.199L803.699 375.1L807.199 369.1H814.099L817.599 375.1L814.099 381.1Z" fill="white" opacity="0.3" />
            <path d="M814.099 366.1H807.199L803.699 360L807.199 354H814.099L817.599 360L814.099 366.1Z" fill="white" opacity="0.3" />
            <path d="M814.099 351H807.199L803.699 345L807.199 338.9H814.099L817.599 345L814.099 351Z" fill="white" opacity="0.3" />
            <path d="M814.099 335.9H807.199L803.699 329.9L807.199 323.9H814.099L817.599 329.9L814.099 335.9Z" fill="white" opacity="0.3" />
            <path d="M801.001 328.3H794.201L790.801 322.4L794.201 316.4H801.001L804.401 322.4L801.001 328.3Z" fill="white" opacity="0.3" />
            <path d="M788 320.8H781.1L777.6 314.8L781.1 308.8H788L791.5 314.8L788 320.8Z" fill="white" opacity="0.3" />
            <path d="M775 313.3H768L764.5 307.3L768 301.3H775L778.5 307.3L775 313.3Z" fill="white" opacity="0.3" />
            <path d="M761.799 305.6H755.099L751.699 299.7L755.099 293.9H761.799L765.199 299.7L761.799 305.6Z" fill="white" opacity="0.3" />
            <path d="M748.9 298.2H741.9L738.4 292.2L741.9 286.2H748.9L752.4 292.2L748.9 298.2Z" fill="white" opacity="0.3" />
            <path d="M735.8 290.7H728.9L725.4 284.7L728.9 278.6H735.8L739.3 284.7L735.8 290.7Z" fill="white" opacity="0.3" />
            <path d="M722.6 282.9H715.9L712.6 277.1L715.9 271.3H722.6L726 277.1L722.6 282.9Z" fill="white" opacity="0.3" />
            <path d="M709.701 275.6H702.801L699.301 269.6L702.801 263.6H709.701L713.201 269.6L709.701 275.6Z" fill="white" opacity="0.3" />
            <path d="M683.599 275.6H676.699L673.199 269.6L676.699 263.6H683.599L687.099 269.6L683.599 275.6Z" fill="white" opacity="0.3" />
            <path d="M670.6 283.2H663.6L660.1 277.1L663.6 271.1H670.6L674 277.1L670.6 283.2Z" fill="white" opacity="0.3" />
            <path d="M657.2 290.2H650.8L647.6 284.7L650.8 279.1H657.2L660.4 284.7L657.2 290.2Z" fill="white" opacity="0.3" />
            <path d="M644.5 298.2H637.5L634 292.2L637.5 286.2H644.5L647.9 292.2L644.5 298.2Z" fill="white" opacity="0.3" />
            <path d="M631.4 305.8H624.4L621 299.7L624.4 293.7H631.4L634.9 299.7L631.4 305.8Z" fill="white" opacity="0.3" />
            <path d="M709.4 546.4H703.1L699.9 540.9L703.1 535.4H709.4L712.6 540.9L709.4 546.4Z" fill="white" opacity="0.3" />
            <path d="M722.801 539.4H715.801L712.301 533.3L715.801 527.3H722.801L726.301 533.3L722.801 539.4Z" fill="white" opacity="0.3" />
            <path d="M735.8 531.8H728.9L725.4 525.8L728.9 519.8H735.8L739.3 525.8L735.8 531.8Z" fill="white" opacity="0.3" />
            <path d="M748.9 524.3H741.9L738.4 518.3L741.9 512.2H748.9L752.4 518.3L748.9 524.3Z" fill="white" opacity="0.3" />
            <path d="M761.9 516.8H755L751.5 510.7L755 504.7H761.9L765.4 510.7L761.9 516.8Z" fill="white" opacity="0.3" />
            <path d="M775 509.2H768L764.5 503.2L768 497.2H775L778.5 503.2L775 509.2Z" fill="white" opacity="0.3" />
            <path d="M788 501.7H781.1L777.6 495.7L781.1 489.6H788L791.5 495.7L788 501.7Z" fill="white" opacity="0.3" />
            <path d="M801.1 494.2H794.1L790.6 488.1L794.1 482.1H801.1L804.6 488.1L801.1 494.2Z" fill="white" opacity="0.3" />
            <path d="M814.099 486.6H807.199L803.699 480.6L807.199 474.6H814.099L817.599 480.6L814.099 486.6Z" fill="white" opacity="0.3" />
            <path d="M827.199 479.1H820.199L816.699 473.1L820.199 467H827.199L830.699 473.1L827.199 479.1Z" fill="white" opacity="0.3" />
            <path d="M827.199 464H820.199L816.699 458L820.199 452H827.199L830.699 458L827.199 464Z" fill="white" opacity="0.3" />
            <path d="M827.199 448.9H820.199L816.699 442.9L820.199 436.9H827.199L830.699 442.9L827.199 448.9Z" fill="white" opacity="0.3" />
            <path d="M826.1 432H821.3L818.9 427.8L821.3 423.7H826.1L828.5 427.8L826.1 432Z" fill="white" opacity="0.3" />
            <path d="M824.4 413.9H823L822.4 412.8L823 411.6H824.4L825 412.8L824.4 413.9Z" fill="white" opacity="0.3" />
            <path d="M827.201 403.7H820.201L816.801 397.7L820.201 391.7H827.201L830.601 397.7L827.201 403.7Z" fill="white" opacity="0.3" />
            <path d="M827.199 388.7H820.199L816.699 382.6L820.199 376.6H827.199L830.699 382.6L827.199 388.7Z" fill="white" opacity="0.3" />
            <path d="M827.199 373.6H820.199L816.699 367.6L820.199 361.5H827.199L830.699 367.6L827.199 373.6Z" fill="white" opacity="0.3" />
            <path d="M827.199 358.5H820.199L816.699 352.5L820.199 346.5H827.199L830.699 352.5L827.199 358.5Z" fill="white" opacity="0.3" />
            <path d="M827.199 343.4H820.199L816.699 337.4L820.199 331.4H827.199L830.699 337.4L827.199 343.4Z" fill="white" opacity="0.3" />
            <path d="M827.199 328.4H820.199L816.699 322.4L820.199 316.3H827.199L830.699 322.4L827.199 328.4Z" fill="white" opacity="0.3" />
            <path d="M814.099 320.8H807.199L803.699 314.8L807.199 308.8H814.099L817.599 314.8L814.099 320.8Z" fill="white" opacity="0.3" />
            <path d="M801.1 313.3H794.1L790.6 307.3L794.1 301.3H801.1L804.6 307.3L801.1 313.3Z" fill="white" opacity="0.3" />
            <path d="M788 305.8H781.1L777.6 299.7L781.1 293.7H788L791.5 299.7L788 305.8Z" fill="white" opacity="0.3" />
            <path d="M775 298.2H768L764.5 292.2L768 286.2H775L778.5 292.2L775 298.2Z" fill="white" opacity="0.3" />
            <path d="M761.9 290.7H755L751.5 284.7L755 278.6H761.9L765.4 284.7L761.9 290.7Z" fill="white" opacity="0.3" />
            <path d="M748.9 283.2H741.9L738.4 277.1L741.9 271.1H748.9L752.4 277.1L748.9 283.2Z" fill="white" opacity="0.3" />
            <path d="M735.8 275.6H728.9L725.4 269.6L728.9 263.6H735.8L739.3 269.6L735.8 275.6Z" fill="white" opacity="0.3" />
            <path d="M722.801 268.1H715.801L712.301 262.1L715.801 256H722.801L726.301 262.1L722.801 268.1Z" fill="white" opacity="0.3" />
            <path d="M709.701 260.6H702.801L699.301 254.5L702.801 248.5H709.701L713.201 254.5L709.701 260.6Z" fill="white" opacity="0.3" />
            <path
              d="M696.699 253H689.699L686.199 247L689.699 241H696.699L700.099 247L696.699 253Z"
              fill="#FF881B"
              className="legend-map"
              id="liechtenstein"
              data-tooltip-html={
                ReactDOMServer.renderToString(
                  <div className={styles.country__item}>
                    <img src={Countries.liechtenstein} alt="Liechtenstein" />
                    <span className={styles.country__title}>
                      {t('Liechtenstein')}
                    </span>
                  </div>,
                )
              }
            />
            <path d="M683.599 260.6H676.699L673.199 254.5L676.699 248.5H683.599L687.099 254.5L683.599 260.6Z" fill="white" opacity="0.3" />
            <path d="M670.6 268.1H663.6L660.1 262.1L663.6 256H670.6L674 262.1L670.6 268.1Z" fill="white" opacity="0.3" />
            <path d="M657.401 275.4H650.701L647.301 269.6L650.701 263.8H657.401L660.701 269.6L657.401 275.4Z" fill="white" opacity="0.3" />
            <path d="M628.9 286.3H627L626 284.7L627 283H628.9L629.8 284.7L628.9 286.3Z" fill="white" opacity="0.3" />
            <path d="M563.3 428.9H562.1L561.5 427.8L562.1 426.8H563.3L563.9 427.8L563.3 428.9Z" fill="white" opacity="0.3" />
            <path d="M708.499 559.9H703.999L701.699 555.9L703.999 552H708.499L710.799 555.9L708.499 559.9Z" fill="white" opacity="0.3" />
            <path d="M722.801 554.4H715.801L712.301 548.4L715.801 542.4H722.801L726.301 548.4L722.801 554.4Z" fill="white" opacity="0.3" />
            <path d="M735.8 546.9H728.9L725.4 540.9L728.9 534.8H735.8L739.3 540.9L735.8 546.9Z" fill="white" opacity="0.3" />
            <path d="M748.9 539.4H741.9L738.4 533.3L741.9 527.3H748.9L752.4 533.3L748.9 539.4Z" fill="white" opacity="0.3" />
            <path d="M761.9 531.8H755L751.5 525.8L755 519.8H761.9L765.4 525.8L761.9 531.8Z" fill="white" opacity="0.3" />
            <path d="M775 524.3H768L764.5 518.3L768 512.2H775L778.5 518.3L775 524.3Z" fill="white" opacity="0.3" />
            <path d="M788 516.8H781.1L777.6 510.7L781.1 504.7H788L791.5 510.7L788 516.8Z" fill="white" opacity="0.3" />
            <path d="M801.1 509.2H794.1L790.6 503.2L794.1 497.2H801.1L804.6 503.2L801.1 509.2Z" fill="white" opacity="0.3" />
            <path d="M814.099 501.7H807.199L803.699 495.7L807.199 489.6H814.099L817.599 495.7L814.099 501.7Z" fill="white" opacity="0.3" />
            <path d="M827.199 494.2H820.199L816.699 488.1L820.199 482.1H827.199L830.699 488.1L827.199 494.2Z" fill="white" opacity="0.3" />
            <path d="M840.201 486.6H833.301L829.801 480.6L833.301 474.6H840.201L843.701 480.6L840.201 486.6Z" fill="white" opacity="0.3" />
            <path d="M840.201 471.6H833.301L829.801 465.5L833.301 459.5H840.201L843.701 465.5L840.201 471.6Z" fill="white" opacity="0.3" />
            <path d="M840.2 456.4H833.3L829.9 450.5L833.3 444.5H840.2L843.6 450.5L840.2 456.4Z" fill="white" opacity="0.3" />
            <path d="M837.9 437.4H835.6L834.4 435.4L835.6 433.3H837.9L839.1 435.4L837.9 437.4Z" fill="white" opacity="0.3" />
            <path d="M839.9 425.7H833.7L830.6 420.3L833.7 414.9H839.9L843 420.3L839.9 425.7Z" fill="white" opacity="0.3" />
            <path d="M840.201 411.3H833.301L829.801 405.2L833.301 399.2H840.201L843.701 405.2L840.201 411.3Z" fill="white" opacity="0.3" />
            <path d="M840.201 396.2H833.301L829.801 390.2L833.301 384.1H840.201L843.701 390.2L840.201 396.2Z" fill="white" opacity="0.3" />
            <path d="M840.201 381.1H833.301L829.801 375.1L833.301 369.1H840.201L843.701 375.1L840.201 381.1Z" fill="white" opacity="0.3" />
            <path d="M840.201 366.1H833.301L829.801 360L833.301 354H840.201L843.701 360L840.201 366.1Z" fill="white" opacity="0.3" />
            <path d="M840.201 351H833.301L829.801 345L833.301 338.9H840.201L843.701 345L840.201 351Z" fill="white" opacity="0.3" />
            <path d="M840.201 335.9H833.301L829.801 329.9L833.301 323.9H840.201L843.701 329.9L840.201 335.9Z" fill="white" opacity="0.3" />
            <path d="M840.201 320.8H833.301L829.801 314.8L833.301 308.8H840.201L843.701 314.8L840.201 320.8Z" fill="white" opacity="0.3" />
            <path d="M827.199 313.3H820.199L816.699 307.3L820.199 301.3H827.199L830.699 307.3L827.199 313.3Z" fill="white" opacity="0.3" />
            <path d="M814.099 305.8H807.199L803.699 299.7L807.199 293.7H814.099L817.599 299.7L814.099 305.8Z" fill="white" opacity="0.3" />
            <path d="M801.1 298.2H794.1L790.6 292.2L794.1 286.2H801.1L804.6 292.2L801.1 298.2Z" fill="white" opacity="0.3" />
            <path d="M788 290.7H781.1L777.6 284.7L781.1 278.6H788L791.5 284.7L788 290.7Z" fill="white" opacity="0.3" />
            <path d="M775 283.2H768L764.5 277.1L768 271.1H775L778.5 277.1L775 283.2Z" fill="white" opacity="0.3" />
            <path d="M761.9 275.6H755L751.5 269.6L755 263.6H761.9L765.4 269.6L761.9 275.6Z" fill="white" opacity="0.3" />
            <path d="M748.9 268.1H741.9L738.4 262.1L741.9 256H748.9L752.4 262.1L748.9 268.1Z" fill="white" opacity="0.3" />
            <path d="M735.8 260.6H728.9L725.4 254.5L728.9 248.5H735.8L739.3 254.5L735.8 260.6Z" fill="white" opacity="0.3" />
            <path d="M722.801 253H715.801L712.301 247L715.801 241H722.801L726.301 247L722.801 253Z" fill="white" opacity="0.3" />
            <path d="M709.701 245.5H702.801L699.301 239.5L702.801 233.4H709.701L713.201 239.5L709.701 245.5Z" fill="white" opacity="0.3" />
            <path d="M696.699 238H689.699L686.199 231.9L689.699 225.9H696.699L700.099 231.9L696.699 238Z" fill="white" opacity="0.3" />
            <path d="M683.599 245.5H676.699L673.199 239.5L676.699 233.4H683.599L687.099 239.5L683.599 245.5Z" fill="white" opacity="0.3" />
            <path d="M670.6 253H663.6L660.1 247L663.6 241H670.6L674 247L670.6 253Z" fill="white" opacity="0.3" />
            <path d="M657.5 260.5H650.6L647.1 254.5L650.6 248.6H657.5L660.9 254.5L657.5 260.5Z" fill="white" opacity="0.3" />
            <path d="M644 267.3H638L635 262.1L638 256.9H644L647 262.1L644 267.3Z" fill="white" opacity="0.3" />
            <path d="M722.801 569.5H715.801L712.301 563.5L715.801 557.5H722.801L726.301 563.5L722.801 569.5Z" fill="white" opacity="0.3" />
            <path d="M735.8 562H728.9L725.4 555.9L728.9 549.9H735.8L739.3 555.9L735.8 562Z" fill="white" opacity="0.3" />
            <path d="M748.9 554.4H741.9L738.4 548.4L741.9 542.4H748.9L752.4 548.4L748.9 554.4Z" fill="white" opacity="0.3" />
            <path d="M761.9 546.9H755L751.5 540.9L755 534.8H761.9L765.4 540.9L761.9 546.9Z" fill="white" opacity="0.3" />
            <path d="M775 539.4H768L764.5 533.3L768 527.3H775L778.5 533.3L775 539.4Z" fill="white" opacity="0.3" />
            <path d="M788 531.8H781.1L777.6 525.8L781.1 519.8H788L791.5 525.8L788 531.8Z" fill="white" opacity="0.3" />
            <path d="M801.1 524.3H794.1L790.6 518.3L794.1 512.2H801.1L804.6 518.3L801.1 524.3Z" fill="white" opacity="0.3" />
            <path d="M814.099 516.8H807.199L803.699 510.7L807.199 504.7H814.099L817.599 510.7L814.099 516.8Z" fill="white" opacity="0.3" />
            <path d="M827.199 509.2H820.199L816.699 503.2L820.199 497.2H827.199L830.699 503.2L827.199 509.2Z" fill="white" opacity="0.3" />
            <path d="M840.201 501.7H833.301L829.801 495.7L833.301 489.6H840.201L843.701 495.7L840.201 501.7Z" fill="white" opacity="0.3" />
            <path d="M853.301 494.2H846.301L842.801 488.1L846.301 482.1H853.301L856.801 488.1L853.301 494.2Z" fill="white" opacity="0.3" />
            <path d="M853.301 479.1H846.301L842.801 473.1L846.301 467H853.301L856.801 473.1L853.301 479.1Z" fill="white" opacity="0.3" />
            <path d="M853.3 464H846.3L842.9 458L846.3 452H853.3L856.7 458L853.3 464Z" fill="white" opacity="0.3" />
            <path d="M853.001 448.5H846.601L843.301 442.9L846.601 437.3H853.001L856.301 442.9L853.001 448.5Z" fill="white" opacity="0.3" />
            <path d="M853.301 433.9H846.301L842.801 427.8L846.301 421.8H853.301L856.801 427.8L853.301 433.9Z" fill="white" opacity="0.3" />
            <path d="M853.301 418.8H846.301L842.801 412.8L846.301 406.7H853.301L856.801 412.8L853.301 418.8Z" fill="white" opacity="0.3" />
            <path d="M853.301 403.7H846.301L842.801 397.7L846.301 391.7H853.301L856.801 397.7L853.301 403.7Z" fill="white" opacity="0.3" />
            <path d="M853.301 388.7H846.301L842.801 382.6L846.301 376.6H853.301L856.801 382.6L853.301 388.7Z" fill="white" opacity="0.3" />
            <path d="M853.301 373.6H846.301L842.801 367.6L846.301 361.5H853.301L856.801 367.6L853.301 373.6Z" fill="white" opacity="0.3" />
            <path d="M853.301 358.5H846.301L842.801 352.5L846.301 346.5H853.301L856.801 352.5L853.301 358.5Z" fill="white" opacity="0.3" />
            <path d="M853.301 343.4H846.301L842.801 337.4L846.301 331.4H853.301L856.801 337.4L853.301 343.4Z" fill="white" opacity="0.3" />
            <path d="M853.301 328.4H846.301L842.801 322.4L846.301 316.3H853.301L856.801 322.4L853.301 328.4Z" fill="white" opacity="0.3" />
            <path d="M853.301 313.3H846.301L842.801 307.3L846.301 301.3H853.301L856.801 307.3L853.301 313.3Z" fill="white" opacity="0.3" />
            <path d="M840.201 305.8H833.301L829.801 299.7L833.301 293.7H840.201L843.701 299.7L840.201 305.8Z" fill="white" opacity="0.3" />
            <path d="M827.199 298.2H820.199L816.699 292.2L820.199 286.2H827.199L830.699 292.2L827.199 298.2Z" fill="white" opacity="0.3" />
            <path d="M814.099 290.7H807.199L803.699 284.7L807.199 278.6H814.099L817.599 284.7L814.099 290.7Z" fill="white" opacity="0.3" />
            <path d="M801.1 283.2H794.1L790.6 277.1L794.1 271.1H801.1L804.6 277.1L801.1 283.2Z" fill="white" opacity="0.3" />
            <path d="M788 275.6H781.1L777.6 269.6L781.1 263.6H788L791.5 269.6L788 275.6Z" fill="white" opacity="0.3" />
            <path d="M775 268.1H768L764.5 262.1L768 256H775L778.5 262.1L775 268.1Z" fill="white" opacity="0.3" />
            <path d="M761.9 260.6H755L751.5 254.5L755 248.5H761.9L765.4 254.5L761.9 260.6Z" fill="white" opacity="0.3" />
            <path d="M748.9 253H741.9L738.4 247L741.9 241H748.9L752.4 247L748.9 253Z" fill="white" opacity="0.3" />
            <path d="M735.8 245.5H728.9L725.4 239.5L728.9 233.4H735.8L739.3 239.5L735.8 245.5Z" fill="white" opacity="0.3" />
            <path d="M722.801 238H715.801L712.301 231.9L715.801 225.9H722.801L726.301 231.9L722.801 238Z" fill="white" opacity="0.3" />
            <path d="M709.701 230.4H702.801L699.301 224.4L702.801 218.4H709.701L713.201 224.4L709.701 230.4Z" fill="white" opacity="0.3" />
            <path d="M696.399 222.4H689.999L686.699 216.9L689.999 211.3H696.399L699.599 216.9L696.399 222.4Z" fill="white" opacity="0.3" />
            <path d="M682 227.7H678.3L676.4 224.4L678.3 221.1H682L683.9 224.4L682 227.7Z" fill="white" opacity="0.3" />
            <path d="M667.6 232.9H666.5L666 231.9L666.5 231H667.6L668.2 231.9L667.6 232.9Z" fill="white" opacity="0.3" />
            <path d="M657.5 245.4H650.6L647.1 239.5L650.6 233.5H657.5L660.9 239.5L657.5 245.4Z" fill="white" opacity="0.3" />
            <path d="M641.7 248.2H640.3L639.5 247L640.3 245.7H641.7L642.4 247L641.7 248.2Z" fill="white" opacity="0.3" />
            <path d="M708.3 589.7H704.2L702.1 586.1L704.2 582.5H708.3L710.4 586.1L708.3 589.7Z" fill="white" opacity="0.3" />
            <path d="M722.801 584.6H715.801L712.301 578.6L715.801 572.5H722.801L726.301 578.6L722.801 584.6Z" fill="white" opacity="0.3" />
            <path d="M735.8 577H728.9L725.4 571L728.9 565H735.8L739.3 571L735.8 577Z" fill="white" opacity="0.3" />
            <path d="M748.9 569.5H741.9L738.4 563.5L741.9 557.5H748.9L752.4 563.5L748.9 569.5Z" fill="white" opacity="0.3" />
            <path d="M761.9 562H755L751.5 555.9L755 549.9H761.9L765.4 555.9L761.9 562Z" fill="white" opacity="0.3" />
            <path d="M775 554.4H768L764.5 548.4L768 542.4H775L778.5 548.4L775 554.4Z" fill="white" opacity="0.3" />
            <path d="M788 546.9H781.1L777.6 540.9L781.1 534.8H788L791.5 540.9L788 546.9Z" fill="white" opacity="0.3" />
            <path d="M801.1 539.4H794.1L790.6 533.3L794.1 527.3H801.1L804.6 533.3L801.1 539.4Z" fill="white" opacity="0.3" />
            <path d="M814.099 531.8H807.199L803.699 525.8L807.199 519.8H814.099L817.599 525.8L814.099 531.8Z" fill="white" opacity="0.3" />
            <path d="M827.199 524.3H820.199L816.699 518.3L820.199 512.2H827.199L830.699 518.3L827.199 524.3Z" fill="white" opacity="0.3" />
            <path d="M840.001 516.4H833.501L830.301 510.7L833.501 505.1H840.001L843.301 510.7L840.001 516.4Z" fill="white" opacity="0.3" />
            <path d="M851.7 506.6H847.9L845.9 503.2L847.9 499.8H851.7L853.7 503.2L851.7 506.6Z" fill="white" opacity="0.3" />
            <path d="M865.9 485.8H859.9L856.9 480.6L859.9 475.4H865.9L868.9 480.6L865.9 485.8Z" fill="white" opacity="0.3" />
            <path d="M866.3 471.6H859.4L855.9 465.5L859.4 459.5H866.3L869.8 465.5L866.3 471.6Z" fill="white" opacity="0.3" />
            <path d="M864.401 453.1H861.301L859.801 450.5L861.301 447.8H864.401L865.901 450.5L864.401 453.1Z" fill="white" opacity="0.3" />
            <path d="M866.3 441.4H859.4L856 435.4L859.4 429.4H866.3L869.8 435.4L866.3 441.4Z" fill="white" opacity="0.3" />
            <path d="M866.3 426.3H859.4L855.9 420.3L859.4 414.3H866.3L869.8 420.3L866.3 426.3Z" fill="white" opacity="0.3" />
            <path d="M866.3 411.3H859.4L855.9 405.2L859.4 399.2H866.3L869.8 405.2L866.3 411.3Z" fill="white" opacity="0.3" />
            <path d="M866.3 396.2H859.4L855.9 390.2L859.4 384.1H866.3L869.8 390.2L866.3 396.2Z" fill="white" opacity="0.3" />
            <path d="M866.3 381.1H859.4L855.9 375.1L859.4 369.1H866.3L869.8 375.1L866.3 381.1Z" fill="white" opacity="0.3" />
            <path d="M866.3 366.1H859.4L855.9 360L859.4 354H866.3L869.8 360L866.3 366.1Z" fill="white" opacity="0.3" />
            <path d="M866.3 351H859.4L855.9 345L859.4 338.9H866.3L869.8 345L866.3 351Z" fill="white" opacity="0.3" />
            <path d="M866.3 335.9H859.4L855.9 329.9L859.4 323.9H866.3L869.8 329.9L866.3 335.9Z" fill="white" opacity="0.3" />
            <path d="M866.3 320.8H859.4L855.9 314.8L859.4 308.8H866.3L869.8 314.8L866.3 320.8Z" fill="white" />
            <path d="M866.3 305.8H859.4L855.9 299.7L859.4 293.7H866.3L869.8 299.7L866.3 305.8Z" fill="white" />
            <path d="M853.301 298.2H846.301L842.801 292.2L846.301 286.2H853.301L856.801 292.2L853.301 298.2Z" fill="white" />
            <path d="M840.201 290.7H833.301L829.801 284.7L833.301 278.6H840.201L843.701 284.7L840.201 290.7Z" fill="white" />
            <path d="M827.199 283.2H820.199L816.699 277.1L820.199 271.1H827.199L830.699 277.1L827.199 283.2Z" fill="white" />
            <path d="M814.099 275.6H807.199L803.699 269.6L807.199 263.6H814.099L817.599 269.6L814.099 275.6Z" fill="white" />
            <path d="M801.1 268.1H794.1L790.6 262.1L794.1 256H801.1L804.6 262.1L801.1 268.1Z" fill="white" />
            <path d="M788 260.6H781.1L777.6 254.5L781.1 248.5H788L791.5 254.5L788 260.6Z" fill="white" />
            <path d="M775 253H768L764.5 247L768 241H775L778.5 247L775 253Z" fill="white" />
            <path d="M761.9 245.5H755L751.5 239.5L755 233.4H761.9L765.4 239.5L761.9 245.5Z" fill="white" />
            <path d="M748.9 238H741.9L738.4 231.9L741.9 225.9H748.9L752.4 231.9L748.9 238Z" fill="white" />
            <path d="M735.8 230.4H728.9L725.4 224.4L728.9 218.4H735.8L739.3 224.4L735.8 230.4Z" fill="white" />
            <path d="M721.6 220.9H717L714.6 216.9L717 212.8H721.6L724 216.9L721.6 220.9Z" fill="white" />
            <path d="M708 212.4H704.4L702.6 209.3L704.4 206.2H708L709.8 209.3L708 212.4Z" fill="white" />
            <path d="M695.401 205.5H691.001L688.801 201.8L691.001 198H695.401L697.501 201.8L695.401 205.5Z" fill="white" />
            <path d="M657.5 230.4H650.6L647.1 224.4L650.6 218.4H657.5L661 224.4L657.5 230.4Z" fill="white" />
            <path d="M644.5 238H637.5L634 231.9L637.5 225.9H644.5L647.9 231.9L644.5 238Z" fill="white" />
            <path d="M709.4 606.6H703.1L700 601.2L703.1 595.8H709.4L712.5 601.2L709.4 606.6Z" fill="white" />
            <path d="M722.801 599.7H715.801L712.301 593.6L715.801 587.6H722.801L726.301 593.6L722.801 599.7Z" fill="white" />
            <path d="M735.8 592.1H728.9L725.4 586.1L728.9 580.1H735.8L739.3 586.1L735.8 592.1Z" fill="white" />
            <path d="M748.9 584.6H741.9L738.4 578.6L741.9 572.5H748.9L752.4 578.6L748.9 584.6Z" fill="white" />
            <path d="M761.9 577H755L751.5 571L755 565H761.9L765.4 571L761.9 577Z" fill="white" />
            <path d="M775 569.5H768L764.5 563.5L768 557.5H775L778.5 563.5L775 569.5Z" fill="white" />
            <path d="M788 562H781.1L777.6 555.9L781.1 549.9H788L791.5 555.9L788 562Z" fill="white" />
            <path d="M801.1 554.4H794.1L790.6 548.4L794.1 542.4H801.1L804.6 548.4L801.1 554.4Z" fill="white" />
            <path d="M814.099 546.9H807.199L803.699 540.9L807.199 534.8H814.099L817.599 540.9L814.099 546.9Z" fill="white" />
            <path d="M827.1 539.3H820.3L816.9 533.3L820.3 527.4H827.1L830.6 533.3L827.1 539.3Z" fill="white" />
            <path d="M877.6 460.9H874.2L872.6 458L874.2 455.1H877.6L879.3 458L877.6 460.9Z" fill="white" />
            <path d="M879.4 433.9H872.4L869 427.8L872.4 421.8H879.4L882.9 427.8L879.4 433.9Z" fill="white" />
            <path d="M879.4 418.8H872.4L869 412.8L872.4 406.7H879.4L882.9 412.8L879.4 418.8Z" fill="white" />
            <path d="M879.4 403.7H872.4L869 397.7L872.4 391.7H879.4L882.9 397.7L879.4 403.7Z" fill="white" />
            <path d="M879.4 388.6H872.4L869 382.6L872.4 376.6H879.4L882.8 382.6L879.4 388.6Z" fill="white" />
            <path d="M876.9 369.2H875L874 367.6L875 365.9H876.9L877.8 367.6L876.9 369.2Z" fill="white" />
            <path
              d="M879.4 358.5H872.4L869 352.5L872.4 346.5H879.4L882.9 352.5L879.4 358.5Z"
              fill="#E23A45"
              id="dubai"
              className="legend-map"
              data-tooltip-html={
                ReactDOMServer.renderToString(
                  <div className={styles.country__item}>
                    <img src={Countries.dubai} alt="Dubai" />
                    <span className={styles.country__title}>
                      {t('Dubai')}
                    </span>
                  </div>,
                )
              }
            />
            <path d="M879.4 343.4H872.4L869 337.4L872.4 331.4H879.4L882.9 337.4L879.4 343.4Z" fill="white" />
            <path d="M879.4 328.4H872.4L869 322.4L872.4 316.3H879.4L882.9 322.4L879.4 328.4Z" fill="white" />
            <path d="M879.4 313.3H872.4L869 307.3L872.4 301.3H879.4L882.9 307.3L879.4 313.3Z" fill="white" />
            <path d="M879.4 298.2H872.4L869 292.2L872.4 286.2H879.4L882.9 292.2L879.4 298.2Z" fill="white" />
            <path d="M866.3 290.7H859.4L855.9 284.7L859.4 278.6H866.3L869.8 284.7L866.3 290.7Z" fill="white" />
            <path d="M853.301 283.2H846.301L842.801 277.1L846.301 271.1H853.301L856.801 277.1L853.301 283.2Z" fill="white" />
            <path d="M840.201 275.6H833.301L829.801 269.6L833.301 263.6H840.201L843.701 269.6L840.201 275.6Z" fill="white" />
            <path d="M827.199 268.1H820.199L816.699 262.1L820.199 256H827.199L830.699 262.1L827.199 268.1Z" fill="white" />
            <path d="M814.099 260.6H807.199L803.699 254.5L807.199 248.5H814.099L817.599 254.5L814.099 260.6Z" fill="white" />
            <path d="M801.1 253H794.1L790.6 247L794.1 241H801.1L804.6 247L801.1 253Z" fill="white" />
            <path d="M788 245.5H781.1L777.6 239.5L781.1 233.4H788L791.5 239.5L788 245.5Z" fill="white" />
            <path d="M775 238H768L764.5 231.9L768 225.9H775L778.5 231.9L775 238Z" fill="white" />
            <path d="M761.9 230.4H755L751.5 224.4L755 218.4H761.9L765.4 224.4L761.9 230.4Z" fill="white" />
            <path d="M748.9 222.9H741.9L738.4 216.9L741.9 210.8H748.9L752.4 216.9L748.9 222.9Z" fill="white" />
            <path d="M722.8 207.8H715.8L712.4 201.8L715.8 195.8H722.8L726.2 201.8L722.8 207.8Z" fill="white" />
            <path d="M709.7 200.2H702.8L699.4 194.2L702.8 188.3H709.7L713.1 194.2L709.7 200.2Z" fill="white" />
            <path d="M696.699 192.7H689.699L686.199 186.7L689.699 180.7H696.699L700.099 186.7L696.699 192.7Z" fill="white" />
            <path d="M681.399 196.4H678.899L677.699 194.2L678.899 192.1H681.399L682.599 194.2L681.399 196.4Z" fill="white" />
            <path d="M656.101 213H651.901L649.801 209.3L651.901 205.7H656.101L658.201 209.3L656.101 213Z" fill="white" />
            <path d="M642.9 220.2H639.1L637.1 216.9L639.1 213.5H642.9L644.8 216.9L642.9 220.2Z" fill="white" />
            <path d="M630.9 229.5H625L622.1 224.4L625 219.3H630.9L633.8 224.4L630.9 229.5Z" fill="white" />
            <path d="M616.3 234.4H613.5L612.1 231.9L613.5 229.5H616.3L617.7 231.9L616.3 234.4Z" fill="white" />
            <path d="M722.801 614.7H715.801L712.301 608.7L715.801 602.7H722.801L726.301 608.7L722.801 614.7Z" fill="white" />
            <path d="M735.8 607.2H728.9L725.4 601.2L728.9 595.1H735.8L739.3 601.2L735.8 607.2Z" fill="white" />
            <path d="M748.9 599.7H741.9L738.4 593.6L741.9 587.6H748.9L752.4 593.6L748.9 599.7Z" fill="white" />
            <path d="M761.9 592.1H755L751.5 586.1L755 580.1H761.9L765.4 586.1L761.9 592.1Z" fill="white" />
            <path d="M775 584.6H768L764.5 578.6L768 572.5H775L778.5 578.6L775 584.6Z" fill="white" />
            <path d="M788 577H781.1L777.6 571L781.1 565H788L791.5 571L788 577Z" fill="white" />
            <path d="M801.1 569.5H794.1L790.6 563.5L794.1 557.5H801.1L804.6 563.5L801.1 569.5Z" fill="white" />
            <path d="M814.099 562H807.199L803.699 555.9L807.199 549.9H814.099L817.599 555.9L814.099 562Z" fill="white" />
            <path d="M827.101 554.4H820.301L816.801 548.4L820.301 542.5H827.101L830.601 548.4L827.101 554.4Z" fill="white" />
            <path d="M892.299 426.2H885.599L882.199 420.3L885.599 414.4H892.299L895.699 420.3L892.299 426.2Z" fill="white" />
            <path d="M892.4 411.3H885.5L882 405.2L885.5 399.2H892.4L895.9 405.2L892.4 411.3Z" fill="white" />
            <path d="M892.299 396H885.599L882.199 390.2L885.599 384.3H892.299L895.699 390.2L892.299 396Z" fill="white" />
            <path d="M891.801 380H886.101L883.301 375.1L886.101 370.2H891.801L894.601 375.1L891.801 380Z" fill="white" />
            <path d="M892.4 351H885.5L882 345L885.5 338.9H892.4L895.9 345L892.4 351Z" fill="white" />
            <path d="M892.4 335.9H885.5L882 329.9L885.5 323.9H892.4L895.9 329.9L892.4 335.9Z" fill="white" />
            <path d="M892.4 320.8H885.5L882 314.8L885.5 308.8H892.4L895.9 314.8L892.4 320.8Z" fill="white" />
            <path d="M892.4 305.8H885.5L882 299.7L885.5 293.7H892.4L895.9 299.7L892.4 305.8Z" fill="white" />
            <path d="M892.4 290.7H885.5L882 284.7L885.5 278.6H892.4L895.9 284.7L892.4 290.7Z" fill="white" />
            <path d="M879.4 283.2H872.4L869 277.1L872.4 271.1H879.4L882.9 277.1L879.4 283.2Z" fill="white" />
            <path d="M866.3 275.6H859.4L855.9 269.6L859.4 263.6H866.3L869.8 269.6L866.3 275.6Z" fill="white" />
            <path d="M853.301 268.1H846.301L842.801 262.1L846.301 256H853.301L856.801 262.1L853.301 268.1Z" fill="white" />
            <path d="M840.201 260.6H833.301L829.801 254.5L833.301 248.5H840.201L843.701 254.5L840.201 260.6Z" fill="white" />
            <path d="M827.199 253H820.199L816.699 247L820.199 241H827.199L830.699 247L827.199 253Z" fill="white" />
            <path d="M814.099 245.5H807.199L803.699 239.5L807.199 233.4H814.099L817.599 239.5L814.099 245.5Z" fill="white" />
            <path d="M801.1 238H794.1L790.6 231.9L794.1 225.9H801.1L804.6 231.9L801.1 238Z" fill="white" />
            <path d="M788 230.4H781.1L777.6 224.4L781.1 218.4H788L791.5 224.4L788 230.4Z" fill="white" />
            <path d="M775 222.9H768L764.5 216.9L768 210.8H775L778.5 216.9L775 222.9Z" fill="white" />
            <path d="M761.9 215.3H755L751.5 209.3L755 203.3H761.9L765.4 209.3L761.9 215.3Z" fill="white" />
            <path d="M748.701 207.5H742.101L738.801 201.8L742.101 196.1H748.701L752.001 201.8L748.701 207.5Z" fill="white" />
            <path d="M733 195.4H731.7L731 194.2L731.7 193.1H733L733.7 194.2L733 195.4Z" fill="white" />
            <path d="M722.801 192.7H715.801L712.301 186.7L715.801 180.7H722.801L726.301 186.7L722.801 192.7Z" fill="white" />
            <path d="M709.701 185.2H702.801L699.301 179.2L702.801 173.1H709.701L713.201 179.2L709.701 185.2Z" fill="white" />
            <path d="M696.699 177.7H689.699L686.199 171.6L689.699 165.6H696.699L700.099 171.6L696.699 177.7Z" fill="white" />
            <path d="M683.099 184.3H677.199L674.199 179.2L677.199 174.1H683.099L685.999 179.2L683.099 184.3Z" fill="white" />
            <path d="M644.4 207.7H637.5L634.1 201.8L637.5 195.8H644.4L647.9 201.8L644.4 207.7Z" fill="white" />
            <path d="M722.7 629.7H715.9L712.5 623.8L715.9 617.9H722.7L726.1 623.8L722.7 629.7Z" fill="white" />
            <path d="M735.8 622.3H728.9L725.4 616.2L728.9 610.2H735.8L739.3 616.2L735.8 622.3Z" fill="white" />
            <path d="M748.9 614.7H741.9L738.4 608.7L741.9 602.7H748.9L752.4 608.7L748.9 614.7Z" fill="white" />
            <path d="M761.9 607.2H755L751.5 601.2L755 595.1H761.9L765.4 601.2L761.9 607.2Z" fill="white" />
            <path d="M775 599.7H768L764.5 593.6L768 587.6H775L778.5 593.6L775 599.7Z" fill="white" />
            <path d="M788 592.1H781.1L777.6 586.1L781.1 580.1H788L791.5 586.1L788 592.1Z" fill="white" />
            <path d="M801.1 584.6H794.1L790.6 578.6L794.1 572.5H801.1L804.6 578.6L801.1 584.6Z" fill="white" />
            <path d="M814.099 577H807.199L803.699 571L807.199 565H814.099L817.599 571L814.099 577Z" fill="white" />
            <path d="M827.199 569.5H820.199L816.699 563.5L820.199 557.5H827.199L830.699 563.5L827.199 569.5Z" fill="white" />
            <path d="M905.199 418.2H898.899L895.699 412.8L898.899 407.3H905.199L908.299 412.8L905.199 418.2Z" fill="white" />
            <path d="M905.101 403.1H898.901L895.801 397.7L898.901 392.3H905.101L908.201 397.7L905.101 403.1Z" fill="white" />
            <path d="M904.8 387.5H899.2L896.4 382.6L899.2 377.8H904.8L907.6 382.6L904.8 387.5Z" fill="white" />
            <path d="M905.5 373.6H898.5L895.1 367.6L898.5 361.5H905.5L909 367.6L905.5 373.6Z" fill="white" />
            <path d="M905.5 358.5H898.5L895.1 352.5L898.5 346.5H905.5L909 352.5L905.5 358.5Z" fill="white" />
            <path d="M905.5 343.4H898.5L895.1 337.4L898.5 331.4H905.5L909 337.4L905.5 343.4Z" fill="white" />
            <path d="M905.5 328.4H898.5L895.1 322.4L898.5 316.3H905.5L909 322.4L905.5 328.4Z" fill="white" />
            <path d="M905.5 313.3H898.5L895.1 307.3L898.5 301.3H905.5L909 307.3L905.5 313.3Z" fill="white" />
            <path d="M905.5 298.2H898.5L895.1 292.2L898.5 286.2H905.5L909 292.2L905.5 298.2Z" fill="white" />
            <path d="M905.5 283.2H898.5L895.1 277.1L898.5 271.1H905.5L909 277.1L905.5 283.2Z" fill="white" />
            <path d="M892.4 275.6H885.5L882 269.6L885.5 263.6H892.4L895.9 269.6L892.4 275.6Z" fill="white" />
            <path d="M879.4 268.1H872.4L869 262.1L872.4 256H879.4L882.9 262.1L879.4 268.1Z" fill="white" />
            <path d="M866.3 260.6H859.4L855.9 254.5L859.4 248.5H866.3L869.8 254.5L866.3 260.6Z" fill="white" />
            <path d="M853.301 253H846.301L842.801 247L846.301 241H853.301L856.801 247L853.301 253Z" fill="white" />
            <path d="M840.201 245.5H833.301L829.801 239.5L833.301 233.4H840.201L843.701 239.5L840.201 245.5Z" fill="white" />
            <path d="M827.199 238H820.199L816.699 231.9L820.199 225.9H827.199L830.699 231.9L827.199 238Z" fill="white" />
            <path d="M814.099 230.4H807.199L803.699 224.4L807.199 218.4H814.099L817.599 224.4L814.099 230.4Z" fill="white" />
            <path d="M801.1 222.9H794.1L790.6 216.9L794.1 210.8H801.1L804.6 216.9L801.1 222.9Z" fill="white" />
            <path d="M788 215.3H781.1L777.6 209.3L781.1 203.3H788L791.5 209.3L788 215.3Z" fill="white" />
            <path d="M775 207.8H768L764.5 201.8L768 195.8H775L778.5 201.8L775 207.8Z" fill="white" />
            <path d="M761.9 200.3H755L751.5 194.2L755 188.2H761.9L765.4 194.2L761.9 200.3Z" fill="white" />
            <path d="M722.801 177.7H715.801L712.301 171.6L715.801 165.6H722.801L726.301 171.6L722.801 177.7Z" fill="white" />
            <path d="M709.701 170.1H702.801L699.301 164.1L702.801 158.1H709.701L713.201 164.1L709.701 170.1Z" fill="white" />
            <path d="M695.7 161H690.6L688.1 156.6L690.6 152.2H695.7L698.3 156.6L695.7 161Z" fill="white" />
            <path d="M680.801 165.3H679.501L678.801 164.1L679.501 162.9H680.801L681.501 164.1L680.801 165.3Z" fill="white" />
            <path d="M641.7 187.9H640.3L639.6 186.7L640.3 185.5H641.7L642.3 186.7L641.7 187.9Z" fill="white" />
            <path d="M722.001 643.6H716.501L713.801 638.8L716.501 634.1H722.001L724.801 638.8L722.001 643.6Z" fill="white" />
            <path d="M735.8 637.3H728.9L725.4 631.3L728.9 625.3H735.8L739.3 631.3L735.8 637.3Z" fill="white" />
            <path d="M748.9 629.8H741.9L738.4 623.8L741.9 617.7H748.9L752.4 623.8L748.9 629.8Z" fill="white" />
            <path d="M761.9 622.3H755L751.5 616.2L755 610.2H761.9L765.4 616.2L761.9 622.3Z" fill="white" />
            <path d="M775 614.7H768L764.5 608.7L768 602.7H775L778.5 608.7L775 614.7Z" fill="white" />
            <path d="M788 607.2H781.1L777.6 601.2L781.1 595.1H788L791.5 601.2L788 607.2Z" fill="white" />
            <path d="M801.1 599.7H794.1L790.6 593.6L794.1 587.6H801.1L804.6 593.6L801.1 599.7Z" fill="white" />
            <path d="M814.099 592.1H807.199L803.699 586.1L807.199 580.1H814.099L817.599 586.1L814.099 592.1Z" fill="white" />
            <path d="M827.201 584.5H820.301L816.801 578.6L820.301 572.6H827.201L830.601 578.6L827.201 584.5Z" fill="white" />
            <path d="M916.1 391.9H914.1L913 390.2L914.1 388.4H916.1L917.1 390.2L916.1 391.9Z" fill="white" />
            <path d="M918.5 381.1H911.6L908.1 375.1L911.6 369.1H918.5L922 375.1L918.5 381.1Z" fill="white" />
            <path d="M918.5 366.1H911.6L908.1 360L911.6 354H918.5L922 360L918.5 366.1Z" fill="white" />
            <path d="M918.5 351H911.6L908.1 345L911.6 338.9H918.5L922 345L918.5 351Z" fill="white" />
            <path d="M918.5 335.9H911.6L908.1 329.9L911.6 323.9H918.5L922 329.9L918.5 335.9Z" fill="white" />
            <path d="M918.5 320.8H911.6L908.1 314.8L911.6 308.8H918.5L922 314.8L918.5 320.8Z" fill="white" />
            <path d="M918.5 305.8H911.6L908.1 299.7L911.6 293.7H918.5L922 299.7L918.5 305.8Z" fill="white" />
            <path d="M918.5 290.7H911.6L908.1 284.7L911.6 278.6H918.5L922 284.7L918.5 290.7Z" fill="white" />
            <path d="M918.5 275.6H911.6L908.1 269.6L911.6 263.6H918.5L922 269.6L918.5 275.6Z" fill="white" />
            <path d="M905.5 268.1H898.5L895.1 262.1L898.5 256H905.5L909 262.1L905.5 268.1Z" fill="white" />
            <path d="M892.4 260.6H885.5L882 254.5L885.5 248.5H892.4L895.9 254.5L892.4 260.6Z" fill="white" />
            <path d="M879.4 253H872.4L869 247L872.4 241H879.4L882.9 247L879.4 253Z" fill="white" />
            <path d="M866.3 245.5H859.4L855.9 239.5L859.4 233.4H866.3L869.8 239.5L866.3 245.5Z" fill="white" />
            <path d="M853.301 238H846.301L842.801 231.9L846.301 225.9H853.301L856.801 231.9L853.301 238Z" fill="white" />
            <path d="M840.201 230.4H833.301L829.801 224.4L833.301 218.4H840.201L843.701 224.4L840.201 230.4Z" fill="white" />
            <path d="M827.199 222.9H820.199L816.699 216.9L820.199 210.8H827.199L830.699 216.9L827.199 222.9Z" fill="white" />
            <path d="M814.099 215.3H807.199L803.699 209.3L807.199 203.3H814.099L817.599 209.3L814.099 215.3Z" fill="white" />
            <path d="M801.1 207.8H794.1L790.6 201.8L794.1 195.8H801.1L804.6 201.8L801.1 207.8Z" fill="white" />
            <path d="M788 200.3H781.1L777.6 194.2L781.1 188.2H788L791.5 194.2L788 200.3Z" fill="white" />
            <path d="M774.9 192.6H768.1L764.6 186.7L768.1 180.8H774.9L778.3 186.7L774.9 192.6Z" fill="white" />
            <path d="M761.9 185.1H755L751.6 179.2L755 173.2H761.9L765.3 179.2L761.9 185.1Z" fill="white" />
            <path d="M748.9 177.7H741.9L738.4 171.6L741.9 165.6H748.9L752.4 171.6L748.9 177.7Z" fill="white" />
            <path d="M734.3 167.5H730.4L728.4 164.1L730.4 160.7H734.3L736.3 164.1L734.3 167.5Z" fill="white" />
            <path d="M722.801 162.6H715.801L712.301 156.6L715.801 150.5H722.801L726.301 156.6L722.801 162.6Z" fill="white" />
            <path d="M709.5 154.8H702.9L699.6 149L702.9 143.3H709.5L712.8 149L709.5 154.8Z" fill="white" />
            <path d="M720.501 656.1H718.001L716.801 653.9L718.001 651.8H720.501L721.801 653.9L720.501 656.1Z" fill="white" />
            <path d="M735.8 652.4H728.9L725.4 646.4L728.9 640.3H735.8L739.3 646.4L735.8 652.4Z" fill="white" />
            <path d="M748.9 644.9H741.9L738.4 638.8L741.9 632.8H748.9L752.4 638.8L748.9 644.9Z" fill="white" />
            <path d="M761.9 637.3H755L751.5 631.3L755 625.3H761.9L765.4 631.3L761.9 637.3Z" fill="white" />
            <path d="M775 629.8H768L764.5 623.8L768 617.7H775L778.5 623.8L775 629.8Z" fill="white" />
            <path d="M788 622.3H781.1L777.6 616.2L781.1 610.2H788L791.5 616.2L788 622.3Z" fill="white" />
            <path d="M801.1 614.7H794.1L790.6 608.7L794.1 602.7H801.1L804.6 608.7L801.1 614.7Z" fill="white" />
            <path d="M813.5 606H807.9L805.1 601.2L807.9 596.3H813.5L816.2 601.2L813.5 606Z" fill="white" />
            <path d="M824.699 595.3H822.699L821.699 593.6L822.699 591.9H824.699L825.699 593.6L824.699 595.3Z" fill="white" />
            <path d="M865.8 576.1H859.9L856.9 571L859.9 565.9H865.8L868.8 571L865.8 576.1Z" fill="white" />
            <path d="M931.599 388.6H924.699L921.199 382.6L924.699 376.6H931.599L935.099 382.6L931.599 388.6Z" fill="white" />
            <path d="M931.599 373.6H924.599L921.199 367.6L924.599 361.5H931.599L935.099 367.6L931.599 373.6Z" fill="white" />
            <path d="M931.599 358.5H924.599L921.199 352.5L924.599 346.5H931.599L935.099 352.5L931.599 358.5Z" fill="white" />
            <path d="M931.599 343.4H924.599L921.199 337.4L924.599 331.4H931.599L935.099 337.4L931.599 343.4Z" fill="white" />
            <path d="M931.599 328.4H924.599L921.199 322.4L924.599 316.3H931.599L935.099 322.4L931.599 328.4Z" fill="white" />
            <path d="M931.599 313.3H924.599L921.199 307.3L924.599 301.3H931.599L935.099 307.3L931.599 313.3Z" fill="white" />
            <path d="M931.599 298.2H924.599L921.199 292.2L924.599 286.2H931.599L935.099 292.2L931.599 298.2Z" fill="white" />
            <path d="M931.599 283.2H924.599L921.199 277.1L924.599 271.1H931.599L935.099 277.1L931.599 283.2Z" fill="white" />
            <path d="M931.599 268.1H924.599L921.199 262.1L924.599 256H931.599L935.099 262.1L931.599 268.1Z" fill="white" />
            <path d="M918.5 260.6H911.6L908.1 254.5L911.6 248.5H918.5L922 254.5L918.5 260.6Z" fill="white" />
            <path d="M905.5 253H898.5L895.1 247L898.5 241H905.5L909 247L905.5 253Z" fill="white" />
            <path d="M892.4 245.5H885.5L882 239.5L885.5 233.4H892.4L895.9 239.5L892.4 245.5Z" fill="white" />
            <path d="M879.4 238H872.4L869 231.9L872.4 225.9H879.4L882.9 231.9L879.4 238Z" fill="white" />
            <path d="M866.3 230.4H859.4L855.9 224.4L859.4 218.4H866.3L869.8 224.4L866.3 230.4Z" fill="white" />
            <path d="M853.301 222.9H846.301L842.801 216.9L846.301 210.8H853.301L856.801 216.9L853.301 222.9Z" fill="white" />
            <path d="M840.201 215.3H833.301L829.801 209.3L833.301 203.3H840.201L843.701 209.3L840.201 215.3Z" fill="white" />
            <path d="M827.199 207.8H820.199L816.699 201.8L820.199 195.8H827.199L830.699 201.8L827.199 207.8Z" fill="white" />
            <path d="M814.099 200.3H807.199L803.699 194.2L807.199 188.2H814.099L817.599 194.2L814.099 200.3Z" fill="white" />
            <path d="M801.1 192.7H794.1L790.6 186.7L794.1 180.7H801.1L804.6 186.7L801.1 192.7Z" fill="white" />
            <path d="M788 185.2H781.1L777.6 179.2L781.1 173.1H788L791.5 179.2L788 185.2Z" fill="white" />
            <path d="M775 177.7H768L764.5 171.6L768 165.6H775L778.5 171.6L775 177.7Z" fill="white" />
            <path d="M761.9 170.1H755L751.5 164.1L755 158.1H761.9L765.4 164.1L761.9 170.1Z" fill="white" />
            <path d="M747.1 159.6H743.6L741.9 156.6L743.6 153.5H747.1L748.9 156.6L747.1 159.6Z" fill="white" />
            <path d="M735.8 155.1H728.9L725.4 149L728.9 143H735.8L739.3 149L735.8 155.1Z" fill="white" />
            <path d="M722.801 147.5H715.801L712.301 141.5L715.801 135.5H722.801L726.301 141.5L722.801 147.5Z" fill="white" />
            <path d="M735.8 667.5H728.9L725.4 661.4L728.9 655.4H735.8L739.3 661.4L735.8 667.5Z" fill="white" />
            <path d="M748.9 659.9H741.9L738.4 653.9L741.9 647.9H748.9L752.4 653.9L748.9 659.9Z" fill="white" />
            <path d="M761.9 652.4H755L751.5 646.4L755 640.3H761.9L765.4 646.4L761.9 652.4Z" fill="white" />
            <path d="M775 644.9H768L764.5 638.8L768 632.8H775L778.5 638.8L775 644.9Z" fill="white" />
            <path d="M788 637.3H781.1L777.6 631.3L781.1 625.3H788L791.5 631.3L788 637.3Z" fill="white" />
            <path d="M801.1 629.8H794.1L790.6 623.8L794.1 617.7H801.1L804.6 623.8L801.1 629.8Z" fill="white" />
            <path d="M811.2 617.2H810.1L809.6 616.2L810.1 615.3H811.2L811.7 616.2L811.2 617.2Z" fill="white" />
            <path d="M853.301 599.7H846.301L842.801 593.6L846.301 587.6H853.301L856.801 593.6L853.301 599.7Z" fill="white" />
            <path d="M866.3 592.1H859.4L855.9 586.1L859.4 580.1H866.3L869.8 586.1L866.3 592.1Z" fill="white" />
            <path d="M943.4 394.1H938.9L936.6 390.2L938.9 386.2H943.4L945.7 390.2L943.4 394.1Z" fill="white" />
            <path d="M944.699 381.1H937.699L934.199 375.1L937.699 369.1H944.699L948.099 375.1L944.699 381.1Z" fill="white" />
            <path d="M944.699 366.1H937.699L934.199 360L937.699 354H944.699L948.099 360L944.699 366.1Z" fill="white" />
            <path d="M944.699 351H937.699L934.199 345L937.699 338.9H944.699L948.099 345L944.699 351Z" fill="white" />
            <path d="M944.699 335.9H937.699L934.199 329.9L937.699 323.9H944.699L948.099 329.9L944.699 335.9Z" fill="white" />
            <path d="M944.699 320.8H937.699L934.199 314.8L937.699 308.8H944.699L948.099 314.8L944.699 320.8Z" fill="white" />
            <path d="M944.699 305.8H937.699L934.199 299.7L937.699 293.7H944.699L948.099 299.7L944.699 305.8Z" fill="white" />
            <path d="M944.699 290.7H937.699L934.199 284.7L937.699 278.6H944.699L948.099 284.7L944.699 290.7Z" fill="white" />
            <path d="M944.699 275.6H937.699L934.199 269.6L937.699 263.6H944.699L948.099 269.6L944.699 275.6Z" fill="white" />
            <path d="M944.699 260.6H937.699L934.199 254.5L937.699 248.5H944.699L948.099 254.5L944.699 260.6Z" fill="white" />
            <path d="M931.599 253H924.599L921.199 247L924.599 241H931.599L935.099 247L931.599 253Z" fill="white" />
            <path d="M918.5 245.5H911.6L908.1 239.5L911.6 233.4H918.5L922 239.5L918.5 245.5Z" fill="white" />
            <path d="M905.5 238H898.5L895.1 231.9L898.5 225.9H905.5L909 231.9L905.5 238Z" fill="white" />
            <path d="M892.4 230.4H885.5L882 224.4L885.5 218.4H892.4L895.9 224.4L892.4 230.4Z" fill="white" />
            <path d="M879.4 222.9H872.4L869 216.9L872.4 210.8H879.4L882.9 216.9L879.4 222.9Z" fill="white" />
            <path d="M866.3 215.3H859.4L855.9 209.3L859.4 203.3H866.3L869.8 209.3L866.3 215.3Z" fill="white" />
            <path d="M853.301 207.8H846.301L842.801 201.8L846.301 195.8H853.301L856.801 201.8L853.301 207.8Z" fill="white" />
            <path d="M840.201 200.3H833.301L829.801 194.2L833.301 188.2H840.201L843.701 194.2L840.201 200.3Z" fill="white" />
            <path d="M827.199 192.7H820.199L816.699 186.7L820.199 180.7H827.199L830.699 186.7L827.199 192.7Z" fill="white" />
            <path d="M814.099 185.2H807.199L803.699 179.2L807.199 173.1H814.099L817.599 179.2L814.099 185.2Z" fill="white" />
            <path d="M801.1 177.7H794.1L790.6 171.6L794.1 165.6H801.1L804.6 171.6L801.1 177.7Z" fill="white" />
            <path d="M788 170.1H781.1L777.6 164.1L781.1 158.1H788L791.5 164.1L788 170.1Z" fill="white" />
            <path d="M775 162.6H768L764.5 156.6L768 150.5H775L778.5 156.6L775 162.6Z" fill="white" />
            <path d="M761.9 155.1H755L751.5 149L755 143H761.9L765.4 149L761.9 155.1Z" fill="white" />
            <path d="M748.8 147.5H742L738.5 141.5L742 135.5H748.8L752.3 141.5L748.8 147.5Z" fill="white" />
            <path d="M735.8 140H728.9L725.4 134L728.9 127.9H735.8L739.3 134L735.8 140Z" fill="white" />
            <path d="M722 131H716.6L714 126.4L716.6 121.8H722L724.6 126.4L722 131Z" fill="white" />
            <path d="M735.401 681.7H729.301L726.301 676.5L729.301 671.3H735.401L738.401 676.5L735.401 681.7Z" fill="white" />
            <path d="M748.9 675H741.9L738.4 669L741.9 663H748.9L752.4 669L748.9 675Z" fill="white" />
            <path d="M761.9 667.5H755L751.5 661.4L755 655.4H761.9L765.4 661.4L761.9 667.5Z" fill="white" />
            <path d="M775 659.9H768L764.5 653.9L768 647.9H775L778.5 653.9L775 659.9Z" fill="white" />
            <path d="M788 652.4H781.1L777.6 646.4L781.1 640.3H788L791.5 646.4L788 652.4Z" fill="white" />
            <path d="M798.201 639.9H797.001L796.301 638.8L797.001 637.7H798.201L798.901 638.8L798.201 639.9Z" fill="white" />
            <path d="M838.1 618.5H835.4L834.1 616.2L835.4 614H838.1L839.4 616.2L838.1 618.5Z" fill="white" />
            <path d="M853.301 614.7H846.301L842.801 608.7L846.301 602.7H853.301L856.801 608.7L853.301 614.7Z" fill="white" />
            <path d="M866.1 606.8H859.6L856.4 601.2L859.6 595.5H866.1L869.4 601.2L866.1 606.8Z" fill="white" />
            <path d="M957.5 403.4H950.9L947.6 397.7L950.9 392H957.5L960.8 397.7L957.5 403.4Z" fill="white" />
            <path d="M957.701 388.7H950.701L947.301 382.6L950.701 376.6H957.701L961.201 382.6L957.701 388.7Z" fill="white" />
            <path d="M957.701 373.6H950.701L947.301 367.6L950.701 361.5H957.701L961.201 367.6L957.701 373.6Z" fill="white" />
            <path d="M957.701 358.5H950.701L947.301 352.5L950.701 346.5H957.701L961.201 352.5L957.701 358.5Z" fill="white" />
            <path d="M957.701 343.4H950.701L947.301 337.4L950.701 331.4H957.701L961.201 337.4L957.701 343.4Z" fill="white" />
            <path d="M957.701 328.4H950.701L947.301 322.4L950.701 316.3H957.701L961.201 322.4L957.701 328.4Z" fill="white" />
            <path d="M957.701 313.3H950.701L947.301 307.3L950.701 301.3H957.701L961.201 307.3L957.701 313.3Z" fill="white" />
            <path d="M957.701 298.2H950.701L947.301 292.2L950.701 286.2H957.701L961.201 292.2L957.701 298.2Z" fill="white" />
            <path d="M957.701 283.2H950.701L947.301 277.1L950.701 271.1H957.701L961.201 277.1L957.701 283.2Z" fill="white" />
            <path d="M957.701 268.1H950.701L947.301 262.1L950.701 256H957.701L961.201 262.1L957.701 268.1Z" fill="white" />
            <path d="M957.701 253H950.701L947.301 247L950.701 241H957.701L961.201 247L957.701 253Z" fill="white" />
            <path d="M944.699 245.5H937.699L934.199 239.5L937.699 233.4H944.699L948.099 239.5L944.699 245.5Z" fill="white" />
            <path d="M931.599 238H924.599L921.199 231.9L924.599 225.9H931.599L935.099 231.9L931.599 238Z" fill="white" />
            <path d="M918.5 230.4H911.6L908.1 224.4L911.6 218.4H918.5L922 224.4L918.5 230.4Z" fill="white" />
            <path d="M905.5 222.9H898.5L895.1 216.9L898.5 210.8H905.5L909 216.9L905.5 222.9Z" fill="white" />
            <path d="M892.4 215.3H885.5L882 209.3L885.5 203.3H892.4L895.9 209.3L892.4 215.3Z" fill="white" />
            <path d="M879.4 207.8H872.4L869 201.8L872.4 195.8H879.4L882.9 201.8L879.4 207.8Z" fill="white" />
            <path d="M866.3 200.3H859.4L855.9 194.2L859.4 188.2H866.3L869.8 194.2L866.3 200.3Z" fill="white" />
            <path d="M853.301 192.7H846.301L842.801 186.7L846.301 180.7H853.301L856.801 186.7L853.301 192.7Z" fill="white" />
            <path d="M840.201 185.2H833.301L829.801 179.2L833.301 173.1H840.201L843.701 179.2L840.201 185.2Z" fill="white" />
            <path d="M827.199 177.7H820.199L816.699 171.6L820.199 165.6H827.199L830.699 171.6L827.199 177.7Z" fill="white" />
            <path d="M814.099 170.1H807.199L803.699 164.1L807.199 158.1H814.099L817.599 164.1L814.099 170.1Z" fill="white" />
            <path d="M800.3 161.3H794.9L792.1 156.6L794.9 151.8H800.3L803.1 156.6L800.3 161.3Z" fill="white" />
            <path d="M788 155.1H781.1L777.6 149L781.1 143H788L791.5 149L788 155.1Z" fill="white" />
            <path d="M775 147.5H768L764.5 141.5L768 135.5H775L778.5 141.5L775 147.5Z" fill="white" />
            <path d="M761.9 140H755L751.5 134L755 127.9H761.9L765.4 134L761.9 140Z" fill="white" />
            <path d="M748.9 132.5H741.9L738.4 126.4L741.9 120.4H748.9L752.4 126.4L748.9 132.5Z" fill="white" />
            <path d="M735.399 124.2H729.299L726.199 118.9L729.299 113.6H735.399L738.499 118.9L735.399 124.2Z" fill="white" />
            <path d="M604.8 154.1H598.9L596 149L598.9 144H604.8L607.7 149L604.8 154.1Z" fill="white" />
            <path d="M591.9 162H585.6L582.5 156.6L585.6 151.1H591.9L595.1 156.6L591.9 162Z" fill="white" />
            <path d="M435.5 267.9H428.8L425.4 262.1L428.8 256.2H435.5L438.9 262.1L435.5 267.9Z" fill="white" />
            <path d="M494.1 441.7H487.4L484 435.9L487.4 430H494.1L497.5 435.9L494.1 441.7Z" fill="white" />
            <path d="M747.3 687.3H743.5L741.6 684L743.5 680.8H747.3L749.2 684L747.3 687.3Z" fill="white" />
            <path d="M761.2 681.2H755.7L753 676.5L755.7 671.8H761.2L763.9 676.5L761.2 681.2Z" fill="white" />
            <path d="M774.899 674.8H768.099L764.699 669L768.099 663.1H774.899L778.299 669L774.899 674.8Z" fill="white" />
            <path d="M785.201 662.5H783.901L783.301 661.4L783.901 660.4H785.201L785.801 661.4L785.201 662.5Z" fill="white" />
            <path d="M837.999 633.5H835.499L834.199 631.3L835.499 629.1H837.999L839.299 631.3L837.999 633.5Z" fill="white" />
            <path d="M853.301 629.8H846.301L842.801 623.8L846.301 617.7H853.301L856.801 623.8L853.301 629.8Z" fill="white" />
            <path d="M865 620H860.7L858.5 616.2L860.7 612.5H865L867.2 616.2L865 620Z" fill="white" />
            <path d="M968.3 437.2H966.2L965.1 435.4L966.2 433.5H968.3L969.4 435.4L968.3 437.2Z" fill="white" />
            <path d="M970.599 426H963.999L960.699 420.3L963.999 414.6H970.599L973.899 420.3L970.599 426Z" fill="white" />
            <path d="M970.801 411.3H963.801L960.301 405.2L963.801 399.2H970.801L974.201 405.2L970.801 411.3Z" fill="white" />
            <path d="M970.801 396.2H963.801L960.301 390.2L963.801 384.1H970.801L974.201 390.2L970.801 396.2Z" fill="white" />
            <path d="M970.801 381.1H963.801L960.301 375.1L963.801 369.1H970.801L974.201 375.1L970.801 381.1Z" fill="white" />
            <path d="M970.801 366.1H963.801L960.301 360L963.801 354H970.801L974.201 360L970.801 366.1Z" fill="white" />
            <path d="M970.801 351H963.801L960.301 345L963.801 338.9H970.801L974.201 345L970.801 351Z" fill="white" />
            <path d="M970.801 335.9H963.801L960.301 329.9L963.801 323.9H970.801L974.201 329.9L970.801 335.9Z" fill="white" />
            <path d="M970.801 320.8H963.801L960.301 314.8L963.801 308.8H970.801L974.201 314.8L970.801 320.8Z" fill="white" />
            <path d="M970.801 305.8H963.801L960.301 299.7L963.801 293.7H970.801L974.201 299.7L970.801 305.8Z" fill="white" />
            <path d="M970.801 290.7H963.801L960.301 284.7L963.801 278.6H970.801L974.201 284.7L970.801 290.7Z" fill="white" />
            <path d="M970.801 275.6H963.801L960.301 269.6L963.801 263.6H970.801L974.201 269.6L970.801 275.6Z" fill="white" />
            <path d="M970.801 260.6H963.801L960.301 254.5L963.801 248.5H970.801L974.201 254.5L970.801 260.6Z" fill="white" />
            <path d="M970.801 245.5H963.801L960.301 239.5L963.801 233.4H970.801L974.201 239.5L970.801 245.5Z" fill="white" />
            <path d="M957.701 238H950.701L947.301 231.9L950.701 225.9H957.701L961.201 231.9L957.701 238Z" fill="white" />
            <path d="M944.699 230.4H937.699L934.199 224.4L937.699 218.4H944.699L948.099 224.4L944.699 230.4Z" fill="white" />
            <path d="M931.599 222.9H924.599L921.199 216.9L924.599 210.8H931.599L935.099 216.9L931.599 222.9Z" fill="white" />
            <path d="M918.5 215.3H911.6L908.1 209.3L911.6 203.3H918.5L922 209.3L918.5 215.3Z" fill="white" />
            <path d="M905.5 207.8H898.5L895.1 201.8L898.5 195.8H905.5L909 201.8L905.5 207.8Z" fill="white" />
            <path d="M892.4 200.3H885.5L882 194.2L885.5 188.2H892.4L895.9 194.2L892.4 200.3Z" fill="white" />
            <path d="M879.4 192.7H872.4L869 186.7L872.4 180.7H879.4L882.9 186.7L879.4 192.7Z" fill="white" />
            <path d="M866.3 185.2H859.4L855.9 179.2L859.4 173.1H866.3L869.8 179.2L866.3 185.2Z" fill="white" />
            <path d="M853.301 177.7H846.301L842.801 171.6L846.301 165.6H853.301L856.801 171.6L853.301 177.7Z" fill="white" />
            <path d="M840.201 170.1H833.301L829.801 164.1L833.301 158.1H840.201L843.701 164.1L840.201 170.1Z" fill="white" />
            <path d="M827.199 162.6H820.199L816.699 156.6L820.199 150.5H827.199L830.699 156.6L827.199 162.6Z" fill="white" />
            <path d="M813.899 154.7H807.399L804.199 149L807.399 143.4H813.899L817.199 149L813.899 154.7Z" fill="white" />
            <path d="M788 140H781.1L777.6 134L781.1 128H788L791.5 134L788 140Z" fill="white" />
            <path d="M775 132.5H768L764.5 126.4L768 120.4H775L778.5 126.4L775 132.5Z" fill="white" />
            <path d="M761.9 124.9H755L751.5 118.9L755 112.9H761.9L765.4 118.9L761.9 124.9Z" fill="white" />
            <path d="M748.4 116.6H742.4L739.4 111.4L742.4 106.1H748.4L751.4 111.4L748.4 116.6Z" fill="white" />
            <path d="M591.801 146.7H585.801L582.801 141.5L585.801 136.3H591.801L594.801 141.5L591.801 146.7Z" fill="white" />
            <path d="M579.1 155H572.3L568.9 149L572.3 143.1H579.1L582.6 149L579.1 155Z" fill="white" />
            <path d="M422.2 244.8H416L412.9 239.5L416 234.1H422.2L425.3 239.5L422.2 244.8Z" fill="white" />
            <path d="M421.6 258.9H416.6L414.1 254.5L416.6 250.2H421.6L424.1 254.5L421.6 258.9Z" fill="white" />
            <path d="M851.399 641.5H848.299L846.699 638.8L848.299 636.2H851.399L852.899 638.8L851.399 641.5Z" fill="white" />
            <path d="M981.8 460.5H978.9L977.4 458L978.9 455.5H981.8L983.2 458L981.8 460.5Z" fill="white" />
            <path d="M983.8 448.9H976.9L973.4 442.9L976.9 436.9H983.8L987.3 442.9L983.8 448.9Z" fill="white" />
            <path d="M983.8 433.9H976.8L973.4 427.8L976.8 421.8H983.8L987.3 427.8L983.8 433.9Z" fill="white" />
            <path d="M983.8 418.8H976.8L973.4 412.8L976.8 406.7H983.8L987.3 412.8L983.8 418.8Z" fill="white" />
            <path d="M983.8 403.7H976.8L973.4 397.7L976.8 391.7H983.8L987.3 397.7L983.8 403.7Z" fill="white" />
            <path d="M983.8 388.7H976.8L973.4 382.6L976.8 376.6H983.8L987.3 382.6L983.8 388.7Z" fill="white" />
            <path d="M983.8 373.6H976.8L973.4 367.6L976.8 361.5H983.8L987.3 367.6L983.8 373.6Z" fill="white" />
            <path d="M983.8 358.5H976.8L973.4 352.5L976.8 346.5H983.8L987.3 352.5L983.8 358.5Z" fill="white" />
            <path d="M983.8 343.4H976.8L973.4 337.4L976.8 331.4H983.8L987.3 337.4L983.8 343.4Z" fill="white" />
            <path d="M983.8 328.4H976.8L973.4 322.4L976.8 316.3H983.8L987.3 322.4L983.8 328.4Z" fill="white" />
            <path d="M983.8 313.3H976.8L973.4 307.3L976.8 301.3H983.8L987.3 307.3L983.8 313.3Z" fill="white" />
            <path d="M983.8 298.2H976.8L973.4 292.2L976.8 286.2H983.8L987.3 292.2L983.8 298.2Z" fill="white" />
            <path d="M983.8 283.2H976.8L973.4 277.1L976.8 271.1H983.8L987.3 277.1L983.8 283.2Z" fill="white" />
            <path d="M983.8 268.1H976.8L973.4 262.1L976.8 256H983.8L987.3 262.1L983.8 268.1Z" fill="white" />
            <path d="M983.8 253H976.8L973.4 247L976.8 241H983.8L987.3 247L983.8 253Z" fill="white" />
            <path d="M983.8 238H976.8L973.4 231.9L976.8 225.9H983.8L987.3 231.9L983.8 238Z" fill="white" />
            <path d="M970.801 230.4H963.801L960.301 224.4L963.801 218.4H970.801L974.201 224.4L970.801 230.4Z" fill="white" />
            <path d="M957.701 222.9H950.701L947.301 216.9L950.701 210.8H957.701L961.201 216.9L957.701 222.9Z" fill="white" />
            <path d="M944.699 215.3H937.699L934.199 209.3L937.699 203.3H944.699L948.099 209.3L944.699 215.3Z" fill="white" />
            <path d="M931.599 207.8H924.599L921.199 201.8L924.599 195.8H931.599L935.099 201.8L931.599 207.8Z" fill="white" />
            <path d="M918.5 200.3H911.6L908.1 194.2L911.6 188.2H918.5L922 194.2L918.5 200.3Z" fill="white" />
            <path d="M905.5 192.7H898.5L895.1 186.7L898.5 180.7H905.5L909 186.7L905.5 192.7Z" fill="white" />
            <path d="M892.4 185.2H885.5L882 179.2L885.5 173.1H892.4L895.9 179.2L892.4 185.2Z" fill="white" />
            <path d="M879.4 177.7H872.4L869 171.6L872.4 165.6H879.4L882.9 171.6L879.4 177.7Z" fill="white" />
            <path d="M866.3 170.1H859.4L855.9 164.1L859.4 158.1H866.3L869.8 164.1L866.3 170.1Z" fill="white" />
            <path d="M853.301 162.6H846.301L842.801 156.6L846.301 150.5H853.301L856.801 156.6L853.301 162.6Z" fill="white" />
            <path d="M840.201 155.1H833.301L829.801 149L833.301 143H840.201L843.701 149L840.201 155.1Z" fill="white" />
            <path d="M827.1 147.4H820.3L816.9 141.5L820.3 135.6H827.1L830.5 141.5L827.1 147.4Z" fill="white" />
            <path d="M813.9 139.6H807.4L804.1 134L807.4 128.3H813.9L817.2 134L813.9 139.6Z" fill="white" />
            <path d="M800.9 132.2H794.3L791 126.4L794.3 120.7H800.9L804.2 126.4L800.9 132.2Z" fill="white" />
            <path d="M787.3 123.7H781.8L779 118.9L781.8 114H787.3L790.1 118.9L787.3 123.7Z" fill="white" />
            <path d="M774.3 116.2H768.7L765.9 111.4L768.7 106.5H774.3L777.1 111.4L774.3 116.2Z" fill="white" />
            <path d="M760.501 107.4H756.401L754.301 103.8L756.401 100.2H760.501L762.601 103.8L760.501 107.4Z" fill="white" />
            <path d="M577.4 136.9H574L572.4 134L574 131.1H577.4L579.1 134L577.4 136.9Z" fill="white" />
            <path d="M485.9 189.3H482.9L481.4 186.7L482.9 184.1H485.9L487.4 186.7L485.9 189.3Z" fill="white" />
            <path d="M421.4 228.5H416.8L414.4 224.4L416.8 220.3H421.4L423.8 224.4L421.4 228.5Z" fill="white" />
            <path d="M409.5 238H402.6L399.1 231.9L402.6 225.9H409.5L413 231.9L409.5 238Z" fill="white" />
            <path d="M407.999 250.4H404.099L402.199 247L404.099 243.6H407.999L409.899 247L407.999 250.4Z" fill="white" />
            <path d="M995.901 469.9H990.801L988.301 465.5L990.801 461.1H995.901L998.501 465.5L995.901 469.9Z" fill="white" />
            <path d="M996.9 456.5H989.9L986.4 450.5L989.9 444.4H996.9L1000.3 450.5L996.9 456.5Z" fill="white" />
            <path d="M996.9 441.4H989.9L986.4 435.4L989.9 429.4H996.9L1000.3 435.4L996.9 441.4Z" fill="white" />
            <path d="M996.9 426.3H989.9L986.4 420.3L989.9 414.3H996.9L1000.3 420.3L996.9 426.3Z" fill="white" />
            <path d="M996.9 411.3H989.9L986.4 405.2L989.9 399.2H996.9L1000.3 405.2L996.9 411.3Z" fill="white" />
            <path d="M996.9 396.2H989.9L986.4 390.2L989.9 384.1H996.9L1000.3 390.2L996.9 396.2Z" fill="white" />
            <path d="M996.9 381.1H989.9L986.4 375.1L989.9 369.1H996.9L1000.3 375.1L996.9 381.1Z" fill="white" />
            <path d="M996.9 366.1H989.9L986.4 360L989.9 354H996.9L1000.3 360L996.9 366.1Z" fill="white" />
            <path d="M996.9 351H989.9L986.4 345L989.9 338.9H996.9L1000.3 345L996.9 351Z" fill="white" />
            <path d="M996.9 335.9H989.9L986.4 329.9L989.9 323.9H996.9L1000.3 329.9L996.9 335.9Z" fill="white" />
            <path d="M996.9 320.8H989.9L986.4 314.8L989.9 308.8H996.9L1000.3 314.8L996.9 320.8Z" fill="white" />
            <path d="M996.9 305.8H989.9L986.4 299.7L989.9 293.7H996.9L1000.3 299.7L996.9 305.8Z" fill="white" />
            <path d="M996.9 290.7H989.9L986.4 284.7L989.9 278.6H996.9L1000.3 284.7L996.9 290.7Z" fill="white" />
            <path d="M996.9 275.6H989.9L986.4 269.6L989.9 263.6H996.9L1000.3 269.6L996.9 275.6Z" fill="white" />
            <path d="M996.9 260.6H989.9L986.4 254.5L989.9 248.5H996.9L1000.3 254.5L996.9 260.6Z" fill="white" />
            <path d="M996.9 245.5H989.9L986.4 239.5L989.9 233.4H996.9L1000.3 239.5L996.9 245.5Z" fill="white" />
            <path d="M996.9 230.4H989.9L986.4 224.4L989.9 218.4H996.9L1000.3 224.4L996.9 230.4Z" fill="white" />
            <path d="M983.8 222.9H976.8L973.4 216.9L976.8 210.8H983.8L987.3 216.9L983.8 222.9Z" fill="white" />
            <path d="M970.801 215.3H963.801L960.301 209.3L963.801 203.3H970.801L974.201 209.3L970.801 215.3Z" fill="white" />
            <path d="M957.701 207.8H950.701L947.301 201.8L950.701 195.8H957.701L961.201 201.8L957.701 207.8Z" fill="white" />
            <path d="M944.699 200.3H937.699L934.199 194.2L937.699 188.2H944.699L948.099 194.2L944.699 200.3Z" fill="white" />
            <path d="M931.599 192.7H924.599L921.199 186.7L924.599 180.7H931.599L935.099 186.7L931.599 192.7Z" fill="white" />
            <path d="M918.5 185.2H911.6L908.1 179.2L911.6 173.1H918.5L922 179.2L918.5 185.2Z" fill="white" />
            <path d="M905.5 177.7H898.5L895.1 171.6L898.5 165.6H905.5L909 171.6L905.5 177.7Z" fill="white" />
            <path d="M892.4 170.1H885.5L882 164.1L885.5 158.1H892.4L895.9 164.1L892.4 170.1Z" fill="white" />
            <path d="M879.4 162.6H872.4L869 156.6L872.4 150.5H879.4L882.9 156.6L879.4 162.6Z" fill="white" />
            <path d="M866.3 155.1H859.4L855.9 149L859.4 143H866.3L869.8 149L866.3 155.1Z" fill="white" />
            <path d="M853.301 147.5H846.301L842.801 141.5L846.301 135.5H853.301L856.801 141.5L853.301 147.5Z" fill="white" />
            <path d="M839.001 137.8H834.501L832.301 134L834.501 130.1H839.001L841.201 134L839.001 137.8Z" fill="white" />
            <path d="M826.701 131.6H820.701L817.801 126.4L820.701 121.3H826.701L829.701 126.4L826.701 131.6Z" fill="white" />
            <path d="M722.8 72.1996H715.8L712.4 66.0996L715.8 60.0996H722.8L726.2 66.0996L722.8 72.1996Z" fill="white" />
            <path d="M708.4 62.3004H704.1L701.9 58.6004L704.1 54.9004H708.4L710.6 58.6004L708.4 62.3004Z" fill="white" />
            <path d="M694.699 53.7H691.699L690.199 51.1L691.699 48.5H694.699L696.199 51.1L694.699 53.7Z" fill="white" />
            <path d="M498.2 165.5H496.6L495.9 164.1L496.6 162.8H498.2L499 164.1L498.2 165.5Z" fill="white" />
            <path d="M487.8 177.7H480.9L477.4 171.6L480.9 165.6H487.8L491.3 171.6L487.8 177.7Z" fill="white" />
            <path d="M474.4 184.5H468.2L465.1 179.2L468.2 173.8H474.4L477.5 179.2L474.4 184.5Z" fill="white" />
            <path d="M409.5 222.9H402.6L399.1 216.9L402.6 210.8H409.5L413 216.9L409.5 222.9Z" fill="white" />
            <path d="M396.5 230.4H389.5L386 224.4L389.5 218.4H396.5L400 224.4L396.5 230.4Z" fill="white" />
            <path d="M396.5 245.5H389.5L386 239.5L389.5 233.4H396.5L400 239.5L396.5 245.5Z" fill="white" />
            <path d="M396.4 260.5H389.6L386.1 254.5L389.6 248.6H396.4L399.9 254.5L396.4 260.5Z" fill="white" />
            <path d="M396 274.8H390L387 269.6L390 264.4H396L398.9 269.6L396 274.8Z" fill="white" />
            <path d="M1008.9 477.3H1004L1001.5 473.1L1004 468.8H1008.9L1011.4 473.1L1008.9 477.3Z" fill="white" />
            <path d="M1009.4 433H1003.4L1000.5 427.8L1003.4 422.7H1009.4L1012.4 427.8L1009.4 433Z" fill="white" />
            <path d="M1009.9 418.8H1002.9L999.5 412.8L1002.9 406.7H1009.9L1013.4 412.8L1009.9 418.8Z" fill="white" />
            <path d="M1009.9 403.7H1002.9L999.5 397.7L1002.9 391.7H1009.9L1013.4 397.7L1009.9 403.7Z" fill="white" />
            <path d="M1009.9 388.7H1002.9L999.5 382.6L1002.9 376.6H1009.9L1013.4 382.6L1009.9 388.7Z" fill="white" />
            <path d="M1009.9 373.6H1002.9L999.5 367.6L1002.9 361.5H1009.9L1013.4 367.6L1009.9 373.6Z" fill="white" />
            <path d="M1009.9 358.5H1002.9L999.5 352.5L1002.9 346.5H1009.9L1013.4 352.5L1009.9 358.5Z" fill="white" />
            <path d="M1009.9 343.4H1002.9L999.5 337.4L1002.9 331.4H1009.9L1013.4 337.4L1009.9 343.4Z" fill="white" />
            <path d="M1009.9 328.4H1002.9L999.5 322.4L1002.9 316.3H1009.9L1013.4 322.4L1009.9 328.4Z" fill="white" />
            <path d="M1009.9 313.3H1002.9L999.5 307.3L1002.9 301.3H1009.9L1013.4 307.3L1009.9 313.3Z" fill="white" />
            <path d="M1009.9 298.2H1002.9L999.5 292.2L1002.9 286.2H1009.9L1013.4 292.2L1009.9 298.2Z" fill="white" />
            <path d="M1009.9 283.2H1002.9L999.5 277.1L1002.9 271.1H1009.9L1013.4 277.1L1009.9 283.2Z" fill="white" />
            <path d="M1009.9 268.1H1002.9L999.5 262.1L1002.9 256H1009.9L1013.4 262.1L1009.9 268.1Z" fill="white" />
            <path d="M1009.9 253H1002.9L999.5 247L1002.9 241H1009.9L1013.4 247L1009.9 253Z" fill="white" />
            <path d="M1009.9 238H1002.9L999.5 231.9L1002.9 225.9H1009.9L1013.4 231.9L1009.9 238Z" fill="white" />
            <path d="M1009.9 222.9H1002.9L999.5 216.9L1002.9 210.8H1009.9L1013.4 216.9L1009.9 222.9Z" fill="white" />
            <path d="M996.9 215.3H989.9L986.4 209.3L989.9 203.3H996.9L1000.3 209.3L996.9 215.3Z" fill="white" />
            <path d="M983.8 207.8H976.8L973.4 201.8L976.8 195.8H983.8L987.3 201.8L983.8 207.8Z" fill="white" />
            <path d="M970.801 200.3H963.801L960.301 194.2L963.801 188.2H970.801L974.201 194.2L970.801 200.3Z" fill="white" />
            <path d="M957.701 192.7H950.701L947.301 186.7L950.701 180.7H957.701L961.201 186.7L957.701 192.7Z" fill="white" />
            <path d="M944.699 185.2H937.699L934.199 179.2L937.699 173.1H944.699L948.099 179.2L944.699 185.2Z" fill="white" />
            <path d="M931.599 177.7H924.599L921.199 171.6L924.599 165.6H931.599L935.099 171.6L931.599 177.7Z" fill="white" />
            <path d="M918.5 170.1H911.6L908.1 164.1L911.6 158.1H918.5L922 164.1L918.5 170.1Z" fill="white" />
            <path d="M905.5 162.6H898.5L895.1 156.6L898.5 150.5H905.5L909 156.6L905.5 162.6Z" fill="white" />
            <path d="M892.4 155.1H885.5L882 149L885.5 143H892.4L895.9 149L892.4 155.1Z" fill="white" />
            <path d="M879.4 147.5H872.4L869 141.5L872.4 135.5H879.4L882.9 141.5L879.4 147.5Z" fill="white" />
            <path d="M866.3 140H859.4L855.9 134L859.4 127.9H866.3L869.8 134L866.3 140Z" fill="white" />
            <path d="M852.2 130.5H847.5L845.1 126.4L847.5 122.4H852.2L854.5 126.4L852.2 130.5Z" fill="white" />
            <path d="M746.1 67.3H744.7L744 66.1L744.7 65H746.1L746.8 66.1L746.1 67.3Z" fill="white" />
            <path d="M733.7 61.0002H730.9L729.5 58.6002L730.9 56.2002H733.7L735.2 58.6002L733.7 61.0002Z" fill="white" />
            <path d="M722.801 57.1H715.801L712.301 51.1L715.801 45H722.801L726.301 51.1L722.801 57.1Z" fill="white" />
            <path d="M709.701 49.6H702.801L699.301 43.5L702.801 37.5H709.701L713.201 43.5L709.701 49.6Z" fill="white" />
            <path d="M696.699 42H689.699L686.199 36L689.699 30H696.699L700.099 36L696.699 42Z" fill="white" />
            <path d="M576.999 106H574.499L573.199 103.8L574.499 101.6H576.999L578.199 103.8L576.999 106Z" fill="white" />
            <path d="M564.8 115H560.6L558.4 111.4L560.6 107.7H564.8L566.9 111.4L564.8 115Z" fill="white" />
            <path d="M553.099 124.9H546.199L542.699 118.9L546.199 112.9H553.099L556.499 118.9L553.099 124.9Z" fill="white" />
            <path d="M540 132.5H533.1L529.6 126.4L533.1 120.4H540L543.5 126.4L540 132.5Z" fill="white" />
            <path d="M527 140H520L516.6 134L520 128H527L530.4 134L527 140Z" fill="white" />
            <path d="M513.7 147.1H507.3L504 141.5L507.3 135.9H513.7L516.9 141.5L513.7 147.1Z" fill="white" />
            <path d="M500.499 154.4H494.299L491.199 149L494.299 143.6H500.499L503.699 149L500.499 154.4Z" fill="white" />
            <path d="M487.8 162.6H480.9L477.4 156.6L480.9 150.5H487.8L491.3 156.6L487.8 162.6Z" fill="white" />
            <path d="M474.801 170.1H467.801L464.301 164.1L467.801 158.1H474.801L478.301 164.1L474.801 170.1Z" fill="white" />
            <path d="M460.9 176.2H455.6L453 171.6L455.6 167.1H460.9L463.5 171.6L460.9 176.2Z" fill="white" />
            <path d="M409.401 207.6H402.701L399.301 201.8L402.701 196H409.401L412.801 201.8L409.401 207.6Z" fill="white" />
            <path d="M396.5 215.3H389.5L386 209.3L389.5 203.3H396.5L400 209.3L396.5 215.3Z" fill="white" />
            <path d="M383.4 222.9H376.5L373 216.9L376.5 210.8H383.4L386.9 216.9L383.4 222.9Z" fill="white" />
            <path d="M383.4 238H376.5L373 231.9L376.5 225.9H383.4L386.9 231.9L383.4 238Z" fill="white" />
            <path d="M383.4 253H376.5L373 247L376.5 241H383.4L386.9 247L383.4 253Z" fill="white" />
            <path d="M383.4 268.1H376.5L373 262.1L376.5 256H383.4L386.9 262.1L383.4 268.1Z" fill="white" />
            <path d="M382.2 281H377.7L375.5 277.1L377.7 273.3H382.2L384.4 277.1L382.2 281Z" fill="white" />
            <path d="M1020.7 422.4H1018.3L1017.1 420.3L1018.3 418.2H1020.7L1021.9 420.3L1020.7 422.4Z" fill="white" />
            <path d="M1023 411.3H1016L1012.5 405.2L1016 399.2H1023L1026.4 405.2L1023 411.3Z" fill="white" />
            <path d="M1023 396.2H1016L1012.5 390.2L1016 384.1H1023L1026.4 390.2L1023 396.2Z" fill="white" />
            <path d="M1023 381.1H1016L1012.5 375.1L1016 369.1H1023L1026.4 375.1L1023 381.1Z" fill="white" />
            <path d="M1023 366.1H1016L1012.5 360L1016 354H1023L1026.4 360L1023 366.1Z" fill="white" />
            <path d="M1023 351H1016L1012.5 345L1016 338.9H1023L1026.4 345L1023 351Z" fill="white" />
            <path d="M1023 335.9H1016L1012.5 329.9L1016 323.9H1023L1026.4 329.9L1023 335.9Z" fill="white" />
            <path d="M1023 320.8H1016L1012.5 314.8L1016 308.8H1023L1026.4 314.8L1023 320.8Z" fill="white" />
            <path d="M1023 305.8H1016L1012.5 299.7L1016 293.7H1023L1026.4 299.7L1023 305.8Z" fill="white" />
            <path d="M1023 290.7H1016L1012.5 284.7L1016 278.6H1023L1026.4 284.7L1023 290.7Z" fill="white" />
            <path d="M1023 275.6H1016L1012.5 269.6L1016 263.6H1023L1026.4 269.6L1023 275.6Z" fill="white" />
            <path d="M1023 260.6H1016L1012.5 254.5L1016 248.5H1023L1026.4 254.5L1023 260.6Z" fill="white" />
            <path d="M1023 245.5H1016L1012.5 239.5L1016 233.4H1023L1026.4 239.5L1023 245.5Z" fill="white" />
            <path d="M1023 230.4H1016L1012.5 224.4L1016 218.4H1023L1026.4 224.4L1023 230.4Z" fill="white" />
            <path d="M1023 215.3H1016L1012.5 209.3L1016 203.3H1023L1026.4 209.3L1023 215.3Z" fill="white" />
            <path d="M1009.9 207.8H1002.9L999.5 201.8L1002.9 195.8H1009.9L1013.4 201.8L1009.9 207.8Z" fill="white" />
            <path d="M996.9 200.3H989.9L986.4 194.2L989.9 188.2H996.9L1000.3 194.2L996.9 200.3Z" fill="white" />
            <path d="M983.8 192.7H976.8L973.4 186.7L976.8 180.7H983.8L987.3 186.7L983.8 192.7Z" fill="white" />
            <path d="M970.801 185.2H963.801L960.301 179.2L963.801 173.1H970.801L974.201 179.2L970.801 185.2Z" fill="white" />
            <path d="M957.701 177.7H950.701L947.301 171.6L950.701 165.6H957.701L961.201 171.6L957.701 177.7Z" fill="white" />
            <path d="M944.699 170.1H937.699L934.199 164.1L937.699 158.1H944.699L948.099 164.1L944.699 170.1Z" fill="white" />
            <path d="M931.599 162.6H924.599L921.199 156.6L924.599 150.5H931.599L935.099 156.6L931.599 162.6Z" fill="white" />
            <path d="M918.5 155.1H911.6L908.1 149L911.6 143H918.5L922 149L918.5 155.1Z" fill="white" />
            <path d="M905.5 147.5H898.5L895.1 141.5L898.5 135.5H905.5L909 141.5L905.5 147.5Z" fill="white" />
            <path d="M892.4 140H885.5L882 134L885.5 127.9H892.4L895.9 134L892.4 140Z" fill="white" />
            <path d="M879.4 132.5H872.4L869 126.4L872.4 120.4H879.4L882.9 126.4L879.4 132.5Z" fill="white" />
            <path d="M863.899 120.8H861.799L860.699 118.9L861.799 117H863.899L864.999 118.9L863.899 120.8Z" fill="white" />
            <path d="M746.7 53.3004H744.1L742.9 51.1004L744.1 48.9004H746.7L747.9 51.1004L746.7 53.3004Z" fill="white" />
            <path d="M734.699 47.6H729.999L727.699 43.5L729.999 39.5H734.699L736.999 43.5L734.699 47.6Z" fill="white" />
            <path d="M721.001 39H717.501L715.801 36L717.501 33H721.001L722.801 36L721.001 39Z" fill="white" />
            <path d="M707.001 29.7002H705.501L704.801 28.5002L705.501 27.2002H707.001L707.701 28.5002L707.001 29.7002Z" fill="white" />
            <path d="M579.1 94.6004H572.4L569 88.8004L572.4 82.9004H579.1L582.4 88.8004L579.1 94.6004Z" fill="white" />
            <path d="M565 100.3H560.4L558.1 96.2998L560.4 92.2998H565L567.3 96.2998L565 100.3Z" fill="white" />
            <path d="M552.299 108.5H546.899L544.199 103.8L546.899 99.0996H552.299L555.099 103.8L552.299 108.5Z" fill="white" />
            <path d="M540 117.4H533.1L529.6 111.4L533.1 105.3H540L543.5 111.4L540 117.4Z" fill="white" />
            <path d="M527 124.9H520L516.6 118.9L520 112.9H527L530.5 118.9L527 124.9Z" fill="white" />
            <path d="M513.9 132.5H507L503.5 126.4L507 120.4H513.9L517.4 126.4L513.9 132.5Z" fill="white" />
            <path d="M500.9 140H493.9L490.5 134L493.9 127.9H500.9L504.4 134L500.9 140Z" fill="white" />
            <path d="M487.8 147.5H480.9L477.4 141.5L480.9 135.5H487.8L491.3 141.5L487.8 147.5Z" fill="white" />
            <path d="M474.801 155.1H467.801L464.301 149L467.801 143H474.801L478.301 149L474.801 155.1Z" fill="white" />
            <path d="M461.1 161.5H455.4L452.6 156.6L455.4 151.7H461.1L463.9 156.6L461.1 161.5Z" fill="white" />
            <path d="M406.6 187.7H405.5L404.9 186.7L405.5 185.7H406.6L407.2 186.7L406.6 187.7Z" fill="white" />
            <path d="M396 199.4H390L387 194.2L390 189.1H396L398.9 194.2L396 199.4Z" fill="white" />
            <path d="M383.4 207.8H376.5L373 201.8L376.5 195.8H383.4L386.9 201.8L383.4 207.8Z" fill="white" />
            <path d="M370.4 215.3H363.4L359.9 209.3L363.4 203.3H370.4L373.9 209.3L370.4 215.3Z" fill="white" />
            <path d="M370.4 230.4H363.4L359.9 224.4L363.4 218.4H370.4L373.9 224.4L370.4 230.4Z" fill="white" />
            <path d="M370.4 245.5H363.4L359.9 239.5L363.4 233.4H370.4L373.9 239.5L370.4 245.5Z" fill="white" />
            <path d="M370.4 260.6H363.4L359.9 254.5L363.4 248.5H370.4L373.9 254.5L370.4 260.6Z" fill="white" />
            <path d="M370.4 275.6H363.4L359.9 269.6L363.4 263.6H370.4L373.9 269.6L370.4 275.6Z" fill="white" />
            <path d="M368.5 287.5H365.3L363.6 284.7L365.3 281.8H368.5L370.2 284.7L368.5 287.5Z" fill="white" />
            <path d="M367.6 300.9H366.2L365.5 299.7L366.2 298.6H367.6L368.3 299.7L367.6 300.9Z" fill="white" />
            <path d="M1036 403.7H1029.1L1025.6 397.7L1029.1 391.7H1036L1039.5 397.7L1036 403.7Z" fill="white" />
            <path d="M1036 388.7H1029.1L1025.6 382.6L1029.1 376.6H1036L1039.5 382.6L1036 388.7Z" fill="white" />
            <path d="M1036 373.6H1029.1L1025.6 367.6L1029.1 361.5H1036L1039.5 367.6L1036 373.6Z" fill="white" />
            <path d="M1036 358.5H1029.1L1025.6 352.5L1029.1 346.5H1036L1039.5 352.5L1036 358.5Z" fill="white" />
            <path d="M1036 343.4H1029.1L1025.6 337.4L1029.1 331.4H1036L1039.5 337.4L1036 343.4Z" fill="white" />
            <path d="M1036 328.4H1029.1L1025.6 322.4L1029.1 316.3H1036L1039.5 322.4L1036 328.4Z" fill="white" />
            <path d="M1036 313.3H1029.1L1025.6 307.3L1029.1 301.3H1036L1039.5 307.3L1036 313.3Z" fill="white" />
            <path d="M1036 298.2H1029.1L1025.6 292.2L1029.1 286.2H1036L1039.5 292.2L1036 298.2Z" fill="white" />
            <path d="M1036 283.2H1029.1L1025.6 277.1L1029.1 271.1H1036L1039.5 277.1L1036 283.2Z" fill="white" />
            <path d="M1036 268.1H1029.1L1025.6 262.1L1029.1 256H1036L1039.5 262.1L1036 268.1Z" fill="white" />
            <path d="M1036 253H1029.1L1025.6 247L1029.1 241H1036L1039.5 247L1036 253Z" fill="white" />
            <path d="M1036 238H1029.1L1025.6 231.9L1029.1 225.9H1036L1039.5 231.9L1036 238Z" fill="white" />
            <path d="M1036 222.9H1029.1L1025.6 216.9L1029.1 210.8H1036L1039.5 216.9L1036 222.9Z" fill="white" />
            <path d="M1036 207.8H1029.1L1025.6 201.8L1029.1 195.8H1036L1039.5 201.8L1036 207.8Z" fill="white" />
            <path d="M1023 200.3H1016L1012.5 194.2L1016 188.2H1023L1026.4 194.2L1023 200.3Z" fill="white" />
            <path d="M1009.9 192.7H1002.9L999.5 186.7L1002.9 180.7H1009.9L1013.4 186.7L1009.9 192.7Z" fill="white" />
            <path d="M996.9 185.2H989.9L986.4 179.2L989.9 173.1H996.9L1000.3 179.2L996.9 185.2Z" fill="white" />
            <path d="M983.8 177.7H976.8L973.4 171.6L976.8 165.6H983.8L987.3 171.6L983.8 177.7Z" fill="white" />
            <path d="M970.801 170.1H963.801L960.301 164.1L963.801 158.1H970.801L974.201 164.1L970.801 170.1Z" fill="white" />
            <path d="M957.701 162.6H950.701L947.301 156.6L950.701 150.5H957.701L961.201 156.6L957.701 162.6Z" fill="white" />
            <path d="M944.699 155.1H937.699L934.199 149L937.699 143H944.699L948.099 149L944.699 155.1Z" fill="white" />
            <path d="M931.599 147.5H924.599L921.199 141.5L924.599 135.5H931.599L935.099 141.5L931.599 147.5Z" fill="white" />
            <path d="M918.5 140H911.6L908.1 134L911.6 127.9H918.5L922 134L918.5 140Z" fill="white" />
            <path d="M905.5 132.5H898.5L895.1 126.4L898.5 120.4H905.5L909 126.4L905.5 132.5Z" fill="white" />
            <path d="M892.4 124.9H885.5L882 118.9L885.5 112.9H892.4L895.9 118.9L892.4 124.9Z" fill="white" />
            <path d="M876.8 113H875L874.1 111.4L875 109.8H876.8L877.8 111.4L876.8 113Z" fill="white" />
            <path d="M865.8 109H859.9L856.9 103.8L859.9 98.7002H865.8L868.8 103.8L865.8 109Z" fill="white" />
            <path d="M850.899 98.1004H848.699L847.699 96.3004L848.699 94.4004H850.899L851.899 96.3004L850.899 98.1004Z" fill="white" />
            <path d="M748.3 41H742.5L739.6 36L742.5 31H748.3L751.2 36L748.3 41Z" fill="white" />
            <path d="M735.3 33.6004H729.4L726.4 28.5004L729.4 23.4004H735.3L738.2 28.5004L735.3 33.6004Z" fill="white" />
            <path d="M589.501 67.3998H588.001L587.301 66.0998L588.001 64.7998H589.501L590.301 66.0998L589.501 67.3998Z" fill="white" />
            <path d="M579.201 79.6002H572.301L568.801 73.7002L572.301 67.7002H579.201L582.601 73.7002L579.201 79.6002Z" fill="white" />
            <path d="M566.199 87.2002H559.199L555.699 81.2002L559.199 75.2002H566.199L569.599 81.2002L566.199 87.2002Z" fill="white" />
            <path d="M553.099 94.8002H546.099L542.699 88.8002L546.099 82.7002H553.099L556.599 88.8002L553.099 94.8002Z" fill="white" />
            <path d="M540 102.3H533.1L529.6 96.2998L533.1 90.2998H540L543.5 96.2998L540 102.3Z" fill="white" />
            <path d="M527 109.9H520L516.6 103.8L520 97.7998H527L530.5 103.8L527 109.9Z" fill="white" />
            <path d="M513.9 117.4H507L503.5 111.4L507 105.3H513.9L517.4 111.4L513.9 117.4Z" fill="white" />
            <path d="M500.9 124.9H493.9L490.5 118.9L493.9 112.9H500.9L504.4 118.9L500.9 124.9Z" fill="white" />
            <path d="M487.8 132.5H480.9L477.4 126.4L480.9 120.4H487.8L491.3 126.4L487.8 132.5Z" fill="white" />
            <path d="M474.801 140H467.801L464.301 134L467.801 127.9H474.801L478.301 134L474.801 140Z" fill="white" />
            <path d="M461.701 147.5H454.801L451.301 141.5L454.801 135.5H461.701L465.201 141.5L461.701 147.5Z" fill="white" />
            <path d="M383 191.9H376.9L373.9 186.7L376.9 181.5H383L386 186.7L383 191.9Z" fill="white" />
            <path d="M370.4 200.3H363.4L359.9 194.2L363.4 188.2H370.4L373.9 194.2L370.4 200.3Z" fill="white" />
            <path d="M357.3 207.8H350.4L346.9 201.8L350.4 195.8H357.3L360.8 201.8L357.3 207.8Z" fill="white" />
            <path d="M357.3 222.9H350.4L346.9 216.9L350.4 210.8H357.3L360.8 216.9L357.3 222.9Z" fill="white" />
            <path d="M357.3 238H350.4L346.9 231.9L350.4 225.9H357.3L360.8 231.9L357.3 238Z" fill="white" />
            <path d="M357.3 253H350.4L346.9 247L350.4 241H357.3L360.8 247L357.3 253Z" fill="white" />
            <path d="M357.3 268.1H350.4L346.9 262.1L350.4 256H357.3L360.8 262.1L357.3 268.1Z" fill="white" />
            <path d="M357.3 283.2H350.4L346.9 277.1L350.4 271.1H357.3L360.8 277.1L357.3 283.2Z" fill="white" />
            <path d="M357.3 298.2H350.4L346.9 292.2L350.4 286.2H357.3L360.8 292.2L357.3 298.2Z" fill="white" />
            <path d="M1047.7 409H1043.4L1041.3 405.2L1043.4 401.5H1047.7L1049.9 405.2L1047.7 409Z" fill="white" />
            <path d="M1049.1 396.2H1042.1L1038.6 390.2L1042.1 384.1H1049.1L1052.5 390.2L1049.1 396.2Z" fill="white" />
            <path d="M1049.1 381.1H1042.1L1038.6 375.1L1042.1 369.1H1049.1L1052.5 375.1L1049.1 381.1Z" fill="white" />
            <path d="M1049.1 366.1H1042.1L1038.6 360L1042.1 354H1049.1L1052.5 360L1049.1 366.1Z" fill="white" />
            <path d="M1049.1 351H1042.1L1038.6 345L1042.1 338.9H1049.1L1052.5 345L1049.1 351Z" fill="white" />
            <path d="M1049.1 335.9H1042.1L1038.6 329.9L1042.1 323.9H1049.1L1052.5 329.9L1049.1 335.9Z" fill="white" />
            <path d="M1049.1 320.8H1042.1L1038.6 314.8L1042.1 308.8H1049.1L1052.5 314.8L1049.1 320.8Z" fill="white" />
            <path d="M1049.1 305.8H1042.1L1038.6 299.7L1042.1 293.7H1049.1L1052.5 299.7L1049.1 305.8Z" fill="white" />
            <path d="M1049.1 290.7H1042.1L1038.6 284.7L1042.1 278.6H1049.1L1052.5 284.7L1049.1 290.7Z" fill="white" />
            <path d="M1049.1 275.6H1042.1L1038.6 269.6L1042.1 263.6H1049.1L1052.5 269.6L1049.1 275.6Z" fill="white" />
            <path d="M1049.1 260.6H1042.1L1038.6 254.5L1042.1 248.5H1049.1L1052.5 254.5L1049.1 260.6Z" fill="white" />
            <path d="M1049.1 245.5H1042.1L1038.6 239.5L1042.1 233.4H1049.1L1052.5 239.5L1049.1 245.5Z" fill="white" />
            <path d="M1049.1 230.4H1042.1L1038.6 224.4L1042.1 218.4H1049.1L1052.5 224.4L1049.1 230.4Z" fill="white" />
            <path d="M1049.1 215.3H1042.1L1038.6 209.3L1042.1 203.3H1049.1L1052.5 209.3L1049.1 215.3Z" fill="white" />
            <path d="M1049.1 200.3H1042.1L1038.6 194.2L1042.1 188.2H1049.1L1052.5 194.2L1049.1 200.3Z" fill="white" />
            <path d="M1036 192.7H1029.1L1025.6 186.7L1029.1 180.7H1036L1039.5 186.7L1036 192.7Z" fill="white" />
            <path d="M1023 185.2H1016L1012.5 179.2L1016 173.1H1023L1026.4 179.2L1023 185.2Z" fill="white" />
            <path d="M1009.9 177.7H1002.9L999.5 171.6L1002.9 165.6H1009.9L1013.4 171.6L1009.9 177.7Z" fill="white" />
            <path d="M996.9 170.1H989.9L986.4 164.1L989.9 158.1H996.9L1000.3 164.1L996.9 170.1Z" fill="white" />
            <path d="M983.8 162.6H976.8L973.4 156.6L976.8 150.5H983.8L987.3 156.6L983.8 162.6Z" fill="white" />
            <path d="M970.801 155.1H963.801L960.301 149L963.801 143H970.801L974.201 149L970.801 155.1Z" fill="white" />
            <path d="M957.701 147.5H950.701L947.301 141.5L950.701 135.5H957.701L961.201 141.5L957.701 147.5Z" fill="white" />
            <path d="M944.699 140H937.699L934.199 134L937.699 127.9H944.699L948.099 134L944.699 140Z" fill="white" />
            <path d="M931.599 132.5H924.599L921.199 126.4L924.599 120.4H931.599L935.099 126.4L931.599 132.5Z" fill="white" />
            <path d="M918.5 124.9H911.6L908.1 118.9L911.6 112.9H918.5L922 118.9L918.5 124.9Z" fill="white" />
            <path d="M903.601 114.1H900.401L898.801 111.4L900.401 108.6H903.601L905.201 111.4L903.601 114.1Z" fill="white" />
            <path d="M865.199 92.6998H860.599L858.199 88.7998L860.599 84.7998H865.199L867.499 88.7998L865.199 92.6998Z" fill="white" />
            <path d="M772.3 37.3996H770.7L769.9 35.9996L770.7 34.5996H772.3L773.1 35.9996L772.3 37.3996Z" fill="white" />
            <path d="M761.501 33.7996H755.401L752.301 28.4996L755.401 23.0996H761.501L764.601 28.4996L761.501 33.7996Z" fill="white" />
            <path d="M746.201 22.3H744.601L743.801 20.9L744.601 19.5H746.201L747.001 20.9L746.201 22.3Z" fill="white" />
            <path d="M589.799 52.8998H587.699L586.699 51.0998L587.699 49.2998H589.799L590.899 51.0998L589.799 52.8998Z" fill="white" />
            <path d="M579.201 64.5996H572.201L568.801 58.5996L572.201 52.5996H579.201L582.701 58.5996L579.201 64.5996Z" fill="white" />
            <path d="M566.199 72.1996H559.199L555.699 66.0996L559.199 60.0996H566.199L569.599 66.0996L566.199 72.1996Z" fill="white" />
            <path d="M553.099 79.7002H546.099L542.699 73.7002L546.099 67.7002H553.099L556.599 73.7002L553.099 79.7002Z" fill="white" />
            <path d="M540 87.2002H533.1L529.6 81.2002L533.1 75.2002H540L543.5 81.2002L540 87.2002Z" fill="white" />
            <path d="M527 94.8002H520L516.6 88.8002L520 82.7002H527L530.5 88.8002L527 94.8002Z" fill="white" />
            <path d="M513.9 102.3H507L503.5 96.2998L507 90.2998H513.9L517.4 96.2998L513.9 102.3Z" fill="white" />
            <path d="M500.9 109.9H493.9L490.5 103.8L493.9 97.7998H500.9L504.4 103.8L500.9 109.9Z" fill="white" />
            <path d="M487.8 117.4H480.9L477.4 111.4L480.9 105.3H487.8L491.3 111.4L487.8 117.4Z" fill="white" />
            <path d="M474.801 124.9H467.801L464.301 118.9L467.801 112.9H474.801L478.301 118.9L474.801 124.9Z" fill="white" />
            <path d="M460.4 130.2H456.1L453.9 126.4L456.1 122.7H460.4L462.6 126.4L460.4 130.2Z" fill="white" />
            <path d="M406.9 158H405.2L404.4 156.6L405.2 155.2H406.9L407.7 156.6L406.9 158Z" fill="white" />
            <path d="M396.5 170.1H389.5L386 164.1L389.5 158.1H396.5L400 164.1L396.5 170.1Z" fill="white" />
            <path d="M381.301 173.9H378.601L377.301 171.6L378.601 169.4H381.301L382.601 171.6L381.301 173.9Z" fill="white" />
            <path d="M370.4 185.2H363.4L359.9 179.2L363.4 173.1H370.4L373.9 179.2L370.4 185.2Z" fill="white" />
            <path d="M357.3 192.7H350.4L346.9 186.7L350.4 180.7H357.3L360.8 186.7L357.3 192.7Z" fill="white" />
            <path d="M343.2 213.5H338.4L335.9 209.3L338.4 205.1H343.2L345.7 209.3L343.2 213.5Z" fill="white" />
            <path d="M344.099 230.1H337.499L334.199 224.4L337.499 218.7H344.099L347.399 224.4L344.099 230.1Z" fill="white" />
            <path d="M344.301 245.5H337.301L333.801 239.5L337.301 233.4H344.301L347.801 239.5L344.301 245.5Z" fill="white" />
            <path d="M344.301 260.6H337.301L333.801 254.5L337.301 248.5H344.301L347.801 254.5L344.301 260.6Z" fill="white" />
            <path d="M344.301 275.6H337.301L333.801 269.6L337.301 263.6H344.301L347.801 269.6L344.301 275.6Z" fill="white" />
            <path d="M344.301 290.7H337.301L333.801 284.7L337.301 278.6H344.301L347.801 284.7L344.301 290.7Z" fill="white" />
            <path d="M344.301 305.8H337.301L333.801 299.7L337.301 293.7H344.301L347.801 299.7L344.301 305.8Z" fill="white" />
            <path d="M344.2 320.7H337.4L334 314.8L337.4 309H344.2L347.5 314.8L344.2 320.7Z" fill="white" />
            <path d="M341.4 330.9H340.2L339.6 329.9L340.2 328.9H341.4L342 329.9L341.4 330.9Z" fill="white" />
            <path d="M1061.5 432.9H1055.7L1052.8 427.8L1055.7 422.8H1061.5L1064.4 427.8L1061.5 432.9Z" fill="white" />
            <path d="M1062.1 418.8H1055.2L1051.7 412.8L1055.2 406.7H1062.1L1065.6 412.8L1062.1 418.8Z" fill="white" />
            <path d="M1062.1 403.7H1055.2L1051.7 397.7L1055.2 391.7H1062.1L1065.6 397.7L1062.1 403.7Z" fill="white" />
            <path d="M1062.1 388.7H1055.2L1051.7 382.6L1055.2 376.6H1062.1L1065.6 382.6L1062.1 388.7Z" fill="white" />
            <path d="M1062.1 373.6H1055.2L1051.7 367.6L1055.2 361.5H1062.1L1065.6 367.6L1062.1 373.6Z" fill="white" />
            <path d="M1062.1 358.5H1055.2L1051.7 352.5L1055.2 346.5H1062.1L1065.6 352.5L1062.1 358.5Z" fill="white" />
            <path d="M1062.1 343.4H1055.2L1051.7 337.4L1055.2 331.4H1062.1L1065.6 337.4L1062.1 343.4Z" fill="white" />
            <path d="M1062.1 328.4H1055.2L1051.7 322.4L1055.2 316.3H1062.1L1065.6 322.4L1062.1 328.4Z" fill="white" />
            <path d="M1062.1 313.3H1055.2L1051.7 307.3L1055.2 301.3H1062.1L1065.6 307.3L1062.1 313.3Z" fill="white" />
            <path d="M1062.1 298.2H1055.2L1051.7 292.2L1055.2 286.2H1062.1L1065.6 292.2L1062.1 298.2Z" fill="white" />
            <path d="M1062.1 283.2H1055.2L1051.7 277.1L1055.2 271.1H1062.1L1065.6 277.1L1062.1 283.2Z" fill="white" />
            <path d="M1062.1 268.1H1055.2L1051.7 262.1L1055.2 256H1062.1L1065.6 262.1L1062.1 268.1Z" fill="white" />
            <path d="M1062.1 253H1055.2L1051.7 247L1055.2 241H1062.1L1065.6 247L1062.1 253Z" fill="white" />
            <path d="M1062.1 238H1055.2L1051.7 231.9L1055.2 225.9H1062.1L1065.6 231.9L1062.1 238Z" fill="white" />
            <path d="M1062.1 222.9H1055.2L1051.7 216.9L1055.2 210.8H1062.1L1065.6 216.9L1062.1 222.9Z" fill="white" />
            <path d="M1062.1 207.8H1055.2L1051.7 201.8L1055.2 195.8H1062.1L1065.6 201.8L1062.1 207.8Z" fill="white" />
            <path d="M1062.1 192.7H1055.2L1051.7 186.7L1055.2 180.7H1062.1L1065.6 186.7L1062.1 192.7Z" fill="white" />
            <path d="M1049.1 185.2H1042.1L1038.6 179.2L1042.1 173.1H1049.1L1052.5 179.2L1049.1 185.2Z" fill="white" />
            <path d="M1036 177.7H1029.1L1025.6 171.6L1029.1 165.6H1036L1039.5 171.6L1036 177.7Z" fill="white" />
            <path d="M1023 170.1H1016L1012.5 164.1L1016 158.1H1023L1026.4 164.1L1023 170.1Z" fill="white" />
            <path d="M1009.9 162.6H1002.9L999.5 156.6L1002.9 150.5H1009.9L1013.4 156.6L1009.9 162.6Z" fill="white" />
            <path d="M996.9 155.1H989.9L986.4 149L989.9 143H996.9L1000.3 149L996.9 155.1Z" fill="white" />
            <path d="M983.8 147.5H976.8L973.4 141.5L976.8 135.5H983.8L987.3 141.5L983.8 147.5Z" fill="white" />
            <path d="M970.801 140H963.801L960.301 134L963.801 127.9H970.801L974.201 134L970.801 140Z" fill="white" />
            <path d="M957.701 132.5H950.701L947.301 126.4L950.701 120.4H957.701L961.201 126.4L957.701 132.5Z" fill="white" />
            <path d="M944.5 124.6H937.9L934.6 118.9L937.9 113.2H944.5L947.7 118.9L944.5 124.6Z" fill="white" />
            <path d="M931.5 117.2H924.8L921.4 111.4L924.8 105.6H931.5L934.8 111.4L931.5 117.2Z" fill="white" />
            <path d="M918.201 109.3H911.901L908.801 103.8L911.901 98.4004H918.201L921.401 103.8L918.201 109.3Z" fill="white" />
            <path d="M878.9 86.2996H873L870 81.1996L873 76.0996H878.9L881.8 81.1996L878.9 86.2996Z" fill="white" />
            <path d="M863.399 74.7002H862.299L861.699 73.7002L862.299 72.7002H863.399L863.999 73.7002L863.399 74.7002Z" fill="white" />
            <path d="M605.099 34.2002H598.499L595.199 28.5002L598.499 22.7002H605.099L608.499 28.5002L605.099 34.2002Z" fill="white" />
            <path d="M591.999 41.6998H585.499L582.199 35.9998L585.499 30.2998H591.999L595.299 35.9998L591.999 41.6998Z" fill="white" />
            <path d="M579.201 49.6H572.201L568.801 43.5L572.201 37.5H579.201L582.701 43.5L579.201 49.6Z" fill="white" />
            <path d="M566.199 57.1H559.199L555.699 51.1L559.199 45H566.199L569.599 51.1L566.199 57.1Z" fill="white" />
            <path d="M553.099 64.5996H546.099L542.699 58.5996L546.099 52.5996H553.099L556.599 58.5996L553.099 64.5996Z" fill="white" />
            <path d="M540 72.1996H533.1L529.6 66.0996L533.1 60.0996H540L543.5 66.0996L540 72.1996Z" fill="white" />
            <path d="M527 79.7002H520L516.6 73.7002L520 67.7002H527L530.5 73.7002L527 79.7002Z" fill="white" />
            <path d="M513.9 87.2002H507L503.5 81.2002L507 75.2002H513.9L517.4 81.2002L513.9 87.2002Z" fill="white" />
            <path d="M500.9 94.8002H493.9L490.5 88.8002L493.9 82.7002H500.9L504.4 88.8002L500.9 94.8002Z" fill="white" />
            <path d="M487.8 102.3H480.9L477.4 96.2998L480.9 90.2998H487.8L491.3 96.2998L487.8 102.3Z" fill="white" />
            <path d="M474.801 109.9H467.801L464.301 103.8L467.801 97.7998H474.801L478.301 103.8L474.801 109.9Z" fill="white" />
            <path d="M461.4 116.8H455.1L452 111.4L455.1 105.9H461.4L464.5 111.4L461.4 116.8Z" fill="white" />
            <path d="M421.099 137.3H417.099L415.199 134L417.099 130.6H421.099L422.999 134L421.099 137.3Z" fill="white" />
            <path d="M409.5 147.5H402.6L399.1 141.5L402.6 135.5H409.5L413 141.5L409.5 147.5Z" fill="white" />
            <path d="M396.5 155.1H389.5L386 149L389.5 143H396.5L400 149L396.5 155.1Z" fill="white" />
            <path d="M383.4 162.6H376.5L373 156.6L376.5 150.6H383.4L386.9 156.6L383.4 162.6Z" fill="white" />
            <path d="M357.3 177.6H350.4L347 171.6L350.4 165.7H357.3L360.7 171.6L357.3 177.6Z" fill="white" />
            <path d="M329.999 220.8H325.499L323.199 216.9L325.499 212.9H329.999L332.299 216.9L329.999 220.8Z" fill="white" />
            <path d="M331.1 237.7H324.4L321.1 231.9L324.4 226.2H331.1L334.4 231.9L331.1 237.7Z" fill="white" />
            <path d="M331.201 253H324.301L320.801 247L324.301 241H331.201L334.701 247L331.201 253Z" fill="white" />
            <path d="M331.201 268.1H324.301L320.801 262.1L324.301 256H331.201L334.701 262.1L331.201 268.1Z" fill="white" />
            <path d="M331.201 283.2H324.301L320.801 277.1L324.301 271.1H331.201L334.701 277.1L331.201 283.2Z" fill="white" />
            <path d="M331.201 298.2H324.301L320.801 292.2L324.301 286.2H331.201L334.701 292.2L331.201 298.2Z" fill="white" />
            <path d="M331.201 313.3H324.301L320.801 307.3L324.301 301.3H331.201L334.701 307.3L331.201 313.3Z" fill="white" />
            <path d="M331.201 328.4H324.301L320.801 322.4L324.301 316.3H331.201L334.701 322.4L331.201 328.4Z" fill="white" />
            <path d="M330.6 342.3H324.9L322.1 337.4L324.9 332.5H330.6L333.4 337.4L330.6 342.3Z" fill="white" />
            <path d="M1074.2 500H1069.2L1066.7 495.7L1069.2 491.4H1074.2L1076.7 495.7L1074.2 500Z" fill="white" />
            <path d="M1073.8 484.2H1069.6L1067.5 480.6L1069.6 477H1073.8L1075.8 480.6L1073.8 484.2Z" fill="white" />
            <path d="M1074.6 440.4H1068.8L1065.9 435.4L1068.8 430.3H1074.6L1077.5 435.4L1074.6 440.4Z" fill="white" />
            <path d="M1075.2 426.3H1068.2L1064.7 420.3L1068.2 414.3H1075.2L1078.6 420.3L1075.2 426.3Z" fill="white" />
            <path d="M1075.2 411.3H1068.2L1064.7 405.2L1068.2 399.2H1075.2L1078.6 405.2L1075.2 411.3Z" fill="white" />
            <path d="M1075.2 396.2H1068.2L1064.7 390.2L1068.2 384.1H1075.2L1078.6 390.2L1075.2 396.2Z" fill="white" />
            <path d="M1075.2 381.1H1068.2L1064.7 375.1L1068.2 369.1H1075.2L1078.6 375.1L1075.2 381.1Z" fill="white" />
            <path d="M1075.2 366.1H1068.2L1064.7 360L1068.2 354H1075.2L1078.6 360L1075.2 366.1Z" fill="white" />
            <path d="M1075.2 351H1068.2L1064.7 345L1068.2 338.9H1075.2L1078.6 345L1075.2 351Z" fill="white" />
            <path d="M1075.2 335.9H1068.2L1064.7 329.9L1068.2 323.9H1075.2L1078.6 329.9L1075.2 335.9Z" fill="white" />
            <path d="M1075.2 320.8H1068.2L1064.7 314.8L1068.2 308.8H1075.2L1078.6 314.8L1075.2 320.8Z" fill="white" />
            <path d="M1075.2 305.8H1068.2L1064.7 299.7L1068.2 293.7H1075.2L1078.6 299.7L1075.2 305.8Z" fill="white" />
            <path d="M1075.2 290.7H1068.2L1064.7 284.7L1068.2 278.6H1075.2L1078.6 284.7L1075.2 290.7Z" fill="white" />
            <path d="M1075.2 275.6H1068.2L1064.7 269.6L1068.2 263.6H1075.2L1078.6 269.6L1075.2 275.6Z" fill="white" />
            <path d="M1075.2 260.6H1068.2L1064.7 254.5L1068.2 248.5H1075.2L1078.6 254.5L1075.2 260.6Z" fill="white" />
            <path d="M1075.2 245.5H1068.2L1064.7 239.5L1068.2 233.4H1075.2L1078.6 239.5L1075.2 245.5Z" fill="white" />
            <path d="M1075.2 230.4H1068.2L1064.7 224.4L1068.2 218.4H1075.2L1078.6 224.4L1075.2 230.4Z" fill="white" />
            <path d="M1075.2 215.3H1068.2L1064.7 209.3L1068.2 203.3H1075.2L1078.6 209.3L1075.2 215.3Z" fill="white" />
            <path d="M1075.2 200.3H1068.2L1064.7 194.2L1068.2 188.2H1075.2L1078.6 194.2L1075.2 200.3Z" fill="white" />
            <path d="M1075.2 185.2H1068.2L1064.7 179.2L1068.2 173.1H1075.2L1078.6 179.2L1075.2 185.2Z" fill="white" />
            <path d="M1062.1 177.7H1055.2L1051.7 171.6L1055.2 165.6H1062.1L1065.6 171.6L1062.1 177.7Z" fill="white" />
            <path d="M1049.1 170.1H1042.1L1038.6 164.1L1042.1 158.1H1049.1L1052.5 164.1L1049.1 170.1Z" fill="white" />
            <path d="M1036 162.6H1029.1L1025.6 156.6L1029.1 150.5H1036L1039.5 156.6L1036 162.6Z" fill="white" />
            <path d="M1023 155.1H1016L1012.5 149L1016 143H1023L1026.4 149L1023 155.1Z" fill="white" />
            <path d="M1009.9 147.5H1002.9L999.5 141.5L1002.9 135.5H1009.9L1013.4 141.5L1009.9 147.5Z" fill="white" />
            <path d="M996.9 140H989.9L986.4 134L989.9 127.9H996.9L1000.3 134L996.9 140Z" fill="white" />
            <path d="M983.8 132.5H976.8L973.4 126.4L976.8 120.4H983.8L987.3 126.4L983.8 132.5Z" fill="white" />
            <path d="M970.801 124.9H963.801L960.301 118.9L963.801 112.9H970.801L974.201 118.9L970.801 124.9Z" fill="white" />
            <path d="M957.701 117.4H950.701L947.301 111.4L950.701 105.3H957.701L961.201 111.4L957.701 117.4Z" fill="white" />
            <path d="M943.1 107.1H939.3L937.4 103.8L939.3 100.6H943.1L944.9 103.8L943.1 107.1Z" fill="white" />
            <path d="M929 97.6998H927.3L926.5 96.2998L927.3 94.7998H929L929.8 96.2998L929 97.6998Z" fill="white" />
            <path d="M891.399 77.8H886.599L884.199 73.7L886.599 69.5H891.399L893.699 73.7L891.399 77.8Z" fill="white" />
            <path d="M591.1 25.0004H586.5L584.1 20.9004L586.5 16.9004H591.1L593.4 20.9004L591.1 25.0004Z" fill="white" />
            <path d="M579.201 34.5004H572.201L568.801 28.5004L572.201 22.4004H579.201L582.701 28.5004L579.201 34.5004Z" fill="white" />
            <path d="M566.199 42H559.199L555.699 36L559.199 30H566.199L569.599 36L566.199 42Z" fill="white" />
            <path d="M553.099 49.6H546.099L542.699 43.5L546.099 37.5H553.099L556.599 43.5L553.099 49.6Z" fill="white" />
            <path d="M540 57.1H533.1L529.6 51.1L533.1 45H540L543.5 51.1L540 57.1Z" fill="white" />
            <path d="M527 64.5996H520L516.6 58.5996L520 52.5996H527L530.5 58.5996L527 64.5996Z" fill="white" />
            <path d="M513.9 72.1996H507L503.5 66.0996L507 60.0996H513.9L517.4 66.0996L513.9 72.1996Z" fill="white" />
            <path d="M500.9 79.7002H493.9L490.5 73.7002L493.9 67.7002H500.9L504.4 73.7002L500.9 79.7002Z" fill="white" />
            <path d="M487.8 87.2002H480.9L477.4 81.2002L480.9 75.2002H487.8L491.3 81.2002L487.8 87.2002Z" fill="white" />
            <path d="M474.801 94.8002H467.801L464.301 88.8002L467.801 82.7002H474.801L478.301 88.8002L474.801 94.8002Z" fill="white" />
            <path d="M461.7 102.2H454.8L451.4 96.2998L454.8 90.2998H461.7L465.1 96.2998L461.7 102.2Z" fill="white" />
            <path d="M445.999 105.1H444.399L443.699 103.8L444.399 102.5H445.999L446.699 103.8L445.999 105.1Z" fill="white" />
            <path d="M408.201 130.1H403.901L401.801 126.4L403.901 122.7H408.201L410.301 126.4L408.201 130.1Z" fill="white" />
            <path d="M396.5 140H389.5L386 134L389.5 127.9H396.5L400 134L396.5 140Z" fill="white" />
            <path d="M383.4 147.5H376.5L373 141.5L376.5 135.5H383.4L386.9 141.5L383.4 147.5Z" fill="white" />
            <path d="M370.3 155H363.4L360 149L363.4 143.1H370.3L373.8 149L370.3 155Z" fill="white" />
            <path d="M354.601 157.9H353.101L352.301 156.6L353.101 155.2H354.601L355.401 156.6L354.601 157.9Z" fill="white" />
            <path d="M342.4 166.9H339.2L337.6 164.1L339.2 161.3H342.4L344 164.1L342.4 166.9Z" fill="white" />
            <path d="M315.7 211.1H313.6L312.6 209.3L313.6 207.5H315.7L316.8 209.3L315.7 211.1Z" fill="white" />
            <path d="M318.199 230.4H311.199L307.699 224.4L311.199 218.4H318.199L321.599 224.4L318.199 230.4Z" fill="white" />
            <path d="M318.199 245.5H311.199L307.699 239.5L311.199 233.4H318.199L321.599 239.5L318.199 245.5Z" fill="white" />
            <path d="M318.199 260.6H311.199L307.699 254.5L311.199 248.5H318.199L321.599 254.5L318.199 260.6Z" fill="white" />
            <path d="M318.199 275.6H311.199L307.699 269.6L311.199 263.6H318.199L321.599 269.6L318.199 275.6Z" fill="white" />
            <path d="M318.199 290.7H311.199L307.699 284.7L311.199 278.6H318.199L321.599 284.7L318.199 290.7Z" fill="white" />
            <path d="M318.199 305.8H311.199L307.699 299.7L311.199 293.7H318.199L321.599 299.7L318.199 305.8Z" fill="white" />
            <path d="M318.199 320.8H311.199L307.699 314.8L311.199 308.8H318.199L321.599 314.8L318.199 320.8Z" fill="white" />
            <path d="M318.199 335.9H311.199L307.699 329.9L311.199 323.9H318.199L321.599 329.9L318.199 335.9Z" fill="white" />
            <path d="M318.1 350.8H311.3L307.9 345L311.3 339.1H318.1L321.5 345L318.1 350.8Z" fill="white" />
            <path d="M315.8 362H313.6L312.4 360L313.6 358.1H315.8L316.9 360L315.8 362Z" fill="white" />
            <path d="M317.601 380.2H311.801L308.801 375.1L311.801 370H317.601L320.501 375.1L317.601 380.2Z" fill="white" />
            <path d="M1087 522.2H1082.5L1080.2 518.3L1082.5 514.3H1087L1089.3 518.3L1087 522.2Z" fill="white" />
            <path d="M1088 508.9H1081.5L1078.2 503.2L1081.5 497.5H1088L1091.3 503.2L1088 508.9Z" fill="white" />
            <path d="M1085.8 490H1083.7L1082.6 488.1L1083.7 486.3H1085.8L1086.9 488.1L1085.8 490Z" fill="white" />
            <path d="M1088.1 478.8H1081.4L1078.1 473.1L1081.4 467.3H1088.1L1091.4 473.1L1088.1 478.8Z" fill="white" />
            <path d="M1085.8 459.8H1083.7L1082.6 458L1083.7 456.2H1085.8L1086.8 458L1085.8 459.8Z" fill="white" />
            <path d="M1088 448.6H1081.4L1078.2 442.9L1081.4 437.2H1088L1091.3 442.9L1088 448.6Z" fill="white" />
            <path d="M1088.2 433.9H1081.3L1077.8 427.8L1081.3 421.8H1088.2L1091.7 427.8L1088.2 433.9Z" fill="white" />
            <path d="M1088.2 418.8H1081.3L1077.8 412.8L1081.3 406.7H1088.2L1091.7 412.8L1088.2 418.8Z" fill="white" />
            <path d="M1088.2 403.7H1081.3L1077.8 397.7L1081.3 391.7H1088.2L1091.7 397.7L1088.2 403.7Z" fill="white" />
            <path d="M1088.2 388.7H1081.3L1077.8 382.6L1081.3 376.6H1088.2L1091.7 382.6L1088.2 388.7Z" fill="white" />
            <path d="M1088.2 373.6H1081.3L1077.8 367.6L1081.3 361.5H1088.2L1091.7 367.6L1088.2 373.6Z" fill="white" />
            <path d="M1088.2 358.5H1081.3L1077.8 352.5L1081.3 346.5H1088.2L1091.7 352.5L1088.2 358.5Z" fill="white" />
            <path d="M1088.2 343.4H1081.3L1077.8 337.4L1081.3 331.4H1088.2L1091.7 337.4L1088.2 343.4Z" fill="white" />
            <path d="M1088.2 328.4H1081.3L1077.8 322.4L1081.3 316.3H1088.2L1091.7 322.4L1088.2 328.4Z" fill="white" />
            <path d="M1088.2 313.3H1081.3L1077.8 307.3L1081.3 301.3H1088.2L1091.7 307.3L1088.2 313.3Z" fill="white" />
            <path d="M1088.2 298.2H1081.3L1077.8 292.2L1081.3 286.2H1088.2L1091.7 292.2L1088.2 298.2Z" fill="white" />
            <path d="M1088.2 283.2H1081.3L1077.8 277.1L1081.3 271.1H1088.2L1091.7 277.1L1088.2 283.2Z" fill="white" />
            <path d="M1088.2 268.1H1081.3L1077.8 262.1L1081.3 256H1088.2L1091.7 262.1L1088.2 268.1Z" fill="white" />
            <path d="M1088.2 253H1081.3L1077.8 247L1081.3 241H1088.2L1091.7 247L1088.2 253Z" fill="white" />
            <path d="M1088.2 238H1081.3L1077.8 231.9L1081.3 225.9H1088.2L1091.7 231.9L1088.2 238Z" fill="white" />
            <path d="M1088.2 222.9H1081.3L1077.8 216.9L1081.3 210.8H1088.2L1091.7 216.9L1088.2 222.9Z" fill="white" />
            <path d="M1088.2 207.8H1081.3L1077.8 201.8L1081.3 195.8H1088.2L1091.7 201.8L1088.2 207.8Z" fill="white" />
            <path d="M1088.2 192.7H1081.3L1077.8 186.7L1081.3 180.7H1088.2L1091.7 186.7L1088.2 192.7Z" fill="white" />
            <path d="M1088.2 177.7H1081.3L1077.8 171.6L1081.3 165.6H1088.2L1091.7 171.6L1088.2 177.7Z" fill="white" />
            <path d="M1075.2 170.1H1068.2L1064.7 164.1L1068.2 158.1H1075.2L1078.6 164.1L1075.2 170.1Z" fill="white" />
            <path d="M1062.1 162.6H1055.2L1051.7 156.6L1055.2 150.5H1062.1L1065.6 156.6L1062.1 162.6Z" fill="white" />
            <path d="M1049.1 155.1H1042.1L1038.6 149L1042.1 143H1049.1L1052.5 149L1049.1 155.1Z" fill="white" />
            <path d="M1036 147.5H1029.1L1025.6 141.5L1029.1 135.5H1036L1039.5 141.5L1036 147.5Z" fill="white" />
            <path d="M1023 140H1016L1012.5 134L1016 127.9H1023L1026.4 134L1023 140Z" fill="white" />
            <path d="M1009.9 132.5H1002.9L999.5 126.4L1002.9 120.4H1009.9L1013.4 126.4L1009.9 132.5Z" fill="white" />
            <path d="M996.9 124.9H989.9L986.4 118.9L989.9 112.9H996.9L1000.3 118.9L996.9 124.9Z" fill="white" />
            <path d="M983.8 117.4H976.8L973.4 111.4L976.8 105.3H983.8L987.3 111.4L983.8 117.4Z" fill="white" />
            <path d="M970.6 109.7H963.9L960.5 103.8L963.9 98H970.6L974 103.8L970.6 109.7Z" fill="white" />
            <path d="M955.9 99.1H952.6L950.9 96.3L952.6 93.5H955.9L957.5 96.3L955.9 99.1Z" fill="white" />
            <path d="M905.1 71.4004H899L895.9 66.1004L899 60.9004H905.1L908.1 66.1004L905.1 71.4004Z" fill="white" />
            <path d="M591.1 10.0002H586.4L584 5.9002L586.4 1.7002H591.1L593.5 5.9002L591.1 10.0002Z" fill="white" />
            <path d="M578.9 18.9004H572.6L569.4 13.4004L572.6 7.90039H578.9L582 13.4004L578.9 18.9004Z" fill="white" />
            <path d="M564.2 23.6002H561.1L559.6 20.9002L561.1 18.2002H564.2L565.8 20.9002L564.2 23.6002Z" fill="white" />
            <path d="M553.099 34.5004H546.099L542.699 28.5004L546.099 22.4004H553.099L556.599 28.5004L553.099 34.5004Z" fill="white" />
            <path d="M540 42H533.1L529.6 36L533.1 30H540L543.5 36L540 42Z" fill="white" />
            <path d="M527 49.6H520L516.6 43.5L520 37.5H527L530.5 43.5L527 49.6Z" fill="white" />
            <path d="M513.9 57.1H507L503.5 51.1L507 45H513.9L517.4 51.1L513.9 57.1Z" fill="white" />
            <path d="M500.9 64.5996H493.9L490.5 58.5996L493.9 52.5996H500.9L504.4 58.5996L500.9 64.5996Z" fill="white" />
            <path d="M487.8 72.1996H480.9L477.4 66.0996L480.9 60.0996H487.8L491.3 66.0996L487.8 72.1996Z" fill="white" />
            <path d="M474.801 79.7002H467.801L464.301 73.7002L467.801 67.7002H474.801L478.301 73.7002L474.801 79.7002Z" fill="white" />
            <path d="M461.701 87.2002H454.801L451.301 81.2002L454.801 75.2002H461.701L465.201 81.2002L461.701 87.2002Z" fill="white" />
            <path d="M446.5 91H443.9L442.6 88.8L443.9 86.5H446.5L447.8 88.8L446.5 91Z" fill="white" />
            <path d="M396.5 124.9H389.5L386 118.9L389.5 112.9H396.5L400 118.9L396.5 124.9Z" fill="white" />
            <path d="M383.2 132.1H376.7L373.4 126.4L376.7 120.7H383.2L386.5 126.4L383.2 132.1Z" fill="white" />
            <path d="M330.4 161.2H325.1L322.4 156.6L325.1 152H330.4L333.1 156.6L330.4 161.2Z" fill="white" />
            <path d="M303.099 174.2H300.199L298.699 171.6L300.199 169.1H303.099L304.599 171.6L303.099 174.2Z" fill="white" />
            <path d="M302.7 203.6H300.6L299.5 201.8L300.6 199.9H302.7L303.8 201.8L302.7 203.6Z" fill="white" />
            <path d="M305.099 222.9H298.199L294.699 216.9L298.199 210.8H305.099L308.599 216.9L305.099 222.9Z" fill="white" />
            <path d="M305.099 238H298.199L294.699 231.9L298.199 225.9H305.099L308.599 231.9L305.099 238Z" fill="white" />
            <path d="M305.099 253H298.199L294.699 247L298.199 241H305.099L308.599 247L305.099 253Z" fill="white" />
            <path d="M305.099 268.1H298.199L294.699 262.1L298.199 256H305.099L308.599 262.1L305.099 268.1Z" fill="white" />
            <path d="M305.099 283.2H298.199L294.699 277.1L298.199 271.1H305.099L308.599 277.1L305.099 283.2Z" fill="white" />
            <path d="M305.099 298.2H298.199L294.699 292.2L298.199 286.2H305.099L308.599 292.2L305.099 298.2Z" fill="white" />
            <path d="M305.099 313.3H298.199L294.699 307.3L298.199 301.3H305.099L308.599 307.3L305.099 313.3Z" fill="white" />
            <path d="M305.099 328.4H298.199L294.699 322.4L298.199 316.3H305.099L308.599 322.4L305.099 328.4Z" fill="white" />
            <path d="M305.099 343.4H298.199L294.699 337.4L298.199 331.4H305.099L308.599 337.4L305.099 343.4Z" fill="white" />
            <path d="M305.099 358.5H298.199L294.699 352.5L298.199 346.5H305.099L308.599 352.5L305.099 358.5Z" fill="white" />
            <path
              d="M302.9 369.8H300.3L299 367.6L300.3 365.3H302.9L304.3 367.6L302.9 369.8Z"
              fill="#FF881B"
              id="bahamas"
              className="legend-map"
              data-tooltip-html={
                ReactDOMServer.renderToString(
                  <div className={styles.country__item}>
                    <img src={Countries.bahamas} alt="Bahamas" />
                    <span className={styles.country__title}>
                      {t('Bahamas')}
                    </span>
                  </div>,
                )
              }
            />
            <path d="M1101.1 531.5H1094.5L1091.2 525.8L1094.5 520.1H1101.1L1104.3 525.8L1101.1 531.5Z" fill="white" />
            <path d="M1101 516.3H1094.6L1091.4 510.7L1094.6 505.2H1101L1104.2 510.7L1101 516.3Z" fill="white" />
            <path d="M1099.3 498.2H1096.3L1094.8 495.7L1096.3 493.1H1099.3L1100.8 495.7L1099.3 498.2Z" fill="white" />
            <path d="M1099.5 483.6H1096.1L1094.4 480.6L1096.1 477.6H1099.5L1101.2 480.6L1099.5 483.6Z" fill="white" />
            <path d="M1101.3 456.5H1094.3L1090.8 450.5L1094.3 444.4H1101.3L1104.8 450.5L1101.3 456.5Z" fill="white" />
            <path d="M1101.3 441.4H1094.3L1090.8 435.4L1094.3 429.4H1101.3L1104.8 435.4L1101.3 441.4Z" fill="white" />
            <path d="M1101.3 426.3H1094.3L1090.8 420.3L1094.3 414.3H1101.3L1104.8 420.3L1101.3 426.3Z" fill="white" />
            <path d="M1101.3 411.3H1094.3L1090.8 405.2L1094.3 399.2H1101.3L1104.8 405.2L1101.3 411.3Z" fill="white" />
            <path d="M1101.3 396.2H1094.3L1090.8 390.2L1094.3 384.1H1101.3L1104.8 390.2L1101.3 396.2Z" fill="white" />
            <path d="M1101.3 381.1H1094.3L1090.8 375.1L1094.3 369.1H1101.3L1104.8 375.1L1101.3 381.1Z" fill="white" />
            <path d="M1101.3 366.1H1094.3L1090.8 360L1094.3 354H1101.3L1104.8 360L1101.3 366.1Z" fill="white" />
            <path d="M1101.3 351H1094.3L1090.8 345L1094.3 338.9H1101.3L1104.8 345L1101.3 351Z" fill="white" />
            <path d="M1101.3 335.9H1094.3L1090.8 329.9L1094.3 323.9H1101.3L1104.8 329.9L1101.3 335.9Z" fill="white" />
            <path d="M1101.3 320.8H1094.3L1090.8 314.8L1094.3 308.8H1101.3L1104.8 314.8L1101.3 320.8Z" fill="white" />
            <path d="M1101.3 305.8H1094.3L1090.8 299.7L1094.3 293.7H1101.3L1104.8 299.7L1101.3 305.8Z" fill="white" />
            <path d="M1101.3 290.7H1094.3L1090.8 284.7L1094.3 278.6H1101.3L1104.8 284.7L1101.3 290.7Z" fill="white" />
            <path d="M1101.3 275.6H1094.3L1090.8 269.6L1094.3 263.6H1101.3L1104.8 269.6L1101.3 275.6Z" fill="white" />
            <path d="M1101.3 260.6H1094.3L1090.8 254.5L1094.3 248.5H1101.3L1104.8 254.5L1101.3 260.6Z" fill="white" />
            <path d="M1101.3 245.5H1094.3L1090.8 239.5L1094.3 233.4H1101.3L1104.8 239.5L1101.3 245.5Z" fill="white" />
            <path d="M1101.3 230.4H1094.3L1090.8 224.4L1094.3 218.4H1101.3L1104.8 224.4L1101.3 230.4Z" fill="white" />
            <path d="M1101.3 215.3H1094.3L1090.8 209.3L1094.3 203.3H1101.3L1104.8 209.3L1101.3 215.3Z" fill="white" />
            <path d="M1101.3 200.3H1094.3L1090.8 194.2L1094.3 188.2H1101.3L1104.8 194.2L1101.3 200.3Z" fill="white" />
            <path d="M1101.3 185.2H1094.3L1090.8 179.2L1094.3 173.1H1101.3L1104.8 179.2L1101.3 185.2Z" fill="white" />
            <path d="M1101.3 170.1H1094.3L1090.8 164.1L1094.3 158.1H1101.3L1104.8 164.1L1101.3 170.1Z" fill="white" />
            <path d="M1088.2 162.6H1081.3L1077.8 156.6L1081.3 150.5H1088.2L1091.7 156.6L1088.2 162.6Z" fill="white" />
            <path d="M1075.2 155.1H1068.2L1064.7 149L1068.2 143H1075.2L1078.6 149L1075.2 155.1Z" fill="white" />
            <path d="M1062.1 147.5H1055.2L1051.7 141.5L1055.2 135.5H1062.1L1065.6 141.5L1062.1 147.5Z" fill="white" />
            <path d="M1049.1 140H1042.1L1038.6 134L1042.1 127.9H1049.1L1052.5 134L1049.1 140Z" fill="white" />
            <path d="M1036 132.5H1029.1L1025.6 126.4L1029.1 120.4H1036L1039.5 126.4L1036 132.5Z" fill="white" />
            <path d="M1023 124.9H1016L1012.5 118.9L1016 112.9H1023L1026.4 118.9L1023 124.9Z" fill="white" />
            <path d="M1009.9 117.4H1002.9L999.5 111.4L1002.9 105.3H1009.9L1013.4 111.4L1009.9 117.4Z" fill="white" />
            <path d="M996.9 109.9H989.9L986.4 103.8L989.9 97.7998H996.9L1000.3 103.8L996.9 109.9Z" fill="white" />
            <path d="M983.8 102.3H976.8L973.4 96.2998L976.8 90.2998H983.8L987.3 96.2998L983.8 102.3Z" fill="white" />
            <path d="M969.801 93.1004H964.801L962.301 88.8004L964.801 84.4004H969.801L972.301 88.8004L969.801 93.1004Z" fill="white" />
            <path d="M877.2 38.1998H874.6L873.4 35.9998L874.6 33.7998H877.2L878.5 35.9998L877.2 38.1998Z" fill="white" />
            <path d="M566 11.7H559.3L555.9 5.9L559.3 0H566L569.4 5.9L566 11.7Z" fill="white" />
            <path d="M552.9 19.1002H546.3L543 13.4002L546.3 7.7002H552.9L556.2 13.4002L552.9 19.1002Z" fill="white" />
            <path d="M540 27.0004H533.1L529.6 20.9004L533.1 14.9004H540L543.5 20.9004L540 27.0004Z" fill="white" />
            <path d="M527 34.5004H520L516.6 28.5004L520 22.4004H527L530.5 28.5004L527 34.5004Z" fill="white" />
            <path d="M513.9 42H507L503.5 36L507 30H513.9L517.4 36L513.9 42Z" fill="white" />
            <path d="M500.9 49.6H493.9L490.5 43.5L493.9 37.5H500.9L504.4 43.5L500.9 49.6Z" fill="white" />
            <path d="M487.8 57.1H480.9L477.4 51.1L480.9 45H487.8L491.3 51.1L487.8 57.1Z" fill="white" />
            <path d="M474.801 64.5996H467.801L464.301 58.5996L467.801 52.5996H474.801L478.301 58.5996L474.801 64.5996Z" fill="white" />
            <path d="M461.701 72.1996H454.801L451.301 66.0996L454.801 60.0996H461.701L465.201 66.0996L461.701 72.1996Z" fill="white" />
            <path d="M448.6 79.4998H441.8L438.5 73.6998L441.8 67.7998H448.6L451.9 73.6998L448.6 79.4998Z" fill="white" />
            <path d="M395 107.3H391L388.9 103.8L391 100.3H395L397.1 103.8L395 107.3Z" fill="white" />
            <path d="M383.4 117.4H376.5L373 111.4L376.5 105.3H383.4L386.9 111.4L383.4 117.4Z" fill="white" />
            <path d="M369.499 123.4H364.299L361.699 118.9L364.299 114.4H369.499L372.099 118.9L369.499 123.4Z" fill="white" />
            <path d="M344.301 140H337.301L333.801 134L337.301 127.9H344.301L347.801 134L344.301 140Z" fill="white" />
            <path d="M331.1 147.2H324.4L321.1 141.5L324.4 135.8H331.1L334.4 141.5L331.1 147.2Z" fill="white" />
            <path d="M318.199 155.1H311.199L307.699 149L311.199 143H318.199L321.599 149L318.199 155.1Z" fill="white" />
            <path d="M305.099 162.6H298.199L294.699 156.6L298.199 150.5H305.099L308.599 156.6L305.099 162.6Z" fill="white" />
            <path d="M292.1 170.1H285.1L281.6 164.1L285.1 158.1H292.1L295.5 164.1L292.1 170.1Z" fill="white" />
            <path d="M291.9 185H285.2L281.9 179.2L285.2 173.4H291.9L295.3 179.2L291.9 185Z" fill="white" />
            <path d="M291.7 199.6H285.5L282.4 194.2L285.5 188.9H291.7L294.7 194.2L291.7 199.6Z" fill="white" />
            <path d="M292.1 215.3H285.1L281.6 209.3L285.1 203.3H292.1L295.5 209.3L292.1 215.3Z" fill="white" />
            <path d="M292.1 230.4H285.1L281.6 224.4L285.1 218.4H292.1L295.5 224.4L292.1 230.4Z" fill="white" />
            <path d="M292.1 245.5H285.1L281.6 239.5L285.1 233.4H292.1L295.5 239.5L292.1 245.5Z" fill="white" />
            <path d="M292.1 260.6H285.1L281.6 254.5L285.1 248.5H292.1L295.5 254.5L292.1 260.6Z" fill="white" />
            <path d="M292.1 275.6H285.1L281.6 269.6L285.1 263.6H292.1L295.5 269.6L292.1 275.6Z" fill="white" />
            <path d="M292.1 290.7H285.1L281.6 284.7L285.1 278.6H292.1L295.5 284.7L292.1 290.7Z" fill="white" />
            <path d="M292.1 305.8H285.1L281.6 299.7L285.1 293.7H292.1L295.5 299.7L292.1 305.8Z" fill="white" />
            <path d="M292.1 320.8H285.1L281.6 314.8L285.1 308.8H292.1L295.5 314.8L292.1 320.8Z" fill="white" />
            <path d="M292.1 335.9H285.1L281.6 329.9L285.1 323.9H292.1L295.5 329.9L292.1 335.9Z" fill="white" />
            <path d="M292.1 351H285.1L281.6 345L285.1 338.9H292.1L295.5 345L292.1 351Z" fill="white" />
            <path d="M291.9 365.8H285.2L281.9 360L285.2 354.2H291.9L295.3 360L291.9 365.8Z" fill="white" />
            <path d="M289.1 466.5H288L287.5 465.5L288 464.6H289.1L289.7 465.5L289.1 466.5Z" fill="white" />
            <path d="M1112.6 536.3H1109.1L1107.4 533.3L1109.1 530.4H1112.6L1114.3 533.3L1112.6 536.3Z" fill="white" />
            <path
              d="M1114.1 463.5H1107.6L1104.4 458L1107.6 452.4H1114.1L1117.3 458L1114.1 463.5Z"
              fill="#FF881B"
              id="singapore"
              className="legend-map"
              data-tooltip-html={
                ReactDOMServer.renderToString(
                  <div className={styles.country__item}>
                    <img src={Countries.singapore} alt="Singapore" />
                    <span className={styles.country__title}>
                      {t('Singapore')}
                    </span>
                  </div>,
                )
              }
            />
            <path d="M1114.3 448.9H1107.4L1103.9 442.9L1107.4 436.9H1114.3L1117.8 442.9L1114.3 448.9Z" fill="white" />
            <path d="M1114.3 433.9H1107.4L1103.9 427.8L1107.4 421.8H1114.3L1117.8 427.8L1114.3 433.9Z" fill="white" />
            <path d="M1112.2 415.1H1109.5L1108.2 412.8L1109.5 410.5H1112.2L1113.5 412.8L1112.2 415.1Z" fill="white" />
            <path d="M1114.3 403.7H1107.4L1103.9 397.7L1107.4 391.7H1114.3L1117.8 397.7L1114.3 403.7Z" fill="white" />
            <path d="M1114.3 388.7H1107.4L1103.9 382.6L1107.4 376.6H1114.3L1117.8 382.6L1114.3 388.7Z" fill="white" />
            <path d="M1114.3 373.6H1107.4L1103.9 367.6L1107.4 361.5H1114.3L1117.8 367.6L1114.3 373.6Z" fill="white" />
            <path d="M1114.3 358.5H1107.4L1103.9 352.5L1107.4 346.5H1114.3L1117.8 352.5L1114.3 358.5Z" fill="white" />
            <path d="M1114.3 343.4H1107.4L1103.9 337.4L1107.4 331.4H1114.3L1117.8 337.4L1114.3 343.4Z" fill="white" />
            <path d="M1114.3 328.4H1107.4L1103.9 322.4L1107.4 316.3H1114.3L1117.8 322.4L1114.3 328.4Z" fill="white" />
            <path d="M1114.3 313.3H1107.4L1103.9 307.3L1107.4 301.3H1114.3L1117.8 307.3L1114.3 313.3Z" fill="white" />
            <path d="M1114.3 298.2H1107.4L1103.9 292.2L1107.4 286.2H1114.3L1117.8 292.2L1114.3 298.2Z" fill="white" />
            <path d="M1114.3 283.2H1107.4L1103.9 277.1L1107.4 271.1H1114.3L1117.8 277.1L1114.3 283.2Z" fill="white" />
            <path d="M1114.3 268.1H1107.4L1103.9 262.1L1107.4 256H1114.3L1117.8 262.1L1114.3 268.1Z" fill="white" />
            <path d="M1114.3 253H1107.4L1103.9 247L1107.4 241H1114.3L1117.8 247L1114.3 253Z" fill="white" />
            <path d="M1114.3 238H1107.4L1103.9 231.9L1107.4 225.9H1114.3L1117.8 231.9L1114.3 238Z" fill="white" />
            <path d="M1114.3 222.9H1107.4L1103.9 216.9L1107.4 210.8H1114.3L1117.8 216.9L1114.3 222.9Z" fill="white" />
            <path d="M1114.3 207.8H1107.4L1103.9 201.8L1107.4 195.8H1114.3L1117.8 201.8L1114.3 207.8Z" fill="white" />
            <path d="M1114.3 192.7H1107.4L1103.9 186.7L1107.4 180.7H1114.3L1117.8 186.7L1114.3 192.7Z" fill="white" />
            <path d="M1114.3 177.7H1107.4L1103.9 171.6L1107.4 165.6H1114.3L1117.8 171.6L1114.3 177.7Z" fill="white" />
            <path d="M1114.3 162.6H1107.4L1103.9 156.6L1107.4 150.5H1114.3L1117.8 156.6L1114.3 162.6Z" fill="white" />
            <path d="M1101.3 155.1H1094.3L1090.8 149L1094.3 143H1101.3L1104.8 149L1101.3 155.1Z" fill="white" />
            <path d="M1088.2 147.5H1081.3L1077.8 141.5L1081.3 135.5H1088.2L1091.7 141.5L1088.2 147.5Z" fill="white" />
            <path d="M1075.2 140H1068.2L1064.7 134L1068.2 127.9H1075.2L1078.6 134L1075.2 140Z" fill="white" />
            <path d="M1062.1 132.5H1055.2L1051.7 126.4L1055.2 120.4H1062.1L1065.6 126.4L1062.1 132.5Z" fill="white" />
            <path d="M1049.1 124.9H1042.1L1038.6 118.9L1042.1 112.9H1049.1L1052.5 118.9L1049.1 124.9Z" fill="white" />
            <path d="M1036 117.4H1029.1L1025.6 111.4L1029.1 105.3H1036L1039.5 111.4L1036 117.4Z" fill="white" />
            <path d="M1023 109.9H1016L1012.5 103.8L1016 97.7998H1023L1026.4 103.8L1023 109.9Z" fill="white" />
            <path d="M1009.9 102.3H1002.9L999.5 96.2998L1002.9 90.2998H1009.9L1013.4 96.2998L1009.9 102.3Z" fill="white" />
            <path d="M996.9 94.8002H989.9L986.4 88.8002L989.9 82.7002H996.9L1000.3 88.8002L996.9 94.8002Z" fill="white" />
            <path d="M983.7 87.1004H976.9L973.6 81.2004L976.9 75.4004H983.7L987.1 81.2004L983.7 87.1004Z" fill="white" />
            <path d="M540.001 11.7H533.201L529.801 5.9L533.201 0H540.001L543.301 5.9L540.001 11.7Z" fill="white" />
            <path d="M527 19.4004H520L516.6 13.4004L520 7.40039H527L530.5 13.4004L527 19.4004Z" fill="white" />
            <path d="M513.9 27.0004H507L503.5 20.9004L507 14.9004H513.9L517.4 20.9004L513.9 27.0004Z" fill="white" />
            <path d="M500.9 34.5004H493.9L490.5 28.5004L493.9 22.4004H500.9L504.4 28.5004L500.9 34.5004Z" fill="white" />
            <path d="M487.8 42H480.9L477.4 36L480.9 30H487.8L491.3 36L487.8 42Z" fill="white" />
            <path d="M474.801 49.6H467.801L464.301 43.5L467.801 37.5H474.801L478.301 43.5L474.801 49.6Z" fill="white" />
            <path d="M461.701 57.1H454.801L451.301 51.1L454.801 45H461.701L465.201 51.1L461.701 57.1Z" fill="white" />
            <path d="M448.699 64.5996H441.699L438.199 58.5996L441.699 52.5996H448.699L452.199 58.5996L448.699 64.5996Z" fill="white" />
            <path d="M435.599 72.1996H428.699L425.199 66.0996L428.699 60.0996H435.599L439.099 66.0996L435.599 72.1996Z" fill="white" />
            <path d="M420.4 76.0004H417.8L416.5 73.7004L417.8 71.4004H420.4L421.7 73.7004L420.4 76.0004Z" fill="white" />
            <path d="M380.6 97.3996H379.3L378.6 96.2996L379.3 95.0996H380.6L381.3 96.2996L380.6 97.3996Z" fill="white" />
            <path d="M370.4 109.9H363.4L359.9 103.8L363.4 97.7998H370.4L373.9 103.8L370.4 109.9Z" fill="white" />
            <path d="M357.3 117.4H350.4L346.9 111.4L350.4 105.3H357.3L360.8 111.4L357.3 117.4Z" fill="white" />
            <path d="M343.501 123.6H338.101L335.301 118.9L338.101 114.2H343.501L346.301 118.9L343.501 123.6Z" fill="white" />
            <path d="M329.699 129.9H325.699L323.699 126.4L325.699 123H329.699L331.799 126.4L329.699 129.9Z" fill="white" />
            <path d="M318.199 140H311.199L307.699 134L311.199 127.9H318.199L321.599 134L318.199 140Z" fill="white" />
            <path d="M305.099 147.5H298.199L294.699 141.5L298.199 135.5H305.099L308.599 141.5L305.099 147.5Z" fill="white" />
            <path d="M292.1 155.1H285.1L281.6 149L285.1 143H292.1L295.5 149L292.1 155.1Z" fill="white" />
            <path d="M279 162.6H272.1L268.6 156.6L272.1 150.5H279L282.5 156.6L279 162.6Z" fill="white" />
            <path d="M279 177.7H272.1L268.6 171.6L272.1 165.6H279L282.5 171.6L279 177.7Z" fill="white" />
            <path d="M279 192.7H272.1L268.6 186.7L272.1 180.7H279L282.5 186.7L279 192.7Z" fill="white" />
            <path d="M279 207.8H272.1L268.6 201.8L272.1 195.8H279L282.5 201.8L279 207.8Z" fill="white" />
            <path d="M279 222.9H272.1L268.6 216.9L272.1 210.8H279L282.5 216.9L279 222.9Z" fill="white" />
            <path d="M279 238H272.1L268.6 231.9L272.1 225.9H279L282.5 231.9L279 238Z" fill="white" />
            <path d="M279 253H272.1L268.6 247L272.1 241H279L282.5 247L279 253Z" fill="white" />
            <path d="M279 268.1H272.1L268.6 262.1L272.1 256H279L282.5 262.1L279 268.1Z" fill="white" />
            <path d="M279 283.2H272.1L268.6 277.1L272.1 271.1H279L282.5 277.1L279 283.2Z" fill="white" />
            <path d="M279 298.2H272.1L268.6 292.2L272.1 286.2H279L282.5 292.2L279 298.2Z" fill="white" />
            <path d="M279 313.3H272.1L268.6 307.3L272.1 301.3H279L282.5 307.3L279 313.3Z" fill="white" />
            <path d="M279 328.4H272.1L268.6 322.4L272.1 316.3H279L282.5 322.4L279 328.4Z" fill="white" />
            <path d="M279 343.4H272.1L268.6 337.4L272.1 331.4H279L282.5 337.4L279 343.4Z" fill="white" />
            <path d="M279 358.5H272.1L268.6 352.5L272.1 346.5H279L282.5 352.5L279 358.5Z" fill="white" />
            <path d="M277.901 371.6H273.201L270.801 367.6L273.201 363.5H277.901L280.201 367.6L277.901 371.6Z" fill="white" />
            <path d="M279 418.8H272.1L268.6 412.8L272.1 406.7H279L282.5 412.8L279 418.8Z" fill="white" />
            <path d="M278.699 433.3H272.399L269.199 427.8L272.399 422.4H278.699L281.799 427.8L278.699 433.3Z" fill="white" />
            <path d="M279 448.9H272.1L268.6 442.9L272.1 436.9H279L282.5 442.9L279 448.9Z" fill="white" />
            <path d="M1127.2 546.7H1120.6L1117.2 540.9L1120.6 535.1H1127.2L1130.6 540.9L1127.2 546.7Z" fill="white" />
            <path d="M1126.3 514.9H1121.5L1119.1 510.7L1121.5 506.6H1126.3L1128.7 510.7L1126.3 514.9Z" fill="white" />
            <path d="M1124.6 451.8H1123.1L1122.4 450.5L1123.1 449.2H1124.6L1125.4 450.5L1124.6 451.8Z" fill="white" />
            <path d="M1125.1 437.5H1122.7L1121.4 435.4L1122.7 433.2H1125.1L1126.4 435.4L1125.1 437.5Z" fill="white" />
            <path d="M1126.5 424.8H1121.3L1118.7 420.3L1121.3 415.8H1126.5L1129.1 420.3L1126.5 424.8Z" fill="white" />
            <path d="M1127.2 411H1120.6L1117.3 405.2L1120.6 399.5H1127.2L1130.5 405.2L1127.2 411Z" fill="white" />
            <path d="M1127.4 396.2H1120.4L1116.9 390.2L1120.4 384.1H1127.4L1130.9 390.2L1127.4 396.2Z" fill="white" />
            <path d="M1127.4 381.1H1120.4L1116.9 375.1L1120.4 369.1H1127.4L1130.9 375.1L1127.4 381.1Z" fill="white" />
            <path d="M1127.4 366.1H1120.4L1116.9 360L1120.4 354H1127.4L1130.9 360L1127.4 366.1Z" fill="white" />
            <path d="M1127.4 351H1120.4L1116.9 345L1120.4 338.9H1127.4L1130.9 345L1127.4 351Z" fill="white" />
            <path d="M1127.4 335.9H1120.4L1116.9 329.9L1120.4 323.9H1127.4L1130.9 329.9L1127.4 335.9Z" fill="white" />
            <path d="M1127.4 320.8H1120.4L1116.9 314.8L1120.4 308.8H1127.4L1130.9 314.8L1127.4 320.8Z" fill="white" />
            <path d="M1127.4 305.8H1120.4L1116.9 299.7L1120.4 293.7H1127.4L1130.9 299.7L1127.4 305.8Z" fill="white" />
            <path d="M1127.4 290.7H1120.4L1116.9 284.7L1120.4 278.6H1127.4L1130.9 284.7L1127.4 290.7Z" fill="white" />
            <path d="M1127.4 275.6H1120.4L1116.9 269.6L1120.4 263.6H1127.4L1130.9 269.6L1127.4 275.6Z" fill="white" />
            <path d="M1127.4 260.6H1120.4L1116.9 254.5L1120.4 248.5H1127.4L1130.9 254.5L1127.4 260.6Z" fill="white" />
            <path d="M1127.4 245.5H1120.4L1116.9 239.5L1120.4 233.4H1127.4L1130.9 239.5L1127.4 245.5Z" fill="white" />
            <path d="M1127.4 230.4H1120.4L1116.9 224.4L1120.4 218.4H1127.4L1130.9 224.4L1127.4 230.4Z" fill="white" />
            <path d="M1127.4 215.3H1120.4L1116.9 209.3L1120.4 203.3H1127.4L1130.9 209.3L1127.4 215.3Z" fill="white" />
            <path d="M1127.4 200.3H1120.4L1116.9 194.2L1120.4 188.2H1127.4L1130.9 194.2L1127.4 200.3Z" fill="white" />
            <path d="M1127.4 185.2H1120.4L1116.9 179.2L1120.4 173.1H1127.4L1130.9 179.2L1127.4 185.2Z" fill="white" />
            <path d="M1127.4 170.1H1120.4L1116.9 164.1L1120.4 158.1H1127.4L1130.9 164.1L1127.4 170.1Z" fill="white" />
            <path d="M1127.4 155.1H1120.4L1116.9 149L1120.4 143H1127.4L1130.9 149L1127.4 155.1Z" fill="white" />
            <path d="M1114.3 147.5H1107.4L1103.9 141.5L1107.4 135.5H1114.3L1117.8 141.5L1114.3 147.5Z" fill="white" />
            <path d="M1101.3 140H1094.3L1090.8 134L1094.3 127.9H1101.3L1104.8 134L1101.3 140Z" fill="white" />
            <path d="M1088.2 132.5H1081.3L1077.8 126.4L1081.3 120.4H1088.2L1091.7 126.4L1088.2 132.5Z" fill="white" />
            <path d="M1075.2 124.9H1068.2L1064.7 118.9L1068.2 112.9H1075.2L1078.6 118.9L1075.2 124.9Z" fill="white" />
            <path d="M1062.1 117.4H1055.2L1051.7 111.4L1055.2 105.3H1062.1L1065.6 111.4L1062.1 117.4Z" fill="white" />
            <path d="M1049.1 109.9H1042.1L1038.6 103.8L1042.1 97.7998H1049.1L1052.5 103.8L1049.1 109.9Z" fill="white" />
            <path d="M1036 102.3H1029.1L1025.6 96.2998L1029.1 90.2998H1036L1039.5 96.2998L1036 102.3Z" fill="white" />
            <path d="M1023 94.8002H1016L1012.5 88.8002L1016 82.7002H1023L1026.4 88.8002L1023 94.8002Z" fill="white" />
            <path d="M1009.9 87.2002H1002.9L999.5 81.2002L1002.9 75.2002H1009.9L1013.4 81.2002L1009.9 87.2002Z" fill="white" />
            <path d="M995.199 76.8H991.499L989.699 73.7L991.499 70.5H995.199L996.999 73.7L995.199 76.8Z" fill="white" />
            <path d="M513.3 10.7H507.7L504.9 5.9L507.7 1H513.3L516.1 5.9L513.3 10.7Z" fill="white" />
            <path d="M500.2 18.1996H494.7L491.9 13.3996L494.7 8.59961H500.2L502.9 13.3996L500.2 18.1996Z" fill="white" />
            <path d="M487 25.5998H481.7L479 20.8998L481.7 16.2998H487L489.7 20.8998L487 25.5998Z" fill="white" />
            <path d="M474.801 34.5004H467.801L464.301 28.5004L467.801 22.4004H474.801L478.301 28.5004L474.801 34.5004Z" fill="white" />
            <path d="M461.701 42H454.801L451.301 36L454.801 30H461.701L465.201 36L461.701 42Z" fill="white" />
            <path d="M448.699 49.6H441.699L438.199 43.5L441.699 37.5H448.699L452.199 43.5L448.699 49.6Z" fill="white" />
            <path d="M435.599 57.1H428.699L425.199 51.1L428.699 45H435.599L439.099 51.1L435.599 57.1Z" fill="white" />
            <path d="M420.7 61.4004H417.5L415.9 58.6004L417.5 55.9004H420.7L422.3 58.6004L420.7 61.4004Z" fill="white" />
            <path d="M409.3 71.8H402.8L399.5 66.1L402.8 60.5H409.3L412.6 66.1L409.3 71.8Z" fill="white" />
            <path d="M357.199 102.1H350.499L347.199 96.3L350.499 90.5H357.199L360.499 96.3L357.199 102.1Z" fill="white" />
            <path d="M344.301 109.9H337.301L333.801 103.8L337.301 97.7998H344.301L347.801 103.8L344.301 109.9Z" fill="white" />
            <path d="M331.201 117.4H324.301L320.801 111.4L324.301 105.3H331.201L334.701 111.4L331.201 117.4Z" fill="white" />
            <path d="M318.201 124.9H311.201L307.801 118.9L311.201 112.9H318.201L321.601 118.9L318.201 124.9Z" fill="white" />
            <path d="M304.8 131.8H298.5L295.4 126.4L298.5 121H304.8L307.9 126.4L304.8 131.8Z" fill="white" />
            <path d="M292.1 140H285.1L281.6 134L285.1 127.9H292.1L295.5 134L292.1 140Z" fill="white" />
            <path d="M279 147.5H272.1L268.6 141.5L272.1 135.5H279L282.5 141.5L279 147.5Z" fill="white" />
            <path d="M266 155.1H259L255.5 149L259 143H266L269.4 149L266 155.1Z" fill="white" />
            <path d="M266 170.1H259L255.5 164.1L259 158.1H266L269.4 164.1L266 170.1Z" fill="white" />
            <path d="M266 185.2H259L255.5 179.2L259 173.1H266L269.4 179.2L266 185.2Z" fill="white" />
            <path d="M266 200.3H259L255.5 194.2L259 188.2H266L269.4 194.2L266 200.3Z" fill="white" />
            <path d="M266 215.3H259L255.5 209.3L259 203.3H266L269.4 209.3L266 215.3Z" fill="white" />
            <path d="M266 230.4H259L255.5 224.4L259 218.4H266L269.4 224.4L266 230.4Z" fill="white" />
            <path d="M266 245.5H259L255.5 239.5L259 233.4H266L269.4 239.5L266 245.5Z" fill="white" />
            <path d="M266 260.6H259L255.5 254.5L259 248.5H266L269.4 254.5L266 260.6Z" fill="white" />
            <path d="M266 275.6H259L255.5 269.6L259 263.6H266L269.4 269.6L266 275.6Z" fill="white" />
            <path d="M266 290.7H259L255.5 284.7L259 278.6H266L269.4 284.7L266 290.7Z" fill="white" />
            <path d="M266 305.8H259L255.5 299.7L259 293.7H266L269.4 299.7L266 305.8Z" fill="white" />
            <path d="M266 320.8H259L255.5 314.8L259 308.8H266L269.4 314.8L266 320.8Z" fill="white" />
            <path d="M266 335.9H259L255.5 329.9L259 323.9H266L269.4 329.9L266 335.9Z" fill="white" />
            <path d="M266 351H259L255.5 345L259 338.9H266L269.4 345L266 351Z" fill="white" />
            <path d="M265.801 365.8H259.101L255.801 360L259.101 354.2H265.801L269.201 360L265.801 365.8Z" fill="white" />
            <path d="M263.399 406.8H261.599L260.699 405.2L261.599 403.7H263.399L264.299 405.2L263.399 406.8Z" fill="white" />
            <path d="M265.9 426.3H259L255.6 420.3L259 414.3H265.9L269.4 420.3L265.9 426.3Z" fill="white" />
            <path d="M265.9 441.4H259L255.5 435.4L259 429.4H265.9L269.4 435.4L265.9 441.4Z" fill="white" />
            <path d="M1140.2 659.5H1133.7L1130.5 653.9L1133.7 648.4H1140.2L1143.4 653.9L1140.2 659.5Z" fill="white" />
            <path d="M1140.4 644.8H1133.5L1130.1 638.8L1133.5 632.9H1140.4L1143.8 638.8L1140.4 644.8Z" fill="white" />
            <path d="M1138.6 626.6H1135.3L1133.7 623.8L1135.3 620.9H1138.6L1140.2 623.8L1138.6 626.6Z" fill="white" />
            <path d="M1139.8 553.4H1134.1L1131.2 548.4L1134.1 543.5H1139.8L1142.7 548.4L1139.8 553.4Z" fill="white" />
            <path d="M1140.4 524.3H1133.5L1130 518.3L1133.5 512.2H1140.4L1143.9 518.3L1140.4 524.3Z" fill="white" />
            <path d="M1140.4 509.2H1133.5L1130 503.2L1133.5 497.2H1140.4L1143.9 503.2L1140.4 509.2Z" fill="white" />
            <path d="M1137.8 489.5H1136.1L1135.3 488.1L1136.1 486.7H1137.8L1138.6 488.1L1137.8 489.5Z" fill="white" />
            <path d="M1140.4 403.7H1133.5L1130 397.7L1133.5 391.7H1140.4L1143.9 397.7L1140.4 403.7Z" fill="white" />
            <path d="M1140.4 388.7H1133.5L1130 382.6L1133.5 376.6H1140.4L1143.9 382.6L1140.4 388.7Z" fill="white" />
            <path d="M1140.4 373.6H1133.5L1130 367.6L1133.5 361.5H1140.4L1143.9 367.6L1140.4 373.6Z" fill="white" />
            <path d="M1140.4 358.5H1133.5L1130 352.5L1133.5 346.5H1140.4L1143.9 352.5L1140.4 358.5Z" fill="white" />
            <path d="M1140.4 343.4H1133.5L1130 337.4L1133.5 331.4H1140.4L1143.9 337.4L1140.4 343.4Z" fill="white" />
            <path d="M1140.4 328.4H1133.5L1130 322.4L1133.5 316.3H1140.4L1143.9 322.4L1140.4 328.4Z" fill="white" />
            <path d="M1140.4 313.3H1133.5L1130 307.3L1133.5 301.3H1140.4L1143.9 307.3L1140.4 313.3Z" fill="white" />
            <path d="M1140.4 298.2H1133.5L1130 292.2L1133.5 286.2H1140.4L1143.9 292.2L1140.4 298.2Z" fill="white" />
            <path d="M1140.4 283.2H1133.5L1130 277.1L1133.5 271.1H1140.4L1143.9 277.1L1140.4 283.2Z" fill="white" />
            <path d="M1140.4 268.1H1133.5L1130 262.1L1133.5 256H1140.4L1143.9 262.1L1140.4 268.1Z" fill="white" />
            <path d="M1140.4 253H1133.5L1130 247L1133.5 241H1140.4L1143.9 247L1140.4 253Z" fill="white" />
            <path d="M1140.4 238H1133.5L1130 231.9L1133.5 225.9H1140.4L1143.9 231.9L1140.4 238Z" fill="white" />
            <path d="M1140.4 222.9H1133.5L1130 216.9L1133.5 210.8H1140.4L1143.9 216.9L1140.4 222.9Z" fill="white" />
            <path d="M1140.4 207.8H1133.5L1130 201.8L1133.5 195.8H1140.4L1143.9 201.8L1140.4 207.8Z" fill="white" />
            <path d="M1140.4 192.7H1133.5L1130 186.7L1133.5 180.7H1140.4L1143.9 186.7L1140.4 192.7Z" fill="white" />
            <path d="M1140.4 177.7H1133.5L1130 171.6L1133.5 165.6H1140.4L1143.9 171.6L1140.4 177.7Z" fill="white" />
            <path d="M1140.4 162.6H1133.5L1130 156.6L1133.5 150.5H1140.4L1143.9 156.6L1140.4 162.6Z" fill="white" />
            <path d="M1140.4 147.5H1133.5L1130 141.5L1133.5 135.5H1140.4L1143.9 141.5L1140.4 147.5Z" fill="white" />
            <path d="M1127.4 140H1120.4L1116.9 134L1120.4 127.9H1127.4L1130.9 134L1127.4 140Z" fill="white" />
            <path d="M1114.3 132.5H1107.4L1103.9 126.4L1107.4 120.4H1114.3L1117.8 126.4L1114.3 132.5Z" fill="white" />
            <path d="M1101.3 124.9H1094.3L1090.8 118.9L1094.3 112.9H1101.3L1104.8 118.9L1101.3 124.9Z" fill="white" />
            <path d="M1088.2 117.4H1081.3L1077.8 111.4L1081.3 105.3H1088.2L1091.7 111.4L1088.2 117.4Z" fill="white" />
            <path d="M1075.2 109.9H1068.2L1064.7 103.8L1068.2 97.7998H1075.2L1078.6 103.8L1075.2 109.9Z" fill="white" />
            <path d="M1062.1 102.3H1055.2L1051.7 96.2998L1055.2 90.2998H1062.1L1065.6 96.2998L1062.1 102.3Z" fill="white" />
            <path d="M1049.1 94.8002H1042.1L1038.6 88.8002L1042.1 82.7002H1049.1L1052.5 88.8002L1049.1 94.8002Z" fill="white" />
            <path d="M1036 87.2002H1029.1L1025.6 81.2002L1029.1 75.2002H1036L1039.5 81.2002L1036 87.2002Z" fill="white" />
            <path d="M1022.9 79.6002H1016L1012.6 73.7002L1016 67.7002H1022.9L1026.4 73.7002L1022.9 79.6002Z" fill="white" />
            <path d="M1007.4 67.8004H1005.4L1004.5 66.1004L1005.4 64.4004H1007.4L1008.4 66.1004L1007.4 67.8004Z" fill="white" />
            <path d="M460.801 25.2996H455.801L453.301 20.8996L455.801 16.5996H460.801L463.301 20.8996L460.801 25.2996Z" fill="white" />
            <path d="M448.5 34.1998H441.9L438.6 28.4998L441.9 22.7998H448.5L451.8 28.4998L448.5 34.1998Z" fill="white" />
            <path d="M435.599 42H428.699L425.199 36L428.699 30H435.599L439.099 36L435.599 42Z" fill="white" />
            <path d="M420.2 45.4996H418L416.9 43.4996L418 41.5996H420.2L421.3 43.4996L420.2 45.4996Z" fill="white" />
            <path d="M408.5 55.3998H403.6L401.1 51.0998L403.6 46.7998H408.5L411 51.0998L408.5 55.3998Z" fill="white" />
            <path d="M369.6 78.3H364.2L361.5 73.7L364.2 69H369.6L372.2 73.7L369.6 78.3Z" fill="white" />
            <path d="M356.901 86.4H350.801L347.801 81.2L350.801 76H356.901L359.901 81.2L356.901 86.4Z" fill="white" />
            <path d="M342.9 92.5H338.6L336.5 88.8L338.6 85H342.9L345.1 88.8L342.9 92.5Z" fill="white" />
            <path d="M331.1 102.1H324.4L321 96.3L324.4 90.5H331.1L334.5 96.3L331.1 102.1Z" fill="white" />
            <path d="M305 117.2H298.3L294.9 111.4L298.3 105.6H305L308.3 111.4L305 117.2Z" fill="white" />
            <path d="M276.6 128.2H274.5L273.5 126.4L274.5 124.7H276.6L277.6 126.4L276.6 128.2Z" fill="white" />
            <path d="M266 140H259L255.5 134L259 127.9H266L269.4 134L266 140Z" fill="white" />
            <path d="M252.9 147.5H245.9L242.5 141.5L245.9 135.5H252.9L256.4 141.5L252.9 147.5Z" fill="white" />
            <path d="M252.9 162.6H245.9L242.5 156.6L245.9 150.5H252.9L256.4 156.6L252.9 162.6Z" fill="white" />
            <path d="M252.9 177.7H245.9L242.5 171.6L245.9 165.6H252.9L256.4 171.6L252.9 177.7Z" fill="white" />
            <path d="M252.9 192.7H245.9L242.5 186.7L245.9 180.7H252.9L256.4 186.7L252.9 192.7Z" fill="white" />
            <path d="M252.9 207.8H245.9L242.5 201.8L245.9 195.8H252.9L256.4 201.8L252.9 207.8Z" fill="white" />
            <path d="M252.9 222.9H245.9L242.5 216.9L245.9 210.8H252.9L256.4 216.9L252.9 222.9Z" fill="white" />
            <path d="M252.9 238H245.9L242.5 231.9L245.9 225.9H252.9L256.4 231.9L252.9 238Z" fill="white" />
            <path d="M252.9 253H245.9L242.5 247L245.9 241H252.9L256.4 247L252.9 253Z" fill="white" />
            <path d="M252.9 268.1H245.9L242.5 262.1L245.9 256H252.9L256.4 262.1L252.9 268.1Z" fill="white" />
            <path d="M252.9 283.2H245.9L242.5 277.1L245.9 271.1H252.9L256.4 277.1L252.9 283.2Z" fill="white" />
            <path d="M252.9 298.2H245.9L242.5 292.2L245.9 286.2H252.9L256.4 292.2L252.9 298.2Z" fill="white" />
            <path d="M252.9 313.3H245.9L242.5 307.3L245.9 301.3H252.9L256.4 307.3L252.9 313.3Z" fill="white" />
            <path d="M252.9 328.4H245.9L242.5 322.4L245.9 316.3H252.9L256.4 322.4L252.9 328.4Z" fill="white" />
            <path d="M252.9 343.4H245.9L242.5 337.4L245.9 331.4H252.9L256.4 337.4L252.9 343.4Z" fill="white" />
            <path d="M252.9 358.5H245.9L242.5 352.5L245.9 346.5H252.9L256.4 352.5L252.9 358.5Z" fill="white" />
            <path d="M251.999 372.1H246.799L244.199 367.6L246.799 363H251.999L254.599 367.6L251.999 372.1Z" fill="white" />
            <path d="M250.299 414.3H248.599L247.699 412.8L248.599 411.3H250.299L251.099 412.8L250.299 414.3Z" fill="white" />
            <path d="M252.9 433.8H246L242.5 427.8L246 421.9H252.9L256.3 427.8L252.9 433.8Z" fill="white" />
            <path d="M1138.7 672H1135.2L1133.4 669L1135.2 665.9H1138.7L1140.5 669L1138.7 672Z" fill="white" />
            <path d="M1153.5 667.5H1146.5L1143 661.4L1146.5 655.4H1153.5L1157 661.4L1153.5 667.5Z" fill="white" />
            <path d="M1153.5 652.4H1146.5L1143 646.4L1146.5 640.3H1153.5L1157 646.4L1153.5 652.4Z" fill="white" />
            <path d="M1153.5 637.3H1146.5L1143 631.3L1146.5 625.3H1153.5L1157 631.3L1153.5 637.3Z" fill="white" />
            <path d="M1152.5 620.6H1147.5L1145 616.2L1147.5 611.9H1152.5L1155 616.2L1152.5 620.6Z" fill="white" />
            <path d="M1153.3 531.5H1146.7L1143.4 525.8L1146.7 520.1H1153.3L1156.6 525.8L1153.3 531.5Z" fill="white" />
            <path d="M1153.5 516.8H1146.5L1143 510.7L1146.5 504.7H1153.5L1157 510.7L1153.5 516.8Z" fill="white" />
            <path d="M1153.5 501.7H1146.5L1143 495.7L1146.5 489.6H1153.5L1157 495.7L1153.5 501.7Z" fill="white" />
            <path d="M1152.9 485.6H1147.1L1144.3 480.6L1147.1 475.6H1152.9L1155.7 480.6L1152.9 485.6Z" fill="white" />
            <path d="M1153.2 395.6H1146.8L1143.7 390.2L1146.8 384.7H1153.2L1156.3 390.2L1153.2 395.6Z" fill="white" />
            <path d="M1153.5 381.1H1146.5L1143 375.1L1146.5 369.1H1153.5L1157 375.1L1153.5 381.1Z" fill="white" />
            <path d="M1153.5 366.1H1146.5L1143 360L1146.5 354H1153.5L1157 360L1153.5 366.1Z" fill="white" />
            <path d="M1153.5 351H1146.5L1143 345L1146.5 338.9H1153.5L1157 345L1153.5 351Z" fill="white" />
            <path d="M1153.5 335.9H1146.5L1143 329.9L1146.5 323.9H1153.5L1157 329.9L1153.5 335.9Z" fill="white" />
            <path d="M1151.9 318.1H1148.1L1146.2 314.8L1148.1 311.6H1151.9L1153.8 314.8L1151.9 318.1Z" fill="white" />
            <path d="M1153.5 305.8H1146.5L1143 299.7L1146.5 293.7H1153.5L1157 299.7L1153.5 305.8Z" fill="white" />
            <path d="M1153.5 290.7H1146.5L1143 284.7L1146.5 278.6H1153.5L1157 284.7L1153.5 290.7Z" fill="white" />
            <path d="M1153.5 275.6H1146.5L1143 269.6L1146.5 263.6H1153.5L1157 269.6L1153.5 275.6Z" fill="white" />
            <path d="M1153.5 260.6H1146.5L1143 254.5L1146.5 248.5H1153.5L1157 254.5L1153.5 260.6Z" fill="white" />
            <path d="M1153.5 245.5H1146.5L1143 239.5L1146.5 233.4H1153.5L1157 239.5L1153.5 245.5Z" fill="white" />
            <path d="M1153.5 230.4H1146.5L1143 224.4L1146.5 218.4H1153.5L1157 224.4L1153.5 230.4Z" fill="white" />
            <path d="M1153.5 215.3H1146.5L1143 209.3L1146.5 203.3H1153.5L1157 209.3L1153.5 215.3Z" fill="white" />
            <path d="M1153.5 200.3H1146.5L1143 194.2L1146.5 188.2H1153.5L1157 194.2L1153.5 200.3Z" fill="white" />
            <path d="M1153.5 185.2H1146.5L1143 179.2L1146.5 173.1H1153.5L1157 179.2L1153.5 185.2Z" fill="white" />
            <path d="M1153.5 170.1H1146.5L1143 164.1L1146.5 158.1H1153.5L1157 164.1L1153.5 170.1Z" fill="white" />
            <path d="M1153.5 155.1H1146.5L1143 149L1146.5 143H1153.5L1157 149L1153.5 155.1Z" fill="white" />
            <path d="M1153.5 140H1146.5L1143 134L1146.5 127.9H1153.5L1157 134L1153.5 140Z" fill="white" />
            <path d="M1140.4 132.5H1133.5L1130 126.4L1133.5 120.4H1140.4L1143.9 126.4L1140.4 132.5Z" fill="white" />
            <path d="M1127.4 124.9H1120.4L1116.9 118.9L1120.4 112.9H1127.4L1130.9 118.9L1127.4 124.9Z" fill="white" />
            <path d="M1114.3 117.4H1107.4L1103.9 111.4L1107.4 105.3H1114.3L1117.8 111.4L1114.3 117.4Z" fill="white" />
            <path d="M1101.3 109.9H1094.3L1090.8 103.8L1094.3 97.7998H1101.3L1104.8 103.8L1101.3 109.9Z" fill="white" />
            <path d="M1088.2 102.3H1081.3L1077.8 96.2998L1081.3 90.2998H1088.2L1091.7 96.2998L1088.2 102.3Z" fill="white" />
            <path d="M1075.1 94.6998H1068.3L1064.9 88.7998L1068.3 82.7998H1075.1L1078.5 88.7998L1075.1 94.6998Z" fill="white" />
            <path d="M1062.1 87.1998H1055.2L1051.8 81.1998L1055.2 75.2998H1062.1L1065.5 81.1998L1062.1 87.1998Z" fill="white" />
            <path d="M1049.1 79.7002H1042.1L1038.6 73.7002L1042.1 67.7002H1049.1L1052.5 73.7002L1049.1 79.7002Z" fill="white" />
            <path d="M1036 72.1996H1029.1L1025.6 66.0996L1029.1 60.0996H1036L1039.5 66.0996L1036 72.1996Z" fill="white" />
            <path d="M995.901 47.8996H990.801L988.301 43.4996L990.801 39.0996H995.901L998.501 43.4996L995.901 47.8996Z" fill="white" />
            <path d="M982 38.8002H978.7L977 36.0002L978.7 33.2002H982L983.6 36.0002L982 38.8002Z" fill="white" />
            <path d="M434.601 25.0998H429.801L427.301 20.8998L429.801 16.7998H434.601L437.001 20.8998L434.601 25.0998Z" fill="white" />
            <path d="M421.001 31.7996H417.201L415.301 28.4996L417.201 25.0996H421.001L423.001 28.4996L421.001 31.7996Z" fill="white" />
            <path d="M409.1 41.3002H403L399.9 36.0002L403 30.7002H409.1L412.2 36.0002L409.1 41.3002Z" fill="white" />
            <path d="M395.1 47.1H390.9L388.9 43.5L390.9 40H395.1L397.1 43.5L395.1 47.1Z" fill="white" />
            <path d="M383.301 56.7998H376.601L373.301 51.0998L376.601 45.2998H383.301L386.601 51.0998L383.301 56.7998Z" fill="white" />
            <path d="M370.4 64.5996H363.4L359.9 58.5996L363.4 52.5996H370.4L373.9 58.5996L370.4 64.5996Z" fill="white" />
            <path d="M357.3 72.1996H350.4L346.9 66.0996L350.4 60.0996H357.3L360.8 66.0996L357.3 72.1996Z" fill="white" />
            <path d="M342.3 76.2002H339.3L337.9 73.7002L339.3 71.2002H342.3L343.7 73.7002L342.3 76.2002Z" fill="white" />
            <path d="M329.4 84.0998H326L324.4 81.1998L326 78.2998H329.4L331.1 81.1998L329.4 84.0998Z" fill="white" />
            <path d="M290.201 106.7H286.901L285.301 103.8L286.901 101H290.201L291.901 103.8L290.201 106.7Z" fill="white" />
            <path d="M277.601 115H273.401L271.301 111.4L273.401 107.7H277.601L279.701 111.4L277.601 115Z" fill="white" />
            <path d="M264.599 122.6H260.299L258.199 118.9L260.299 115.2H264.599L266.799 118.9L264.599 122.6Z" fill="white" />
            <path d="M250.4 128.1H248.4L247.5 126.4L248.4 124.7H250.4L251.4 126.4L250.4 128.1Z" fill="white" />
            <path d="M239.9 140H232.9L229.4 134L232.9 127.9H239.9L243.3 134L239.9 140Z" fill="white" />
            <path d="M239.9 155.1H232.9L229.4 149L232.9 143H239.9L243.3 149L239.9 155.1Z" fill="white" />
            <path d="M239.9 170.1H232.9L229.4 164.1L232.9 158.1H239.9L243.3 164.1L239.9 170.1Z" fill="white" />
            <path d="M239.9 185.2H232.9L229.4 179.2L232.9 173.1H239.9L243.3 179.2L239.9 185.2Z" fill="white" />
            <path d="M239.9 200.3H232.9L229.4 194.2L232.9 188.2H239.9L243.3 194.2L239.9 200.3Z" fill="white" />
            <path d="M239.9 215.3H232.9L229.4 209.3L232.9 203.3H239.9L243.3 209.3L239.9 215.3Z" fill="white" />
            <path d="M239.9 230.4H232.9L229.4 224.4L232.9 218.4H239.9L243.3 224.4L239.9 230.4Z" fill="white" />
            <path d="M239.9 245.5H232.9L229.4 239.5L232.9 233.4H239.9L243.3 239.5L239.9 245.5Z" fill="white" />
            <path d="M239.9 260.6H232.9L229.4 254.5L232.9 248.5H239.9L243.3 254.5L239.9 260.6Z" fill="white" />
            <path d="M239.9 275.6H232.9L229.4 269.6L232.9 263.6H239.9L243.3 269.6L239.9 275.6Z" fill="white" />
            <path d="M239.9 290.7H232.9L229.4 284.7L232.9 278.6H239.9L243.3 284.7L239.9 290.7Z" fill="white" />
            <path d="M239.9 305.8H232.9L229.4 299.7L232.9 293.7H239.9L243.3 299.7L239.9 305.8Z" fill="white" />
            <path d="M239.9 320.8H232.9L229.4 314.8L232.9 308.8H239.9L243.3 314.8L239.9 320.8Z" fill="white" />
            <path d="M239.9 335.9H232.9L229.4 329.9L232.9 323.9H239.9L243.3 329.9L239.9 335.9Z" fill="white" />
            <path d="M239.9 351H232.9L229.4 345L232.9 338.9H239.9L243.3 345L239.9 351Z" fill="white" />
            <path d="M239.9 366.1H232.9L229.4 360L232.9 354H239.9L243.3 360L239.9 366.1Z" fill="white" />
            <path d="M239.9 381.1H232.9L229.4 375.1L232.9 369.1H239.9L243.3 375.1L239.9 381.1Z" fill="white" />
            <path d="M239.9 396.2H232.9L229.4 390.2L232.9 384.1H239.9L243.3 390.2L239.9 396.2Z" fill="white" />
            <path d="M239.9 411.3H232.9L229.4 405.2L232.9 399.2H239.9L243.3 405.2L239.9 411.3Z" fill="white" />
            <path d="M239.9 426.3H232.9L229.4 420.3L232.9 414.3H239.9L243.3 420.3L239.9 426.3Z" fill="white" />
            <path d="M237.1 436.7H235.6L234.9 435.4L235.6 434.1H237.1L237.9 435.4L237.1 436.7Z" fill="white" />
            <path d="M1138.1 686H1135.8L1134.7 684L1135.8 682.1H1138.1L1139.2 684L1138.1 686Z" fill="white" />
            <path d="M1153.5 682.5H1146.5L1143 676.5L1146.5 670.5H1153.5L1157 676.5L1153.5 682.5Z" fill="white" />
            <path d="M1166.5 675H1159.6L1156.1 669L1159.6 663H1166.5L1170 669L1166.5 675Z" fill="white" />
            <path d="M1166.5 659.9H1159.6L1156.1 653.9L1159.6 647.9H1166.5L1170 653.9L1166.5 659.9Z" fill="white" />
            <path d="M1166.5 644.9H1159.6L1156.1 638.8L1159.6 632.8H1166.5L1170 638.8L1166.5 644.9Z" fill="white" />
            <path d="M1166.5 629.8H1159.6L1156.1 623.8L1159.6 617.7H1166.5L1170 623.8L1166.5 629.8Z" fill="white" />
            <path d="M1166.4 614.5H1159.7L1156.4 608.7L1159.7 602.9H1166.4L1169.7 608.7L1166.4 614.5Z" fill="white" />
            <path d="M1163.7 594.7H1162.4L1161.8 593.6L1162.4 592.6H1163.7L1164.3 593.6L1163.7 594.7Z" fill="white" />
            <path d="M1165.2 522H1160.9L1158.8 518.3L1160.9 514.6H1165.2L1167.3 518.3L1165.2 522Z" fill="white" />
            <path d="M1164.2 505.1H1161.9L1160.8 503.2L1161.9 501.3H1164.2L1165.3 503.2L1164.2 505.1Z" fill="white" />
            <path d="M1165.2 491.8H1160.9L1158.8 488.1L1160.9 484.4H1165.2L1167.3 488.1L1165.2 491.8Z" fill="white" />
            <path d="M1164.8 400.7H1161.3L1159.6 397.7L1161.3 394.7H1164.8L1166.5 397.7L1164.8 400.7Z" fill="white" />
            <path d="M1164.1 384.5H1162L1160.9 382.6L1162 380.8H1164.1L1165.2 382.6L1164.1 384.5Z" fill="white" />
            <path d="M1166.2 373H1159.9L1156.7 367.6L1159.9 362.1H1166.2L1169.4 367.6L1166.2 373Z" fill="white" />
            <path d="M1166.4 358.3H1159.7L1156.3 352.5L1159.7 346.7H1166.4L1169.8 352.5L1166.4 358.3Z" fill="white" />
            <path d="M1163.6 338.4H1162.5L1161.9 337.4L1162.5 336.4H1163.6L1164.2 337.4L1163.6 338.4Z" fill="white" />
            <path d="M1164.5 324.8H1161.6L1160.2 322.4L1161.6 319.9H1164.5L1165.9 322.4L1164.5 324.8Z" fill="white" />
            <path d="M1166.1 312.6H1160L1157 307.3L1160 302H1166.1L1169.1 307.3L1166.1 312.6Z" fill="white" />
            <path d="M1166.5 298.2H1159.6L1156.1 292.2L1159.6 286.2H1166.5L1170 292.2L1166.5 298.2Z" fill="white" />
            <path d="M1166.5 283.2H1159.6L1156.1 277.1L1159.6 271.1H1166.5L1170 277.1L1166.5 283.2Z" fill="white" />
            <path d="M1166.5 268.1H1159.6L1156.1 262.1L1159.6 256H1166.5L1170 262.1L1166.5 268.1Z" fill="white" />
            <path d="M1166.5 253H1159.6L1156.1 247L1159.6 241H1166.5L1170 247L1166.5 253Z" fill="white" />
            <path d="M1166.5 238H1159.6L1156.1 231.9L1159.6 225.9H1166.5L1170 231.9L1166.5 238Z" fill="white" />
            <path d="M1166.5 222.9H1159.6L1156.1 216.9L1159.6 210.8H1166.5L1170 216.9L1166.5 222.9Z" fill="white" />
            <path d="M1166.5 207.8H1159.6L1156.1 201.8L1159.6 195.8H1166.5L1170 201.8L1166.5 207.8Z" fill="white" />
            <path d="M1166.5 192.7H1159.6L1156.1 186.7L1159.6 180.7H1166.5L1170 186.7L1166.5 192.7Z" fill="white" />
            <path d="M1166.5 177.7H1159.6L1156.1 171.6L1159.6 165.6H1166.5L1170 171.6L1166.5 177.7Z" fill="white" />
            <path d="M1166.5 162.6H1159.6L1156.1 156.6L1159.6 150.5H1166.5L1170 156.6L1166.5 162.6Z" fill="white" />
            <path d="M1166.5 147.5H1159.6L1156.1 141.5L1159.6 135.5H1166.5L1170 141.5L1166.5 147.5Z" fill="white" />
            <path d="M1166.5 132.5H1159.6L1156.1 126.4L1159.6 120.4H1166.5L1170 126.4L1166.5 132.5Z" fill="white" />
            <path d="M1153.5 124.9H1146.5L1143 118.9L1146.5 112.9H1153.5L1157 118.9L1153.5 124.9Z" fill="white" />
            <path d="M1140.4 117.4H1133.5L1130 111.4L1133.5 105.3H1140.4L1143.9 111.4L1140.4 117.4Z" fill="white" />
            <path d="M1127.4 109.9H1120.4L1116.9 103.8L1120.4 97.7998H1127.4L1130.9 103.8L1127.4 109.9Z" fill="white" />
            <path d="M1114.3 102.3H1107.4L1103.9 96.2998L1107.4 90.2998H1114.3L1117.7 96.2998L1114.3 102.3Z" fill="white" />
            <path d="M1099.3 91.3996H1096.2L1094.7 88.7996L1096.2 86.0996H1099.3L1100.9 88.7996L1099.3 91.3996Z" fill="white" />
            <path d="M1073.3 76.4004H1070.1L1068.5 73.7004L1070.1 70.9004H1073.3L1074.9 73.7004L1073.3 76.4004Z" fill="white" />
            <path d="M1062.1 72.1002H1055.2L1051.7 66.1002L1055.2 60.2002H1062.1L1065.5 66.1002L1062.1 72.1002Z" fill="white" />
            <path d="M1008.1 38.8002H1004.8L1003.2 36.0002L1004.8 33.2002H1008.1L1009.7 36.0002L1008.1 38.8002Z" fill="white" />
            <path d="M421.499 17.6002H416.699L414.199 13.4002L416.699 9.2002H421.499L423.999 13.4002L421.499 17.6002Z" fill="white" />
            <path d="M409.5 27.0004H402.6L399.1 20.9004L402.6 14.9004H409.5L413 20.9004L409.5 27.0004Z" fill="white" />
            <path d="M396.5 34.5004H389.5L386 28.5004L389.5 22.4004H396.5L400 28.5004L396.5 34.5004Z" fill="white" />
            <path d="M383.4 42H376.5L373 36L376.5 30H383.4L386.9 36L383.4 42Z" fill="white" />
            <path d="M370.4 49.6H363.4L359.9 43.5L363.4 37.5H370.4L373.9 43.5L370.4 49.6Z" fill="white" />
            <path d="M357.3 57.1H350.4L346.9 51.1L350.4 45H357.3L360.8 51.1L357.3 57.1Z" fill="white" />
            <path d="M343.801 63.8004H337.801L334.801 58.6004L337.801 53.4004H343.801L346.801 58.6004L343.801 63.8004Z" fill="white" />
            <path d="M330.499 70.9998H324.999L322.199 66.0998L324.999 61.2998H330.499L333.299 66.0998L330.499 70.9998Z" fill="white" />
            <path d="M315.6 75.2002H313.8L312.9 73.7002L313.8 72.2002H315.6L316.4 73.7002L315.6 75.2002Z" fill="white" />
            <path d="M292.1 94.8002H285.1L281.6 88.8002L285.1 82.7002H292.1L295.5 88.8002L292.1 94.8002Z" fill="white" />
            <path d="M276.701 98.1998H274.401L273.301 96.2998L274.401 94.2998H276.701L277.801 96.2998L276.701 98.1998Z" fill="white" />
            <path d="M266 109.9H259L255.5 103.8L259 97.7998H266L269.4 103.8L266 109.9Z" fill="white" />
            <path d="M252.9 117.4H245.9L242.5 111.4L245.9 105.3H252.9L256.4 111.4L252.9 117.4Z" fill="white" />
            <path d="M239.4 124H233.4L230.4 118.9L233.4 113.7H239.4L242.3 118.9L239.4 124Z" fill="white" />
            <path d="M226.5 131.9H220.2L217 126.4L220.2 120.9H226.5L229.7 126.4L226.5 131.9Z" fill="white" />
            <path d="M226.8 147.5H219.8L216.4 141.5L219.8 135.5H226.8L230.3 141.5L226.8 147.5Z" fill="white" />
            <path d="M226.8 162.6H219.8L216.4 156.6L219.8 150.5H226.8L230.3 156.6L226.8 162.6Z" fill="white" />
            <path d="M226.8 177.7H219.8L216.4 171.6L219.8 165.6H226.8L230.3 171.6L226.8 177.7Z" fill="white" />
            <path d="M226.8 192.7H219.8L216.4 186.7L219.8 180.7H226.8L230.3 186.7L226.8 192.7Z" fill="white" />
            <path d="M226.8 207.8H219.8L216.4 201.8L219.8 195.8H226.8L230.3 201.8L226.8 207.8Z" fill="white" />
            <path d="M226.8 222.9H219.8L216.4 216.9L219.8 210.8H226.8L230.3 216.9L226.8 222.9Z" fill="white" />
            <path d="M226.8 238H219.8L216.4 231.9L219.8 225.9H226.8L230.3 231.9L226.8 238Z" fill="white" />
            <path d="M226.8 253H219.8L216.4 247L219.8 241H226.8L230.3 247L226.8 253Z" fill="white" />
            <path d="M226.8 268.1H219.8L216.4 262.1L219.8 256H226.8L230.3 262.1L226.8 268.1Z" fill="white" />
            <path d="M226.8 283.2H219.8L216.4 277.1L219.8 271.1H226.8L230.3 277.1L226.8 283.2Z" fill="white" />
            <path d="M226.8 298.2H219.8L216.4 292.2L219.8 286.2H226.8L230.3 292.2L226.8 298.2Z" fill="white" />
            <path d="M226.8 313.3H219.8L216.4 307.3L219.8 301.3H226.8L230.3 307.3L226.8 313.3Z" fill="white" />
            <path d="M226.8 328.4H219.8L216.4 322.4L219.8 316.3H226.8L230.3 322.4L226.8 328.4Z" fill="white" />
            <path d="M226.8 343.4H219.8L216.4 337.4L219.8 331.4H226.8L230.3 337.4L226.8 343.4Z" fill="white" />
            <path d="M226.8 358.5H219.8L216.4 352.5L219.8 346.5H226.8L230.3 352.5L226.8 358.5Z" fill="white" />
            <path d="M226.8 373.6H219.8L216.4 367.6L219.8 361.5H226.8L230.3 367.6L226.8 373.6Z" fill="white" />
            <path d="M226.8 388.7H219.8L216.4 382.6L219.8 376.6H226.8L230.3 382.6L226.8 388.7Z" fill="white" />
            <path d="M226.8 403.7H219.8L216.4 397.7L219.8 391.7H226.8L230.3 397.7L226.8 403.7Z" fill="white" />
            <path d="M226.8 418.8H219.8L216.4 412.8L219.8 406.7H226.8L230.3 412.8L226.8 418.8Z" fill="white" />
            <path d="M225 430.7H221.7L220 427.8L221.7 425H225L226.6 427.8L225 430.7Z" fill="white" />
            <path d="M1177.8 679.5H1174.4L1172.6 676.5L1174.4 673.5H1177.8L1179.6 676.5L1177.8 679.5Z" fill="white" />
            <path d="M1179.6 667.5H1172.6L1169.1 661.4L1172.6 655.4H1179.6L1183.1 661.4L1179.6 667.5Z" fill="white" />
            <path d="M1179.6 652.4H1172.6L1169.1 646.4L1172.6 640.3H1179.6L1183.1 646.4L1179.6 652.4Z" fill="white" />
            <path d="M1179.6 637.3H1172.6L1169.1 631.3L1172.6 625.3H1179.6L1183.1 631.3L1179.6 637.3Z" fill="white" />
            <path d="M1179.6 622.3H1172.6L1169.1 616.2L1172.6 610.2H1179.6L1183.1 616.2L1179.6 622.3Z" fill="white" />
            <path d="M1179.6 607.2H1172.6L1169.1 601.2L1172.6 595.1H1179.6L1183.1 601.2L1179.6 607.2Z" fill="white" />
            <path d="M1177.5 528.3H1174.7L1173.3 525.8L1174.7 523.3H1177.5L1178.9 525.8L1177.5 528.3Z" fill="white" />
            <path d="M1179.3 516.4H1172.9L1169.6 510.7L1172.9 505.1H1179.3L1182.6 510.7L1179.3 516.4Z" fill="white" />
            <path d="M1179.6 426.3H1172.6L1169.1 420.3L1172.6 414.3H1179.6L1183.1 420.3L1179.6 426.3Z" fill="white" />
            <path d="M1178.5 319H1173.7L1171.3 314.8L1173.7 310.7H1178.5L1180.9 314.8L1178.5 319Z" fill="white" />
            <path d="M1179.6 305.8H1172.6L1169.1 299.7L1172.6 293.7H1179.6L1183.1 299.7L1179.6 305.8Z" fill="white" />
            <path d="M1179.6 290.7H1172.6L1169.1 284.7L1172.6 278.6H1179.6L1183.1 284.7L1179.6 290.7Z" fill="white" />
            <path d="M1179.6 275.6H1172.6L1169.1 269.6L1172.6 263.6H1179.6L1183.1 269.6L1179.6 275.6Z" fill="white" />
            <path d="M1179.6 260.6H1172.6L1169.1 254.5L1172.6 248.5H1179.6L1183.1 254.5L1179.6 260.6Z" fill="white" />
            <path d="M1179.6 245.5H1172.6L1169.1 239.5L1172.6 233.4H1179.6L1183.1 239.5L1179.6 245.5Z" fill="white" />
            <path d="M1179.6 230.4H1172.6L1169.1 224.4L1172.6 218.4H1179.6L1183.1 224.4L1179.6 230.4Z" fill="white" />
            <path d="M1179.6 215.3H1172.6L1169.1 209.3L1172.6 203.3H1179.6L1183.1 209.3L1179.6 215.3Z" fill="white" />
            <path d="M1179.6 200.3H1172.6L1169.1 194.2L1172.6 188.2H1179.6L1183.1 194.2L1179.6 200.3Z" fill="white" />
            <path d="M1179.6 185.2H1172.6L1169.1 179.2L1172.6 173.1H1179.6L1183.1 179.2L1179.6 185.2Z" fill="white" />
            <path d="M1179.6 170.1H1172.6L1169.1 164.1L1172.6 158.1H1179.6L1183.1 164.1L1179.6 170.1Z" fill="white" />
            <path d="M1179.6 155.1H1172.6L1169.1 149L1172.6 143H1179.6L1183.1 149L1179.6 155.1Z" fill="white" />
            <path d="M1179.6 140H1172.6L1169.1 134L1172.6 127.9H1179.6L1183.1 134L1179.6 140Z" fill="white" />
            <path d="M1179.6 124.9H1172.6L1169.1 118.9L1172.6 112.9H1179.6L1183.1 118.9L1179.6 124.9Z" fill="white" />
            <path d="M1166.5 117.4H1159.6L1156.1 111.4L1159.6 105.3H1166.5L1170 111.4L1166.5 117.4Z" fill="white" />
            <path d="M1153.5 109.9H1146.5L1143 103.8L1146.5 97.7998H1153.5L1157 103.8L1153.5 109.9Z" fill="white" />
            <path d="M1138.9 99.6H1135L1133.1 96.3L1135 93H1138.9L1140.8 96.3L1138.9 99.6Z" fill="white" />
            <path d="M395.7 18.1002H390.3L387.5 13.4002L390.3 8.7002H395.7L398.5 13.4002L395.7 18.1002Z" fill="white" />
            <path d="M383.4 27.0004H376.5L373 20.9004L376.5 14.9004H383.4L386.9 20.9004L383.4 27.0004Z" fill="white" />
            <path d="M370.4 34.5004H363.4L359.9 28.5004L363.4 22.4004H370.4L373.9 28.5004L370.4 34.5004Z" fill="white" />
            <path d="M357.3 42H350.4L346.9 36L350.4 30H357.3L360.8 36L357.3 42Z" fill="white" />
            <path d="M342.899 47.1H338.699L336.699 43.5L338.699 40H342.899L344.899 43.5L342.899 47.1Z" fill="white" />
            <path d="M330.499 55.8998H324.999L322.199 51.0998L324.999 46.2998H330.499L333.299 51.0998L330.499 55.8998Z" fill="white" />
            <path d="M302.601 67.8H300.701L299.801 66.1L300.701 64.5H302.601L303.501 66.1L302.601 67.8Z" fill="white" />
            <path d="M291.499 78.6996H285.699L282.699 73.6996L285.699 68.5996H291.499L294.399 73.6996L291.499 78.6996Z" fill="white" />
            <path d="M277 83.7002H274.1L272.6 81.2002L274.1 78.7002H277L278.5 81.2002L277 83.7002Z" fill="white" />
            <path d="M265.4 93.8996H259.5L256.6 88.7996L259.5 83.5996H265.4L268.4 88.7996L265.4 93.8996Z" fill="white" />
            <path d="M252.9 102.3H246L242.5 96.2998L246 90.2998H252.9L256.3 96.2998L252.9 102.3Z" fill="white" />
            <path d="M239.9 109.9H232.9L229.4 103.8L232.9 97.7998H239.9L243.3 103.8L239.9 109.9Z" fill="white" />
            <path d="M226.399 116.7H220.199L217.199 111.4L220.199 106H226.399L229.499 111.4L226.399 116.7Z" fill="white" />
            <path d="M211.099 120.3H209.499L208.699 118.9L209.499 117.5H211.099L211.899 118.9L211.099 120.3Z" fill="white" />
            <path d="M213.801 140H206.801L203.301 134L206.801 127.9H213.801L217.201 134L213.801 140Z" fill="white" />
            <path d="M213.801 155.1H206.801L203.301 149L206.801 143H213.801L217.201 149L213.801 155.1Z" fill="white" />
            <path d="M213.801 170.1H206.801L203.301 164.1L206.801 158.1H213.801L217.201 164.1L213.801 170.1Z" fill="white" />
            <path d="M213.801 185.2H206.801L203.301 179.2L206.801 173.1H213.801L217.201 179.2L213.801 185.2Z" fill="white" />
            <path d="M213.801 200.3H206.801L203.301 194.2L206.801 188.2H213.801L217.201 194.2L213.801 200.3Z" fill="white" />
            <path d="M213.801 215.3H206.801L203.301 209.3L206.801 203.3H213.801L217.201 209.3L213.801 215.3Z" fill="white" />
            <path d="M213.801 230.4H206.801L203.301 224.4L206.801 218.4H213.801L217.201 224.4L213.801 230.4Z" fill="white" />
            <path d="M213.801 245.5H206.801L203.301 239.5L206.801 233.4H213.801L217.201 239.5L213.801 245.5Z" fill="white" />
            <path d="M213.801 260.6H206.801L203.301 254.5L206.801 248.5H213.801L217.201 254.5L213.801 260.6Z" fill="white" />
            <path d="M213.801 275.6H206.801L203.301 269.6L206.801 263.6H213.801L217.201 269.6L213.801 275.6Z" fill="white" />
            <path d="M213.801 290.7H206.801L203.301 284.7L206.801 278.6H213.801L217.201 284.7L213.801 290.7Z" fill="white" />
            <path d="M213.801 305.8H206.801L203.301 299.7L206.801 293.7H213.801L217.201 299.7L213.801 305.8Z" fill="white" />
            <path d="M213.801 320.8H206.801L203.301 314.8L206.801 308.8H213.801L217.201 314.8L213.801 320.8Z" fill="white" />
            <path d="M213.801 335.9H206.801L203.301 329.9L206.801 323.9H213.801L217.201 329.9L213.801 335.9Z" fill="white" />
            <path d="M213.801 351H206.801L203.301 345L206.801 338.9H213.801L217.201 345L213.801 351Z" fill="white" />
            <path d="M213.801 366.1H206.801L203.301 360L206.801 354H213.801L217.201 360L213.801 366.1Z" fill="white" />
            <path d="M213.801 381.1H206.801L203.301 375.1L206.801 369.1H213.801L217.201 375.1L213.801 381.1Z" fill="white" />
            <path d="M213.801 396.2H206.801L203.301 390.2L206.801 384.1H213.801L217.201 390.2L213.801 396.2Z" fill="white" />
            <path d="M213.7 411.2H206.8L203.4 405.2L206.8 399.3H213.7L217.1 405.2L213.7 411.2Z" fill="white" />
            <path d="M211.7 422.8H208.9L207.4 420.3L208.9 417.9H211.7L213.1 420.3L211.7 422.8Z" fill="white" />
            <path d="M498.7 528H496.1L494.9 525.8L496.1 523.6H498.7L500 525.8L498.7 528Z" fill="white" />
            <path d="M474.801 531.8H467.801L464.301 525.8L467.801 519.8H474.801L478.301 525.8L474.801 531.8Z" fill="white" />
            <path d="M487.8 539.4H480.9L477.4 533.3L480.9 527.3H487.8L491.3 533.3L487.8 539.4Z" fill="white" />
            <path d="M500.9 546.9H493.9L490.5 540.9L493.9 534.8H500.9L504.4 540.9L500.9 546.9Z" fill="white" />
            <path d="M461.701 539.4H454.801L451.301 533.3L454.801 527.3H461.701L465.201 533.3L461.701 539.4Z" fill="white" />
            <path d="M474.801 546.9H467.801L464.301 540.9L467.801 534.8H474.801L478.301 540.9L474.801 546.9Z" fill="white" />
            <path d="M487.8 554.4H480.9L477.4 548.4L480.9 542.4H487.8L491.3 548.4L487.8 554.4Z" fill="white" />
            <path d="M500.6 561.6H494.2L490.9 555.9L494.2 550.3H500.6L503.9 555.9L500.6 561.6Z" fill="white" />
            <path d="M461.1 523.2H455.4L452.6 518.3L455.4 513.4H461.1L463.9 518.3L461.1 523.2Z" fill="white" />
            <path d="M448.699 546.9H441.699L438.199 540.9L441.699 534.8H448.699L452.199 540.9L448.699 546.9Z" fill="white" />
            <path d="M461.701 554.4H454.801L451.301 548.4L454.801 542.4H461.701L465.201 548.4L461.701 554.4Z" fill="white" />
            <path d="M474.801 562H467.801L464.301 555.9L467.801 549.9H474.801L478.301 555.9L474.801 562Z" fill="white" />
            <path d="M487.8 569.5H480.9L477.4 563.5L480.9 557.5H487.8L491.3 563.5L487.8 569.5Z" fill="white" />
            <path d="M447.3 514.4H443.1L441 510.7L443.1 507.1H447.3L449.4 510.7L447.3 514.4Z" fill="white" />
            <path d="M448.699 531.8H441.699L438.199 525.8L441.699 519.8H448.699L452.199 525.8L448.699 531.8Z" fill="white" />
            <path d="M435.599 554.4H428.699L425.199 548.4L428.699 542.4H435.599L439.099 548.4L435.599 554.4Z" fill="white" />
            <path d="M448.699 562H441.699L438.199 555.9L441.699 549.9H448.699L452.199 555.9L448.699 562Z" fill="white" />
            <path d="M461.701 569.5H454.801L451.301 563.5L454.801 557.5H461.701L465.201 563.5L461.701 569.5Z" fill="white" />
            <path d="M474.801 577H467.801L464.301 571L467.801 565H474.801L478.301 571L474.801 577Z" fill="white" />
            <path d="M487.2 583.5H481.5L478.6 578.6L481.5 573.6H487.2L490.1 578.6L487.2 583.5Z" fill="white" />
            <path d="M434.4 492H429.9L427.6 488.1L429.9 484.2H434.4L436.7 488.1L434.4 492Z" fill="white" />
            <path d="M435.5 509H428.8L425.4 503.2L428.8 497.4H435.5L438.9 503.2L435.5 509Z" fill="white" />
            <path d="M435.599 524.3H428.699L425.199 518.3L428.699 512.2H435.599L439.099 518.3L435.599 524.3Z" fill="white" />
            <path d="M435.599 539.4H428.699L425.199 533.3L428.699 527.3H435.599L439.099 533.3L435.599 539.4Z" fill="white" />
            <path d="M422.6 562H415.6L412.1 555.9L415.6 549.9H422.6L426.1 555.9L422.6 562Z" fill="white" />
            <path d="M435.599 569.5H428.699L425.199 563.5L428.699 557.5H435.599L439.099 563.5L435.599 569.5Z" fill="white" />
            <path d="M448.699 577H441.699L438.199 571L441.699 565H448.699L452.199 571L448.699 577Z" fill="white" />
            <path d="M461.701 584.6H454.801L451.301 578.6L454.801 572.5H461.701L465.201 578.6L461.701 584.6Z" fill="white" />
            <path d="M474.801 592.1H467.801L464.301 586.1L467.801 580.1H474.801L478.301 586.1L474.801 592.1Z" fill="white" />
            <path d="M485.201 595H483.601L482.801 593.6L483.601 592.3H485.201L485.901 593.6L485.201 595Z" fill="white" />
            <path d="M421 483.8H417.2L415.4 480.6L417.2 477.4H421L422.8 480.6L421 483.8Z" fill="white" />
            <path d="M422.6 501.7H415.6L412.1 495.7L415.6 489.6H422.6L426.1 495.7L422.6 501.7Z" fill="white" />
            <path d="M422.6 516.8H415.6L412.1 510.7L415.6 504.7H422.6L426.1 510.7L422.6 516.8Z" fill="white" />
            <path d="M422.6 531.8H415.6L412.1 525.8L415.6 519.8H422.6L426.1 525.8L422.6 531.8Z" fill="white" />
            <path d="M422.6 546.9H415.6L412.1 540.9L415.6 534.8H422.6L426.1 540.9L422.6 546.9Z" fill="white" />
            <path d="M409.5 569.5H402.6L399.1 563.5L402.6 557.5H409.5L413 563.5L409.5 569.5Z" fill="white" />
            <path d="M422.6 577H415.6L412.1 571L415.6 565H422.6L426.1 571L422.6 577Z" fill="white" />
            <path d="M435.599 584.6H428.699L425.199 578.6L428.699 572.5H435.599L439.099 578.6L435.599 584.6Z" fill="white" />
            <path d="M448.699 592.1H441.699L438.199 586.1L441.699 580.1H448.699L452.199 586.1L448.699 592.1Z" fill="white" />
            <path d="M461.701 599.7H454.801L451.301 593.6L454.801 587.6H461.701L465.201 593.6L461.701 599.7Z" fill="white" />
            <path d="M474.801 607.2H467.801L464.301 601.2L467.801 595.1H474.801L478.301 601.2L474.801 607.2Z" fill="white" />
            <path d="M407.3 475.2H404.8L403.6 473.1L404.8 470.9H407.3L408.5 473.1L407.3 475.2Z" fill="white" />
            <path d="M409.5 494.2H402.6L399.1 488.1L402.6 482.1H409.5L413 488.1L409.5 494.2Z" fill="white" />
            <path d="M409.5 509.2H402.6L399.1 503.2L402.6 497.2H409.5L413 503.2L409.5 509.2Z" fill="white" />
            <path d="M409.5 524.3H402.6L399.1 518.3L402.6 512.2H409.5L413 518.3L409.5 524.3Z" fill="white" />
            <path d="M409.5 539.4H402.6L399.1 533.3L402.6 527.3H409.5L413 533.3L409.5 539.4Z" fill="white" />
            <path d="M409.5 554.4H402.6L399.1 548.4L402.6 542.4H409.5L413 548.4L409.5 554.4Z" fill="white" />
            <path d="M396.5 577H389.5L386 571L389.5 565H396.5L400 571L396.5 577Z" fill="white" />
            <path d="M409.5 584.6H402.6L399.1 578.6L402.6 572.5H409.5L413 578.6L409.5 584.6Z" fill="white" />
            <path d="M422.6 592.1H415.6L412.1 586.1L415.6 580.1H422.6L426.1 586.1L422.6 592.1Z" fill="white" />
            <path d="M435.599 599.7H428.699L425.199 593.6L428.699 587.6H435.599L439.099 593.6L435.599 599.7Z" fill="white" />
            <path d="M448.699 607.2H441.699L438.199 601.2L441.699 595.1H448.699L452.199 601.2L448.699 607.2Z" fill="white" />
            <path d="M461.701 614.7H454.801L451.301 608.7L454.801 602.7H461.701L465.201 608.7L461.701 614.7Z" fill="white" />
            <path d="M474.801 622.3H467.801L464.301 616.2L467.801 610.2H474.801L478.301 616.2L474.801 622.3Z" fill="white" />
            <path d="M396.3 471.2H389.7L386.4 465.5L389.7 459.8H396.3L399.6 465.5L396.3 471.2Z" fill="white" />
            <path d="M396.5 486.6H389.5L386 480.6L389.5 474.6H396.5L400 480.6L396.5 486.6Z" fill="white" />
            <path d="M396.5 501.7H389.5L386 495.7L389.5 489.6H396.5L400 495.7L396.5 501.7Z" fill="white" />
            <path d="M396.5 516.8H389.5L386 510.7L389.5 504.7H396.5L400 510.7L396.5 516.8Z" fill="white" />
            <path d="M396.5 546.9H389.5L386 540.9L389.5 534.8H396.5L400 540.9L396.5 546.9Z" fill="white" />
            <path d="M396.5 562H389.5L386 555.9L389.5 549.9H396.5L400 555.9L396.5 562Z" fill="white" />
            <path d="M383.4 584.6H376.5L373 578.6L376.5 572.5H383.4L386.9 578.6L383.4 584.6Z" fill="white" />
            <path d="M396.5 592.1H389.5L386 586.1L389.5 580.1H396.5L400 586.1L396.5 592.1Z" fill="white" />
            <path d="M409.5 599.7H402.6L399.1 593.6L402.6 587.6H409.5L413 593.6L409.5 599.7Z" fill="white" />
            <path d="M422.6 607.2H415.6L412.1 601.2L415.6 595.1H422.6L426.1 601.2L422.6 607.2Z" fill="white" />
            <path d="M435.599 614.7H428.699L425.199 608.7L428.699 602.7H435.599L439.099 608.7L435.599 614.7Z" fill="white" />
            <path d="M448.699 622.3H441.699L438.199 616.2L441.699 610.2H448.699L452.199 616.2L448.699 622.3Z" fill="white" />
            <path d="M461.701 629.8H454.801L451.301 623.8L454.801 617.8H461.701L465.201 623.8L461.701 629.8Z" fill="white" />
            <path d="M382.799 463H377.099L374.199 458L377.099 453H382.799L385.699 458L382.799 463Z" fill="white" />
            <path d="M383.4 479.1H376.5L373 473.1L376.5 467H383.4L386.9 473.1L383.4 479.1Z" fill="white" />
            <path d="M383.4 494.2H376.5L373 488.1L376.5 482.1H383.4L386.9 488.1L383.4 494.2Z" fill="white" />
            <path d="M383.4 509.2H376.5L373 503.2L376.5 497.2H383.4L386.9 503.2L383.4 509.2Z" fill="white" />
            <path d="M383.4 524.3H376.5L373 518.3L376.5 512.2H383.4L386.9 518.3L383.4 524.3Z" fill="white" />
            <path d="M383.4 539.4H376.5L373 533.3L376.5 527.3H383.4L386.9 533.3L383.4 539.4Z" fill="white" />
            <path d="M383.4 554.4H376.5L373 548.4L376.5 542.4H383.4L386.9 548.4L383.4 554.4Z" fill="white" />
            <path d="M383.4 569.5H376.5L373 563.5L376.5 557.5H383.4L386.9 563.5L383.4 569.5Z" fill="white" />
            <path d="M370.4 592.1H363.4L359.9 586.1L363.4 580.1H370.4L373.9 586.1L370.4 592.1Z" fill="white" />
            <path d="M383.4 599.7H376.5L373 593.6L376.5 587.6H383.4L386.9 593.6L383.4 599.7Z" fill="white" />
            <path d="M396.5 607.2H389.5L386 601.2L389.5 595.1H396.5L400 601.2L396.5 607.2Z" fill="white" />
            <path d="M409.5 614.7H402.6L399.1 608.7L402.6 602.7H409.5L413 608.7L409.5 614.7Z" fill="white" />
            <path d="M422.6 622.3H415.6L412.1 616.2L415.6 610.2H422.6L426.1 616.2L422.6 622.3Z" fill="white" />
            <path d="M435.599 629.8H428.699L425.199 623.8L428.699 617.7H435.599L439.099 623.8L435.599 629.8Z" fill="white" />
            <path d="M448.699 637.3H441.699L438.199 631.3L441.699 625.3H448.699L452.199 631.3L448.699 637.3Z" fill="white" />
            <path d="M368.9 423.8H364.9L362.9 420.3L364.9 416.8H368.9L370.9 420.3L368.9 423.8Z" fill="white" />
            <path d="M367.9 452.1H365.9L365 450.5L365.9 448.8H367.9L368.8 450.5L367.9 452.1Z" fill="white" />
            <path d="M370.4 471.6H363.4L359.9 465.5L363.4 459.5H370.4L373.9 465.5L370.4 471.6Z" fill="white" />
            <path d="M370.4 486.6H363.4L359.9 480.6L363.4 474.6H370.4L373.9 480.6L370.4 486.6Z" fill="white" />
            <path d="M370.4 501.7H363.4L359.9 495.7L363.4 489.6H370.4L373.9 495.7L370.4 501.7Z" fill="white" />
            <path d="M370.4 516.8H363.4L359.9 510.7L363.4 504.7H370.4L373.9 510.7L370.4 516.8Z" fill="white" />
            <path d="M370.4 531.8H363.4L359.9 525.8L363.4 519.8H370.4L373.9 525.8L370.4 531.8Z" fill="white" />
            <path d="M370.4 546.9H363.4L359.9 540.9L363.4 534.8H370.4L373.9 540.9L370.4 546.9Z" fill="white" />
            <path d="M370.4 562H363.4L359.9 555.9L363.4 549.9H370.4L373.9 555.9L370.4 562Z" fill="white" />
            <path d="M370.4 577H363.4L359.9 571L363.4 565H370.4L373.9 571L370.4 577Z" fill="white" />
            <path d="M357.3 599.7H350.4L346.9 593.6L350.4 587.6H357.3L360.8 593.6L357.3 599.7Z" fill="white" />
            <path d="M370.4 607.2H363.4L359.9 601.2L363.4 595.1H370.4L373.9 601.2L370.4 607.2Z" fill="white" />
            <path d="M383.4 614.7H376.5L373 608.7L376.5 602.7H383.4L386.9 608.7L383.4 614.7Z" fill="white" />
            <path d="M396.5 622.3H389.5L386 616.2L389.5 610.2H396.5L400 616.2L396.5 622.3Z" fill="white" />
            <path d="M409.5 629.8H402.6L399.1 623.8L402.6 617.7H409.5L413 623.8L409.5 629.8Z" fill="white" />
            <path d="M422.6 637.3H415.6L412.1 631.3L415.6 625.3H422.6L426.1 631.3L422.6 637.3Z" fill="white" />
            <path d="M435.599 644.9H428.699L425.199 638.8L428.699 632.8H435.599L439.099 638.8L435.599 644.9Z" fill="white" />
            <path d="M448.699 652.4H441.699L438.199 646.4L441.699 640.3H448.699L452.199 646.4L448.699 652.4Z" fill="white" />
            <path d="M354.901 399.5H352.801L351.801 397.7L352.801 395.9H354.901L355.901 397.7L354.901 399.5Z" fill="white" />
            <path d="M356.899 418.1H350.799L347.699 412.8L350.799 407.4H356.899L359.999 412.8L356.899 418.1Z" fill="white" />
            <path d="M357.3 464H350.4L346.9 458L350.4 452H357.3L360.8 458L357.3 464Z" fill="white" />
            <path d="M357.3 479.1H350.4L346.9 473.1L350.4 467H357.3L360.8 473.1L357.3 479.1Z" fill="white" />
            <path d="M357.3 494.2H350.4L346.9 488.1L350.4 482.1H357.3L360.8 488.1L357.3 494.2Z" fill="white" />
            <path d="M357.3 509.2H350.4L346.9 503.2L350.4 497.2H357.3L360.8 503.2L357.3 509.2Z" fill="white" />
            <path d="M357.3 524.3H350.4L346.9 518.3L350.4 512.2H357.3L360.8 518.3L357.3 524.3Z" fill="white" />
            <path d="M357.3 539.4H350.4L346.9 533.3L350.4 527.3H357.3L360.8 533.3L357.3 539.4Z" fill="white" />
            <path d="M357.3 554.4H350.4L346.9 548.4L350.4 542.4H357.3L360.8 548.4L357.3 554.4Z" fill="white" />
            <path d="M357.3 569.5H350.4L346.9 563.5L350.4 557.5H357.3L360.8 563.5L357.3 569.5Z" fill="white" />
            <path d="M357.3 584.6H350.4L346.9 578.6L350.4 572.5H357.3L360.8 578.6L357.3 584.6Z" fill="white" />
            <path d="M356.901 613.9H350.801L347.801 608.7L350.801 603.5H356.901L359.901 608.7L356.901 613.9Z" fill="white" />
            <path d="M370.4 622.3H363.4L359.9 616.2L363.4 610.2H370.4L373.9 616.2L370.4 622.3Z" fill="white" />
            <path d="M383.4 629.8H376.5L373 623.8L376.5 617.7H383.4L386.9 623.8L383.4 629.8Z" fill="white" />
            <path d="M396.5 637.3H389.5L386 631.3L389.5 625.3H396.5L400 631.3L396.5 637.3Z" fill="white" />
            <path d="M409.5 644.9H402.6L399.1 638.8L402.6 632.8H409.5L413 638.8L409.5 644.9Z" fill="white" />
            <path d="M422.6 652.4H415.6L412.1 646.4L415.6 640.3H422.6L426.1 646.4L422.6 652.4Z" fill="white" />
            <path d="M435.599 659.9H428.699L425.199 653.9L428.699 647.9H435.599L439.099 653.9L435.599 659.9Z" fill="white" />
            <path d="M446.699 664H443.699L442.199 661.4L443.699 658.8H446.699L448.199 661.4L446.699 664Z" fill="white" />
            <path d="M342.099 392.4H339.499L338.199 390.2L339.499 387.9H342.099L343.399 390.2L342.099 392.4Z" fill="white" />
            <path d="M341.7 406.9H339.8L338.9 405.2L339.8 403.6H341.7L342.7 405.2L341.7 406.9Z" fill="white" />
            <path d="M342.7 423.7H338.9L336.9 420.3L338.9 417H342.7L344.7 420.3L342.7 423.7Z" fill="white" />
            <path d="M344.099 456.2H337.499L334.199 450.5L337.499 444.7H344.099L347.399 450.5L344.099 456.2Z" fill="white" />
            <path d="M344.301 471.6H337.301L333.801 465.5L337.301 459.5H344.301L347.801 465.5L344.301 471.6Z" fill="white" />
            <path d="M344.301 486.6H337.301L333.801 480.6L337.301 474.6H344.301L347.801 480.6L344.301 486.6Z" fill="white" />
            <path d="M344.301 501.7H337.301L333.801 495.7L337.301 489.6H344.301L347.801 495.7L344.301 501.7Z" fill="white" />
            <path d="M344.301 516.8H337.301L333.801 510.7L337.301 504.7H344.301L347.801 510.7L344.301 516.8Z" fill="white" />
            <path d="M344.301 531.8H337.301L333.801 525.8L337.301 519.8H344.301L347.801 525.8L344.301 531.8Z" fill="white" />
            <path d="M344.301 546.9H337.301L333.801 540.9L337.301 534.8H344.301L347.801 540.9L344.301 546.9Z" fill="white" />
            <path d="M344.301 562H337.301L333.801 555.9L337.301 549.9H344.301L347.801 555.9L344.301 562Z" fill="white" />
            <path d="M344.301 592.1H337.301L333.801 586.1L337.301 580.1H344.301L347.801 586.1L344.301 592.1Z" fill="white" />
            <path d="M356.699 628.7H350.999L348.199 623.8L350.999 618.9H356.699L359.499 623.8L356.699 628.7Z" fill="white" />
            <path d="M370.4 637.3H363.4L359.9 631.3L363.4 625.3H370.4L373.9 631.3L370.4 637.3Z" fill="white" />
            <path d="M383.4 644.9H376.5L373 638.8L376.5 632.8H383.4L386.9 638.8L383.4 644.9Z" fill="white" />
            <path d="M396.5 652.4H389.5L386 646.4L389.5 640.3H396.5L400 646.4L396.5 652.4Z" fill="white" />
            <path d="M409.5 659.9H402.6L399.1 653.9L402.6 647.9H409.5L413 653.9L409.5 659.9Z" fill="white" />
            <path d="M422.6 667.5H415.6L412.1 661.4L415.6 655.4H422.6L426.1 661.4L422.6 667.5Z" fill="white" />
            <path d="M435.601 674.9H428.701L425.301 669L428.701 663H435.601L439.001 669L435.601 674.9Z" fill="white" />
            <path d="M329.001 414.9H326.501L325.301 412.8L326.501 410.6H329.001L330.201 412.8L329.001 414.9Z" fill="white" />
            <path d="M330.499 462.8H324.999L322.199 458L324.999 453.2H330.499L333.299 458L330.499 462.8Z" fill="white" />
            <path d="M331.201 479.1H324.301L320.801 473.1L324.301 467H331.201L334.701 473.1L331.201 479.1Z" fill="white" />
            <path d="M331.201 494.2H324.301L320.801 488.1L324.301 482.1H331.201L334.701 488.1L331.201 494.2Z" fill="white" />
            <path d="M331.201 509.2H324.301L320.801 503.2L324.301 497.2H331.201L334.701 503.2L331.201 509.2Z" fill="white" />
            <path d="M331.201 524.3H324.301L320.801 518.3L324.301 512.2H331.201L334.701 518.3L331.201 524.3Z" fill="white" />
            <path d="M331.201 539.4H324.301L320.801 533.3L324.301 527.3H331.201L334.701 533.3L331.201 539.4Z" fill="white" />
            <path d="M331.201 554.4H324.301L320.801 548.4L324.301 542.4H331.201L334.701 548.4L331.201 554.4Z" fill="white" />
            <path d="M331.2 569.4H324.3L320.9 563.5L324.3 557.5H331.2L334.6 563.5L331.2 569.4Z" fill="white" />
            <path d="M329.8 582.1H325.7L323.6 578.6L325.7 575H329.8L331.8 578.6L329.8 582.1Z" fill="white" />
            <path d="M356.399 643.3H351.199L348.699 638.8L351.199 634.3H356.399L358.999 638.8L356.399 643.3Z" fill="white" />
            <path d="M370.4 652.4H363.4L359.9 646.4L363.4 640.3H370.4L373.9 646.4L370.4 652.4Z" fill="white" />
            <path d="M383.4 659.9H376.5L373 653.9L376.5 647.9H383.4L386.9 653.9L383.4 659.9Z" fill="white" />
            <path d="M396.5 667.5H389.5L386 661.4L389.5 655.4H396.5L400 661.4L396.5 667.5Z" fill="white" />
            <path d="M422.599 682.5H415.599L412.199 676.5L415.599 670.5H422.599L425.999 676.5L422.599 682.5Z" fill="white" />
            <path d="M315.601 406.8H313.801L312.801 405.2L313.801 403.6H315.601L316.501 405.2L315.601 406.8Z" fill="white" />
            <path d="M318 471.3H311.4L308.1 465.5L311.4 459.8H318L321.3 465.5L318 471.3Z" fill="white" />
            <path d="M315.699 482.3H313.699L312.699 480.6L313.699 478.9H315.699L316.699 480.6L315.699 482.3Z" fill="white" />
            <path d="M316.8 499.3H312.6L310.5 495.7L312.6 492H316.8L318.9 495.7L316.8 499.3Z" fill="white" />
            <path d="M318.199 516.8H311.199L307.699 510.7L311.199 504.7H318.199L321.599 510.7L318.199 516.8Z" fill="white" />
            <path d="M318.101 531.8H311.201L307.801 525.8L311.201 519.8H318.101L321.601 525.8L318.101 531.8Z" fill="white" />
            <path d="M316.1 543.2H313.3L312 540.9L313.3 538.5H316.1L317.4 540.9L316.1 543.2Z" fill="white" />
            <path d="M317.7 561.2H311.6L308.6 555.9L311.6 550.7H317.7L320.8 555.9L317.7 561.2Z" fill="white" />
            <path d="M355.8 657.3H351.9L349.9 653.9L351.9 650.5H355.8L357.8 653.9L355.8 657.3Z" fill="white" />
            <path d="M370.4 667.5H363.4L359.9 661.4L363.4 655.4H370.4L373.9 661.4L370.4 667.5Z" fill="white" />
            <path d="M383.4 675H376.5L373 669L376.5 663H383.4L386.9 669L383.4 675Z" fill="white" />
            <path d="M396.5 682.5H389.5L386 676.5L389.5 670.5H396.5L400 676.5L396.5 682.5Z" fill="white" />
            <path d="M409.5 690.1H402.6L399.1 684L402.6 678H409.5L413 684L409.5 690.1Z" fill="white" />
            <path d="M420.9 694.7H417.3L415.5 691.6L417.3 688.5H420.9L422.7 691.6L420.9 694.7Z" fill="white" />
            <path d="M304.7 403H298.6L295.6 397.7L298.6 392.5H304.7L307.7 397.7L304.7 403Z" fill="white" />
            <path d="M302.4 444.3H300.8L300 442.9L300.8 441.5H302.4L303.2 442.9L302.4 444.3Z" fill="white" />
            <path d="M302.7 459.8H300.6L299.5 458L300.6 456.1H302.7L303.8 458L302.7 459.8Z" fill="white" />
            <path d="M303.101 475.5H300.201L298.801 473.1L300.201 470.6H303.101L304.501 473.1L303.101 475.5Z" fill="white" />
            <path d="M356.9 674.3H350.7L347.6 669L350.7 663.6H356.9L360 669L356.9 674.3Z" fill="white" />
            <path d="M370.4 682.5H363.4L359.9 676.5L363.4 670.5H370.4L373.9 676.5L370.4 682.5Z" fill="white" />
            <path d="M383.4 690.1H376.5L373 684L376.5 678H383.4L386.9 684L383.4 690.1Z" fill="white" />
            <path d="M396.5 697.6H389.5L386 691.6L389.5 685.6H396.5L400 691.6L396.5 697.6Z" fill="white" />
            <path d="M409.5 705.1H402.6L399.1 699.1L402.6 693.1H409.5L413 699.1L409.5 705.1Z" fill="white" />
            <path d="M292.1 441.4H285.1L281.6 435.4L285.1 429.4H292.1L295.5 435.4L292.1 441.4Z" fill="white" />
            <path d="M292.1 456.5H285.1L281.6 450.5L285.1 444.4H292.1L295.5 450.5L292.1 456.5Z" fill="white" />
            <path d="M357.3 690.1H350.4L346.9 684L350.4 678H357.3L360.8 684L357.3 690.1Z" fill="white" />
            <path d="M370.4 697.6H363.4L359.9 691.6L363.4 685.6H370.4L373.9 691.6L370.4 697.6Z" fill="white" />
            <path d="M383.4 705.1H376.5L373 699.1L376.5 693.1H383.4L386.9 699.1L383.4 705.1Z" fill="white" />
            <path d="M396.5 712.6H389.5L386.1 706.7L389.5 700.7H396.5L399.9 706.7L396.5 712.6Z" fill="white" />
            <path d="M357.199 704.9H350.499L347.199 699.1L350.499 693.3H357.199L360.499 699.1L357.199 704.9Z" fill="white" />
            <path d="M370.4 712.7H363.4L359.9 706.7L363.4 700.6H370.4L373.9 706.7L370.4 712.7Z" fill="white" />
            <path d="M383.4 720.2H376.5L373 714.2L376.5 708.2H383.4L386.9 714.2L383.4 720.2Z" fill="white" />
            <path d="M394 723.4H392L391 721.7L392 720H394L395 721.7L394 723.4Z" fill="white" />
            <path d="M357 719.7H350.7L347.5 714.2L350.7 708.7H357L360.1 714.2L357 719.7Z" fill="white" />
            <path d="M370.4 727.8H363.4L359.9 721.7L363.4 715.7H370.4L373.9 721.7L370.4 727.8Z" fill="white" />
            <path d="M383.4 735.3H376.5L373 729.3L376.5 723.2H383.4L386.9 729.3L383.4 735.3Z" fill="white" />
            <path d="M357.3 735.3H350.4L346.9 729.3L350.4 723.2H357.3L360.8 729.3L357.3 735.3Z" fill="white" />
            <path d="M383.4 750.3H376.5L373 744.3L376.5 738.3H383.4L386.9 744.3L383.4 750.3Z" fill="white" />
            <path d="M357.3 750.3H350.4L346.9 744.3L350.4 738.3H357.3L360.8 744.3L357.3 750.3Z" fill="white" />
            <path d="M370.4 757.9H363.4L359.9 751.9L363.4 745.8H370.4L373.9 751.9L370.4 757.9Z" fill="white" />
            <path d="M383.4 765.3H376.5L373.1 759.4L376.5 753.5H383.4L386.8 759.4L383.4 765.3Z" fill="white" />
            <path d="M356.901 764.6H350.801L347.801 759.4L350.801 754.2H356.901L359.901 759.4L356.901 764.6Z" fill="white" />
            <path d="M370.4 773H363.4L359.9 766.9L363.4 760.9H370.4L373.9 766.9L370.4 773Z" fill="white" />
            <path d="M381 776.2H378.9L377.9 774.5L378.9 772.7H381L382 774.5L381 776.2Z" fill="white" />
            <path d="M355.7 777.8H351.9L350 774.5L351.9 771.2H355.7L357.6 774.5L355.7 777.8Z" fill="white" />
            <path d="M370.4 788H363.4L359.9 782L363.4 776H370.4L373.9 782L370.4 788Z" fill="white" />
            <path d="M383.2 795.1H376.7L373.5 789.5L376.7 783.9H383.2L386.4 789.5L383.2 795.1Z" fill="white" />
            <path d="M394.5 799.7H391.5L389.9 797.1L391.5 794.4H394.5L396.1 797.1L394.5 799.7Z" fill="white" />
            <path d="M1191.8 673.6H1186.5L1183.8 669L1186.5 664.4H1191.8L1194.5 669L1191.8 673.6Z" fill="white" />
            <path d="M1192.6 659.9H1185.7L1182.2 653.9L1185.7 647.9H1192.6L1196.1 653.9L1192.6 659.9Z" fill="white" />
            <path d="M1192.6 644.9H1185.7L1182.2 638.8L1185.7 632.8H1192.6L1196.1 638.8L1192.6 644.9Z" fill="white" />
            <path d="M1192.6 629.8H1185.7L1182.2 623.8L1185.7 617.7H1192.6L1196.1 623.8L1192.6 629.8Z" fill="white" />
            <path d="M1192.6 614.7H1185.7L1182.2 608.7L1185.7 602.7H1192.6L1196.1 608.7L1192.6 614.7Z" fill="white" />
            <path d="M1192.6 599.7H1185.7L1182.2 593.6L1185.7 587.6H1192.6L1196.1 593.6L1192.6 599.7Z" fill="white" />
            <path d="M1191.7 583H1186.6L1184 578.6L1186.6 574.1H1191.7L1194.3 578.6L1191.7 583Z" fill="white" />
            <path d="M1190.5 460.3H1187.8L1186.5 458L1187.8 455.7H1190.5L1191.8 458L1190.5 460.3Z" fill="white" />
            <path d="M1190.2 444.8H1188.1L1187 442.9L1188.1 441H1190.2L1191.3 442.9L1190.2 444.8Z" fill="white" />
            <path d="M1192.5 328.1H1185.9L1182.5 322.4L1185.9 316.6H1192.5L1195.8 322.4L1192.5 328.1Z" fill="white" />
            <path d="M1191.7 311.7H1186.6L1184 307.3L1186.6 302.8H1191.7L1194.3 307.3L1191.7 311.7Z" fill="white" />
            <path d="M1192.6 298.2H1185.7L1182.2 292.2L1185.7 286.2H1192.6L1196.1 292.2L1192.6 298.2Z" fill="white" />
            <path d="M1192.6 283.2H1185.7L1182.2 277.1L1185.7 271.1H1192.6L1196.1 277.1L1192.6 283.2Z" fill="white" />
            <path d="M1192.6 268.1H1185.7L1182.2 262.1L1185.7 256H1192.6L1196.1 262.1L1192.6 268.1Z" fill="white" />
            <path d="M1192.6 253H1185.7L1182.2 247L1185.7 241H1192.6L1196.1 247L1192.6 253Z" fill="white" />
            <path d="M1192.6 238H1185.7L1182.2 231.9L1185.7 225.9H1192.6L1196.1 231.9L1192.6 238Z" fill="white" />
            <path d="M1192.6 222.9H1185.7L1182.2 216.9L1185.7 210.8H1192.6L1196.1 216.9L1192.6 222.9Z" fill="white" />
            <path d="M1192.6 207.8H1185.7L1182.2 201.8L1185.7 195.8H1192.6L1196.1 201.8L1192.6 207.8Z" fill="white" />
            <path d="M1192.6 192.7H1185.7L1182.2 186.7L1185.7 180.7H1192.6L1196.1 186.7L1192.6 192.7Z" fill="white" />
            <path d="M1192.6 177.7H1185.7L1182.2 171.6L1185.7 165.6H1192.6L1196.1 171.6L1192.6 177.7Z" fill="white" />
            <path d="M1192.6 162.6H1185.7L1182.2 156.6L1185.7 150.5H1192.6L1196.1 156.6L1192.6 162.6Z" fill="white" />
            <path d="M1192.6 147.5H1185.7L1182.2 141.5L1185.7 135.5H1192.6L1196.1 141.5L1192.6 147.5Z" fill="white" />
            <path d="M1192.6 132.5H1185.7L1182.2 126.4L1185.7 120.4H1192.6L1196.1 126.4L1192.6 132.5Z" fill="white" />
            <path d="M1192.6 117.4H1185.7L1182.2 111.4L1185.7 105.3H1192.6L1196.1 111.4L1192.6 117.4Z" fill="white" />
            <path d="M1179.6 109.9H1172.6L1169.1 103.8L1172.6 97.7998H1179.6L1183.1 103.8L1179.6 109.9Z" fill="white" />
            <path d="M1165 99.6H1161.1L1159.2 96.3L1161.1 93H1165L1166.9 96.3L1165 99.6Z" fill="white" />
            <path d="M368.999 16.9998H364.799L362.699 13.3998L364.799 9.7998H368.999L371.099 13.3998L368.999 16.9998Z" fill="white" />
            <path d="M357.3 27.0004H350.4L346.9 20.9004L350.4 14.9004H357.3L360.8 20.9004L357.3 27.0004Z" fill="white" />
            <path d="M343.299 32.7996H338.299L335.699 28.4996L338.299 24.0996H343.299L345.799 28.4996L343.299 32.7996Z" fill="white" />
            <path d="M331.201 42H324.301L320.801 36L324.301 30H331.201L334.701 36L331.201 42Z" fill="white" />
            <path d="M276.601 68.1002H274.401L273.301 66.1002L274.401 64.2002H276.601L277.701 66.1002L276.601 68.1002Z" fill="white" />
            <path d="M265.8 79.4H259.2L255.9 73.7L259.2 68H265.8L269 73.7L265.8 79.4Z" fill="white" />
            <path d="M251.9 85.4H247L244.6 81.2L247 77H251.9L254.3 81.2L251.9 85.4Z" fill="white" />
            <path d="M239.8 94.8002H232.9L229.4 88.8002L232.9 82.7002H239.8L243.3 88.8002L239.8 94.8002Z" fill="white" />
            <path d="M226.8 102.3H219.8L216.4 96.2998L219.8 90.2998H226.8L230.3 96.2998L226.8 102.3Z" fill="white" />
            <path d="M211.1 105.3H209.4L208.5 103.8L209.4 102.3H211.1L212 103.8L211.1 105.3Z" fill="white" />
            <path d="M200.701 132.5H193.701L190.301 126.4L193.701 120.4H200.701L204.201 126.4L200.701 132.5Z" fill="white" />
            <path d="M200.701 147.5H193.701L190.301 141.5L193.701 135.5H200.701L204.201 141.5L200.701 147.5Z" fill="white" />
            <path d="M200.701 162.6H193.701L190.301 156.6L193.701 150.5H200.701L204.201 156.6L200.701 162.6Z" fill="white" />
            <path d="M200.701 177.7H193.701L190.301 171.6L193.701 165.6H200.701L204.201 171.6L200.701 177.7Z" fill="white" />
            <path d="M200.701 192.7H193.701L190.301 186.7L193.701 180.7H200.701L204.201 186.7L200.701 192.7Z" fill="white" />
            <path d="M200.701 207.8H193.701L190.301 201.8L193.701 195.8H200.701L204.201 201.8L200.701 207.8Z" fill="white" />
            <path d="M200.701 222.9H193.701L190.301 216.9L193.701 210.8H200.701L204.201 216.9L200.701 222.9Z" fill="white" />
            <path d="M200.701 238H193.701L190.301 231.9L193.701 225.9H200.701L204.201 231.9L200.701 238Z" fill="white" />
            <path d="M200.701 253H193.701L190.301 247L193.701 241H200.701L204.201 247L200.701 253Z" fill="white" />
            <path d="M200.701 268.1H193.701L190.301 262.1L193.701 256H200.701L204.201 262.1L200.701 268.1Z" fill="white" />
            <path d="M200.701 283.2H193.701L190.301 277.1L193.701 271.1H200.701L204.201 277.1L200.701 283.2Z" fill="white" />
            <path d="M200.701 298.2H193.701L190.301 292.2L193.701 286.2H200.701L204.201 292.2L200.701 298.2Z" fill="white" />
            <path d="M200.701 313.3H193.701L190.301 307.3L193.701 301.3H200.701L204.201 307.3L200.701 313.3Z" fill="white" />
            <path d="M200.701 328.4H193.701L190.301 322.4L193.701 316.3H200.701L204.201 322.4L200.701 328.4Z" fill="white" />
            <path d="M200.701 343.4H193.701L190.301 337.4L193.701 331.4H200.701L204.201 337.4L200.701 343.4Z" fill="white" />
            <path d="M200.701 358.5H193.701L190.301 352.5L193.701 346.5H200.701L204.201 352.5L200.701 358.5Z" fill="white" />
            <path d="M200.701 373.6H193.701L190.301 367.6L193.701 361.5H200.701L204.201 367.6L200.701 373.6Z" fill="white" />
            <path d="M200.499 388.3H193.999L190.699 382.6L193.999 377H200.499L203.699 382.6L200.499 388.3Z" fill="white" />
            <path d="M1205.7 667.5H1198.7L1195.2 661.4L1198.7 655.4H1205.7L1209.2 661.4L1205.7 667.5Z" fill="white" />
            <path d="M1205.7 652.4H1198.7L1195.2 646.4L1198.7 640.3H1205.7L1209.2 646.4L1205.7 652.4Z" fill="white" />
            <path d="M1205.7 637.3H1198.7L1195.2 631.3L1198.7 625.3H1205.7L1209.2 631.3L1205.7 637.3Z" fill="white" />
            <path d="M1205.7 622.3H1198.7L1195.2 616.2L1198.7 610.2H1205.7L1209.2 616.2L1205.7 622.3Z" fill="white" />
            <path d="M1205.7 607.2H1198.7L1195.2 601.2L1198.7 595.1H1205.7L1209.2 601.2L1205.7 607.2Z" fill="white" />
            <path d="M1205.7 592.1H1198.7L1195.2 586.1L1198.7 580.1H1205.7L1209.2 586.1L1205.7 592.1Z" fill="white" />
            <path d="M1203.2 572.8H1201.2L1200.1 571L1201.2 569.2H1203.2L1204.3 571L1203.2 572.8Z" fill="white" />
            <path d="M1205.6 456.3H1198.8L1195.5 450.5L1198.8 444.6H1205.6L1208.9 450.5L1205.6 456.3Z" fill="white" />
            <path d="M1204.8 349.5H1199.6L1197 345L1199.6 340.4H1204.8L1207.4 345L1204.8 349.5Z" fill="white" />
            <path d="M1202.7 330.8H1201.7L1201.1 329.9L1201.7 329H1202.7L1203.3 329.9L1202.7 330.8Z" fill="white" />
            <path d="M1205.5 290.4H1198.9L1195.6 284.7L1198.9 279H1205.5L1208.8 284.7L1205.5 290.4Z" fill="white" />
            <path d="M1205.7 275.6H1198.7L1195.2 269.6L1198.7 263.6H1205.7L1209.2 269.6L1205.7 275.6Z" fill="white" />
            <path d="M1205.7 260.6H1198.7L1195.2 254.5L1198.7 248.5H1205.7L1209.2 254.5L1205.7 260.6Z" fill="white" />
            <path d="M1205.7 245.5H1198.7L1195.2 239.5L1198.7 233.4H1205.7L1209.2 239.5L1205.7 245.5Z" fill="white" />
            <path d="M1205.6 230.3H1198.8L1195.4 224.4L1198.8 218.5H1205.6L1209 224.4L1205.6 230.3Z" fill="white" />
            <path d="M1204.5 213.3H1199.9L1197.6 209.3L1199.9 205.4H1204.5L1206.8 209.3L1204.5 213.3Z" fill="white" />
            <path d="M1205.7 200.3H1198.7L1195.2 194.2L1198.7 188.2H1205.7L1209.2 194.2L1205.7 200.3Z" fill="white" />
            <path d="M1205.7 185.2H1198.7L1195.2 179.2L1198.7 173.1H1205.7L1209.2 179.2L1205.7 185.2Z" fill="white" />
            <path d="M1205.7 170.1H1198.7L1195.2 164.1L1198.7 158.1H1205.7L1209.2 164.1L1205.7 170.1Z" fill="white" />
            <path d="M1205.7 155.1H1198.7L1195.2 149L1198.7 143H1205.7L1209.2 149L1205.7 155.1Z" fill="white" />
            <path d="M1205.7 140H1198.7L1195.2 134L1198.7 127.9H1205.7L1209.2 134L1205.7 140Z" fill="white" />
            <path d="M1205.7 124.9H1198.7L1195.2 118.9L1198.7 112.9H1205.7L1209.2 118.9L1205.7 124.9Z" fill="white" />
            <path d="M1205.7 109.9H1198.7L1195.2 103.8L1198.7 97.7998H1205.7L1209.2 103.8L1205.7 109.9Z" fill="white" />
            <path d="M1192.6 102.3H1185.7L1182.2 96.2998L1185.7 90.2998H1192.6L1196.1 96.2998L1192.6 102.3Z" fill="white" />
            <path d="M1177.8 91.6998H1174.4L1172.7 88.7998L1174.4 85.7998H1177.8L1179.5 88.7998L1177.8 91.6998Z" fill="white" />
            <path d="M341.4 14.4004H340.2L339.6 13.4004L340.2 12.4004H341.4L342 13.4004L341.4 14.4004Z" fill="white" />
            <path d="M328.3 22.0004H327.2L326.6 20.9004L327.2 19.9004H328.3L328.9 20.9004L328.3 22.0004Z" fill="white" />
            <path d="M263.801 60.8998H261.201L259.801 58.5998L261.201 56.2998H263.801L265.101 58.5998L263.801 60.8998Z" fill="white" />
            <path d="M250.299 67.6002H248.599L247.699 66.1002L248.599 64.7002H250.299L251.099 66.1002L250.299 67.6002Z" fill="white" />
            <path d="M237.901 76.2996H234.901L233.301 73.6996L234.901 71.0996H237.901L239.401 73.6996L237.901 76.2996Z" fill="white" />
            <path d="M226.699 87.0004H219.999L216.699 81.2004L219.999 75.4004H226.699L229.999 81.2004L226.699 87.0004Z" fill="white" />
            <path d="M213.801 94.8002H206.801L203.301 88.8002L206.801 82.7002H213.801L217.201 88.8002L213.801 94.8002Z" fill="white" />
            <path d="M200.701 102.3H193.701L190.301 96.2998L193.701 90.2998H200.701L204.201 96.2998L200.701 102.3Z" fill="white" />
            <path d="M186.2 122.4H182.2L180.1 118.9L182.2 115.4H186.2L188.2 118.9L186.2 122.4Z" fill="white" />
            <path d="M187.699 140H180.699L177.199 134L180.699 127.9H187.699L191.099 134L187.699 140Z" fill="white" />
            <path d="M187.699 155.1H180.699L177.199 149L180.699 143H187.699L191.099 149L187.699 155.1Z" fill="white" />
            <path d="M187.699 170.1H180.699L177.199 164.1L180.699 158.1H187.699L191.099 164.1L187.699 170.1Z" fill="white" />
            <path d="M187.699 185.2H180.699L177.199 179.2L180.699 173.1H187.699L191.099 179.2L187.699 185.2Z" fill="white" />
            <path d="M187.699 200.3H180.699L177.199 194.2L180.699 188.2H187.699L191.099 194.2L187.699 200.3Z" fill="white" />
            <path d="M187.699 215.3H180.699L177.199 209.3L180.699 203.3H187.699L191.099 209.3L187.699 215.3Z" fill="white" />
            <path d="M187.699 230.4H180.699L177.199 224.4L180.699 218.4H187.699L191.099 224.4L187.699 230.4Z" fill="white" />
            <path d="M187.699 245.5H180.699L177.199 239.5L180.699 233.4H187.699L191.099 239.5L187.699 245.5Z" fill="white" />
            <path d="M187.699 260.6H180.699L177.199 254.5L180.699 248.5H187.699L191.099 254.5L187.699 260.6Z" fill="white" />
            <path d="M187.699 275.6H180.699L177.199 269.6L180.699 263.6H187.699L191.099 269.6L187.699 275.6Z" fill="white" />
            <path d="M187.699 290.7H180.699L177.199 284.7L180.699 278.6H187.699L191.099 284.7L187.699 290.7Z" fill="white" />
            <path d="M187.699 305.8H180.699L177.199 299.7L180.699 293.7H187.699L191.099 299.7L187.699 305.8Z" fill="white" />
            <path d="M187.699 320.8H180.699L177.199 314.8L180.699 308.8H187.699L191.099 314.8L187.699 320.8Z" fill="white" />
            <path d="M187.699 335.9H180.699L177.199 329.9L180.699 323.9H187.699L191.099 329.9L187.699 335.9Z" fill="white" />
            <path d="M187.699 351H180.699L177.199 345L180.699 338.9H187.699L191.099 345L187.699 351Z" fill="white" />
            <path d="M187.6 365.9H180.8L177.4 360L180.8 354.1H187.6L191 360L187.6 365.9Z" fill="white" />
            <path d="M187.199 380.3H181.199L178.199 375.1L181.199 369.9H187.199L190.099 375.1L187.199 380.3Z" fill="white" />
            <path d="M186.7 394.6H181.6L179 390.2L181.6 385.7H186.7L189.3 390.2L186.7 394.6Z" fill="white" />
            <path d="M1216.6 686.4H1213.9L1212.6 684L1213.9 681.7H1216.6L1217.9 684L1216.6 686.4Z" fill="white" />
            <path d="M1218.7 675H1211.8L1208.3 669L1211.8 663H1218.7L1222.2 669L1218.7 675Z" fill="white" />
            <path d="M1218.7 659.9H1211.8L1208.3 653.9L1211.8 647.9H1218.7L1222.2 653.9L1218.7 659.9Z" fill="white" />
            <path d="M1218.7 644.9H1211.8L1208.3 638.8L1211.8 632.8H1218.7L1222.2 638.8L1218.7 644.9Z" fill="white" />
            <path d="M1218.7 629.8H1211.8L1208.3 623.8L1211.8 617.7H1218.7L1222.2 623.8L1218.7 629.8Z" fill="white" />
            <path d="M1218.7 614.7H1211.8L1208.3 608.7L1211.8 602.7H1218.7L1222.2 608.7L1218.7 614.7Z" fill="white" />
            <path d="M1218.7 599.7H1211.8L1208.3 593.6L1211.8 587.6H1218.7L1222.2 593.6L1218.7 599.7Z" fill="white" />
            <path d="M1218.7 584.6H1211.8L1208.3 578.6L1211.8 572.5H1218.7L1222.2 578.6L1218.7 584.6Z" fill="white" />
            <path d="M1216.9 566.3H1213.6L1212 563.5L1213.6 560.7H1216.9L1218.5 563.5L1216.9 566.3Z" fill="white" />
            <path d="M1217.2 521.6H1213.3L1211.4 518.3L1213.3 514.9H1217.2L1219.1 518.3L1217.2 521.6Z" fill="white" />
            <path d="M1218.6 508.9H1211.9L1208.6 503.2L1211.9 497.5H1218.6L1221.9 503.2L1218.6 508.9Z" fill="white" />
            <path d="M1218.5 343H1212L1208.8 337.4L1212 331.8H1218.5L1221.7 337.4L1218.5 343Z" fill="white" />
            <path d="M1215.9 278.3H1214.6L1213.9 277.1L1214.6 276H1215.9L1216.6 277.1L1215.9 278.3Z" fill="white" />
            <path d="M1218.7 268H1211.8L1208.4 262.1L1211.8 256.1H1218.7L1222.2 262.1L1218.7 268Z" fill="white" />
            <path d="M1218.7 253H1211.8L1208.3 247L1211.8 241H1218.7L1222.2 247L1218.7 253Z" fill="white" />
            <path d="M1218.7 237.9H1211.8L1208.4 231.9L1211.8 226H1218.7L1222.1 231.9L1218.7 237.9Z" fill="white" />
            <path d="M1216.7 219.4H1213.8L1212.3 216.9L1213.8 214.3H1216.7L1218.2 216.9L1216.7 219.4Z" fill="white" />
            <path d="M1218.6 192.5H1211.9L1208.5 186.7L1211.9 180.9H1218.6L1222 186.7L1218.6 192.5Z" fill="white" />
            <path d="M1218.7 177.7H1211.8L1208.3 171.6L1211.8 165.6H1218.7L1222.2 171.6L1218.7 177.7Z" fill="white" />
            <path d="M1218.7 162.6H1211.8L1208.3 156.6L1211.8 150.5H1218.7L1222.2 156.6L1218.7 162.6Z" fill="white" />
            <path d="M1218.7 147.5H1211.8L1208.3 141.5L1211.8 135.5H1218.7L1222.2 141.5L1218.7 147.5Z" fill="white" />
            <path d="M1218.7 132.5H1211.8L1208.3 126.4L1211.8 120.4H1218.7L1222.2 126.4L1218.7 132.5Z" fill="white" />
            <path d="M1218.7 117.4H1211.8L1208.3 111.4L1211.8 105.3H1218.7L1222.2 111.4L1218.7 117.4Z" fill="white" />
            <path d="M1179.2 78.9998H1173L1169.9 73.6998L1173 68.2998H1179.2L1182.3 73.6998L1179.2 78.9998Z" fill="white" />
            <path d="M1165.8 70.9004H1160.3L1157.5 66.1004L1160.3 61.4004H1165.8L1168.6 66.1004L1165.8 70.9004Z" fill="white" />
            <path d="M211.501 75.8H209.001L207.801 73.7L209.001 71.5H211.501L212.801 73.7L211.501 75.8Z" fill="white" />
            <path d="M198.999 84.2002H195.499L193.699 81.2002L195.499 78.2002H198.999L200.699 81.2002L198.999 84.2002Z" fill="white" />
            <path d="M174.599 132.5H167.599L164.199 126.4L167.599 120.4H174.599L178.099 126.4L174.599 132.5Z" fill="white" />
            <path d="M174.599 147.5H167.599L164.199 141.5L167.599 135.5H174.599L178.099 141.5L174.599 147.5Z" fill="white" />
            <path d="M174.599 162.6H167.599L164.199 156.6L167.599 150.5H174.599L178.099 156.6L174.599 162.6Z" fill="white" />
            <path d="M174.599 177.7H167.599L164.199 171.6L167.599 165.6H174.599L178.099 171.6L174.599 177.7Z" fill="white" />
            <path d="M174.599 192.7H167.599L164.199 186.7L167.599 180.7H174.599L178.099 186.7L174.599 192.7Z" fill="white" />
            <path d="M174.599 207.8H167.599L164.199 201.8L167.599 195.8H174.599L178.099 201.8L174.599 207.8Z" fill="white" />
            <path d="M174.599 222.9H167.599L164.199 216.9L167.599 210.8H174.599L178.099 216.9L174.599 222.9Z" fill="white" />
            <path d="M174.599 238H167.599L164.199 231.9L167.599 225.9H174.599L178.099 231.9L174.599 238Z" fill="white" />
            <path d="M174.599 253H167.599L164.199 247L167.599 241H174.599L178.099 247L174.599 253Z" fill="white" />
            <path d="M174.599 268.1H167.599L164.199 262.1L167.599 256H174.599L178.099 262.1L174.599 268.1Z" fill="white" />
            <path d="M174.599 283.2H167.599L164.199 277.1L167.599 271.1H174.599L178.099 277.1L174.599 283.2Z" fill="white" />
            <path d="M174.599 298.2H167.599L164.199 292.2L167.599 286.2H174.599L178.099 292.2L174.599 298.2Z" fill="white" />
            <path d="M174.599 313.3H167.599L164.199 307.3L167.599 301.3H174.599L178.099 307.3L174.599 313.3Z" fill="white" />
            <path d="M174.599 328.4H167.599L164.199 322.4L167.599 316.3H174.599L178.099 322.4L174.599 328.4Z" fill="white" />
            <path d="M174.599 343.4H167.599L164.199 337.4L167.599 331.4H174.599L178.099 337.4L174.599 343.4Z" fill="white" />
            <path d="M174.599 358.5H167.599L164.199 352.5L167.599 346.5H174.599L178.099 352.5L174.599 358.5Z" fill="white" />
            <path d="M174.4 373.2H167.9L164.6 367.6L167.9 361.9H174.4L177.6 367.6L174.4 373.2Z" fill="white" />
            <path d="M1229.3 693.3H1227.3L1226.4 691.6L1227.3 689.9H1229.3L1230.2 691.6L1229.3 693.3Z" fill="white" />
            <path d="M1231.8 682.5H1224.8L1221.3 676.5L1224.8 670.5H1231.8L1235.3 676.5L1231.8 682.5Z" fill="white" />
            <path d="M1231.8 667.5H1224.8L1221.3 661.4L1224.8 655.4H1231.8L1235.3 661.4L1231.8 667.5Z" fill="white" />
            <path d="M1231.8 652.4H1224.8L1221.3 646.4L1224.8 640.3H1231.8L1235.3 646.4L1231.8 652.4Z" fill="white" />
            <path d="M1231.8 637.3H1224.8L1221.3 631.3L1224.8 625.3H1231.8L1235.3 631.3L1231.8 637.3Z" fill="white" />
            <path d="M1231.8 622.3H1224.8L1221.3 616.2L1224.8 610.2H1231.8L1235.3 616.2L1231.8 622.3Z" fill="white" />
            <path d="M1231.8 607.2H1224.8L1221.3 601.2L1224.8 595.1H1231.8L1235.3 601.2L1231.8 607.2Z" fill="white" />
            <path d="M1231.8 592.1H1224.8L1221.3 586.1L1224.8 580.1H1231.8L1235.3 586.1L1231.8 592.1Z" fill="white" />
            <path d="M1231.8 577H1224.8L1221.3 571L1224.8 565H1231.8L1235.3 571L1231.8 577Z" fill="white" />
            <path d="M1231.8 335.9H1224.8L1221.3 329.9L1224.8 323.9H1231.8L1235.3 329.9L1231.8 335.9Z" fill="white" />
            <path d="M1230.1 287.8H1226.5L1224.7 284.7L1226.5 281.5H1230.1L1231.9 284.7L1230.1 287.8Z" fill="white" />
            <path d="M1229.4 256.4H1227.2L1226.1 254.5L1227.2 252.6H1229.4L1230.5 254.5L1229.4 256.4Z" fill="white" />
            <path d="M1230.5 243.3H1226.1L1223.8 239.5L1226.1 235.6H1230.5L1232.8 239.5L1230.5 243.3Z" fill="white" />
            <path d="M1230.9 229H1225.7L1223 224.4L1225.7 219.8H1230.9L1233.6 224.4L1230.9 229Z" fill="white" />
            <path d="M1231.8 185.2H1224.8L1221.3 179.2L1224.8 173.1H1231.8L1235.3 179.2L1231.8 185.2Z" fill="white" />
            <path d="M1231.8 170.1H1224.8L1221.3 164.1L1224.8 158.1H1231.8L1235.3 164.1L1231.8 170.1Z" fill="white" />
            <path d="M1231.8 155.1H1224.8L1221.3 149L1224.8 143H1231.8L1235.3 149L1231.8 155.1Z" fill="white" />
            <path d="M1231.8 140H1224.8L1221.3 134L1224.8 127.9H1231.8L1235.3 134L1231.8 140Z" fill="white" />
            <path d="M1231.8 124.9H1224.8L1221.3 118.9L1224.8 112.9H1231.8L1235.3 118.9L1231.8 124.9Z" fill="white" />
            <path d="M1229.2 105.4H1227.4L1226.5 103.8L1227.4 102.3H1229.2L1230.1 103.8L1229.2 105.4Z" fill="white" />
            <path d="M160.3 122.8H155.8L153.6 118.9L155.8 115H160.3L162.6 118.9L160.3 122.8Z" fill="white" />
            <path d="M161.5 140H154.6L151.1 134L154.6 127.9H161.5L165 134L161.5 140Z" fill="white" />
            <path d="M161.5 155.1H154.6L151.1 149L154.6 143H161.5L165 149L161.5 155.1Z" fill="white" />
            <path d="M161.5 170.1H154.6L151.1 164.1L154.6 158.1H161.5L165 164.1L161.5 170.1Z" fill="white" />
            <path d="M161.5 185.2H154.6L151.1 179.2L154.6 173.1H161.5L165 179.2L161.5 185.2Z" fill="white" />
            <path d="M161.5 200.3H154.6L151.1 194.2L154.6 188.2H161.5L165 194.2L161.5 200.3Z" fill="white" />
            <path d="M161.5 215.3H154.6L151.1 209.3L154.6 203.3H161.5L165 209.3L161.5 215.3Z" fill="white" />
            <path d="M161.5 230.4H154.6L151.1 224.4L154.6 218.4H161.5L165 224.4L161.5 230.4Z" fill="white" />
            <path d="M161.5 245.5H154.6L151.1 239.5L154.6 233.4H161.5L165 239.5L161.5 245.5Z" fill="white" />
            <path d="M161.5 260.6H154.6L151.1 254.5L154.6 248.5H161.5L165 254.5L161.5 260.6Z" fill="white" />
            <path d="M161.5 275.6H154.6L151.1 269.6L154.6 263.6H161.5L165 269.6L161.5 275.6Z" fill="white" />
            <path d="M161.5 290.7H154.6L151.1 284.7L154.6 278.6H161.5L165 284.7L161.5 290.7Z" fill="white" />
            <path d="M161.5 305.8H154.6L151.1 299.7L154.6 293.7H161.5L165 299.7L161.5 305.8Z" fill="white" />
            <path d="M161.5 320.8H154.6L151.1 314.8L154.6 308.8H161.5L165 314.8L161.5 320.8Z" fill="white" />
            <path d="M161.5 335.9H154.6L151.1 329.9L154.6 323.9H161.5L165 329.9L161.5 335.9Z" fill="white" />
            <path d="M159.1 346.8H157L155.9 345L157 343.1H159.1L160.2 345L159.1 346.8Z" fill="white" />
            <path d="M1243.5 718H1239.2L1237 714.2L1239.2 710.4H1243.5L1245.7 714.2L1243.5 718Z" fill="white" />
            <path d="M1244.8 705.1H1237.9L1234.4 699.1L1237.9 693.1H1244.8L1248.3 699.1L1244.8 705.1Z" fill="white" />
            <path d="M1244.8 690.1H1237.9L1234.4 684L1237.9 678H1244.8L1248.3 684L1244.8 690.1Z" fill="white" />
            <path d="M1244.8 675H1237.9L1234.4 669L1237.9 663H1244.8L1248.3 669L1244.8 675Z" fill="white" />
            <path d="M1244.8 659.9H1237.9L1234.4 653.9L1237.9 647.9H1244.8L1248.3 653.9L1244.8 659.9Z" fill="white" />
            <path d="M1244.8 644.9H1237.9L1234.4 638.8L1237.9 632.8H1244.8L1248.3 638.8L1244.8 644.9Z" fill="white" />
            <path d="M1244.8 629.8H1237.9L1234.4 623.8L1237.9 617.7H1244.8L1248.3 623.8L1244.8 629.8Z" fill="white" />
            <path d="M1244.8 614.7H1237.9L1234.4 608.7L1237.9 602.7H1244.8L1248.3 608.7L1244.8 614.7Z" fill="white" />
            <path d="M1244.8 599.7H1237.9L1234.4 593.6L1237.9 587.6H1244.8L1248.3 593.6L1244.8 599.7Z" fill="white" />
            <path d="M1242.2 534.8H1240.5L1239.7 533.3L1240.5 531.9H1242.2L1243 533.3L1242.2 534.8Z" fill="white" />
            <path d="M1244.8 524.3H1237.9L1234.4 518.3L1237.9 512.2H1244.8L1248.3 518.3L1244.8 524.3Z" fill="white" />
            <path d="M1244.4 327.7H1238.3L1235.2 322.4L1238.3 317H1244.4L1247.5 322.4L1244.4 327.7Z" fill="white" />
            <path d="M1243.4 310.8H1239.3L1237.3 307.3L1239.3 303.8H1243.4L1245.4 307.3L1243.4 310.8Z" fill="white" />
            <path d="M1242.6 294.3H1240.2L1239 292.2L1240.2 290.1H1242.6L1243.8 292.2L1242.6 294.3Z" fill="white" />
            <path d="M1244.5 282.6H1238.2L1235.1 277.1L1238.2 271.7H1244.5L1247.6 277.1L1244.5 282.6Z" fill="white" />
            <path d="M1242.8 249.5H1239.9L1238.5 247L1239.9 244.5H1242.8L1244.2 247L1242.8 249.5Z" fill="white" />
            <path d="M1244.7 192.5H1238L1234.6 186.7L1238 180.9H1244.7L1248.1 186.7L1244.7 192.5Z" fill="white" />
            <path d="M1244.8 177.7H1237.9L1234.4 171.6L1237.9 165.6H1244.8L1248.3 171.6L1244.8 177.7Z" fill="white" />
            <path d="M1244.8 162.6H1237.9L1234.4 156.6L1237.9 150.5H1244.8L1248.3 156.6L1244.8 162.6Z" fill="white" />
            <path d="M1244.8 147.5H1237.9L1234.4 141.5L1237.9 135.5H1244.8L1248.3 141.5L1244.8 147.5Z" fill="white" />
            <path d="M1244.8 132.5H1237.9L1234.4 126.4L1237.9 120.4H1244.8L1248.3 126.4L1244.8 132.5Z" fill="white" />
            <path d="M1244.8 117.4H1237.9L1234.4 111.4L1237.9 105.3H1244.8L1248.3 111.4L1244.8 117.4Z" fill="white" />
            <path d="M148.5 132.5H141.5L138.1 126.4L141.5 120.4H148.5L152 126.4L148.5 132.5Z" fill="white" />
            <path d="M148.5 147.5H141.5L138.1 141.5L141.5 135.5H148.5L152 141.5L148.5 147.5Z" fill="white" />
            <path d="M148.5 162.6H141.5L138.1 156.6L141.5 150.5H148.5L152 156.6L148.5 162.6Z" fill="white" />
            <path d="M148.5 177.7H141.5L138.1 171.6L141.5 165.6H148.5L152 171.6L148.5 177.7Z" fill="white" />
            <path d="M148.5 192.7H141.5L138.1 186.7L141.5 180.7H148.5L152 186.7L148.5 192.7Z" fill="white" />
            <path d="M148.5 207.8H141.5L138.1 201.8L141.5 195.8H148.5L152 201.8L148.5 207.8Z" fill="white" />
            <path d="M148.3 222.6H141.7L138.4 216.9L141.7 211.1H148.3L151.6 216.9L148.3 222.6Z" fill="white" />
            <path d="M147.6 236.3H142.5L139.9 231.9L142.5 227.5H147.6L150.1 231.9L147.6 236.3Z" fill="white" />
            <path d="M146.3 249.2H143.8L142.5 247L143.8 244.8H146.3L147.5 247L146.3 249.2Z" fill="white" />
            <path d="M145.8 263.4H144.3L143.5 262.1L144.3 260.8H145.8L146.5 262.1L145.8 263.4Z" fill="white" />
            <path d="M146.099 279.1H143.899L142.699 277.1L143.899 275.2H146.099L147.299 277.1L146.099 279.1Z" fill="white" />
            <path d="M146.3 294.4H143.8L142.5 292.2L143.8 290H146.3L147.5 292.2L146.3 294.4Z" fill="white" />
            <path d="M147.5 311.7H142.5L140 307.3L142.5 302.9H147.5L150.1 307.3L147.5 311.7Z" fill="white" />
            <path d="M146.3 324.5H143.8L142.5 322.4L143.8 320.2H146.3L147.5 322.4L146.3 324.5Z" fill="white" />
            <path d="M1257.9 727.7H1250.9L1247.5 721.7L1250.9 715.7H1257.9L1261.3 721.7L1257.9 727.7Z" fill="white" />
            <path d="M1256.1 709.6H1252.7L1251 706.7L1252.7 703.7H1256.1L1257.8 706.7L1256.1 709.6Z" fill="white" />
            <path d="M1257.9 697.6H1250.9L1247.5 691.6L1250.9 685.6H1257.9L1261.4 691.6L1257.9 697.6Z" fill="white" />
            <path d="M1257.9 682.5H1250.9L1247.5 676.5L1250.9 670.5H1257.9L1261.4 676.5L1257.9 682.5Z" fill="white" />
            <path d="M1257.9 667.5H1250.9L1247.5 661.4L1250.9 655.4H1257.9L1261.4 661.4L1257.9 667.5Z" fill="white" />
            <path d="M1257.9 652.4H1250.9L1247.5 646.4L1250.9 640.3H1257.9L1261.4 646.4L1257.9 652.4Z" fill="white" />
            <path d="M1257.9 637.3H1250.9L1247.5 631.3L1250.9 625.3H1257.9L1261.4 631.3L1257.9 637.3Z" fill="white" />
            <path d="M1257.9 622.3H1250.9L1247.5 616.2L1250.9 610.2H1257.9L1261.4 616.2L1257.9 622.3Z" fill="white" />
            <path d="M1257.9 607.2H1250.9L1247.5 601.2L1250.9 595.1H1257.9L1261.4 601.2L1257.9 607.2Z" fill="white" />
            <path d="M1255.8 588.5H1253L1251.7 586.1L1253 583.7H1255.8L1257.2 586.1L1255.8 588.5Z" fill="white" />
            <path d="M1257.8 546.7H1251L1247.7 540.9L1251 535H1257.8L1261.2 540.9L1257.8 546.7Z" fill="white" />
            <path d="M1257.9 531.8H1250.9L1247.5 525.8L1250.9 519.8H1257.9L1261.4 525.8L1257.9 531.8Z" fill="white" />
            <path d="M1255.2 512.2H1253.6L1252.7 510.7L1253.6 509.3H1255.2L1256.1 510.7L1255.2 512.2Z" fill="white" />
            <path d="M1256.6 198.1H1252.2L1250 194.2L1252.2 190.4H1256.6L1258.8 194.2L1256.6 198.1Z" fill="white" />
            <path d="M1257.9 185.2H1250.9L1247.5 179.2L1250.9 173.1H1257.9L1261.4 179.2L1257.9 185.2Z" fill="white" />
            <path d="M1257.9 170.1H1250.9L1247.5 164.1L1250.9 158.1H1257.9L1261.4 164.1L1257.9 170.1Z" fill="white" />
            <path d="M1257.9 155.1H1250.9L1247.5 149L1250.9 143H1257.9L1261.4 149L1257.9 155.1Z" fill="white" />
            <path d="M1257.9 140H1250.9L1247.5 134L1250.9 127.9H1257.9L1261.4 134L1257.9 140Z" fill="white" />
            <path d="M1257.8 124.8H1251L1247.6 118.9L1251 113H1257.8L1261.2 118.9L1257.8 124.8Z" fill="white" />
            <path d="M134.5 123.2H129.5L127 118.9L129.5 114.6H134.5L137 118.9L134.5 123.2Z" fill="white" />
            <path d="M135.4 140H128.5L125 134L128.5 127.9H135.4L138.9 134L135.4 140Z" fill="white" />
            <path d="M135.4 155.1H128.5L125 149L128.5 143H135.4L138.9 149L135.4 155.1Z" fill="white" />
            <path d="M135.4 170.1H128.5L125 164.1L128.5 158.1H135.4L138.9 164.1L135.4 170.1Z" fill="white" />
            <path d="M135.4 185.2H128.5L125 179.2L128.5 173.1H135.4L138.9 179.2L135.4 185.2Z" fill="white" />
            <path d="M135.4 200.3H128.5L125 194.2L128.5 188.2H135.4L138.9 194.2L135.4 200.3Z" fill="white" />
            <path d="M134.7 214H129.2L126.5 209.3L129.2 204.6H134.7L137.4 209.3L134.7 214Z" fill="white" />
            <path d="M1268.3 715.6H1266.7L1265.9 714.2L1266.7 712.8H1268.3L1269.1 714.2L1268.3 715.6Z" fill="white" />
            <path d="M1270.8 704.8H1264.2L1260.9 699.1L1264.2 693.4H1270.8L1274 699.1L1270.8 704.8Z" fill="white" />
            <path d="M1270.9 690.1H1264L1260.5 684L1264 678H1270.9L1274.4 684L1270.9 690.1Z" fill="white" />
            <path d="M1270.9 675H1264L1260.5 669L1264 663H1270.9L1274.4 669L1270.9 675Z" fill="white" />
            <path d="M1270.9 659.9H1264L1260.5 653.9L1264 647.9H1270.9L1274.4 653.9L1270.9 659.9Z" fill="white" />
            <path d="M1270.9 644.9H1264L1260.5 638.8L1264 632.8H1270.9L1274.4 638.8L1270.9 644.9Z" fill="white" />
            <path d="M1270.9 629.8H1264L1260.5 623.8L1264 617.7H1270.9L1274.4 623.8L1270.9 629.8Z" fill="white" />
            <path d="M1270.9 614.7H1264L1260.5 608.7L1264 602.7H1270.9L1274.4 608.7L1270.9 614.7Z" fill="white" />
            <path d="M1270.9 599.7H1264L1260.5 593.6L1264 587.6H1270.9L1274.4 593.6L1270.9 599.7Z" fill="white" />
            <path d="M1270.6 583.9H1264.4L1261.2 578.6L1264.4 573.2H1270.6L1273.7 578.6L1270.6 583.9Z" fill="white" />
            <path d="M1268.6 565.4H1266.3L1265.2 563.5L1266.3 561.5H1268.6L1269.7 563.5L1268.6 565.4Z" fill="white" />
            <path d="M1270.7 554H1264.3L1261 548.4L1264.3 542.9H1270.7L1273.9 548.4L1270.7 554Z" fill="white" />
            <path d="M1270.9 539.4H1264L1260.5 533.3L1264 527.3H1270.9L1274.4 533.3L1270.9 539.4Z" fill="white" />
            <path d="M1270.9 177.6H1264L1260.5 171.6L1264 165.6H1270.9L1274.4 171.6L1270.9 177.6Z" fill="white" />
            <path d="M1270.9 162.6H1264L1260.5 156.6L1264 150.5H1270.9L1274.4 156.6L1270.9 162.6Z" fill="white" />
            <path d="M1270.9 147.5H1264L1260.5 141.5L1264 135.5H1270.9L1274.4 141.5L1270.9 147.5Z" fill="white" />
            <path d="M1270.9 132.5H1264L1260.5 126.4L1264 120.4H1270.9L1274.4 126.4L1270.9 132.5Z" fill="white" />
            <path d="M119.6 112.5H118.3L117.6 111.4L118.3 110.2H119.6L120.2 111.4L119.6 112.5Z" fill="white" />
            <path d="M122.4 132.5H115.4L112 126.4L115.4 120.4H122.4L125.9 126.4L122.4 132.5Z" fill="white" />
            <path d="M122.4 147.5H115.4L112 141.5L115.4 135.5H122.4L125.9 141.5L122.4 147.5Z" fill="white" />
            <path d="M122.4 162.6H115.4L112 156.6L115.4 150.5H122.4L125.9 156.6L122.4 162.6Z" fill="white" />
            <path d="M122.4 177.7H115.4L112 171.6L115.4 165.6H122.4L125.9 171.6L122.4 177.7Z" fill="white" />
            <path d="M122.4 192.7H115.4L112 186.7L115.4 180.7H122.4L125.9 186.7L122.4 192.7Z" fill="white" />
            <path d="M121.4 206.1H116.4L113.9 201.8L116.4 197.5H121.4L123.9 201.8L121.4 206.1Z" fill="white" />
            <path d="M1282.6 695.2H1278.4L1276.3 691.6L1278.4 688H1282.6L1284.7 691.6L1282.6 695.2Z" fill="white" />
            <path d="M1284 682.5H1277L1273.6 676.5L1277 670.5H1284L1287.5 676.5L1284 682.5Z" fill="white" />
            <path d="M1284 667.5H1277L1273.6 661.4L1277 655.4H1284L1287.5 661.4L1284 667.5Z" fill="white" />
            <path d="M1284 652.4H1277L1273.6 646.4L1277 640.3H1284L1287.5 646.4L1284 652.4Z" fill="white" />
            <path d="M1284 637.3H1277L1273.6 631.3L1277 625.3H1284L1287.5 631.3L1284 637.3Z" fill="white" />
            <path d="M1284 622.3H1277L1273.6 616.2L1277 610.2H1284L1287.5 616.2L1284 622.3Z" fill="white" />
            <path d="M1283.5 606.4H1277.5L1274.5 601.2L1277.5 595.9H1283.5L1286.5 601.2L1283.5 606.4Z" fill="white" />
            <path d="M1283.9 546.7H1277.1L1273.7 540.9L1277.1 535H1283.9L1287.3 540.9L1283.9 546.7Z" fill="white" />
            <path d="M1281.2 240.6H1279.9L1279.2 239.5L1279.9 238.3H1281.2L1281.8 239.5L1281.2 240.6Z" fill="white" />
            <path d="M1283.7 230H1277.3L1274 224.4L1277.3 218.8H1283.7L1287 224.4L1283.7 230Z" fill="white" />
            <path d="M1284 215.3H1277L1273.6 209.3L1277 203.3H1284L1287.5 209.3L1284 215.3Z" fill="white" />
            <path d="M1283.9 200.1H1277.1L1273.8 194.2L1277.1 188.4H1283.9L1287.3 194.2L1283.9 200.1Z" fill="white" />
            <path d="M1282.2 182.1H1278.8L1277.2 179.2L1278.8 176.3H1282.2L1283.9 179.2L1282.2 182.1Z" fill="white" />
            <path d="M1284 170.1H1277L1273.6 164.1L1277 158.1H1284L1287.5 164.1L1284 170.1Z" fill="white" />
            <path d="M1284 155.1H1277L1273.6 149L1277 143H1284L1287.5 149L1284 155.1Z" fill="white" />
            <path d="M1284 140H1277L1273.6 134L1277 127.9H1284L1287.5 134L1284 140Z" fill="white" />
            <path d="M1283.9 124.7H1277.2L1273.8 118.9L1277.2 113.1H1283.9L1287.2 118.9L1283.9 124.7Z" fill="white" />
            <path d="M109.3 124.9H102.4L98.9004 118.9L102.4 112.9H109.3L112.8 118.9L109.3 124.9Z" fill="white" />
            <path d="M109.3 140H102.4L98.9004 134L102.4 127.9H109.3L112.8 134L109.3 140Z" fill="white" />
            <path d="M109.3 155.1H102.4L98.9004 149L102.4 143H109.3L112.8 149L109.3 155.1Z" fill="white" />
            <path d="M109.3 170.1H102.4L98.9004 164.1L102.4 158.1H109.3L112.8 164.1L109.3 170.1Z" fill="white" />
            <path d="M109.3 185.2H102.4L98.9004 179.2L102.4 173.1H109.3L112.8 179.2L109.3 185.2Z" fill="white" />
            <path d="M106.8 195.8H105L104.1 194.2L105 192.7H106.8L107.7 194.2L106.8 195.8Z" fill="white" />
            <path d="M1296.2 673.6H1290.9L1288.3 669L1290.9 664.4H1296.2L1298.9 669L1296.2 673.6Z" fill="white" />
            <path d="M1297 659.9H1290.1L1286.6 653.9L1290.1 647.9H1297L1300.5 653.9L1297 659.9Z" fill="white" />
            <path d="M1297 644.7H1290.2L1286.7 638.8L1290.2 632.9H1297L1300.4 638.8L1297 644.7Z" fill="white" />
            <path d="M1295.9 627.7H1291.3L1289 623.8L1291.3 619.8H1295.9L1298.2 623.8L1295.9 627.7Z" fill="white" />
            <path d="M1294.9 550.7H1292.2L1290.9 548.4L1292.2 546.1H1294.9L1296.2 548.4L1294.9 550.7Z" fill="white" />
            <path d="M1296.9 222.6H1290.3L1287 216.9L1290.3 211.1H1296.9L1300.2 216.9L1296.9 222.6Z" fill="white" />
            <path d="M1297 207.7H1290.2L1286.8 201.8L1290.2 195.9H1297L1300.4 201.8L1297 207.7Z" fill="white" />
            <path d="M1297 192.7H1290.1L1286.7 186.7L1290.1 180.8H1297L1300.4 186.7L1297 192.7Z" fill="white" />
            <path d="M1297 177.7H1290.1L1286.6 171.6L1290.1 165.6H1297L1300.5 171.6L1297 177.7Z" fill="white" />
            <path d="M1297 162.6H1290.1L1286.6 156.6L1290.1 150.5H1297L1300.5 156.6L1297 162.6Z" fill="white" />
            <path d="M1297 147.5H1290.1L1286.6 141.5L1290.1 135.5H1297L1300.5 141.5L1297 147.5Z" fill="white" />
            <path d="M1297 132.5H1290.1L1286.6 126.4L1290.1 120.4H1297L1300.5 126.4L1297 132.5Z" fill="white" />
            <path d="M1295.1 114H1292L1290.5 111.4L1292 108.7H1295.1L1296.6 111.4L1295.1 114Z" fill="white" />
            <path d="M94.5992 114.5H90.9992L89.1992 111.4L90.9992 108.2H94.5992L96.4992 111.4L94.5992 114.5Z" fill="white" />
            <path d="M96.3008 132.5H89.3008L85.8008 126.4L89.3008 120.4H96.3008L99.8008 126.4L96.3008 132.5Z" fill="white" />
            <path d="M96.3008 147.5H89.3008L85.8008 141.5L89.3008 135.5H96.3008L99.8008 141.5L96.3008 147.5Z" fill="white" />
            <path d="M96.3008 162.6H89.3008L85.8008 156.6L89.3008 150.5H96.3008L99.8008 156.6L96.3008 162.6Z" fill="white" />
            <path d="M96.3008 177.7H89.3008L85.8008 171.6L89.3008 165.6H96.3008L99.8008 171.6L96.3008 177.7Z" fill="white" />
            <path d="M96.3008 192.7H89.3008L85.8008 186.7L89.3008 180.7H96.3008L99.8008 186.7L96.3008 192.7Z" fill="white" />
            <path d="M1307.9 618.5H1305.3L1304.1 616.2L1305.3 614H1307.9L1309.2 616.2L1307.9 618.5Z" fill="white" />
            <path d="M1307.5 602.7H1305.7L1304.8 601.2L1305.7 599.6H1307.5L1308.4 601.2L1307.5 602.7Z" fill="white" />
            <path d="M1310.1 185.2H1303.2L1299.7 179.2L1303.2 173.2H1310.1L1313.6 179.2L1310.1 185.2Z" fill="white" />
            <path d="M1310.1 170.1H1303.1L1299.7 164.1L1303.1 158.1H1310.1L1313.6 164.1L1310.1 170.1Z" fill="white" />
            <path d="M1310.1 155.1H1303.1L1299.7 149L1303.1 143H1310.1L1313.6 149L1310.1 155.1Z" fill="white" />
            <path d="M1310.1 140H1303.1L1299.7 134L1303.1 127.9H1310.1L1313.6 134L1310.1 140Z" fill="white" />
            <path d="M1310.1 124.9H1303.2L1299.7 118.9L1303.2 112.9H1310.1L1313.6 118.9L1310.1 124.9Z" fill="white" />
            <path d="M80.5996 105.2H78.9996L78.0996 103.8L78.9996 102.4H80.5996L81.3996 103.8L80.5996 105.2Z" fill="white" />
            <path d="M83.2008 124.9H76.3008L72.8008 118.9L76.3008 112.9H83.2008L86.7008 118.9L83.2008 124.9Z" fill="white" />
            <path d="M83.2008 140H76.3008L72.8008 134L76.3008 127.9H83.2008L86.7008 134L83.2008 140Z" fill="white" />
            <path d="M83.2008 155.1H76.3008L72.8008 149L76.3008 143H83.2008L86.7008 149L83.2008 155.1Z" fill="white" />
            <path d="M83.2008 170.1H76.3008L72.8008 164.1L76.3008 158.1H83.2008L86.7008 164.1L83.2008 170.1Z" fill="white" />
            <path d="M83.2008 185.2H76.3008L72.8008 179.2L76.3008 173.1H83.2008L86.7008 179.2L83.2008 185.2Z" fill="white" />
            <path d="M83.2008 200.3H76.3008L72.8008 194.2L76.3008 188.2H83.2008L86.7008 194.2L83.2008 200.3Z" fill="white" />
            <path d="M83.2008 215.3H76.3008L72.8008 209.3L76.3008 203.3H83.2008L86.7008 209.3L83.2008 215.3Z" fill="white" />
            <path d="M1320.7 188.5H1318.7L1317.7 186.7L1318.7 185H1320.7L1321.7 186.7L1320.7 188.5Z" fill="white" />
            <path d="M1323.2 177.7H1316.2L1312.7 171.6L1316.2 165.6H1323.2L1326.6 171.6L1323.2 177.7Z" fill="white" />
            <path d="M1323.2 162.6H1316.2L1312.7 156.6L1316.2 150.5H1323.2L1326.6 156.6L1323.2 162.6Z" fill="white" />
            <path d="M1323.2 147.5H1316.2L1312.7 141.5L1316.2 135.5H1323.2L1326.6 141.5L1323.2 147.5Z" fill="white" />
            <path d="M1323.2 132.5H1316.2L1312.7 126.4L1316.2 120.4H1323.2L1326.6 126.4L1323.2 132.5Z" fill="white" />
            <path d="M69.7008 116.5H63.7008L60.8008 111.4L63.7008 106.2H69.7008L72.6008 111.4L69.7008 116.5Z" fill="white" />
            <path d="M70.1992 132.5H63.1992L59.6992 126.4L63.1992 120.4H70.1992L73.6992 126.4L70.1992 132.5Z" fill="white" />
            <path d="M70.1992 147.5H63.1992L59.6992 141.5L63.1992 135.5H70.1992L73.6992 141.5L70.1992 147.5Z" fill="white" />
            <path d="M70.1992 162.6H63.1992L59.6992 156.6L63.1992 150.5H70.1992L73.6992 156.6L70.1992 162.6Z" fill="white" />
            <path d="M70.1992 177.7H63.1992L59.6992 171.6L63.1992 165.6H70.1992L73.6992 171.6L70.1992 177.7Z" fill="white" />
            <path d="M70.1992 192.7H63.1992L59.6992 186.7L63.1992 180.7H70.1992L73.6992 186.7L70.1992 192.7Z" fill="white" />
            <path d="M70.1008 207.7H63.3008L59.8008 201.8L63.3008 195.8H70.1008L73.6008 201.8L70.1008 207.7Z" fill="white" />
            <path d="M69.9008 222.4H63.5008L60.3008 216.9L63.5008 211.3H69.9008L73.1008 216.9L69.9008 222.4Z" fill="white" />
            <path d="M1333.3 753H1332.1L1331.5 751.9L1332.1 750.8H1333.3L1334 751.9L1333.3 753Z" fill="white" />
            <path d="M1336.2 170.1H1329.2L1325.8 164.1L1329.2 158.1H1336.2L1339.7 164.1L1336.2 170.1Z" fill="white" />
            <path d="M1336.2 155.1H1329.2L1325.8 149L1329.2 143H1336.2L1339.7 149L1336.2 155.1Z" fill="white" />
            <path d="M1336.2 140H1329.2L1325.8 134L1329.2 127.9H1336.2L1339.7 134L1336.2 140Z" fill="white" />
            <path d="M1333.5 120.3H1331.9L1331.1 118.9L1331.9 117.5H1333.5L1334.3 118.9L1333.5 120.3Z" fill="white" />
            <path d="M57.0992 124.9H50.1992L46.6992 118.9L50.1992 112.9H57.0992L60.5992 118.9L57.0992 124.9Z" fill="white" />
            <path d="M56.7 139.3H50.6L47.5 134L50.6 128.7H56.7L59.8 134L56.7 139.3Z" fill="white" />
            <path d="M57.0992 155.1H50.1992L46.6992 149L50.1992 143H57.0992L60.5992 149L57.0992 155.1Z" fill="white" />
            <path d="M57.0992 170.1H50.1992L46.6992 164.1L50.1992 158.1H57.0992L60.5992 164.1L57.0992 170.1Z" fill="white" />
            <path d="M57.0992 185.2H50.1992L46.6992 179.2L50.1992 173.1H57.0992L60.5992 179.2L57.0992 185.2Z" fill="white" />
            <path d="M57.0992 200.3H50.1992L46.6992 194.2L50.1992 188.2H57.0992L60.5992 194.2L57.0992 200.3Z" fill="white" />
            <path d="M57.0992 215.3H50.1992L46.6992 209.3L50.1992 203.3H57.0992L60.5992 209.3L57.0992 215.3Z" fill="white" />
            <path d="M1349.1 750.1H1342.4L1339.1 744.3L1342.4 738.5H1349.1L1352.5 744.3L1349.1 750.1Z" fill="white" />
            <path d="M1346.9 731.2H1344.6L1343.5 729.3L1344.6 727.3H1346.9L1348.1 729.3L1346.9 731.2Z" fill="white" />
            <path d="M1346.8 173.3H1344.8L1343.8 171.6L1344.8 169.9H1346.8L1347.7 171.6L1346.8 173.3Z" fill="white" />
            <path d="M1347.9 160.2H1343.7L1341.5 156.6L1343.7 152.9H1347.9L1350 156.6L1347.9 160.2Z" fill="white" />
            <path d="M1349.3 147.5H1342.3L1338.8 141.5L1342.3 135.5H1349.3L1352.7 141.5L1349.3 147.5Z" fill="white" />
            <path d="M1348.6 131.3H1342.9L1340.1 126.4L1342.9 121.5H1348.6L1351.4 126.4L1348.6 131.3Z" fill="white" />
            <path d="M41.2004 112.4H40.0004L39.4004 111.4L40.0004 110.3H41.2004L41.8004 111.4L41.2004 112.4Z" fill="white" />
            <path d="M42.2004 129.2H39.0004L37.4004 126.4L39.0004 123.6H42.2004L43.9004 126.4L42.2004 129.2Z" fill="white" />
            <path d="M44.0008 147.4H37.2008L33.8008 141.5L37.2008 135.6H44.0008L47.5008 141.5L44.0008 147.4Z" fill="white" />
            <path d="M43.2996 161.3H37.8996L35.0996 156.6L37.8996 151.8H43.2996L46.0996 156.6L43.2996 161.3Z" fill="white" />
            <path d="M44.0996 177.7H37.0996L33.5996 171.6L37.0996 165.6H44.0996L47.5996 171.6L44.0996 177.7Z" fill="white" />
            <path d="M44.0996 192.7H37.0996L33.5996 186.7L37.0996 180.7H44.0996L47.5996 186.7L44.0996 192.7Z" fill="white" />
            <path d="M44.0996 207.8H37.0996L33.5996 201.8L37.0996 195.8H44.0996L47.5996 201.8L44.0996 207.8Z" fill="white" />
            <path d="M44.0992 222.9H37.0992L33.6992 216.9L37.0992 210.8H44.0992L47.4992 216.9L44.0992 222.9Z" fill="white" />
            <path d="M1360.9 740.4H1356.8L1354.7 736.8L1356.8 733.2H1360.9L1362.9 736.8L1360.9 740.4Z" fill="white" />
            <path d="M1362 727.3H1355.6L1352.4 721.7L1355.6 716.2H1362L1365.2 721.7L1362 727.3Z" fill="white" />
            <path d="M1361.2 153.2H1356.4L1354 149L1356.4 144.9H1361.2L1363.7 149L1361.2 153.2Z" fill="white" />
            <path d="M1361.1 137.9H1356.6L1354.3 134L1356.6 130.1H1361.1L1363.3 134L1361.1 137.9Z" fill="white" />
            <path d="M28.7008 135.9H26.4008L25.3008 134L26.4008 132H28.7008L29.8008 134L28.7008 135.9Z" fill="white" />
            <path d="M30.9996 155.1H24.0996L20.5996 149L24.0996 143H30.9996L34.4996 149L30.9996 155.1Z" fill="white" />
            <path d="M28.1004 165.1H27.0004L26.4004 164.1L27.0004 163.1H28.1004L28.7004 164.1L28.1004 165.1Z" fill="white" />
            <path d="M30.9996 185.2H24.0996L20.5996 179.2L24.0996 173.1H30.9996L34.4996 179.2L30.9996 185.2Z" fill="white" />
            <path d="M30.9996 200.3H24.0996L20.5996 194.2L24.0996 188.2H30.9996L34.4996 194.2L30.9996 200.3Z" fill="white" />
            <path d="M29.1004 212H26.0004L24.4004 209.3L26.0004 206.6H29.1004L30.7004 209.3L29.1004 212Z" fill="white" />
            <path d="M29.4992 227.7H25.5992L23.6992 224.4L25.5992 221.1H29.4992L31.3992 224.4L29.4992 227.7Z" fill="white" />
            <path d="M30.3 244.3H24.8L22 239.5L24.8 234.7H30.3L33.1 239.5L30.3 244.3Z" fill="white" />
            <path d="M1372.6 730.5H1371.2L1370.5 729.3L1371.2 728.1H1372.6L1373.3 729.3L1372.6 730.5Z" fill="white" />
            <path d="M1374 717.9H1369.7L1367.6 714.2L1369.7 710.5H1374L1376.2 714.2L1374 717.9Z" fill="white" />
            <path d="M1373 701.1H1370.7L1369.6 699.1L1370.7 697.2H1373L1374.1 699.1L1373 701.1Z" fill="white" />
            <path d="M1373.8 159.9H1370L1368 156.6L1370 153.2H1373.8L1375.7 156.6L1373.8 159.9Z" fill="white" />
            <path d="M1373.9 145H1369.9L1367.8 141.5L1369.9 138H1373.9L1375.9 141.5L1373.9 145Z" fill="white" />
            <path d="M16.6996 145.3H12.2996L10.0996 141.5L12.2996 137.7H16.6996L18.8996 141.5L16.6996 145.3Z" fill="white" />
            <path d="M17.2 176.4H11.8L9 171.6L11.8 166.9H17.2L20 171.6L17.2 176.4Z" fill="white" />
            <path d="M18 192.7H11L7.5 186.7L11 180.7H18L21.5 186.7L18 192.7Z" fill="white" />
            <path d="M17.2 206.5H11.8L9 201.8L11.8 197.1H17.2L20 201.8L17.2 206.5Z" fill="white" />
            <path d="M15.3004 233.3H13.7004L12.9004 231.9L13.7004 230.6H15.3004L16.1004 231.9L15.3004 233.3Z" fill="white" />
            <path d="M16.6008 250.6H12.4008L10.3008 247L12.4008 243.4H16.6008L18.7008 247L16.6008 250.6Z" fill="white" />
            <path d="M1387.8 711.7H1382L1379.1 706.7L1382 701.6H1387.8L1390.8 706.7L1387.8 711.7Z" fill="white" />
            <path d="M1388.4 697.5H1381.5L1378.1 691.6L1381.5 685.6H1388.4L1391.8 691.6L1388.4 697.5Z" fill="white" />
            <path d="M1385.6 677.7H1384.2L1383.6 676.5L1384.2 675.3H1385.6L1386.3 676.5L1385.6 677.7Z" fill="white" />
            <path d="M2.2 180.4H0.7L0 179.2L0.7 177.9H2.2L2.9 179.2L2.2 180.4Z" fill="white" />
            <path d="M2.2 240.7H0.7L0 239.5L0.7 238.2H2.2L2.9 239.5L2.2 240.7Z" fill="white" />
            <path d="M2.09961 255.7H0.799609L0.0996094 254.5L0.799609 253.4H2.09961L2.79961 254.5L2.09961 255.7Z" fill="white" />
            <path d="M1399 685.8H1397L1396 684L1397 682.3H1399L1400 684L1399 685.8Z" fill="white" />
          </g>
        </svg>
      </div>

      <ReactTooltip
        anchorId="liechtenstein"
        place="bottom"
        className={styles.weareactive__tip}
      />

      <ReactTooltip
        anchorId="bahamas"
        place="bottom"
        className={styles.weareactive__tip}
      />

      <ReactTooltip
        anchorId="nigeria"
        place="bottom"
        className={styles.weareactive__tip}
      />

      <ReactTooltip
        anchorId="dubai"
        place="bottom"
        className={styles.weareactive__tip}
      />

      <ReactTooltip
        anchorId="singapore"
        place="bottom"
        className={styles.weareactive__tip}
      />

      <div className={classNames("center", styles.weareactive__center, styles.weareactive__information)}>
        <div className={styles.weareactive__box}>
          <div className={styles.weareactive__inner}>
            <div className={styles.weareactive__box_title}>
              {t('Legend')}
            </div>
          </div>
        </div>

        <div className={styles.weareactive__box}>
          <div className={styles.weareactive__inner}>
            <div className={styles.weareactive__box_icon}>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4 12H3.5L0 6L3.5 0H10.4L13.9 6L10.4 12Z" fill="#392DCA" />
              </svg>
            </div>
            <div className={styles.weareactive__box_title}>
              {t('10x Jurisdictions')}
            </div>
          </div>
        </div>

        <div className={styles.weareactive__box}>
          <div className={styles.weareactive__inner}>
            <div className={styles.weareactive__box_icon}>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4 12H3.5L0 6L3.5 0H10.4L13.9 6L10.4 12Z" fill="#E23A45" />
              </svg>
            </div>
            <div className={styles.weareactive__box_title}>
              {t('Emerging Hubs')}
            </div>
          </div>
        </div>

        <div className={styles.weareactive__box}>
          <div className={styles.weareactive__inner}>
            <div className={styles.weareactive__box_icon}>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.4 12H3.5L0 6L3.5 0H10.4L13.9 6L10.4 12Z" fill="#FF881B" />
              </svg>
            </div>
            <div className={styles.weareactive__box_title}>
              {t('Leading Hubs')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WeAreActive
