import React, { useState } from 'react'
import {
  CircularProgress,
  Dialog,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../../myHooks'
import ModalInnerMaterialDialog from '../../ModalInnerMaterialDialog'
import SetupTwoFactorAuth from './SetupTwoFactorAuth'
import DisableTwoFactorAuth from './DisableTwoFactorAuth'

const TwoFactorAuth = () => {
  const { t } = useTranslation()
  const [is2FaDialogOpened, setIs2FaDialogOpened] = useState(false)
  const { data: { me } = {}, loading, refetch } = useMe({ fetchPolicy: 'network-only' })

  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      <div className="faq__title tenant__title h4">
        {t('Two-Factor Authentication')}
        {' '}
        {me.isTwoFaEnabled && (
          <span className="status-green" style={{ verticalAlign: 'middle' }}>{t('Enabled')}</span>
        )}
        {!me.isTwoFaEnabled && (
          <span className="status-red" style={{ verticalAlign: 'middle' }}>{t('Disabled')}</span>
        )}
      </div>
      <div className="faq__info discover__info">
        {t('Two-factor authentication is a method used for protection of your web account. When it is activated you are required to enter not only your password, but also an activation code. This code will be issueed to you by a mobile application. Even if anyone would find out your password 2FA would still protect your account from unauthorized access. Security is our top priority at all times.')}
      </div>

      <br />

      <div className="details__fieldset">
        {!me.isTwoFaEnabled && (
          <button type="button" className="button" onClick={() => setIs2FaDialogOpened(true)}>{t('Enable 2FA')}</button>
        )}
        {me.isTwoFaEnabled && (
          <button type="button" className="button" onClick={() => setIs2FaDialogOpened(true)}>{t('Disable 2FA')}</button>
        )}
      </div>
      <Dialog
        open={is2FaDialogOpened}
        onClose={() => setIs2FaDialogOpened(false)}
        className="investhub-modal investhub-modal__m"
      >
        <ModalInnerMaterialDialog
          modalTitle={t('Two-Factor Authentication')}
        >
          {!me.isTwoFaEnabled && (
            <SetupTwoFactorAuth
              onClose={() => setIs2FaDialogOpened(false)}
              onComplete={() => {
                refetch()
                setIs2FaDialogOpened(false)
              }}
            />
          )}
          {me.isTwoFaEnabled && (
            <DisableTwoFactorAuth
              onClose={() => setIs2FaDialogOpened(false)}
              onComplete={() => {
                refetch()
                setIs2FaDialogOpened(false)
              }}
            />
          )}
        </ModalInnerMaterialDialog>
      </Dialog>
    </div>
  )
}

export default TwoFactorAuth
