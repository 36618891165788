import React from 'react'
import classNames from 'classnames'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'

import {
  PUBLIC_SETTINGS,
} from '../../../queriesAndMutations'
import MediumFeedGrid from '../../common/MediumFeedGrid'

import styles from './styles.module.scss'

const CustomerSays = () => {
  const { t } = useTranslation()
  const { data: { publicSettings } = {} } = useQuery(PUBLIC_SETTINGS)

  return (
    <>
      {publicSettings?.medium?.username && (
        <div className={classNames('section customersay', styles.customersay)}>
          <div className={classNames('center', styles.customersay__center)}>
            <h2 className={classNames('h3 text-center', styles.customersay__title)}>
              {t('Our Blog')}
            </h2>

            <div className={styles.customersay__text}>&nbsp;</div>

            <MediumFeedGrid numberOfPosts={3} />

            <div className="variants__note investhub-blog__note">
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events */}
              <a
                href={`https://${publicSettings?.medium?.username}.medium.com`}
                target="_blank"
                rel="noreferrer"
                className="button-stroke button-small"
              >
                {t('View all')}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CustomerSays
