import liechtenstein from './liechtenstein.png'
import bahamas from './bahamas.png'
import dubai from './dubai.png'
import nigeria from './nigeria.png'
import singapore from './singapore.png'

export default {
  liechtenstein,
  bahamas,
  dubai,
  nigeria,
  singapore
}