import React, { useState } from 'react'
import i18n from 'i18next'
import OutsideClcikHandler from 'react-outside-click-handler'
import * as languageHelper from '../../../utils/lang'

const TranslationToggle = () => {
  const [showDropdownLang, setShowDropdownLang] = useState(false)

  return (
    <>
      <div className={`select-lang tablet-hide ${showDropdownLang ? "active" : ""}`}>
        <OutsideClcikHandler
          onOutsideClick={() => setShowDropdownLang(false)}
        >
          <div className="select-lang__wrap">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className="select-lang__inner select"
              onClick={() => setShowDropdownLang(!showDropdownLang)}
            >
              <div className="select-dropdown__selection current">
                <img src={languageHelper.getCurrentFlagIcon()} alt={languageHelper.getCurrentLang()} />
              </div>

              <div className="select-lang__dropdown list">
                {Object.values(languageHelper.supportedLanguages).map((lang) => (
                  <React.Fragment key={lang.code}>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                      className={`select-lang__lists option ${languageHelper.getLocale() === lang.code ? 'selected' : ''}`}
                      key={lang.code}
                      onClick={() => { languageHelper.setLocale(lang.code); i18n.changeLanguage(lang.code) }}
                    >
                      <img src={lang.flagIcon} alt={lang.label} />
                      <span className="faq__stage">{lang.label}</span>
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </OutsideClcikHandler>
      </div>
    </>
  )
}

export default TranslationToggle
