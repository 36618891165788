import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { animated } from 'react-spring'
import { use3dEffect } from 'use-3d-effect'

import ContentImage from '../../../media/images/feature/feature'
import styles from './styles.module.scss'

const ContentTwo = () => {
  const { t } = useTranslation()

  const ref = React.useRef(null)
  const { style, ...mouseHandlers } = use3dEffect(ref)

  const ref2 = React.useRef(null)
  const { style2, ...mouseHandlers2 } = use3dEffect(ref2)

  const ref3 = React.useRef(null)
  const { style3, ...mouseHandlers3 } = use3dEffect(ref3)

  return (
    <>
      <div className={classNames('section', styles.content, styles.content__left)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref2}
              style={{
                ...style2,
              }}
              {...mouseHandlers2}
            >
              <img src={ContentImage.allocatecapital} alt={t('Allocate capital in a breeze.')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Allocate capital in a breeze.')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('Once you provide consent, we will provide your KYC data to the Issuer, so you can easily switch between Opportunities.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__right)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref}
              style={{
                ...style,
              }}
              {...mouseHandlers}
            >
              <img src={ContentImage.governance} alt={t('Governance: Active or delegate')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Governance: Active or delegate')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('You dont want to take an active part of the Community but rather a passive one? Delegate your Governance Tokens to a trusted and active Community Member.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__left)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref3}
              style={{
                ...style3,
              }}
              {...mouseHandlers3}
            >
              <img src={ContentImage.thenewformofemail} alt={t('The new form of Email.')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('The new form of Email.')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('On Chain Wallet Messages have a benefit over Emails or SMS, only you with your Wallet can read the messages be informed about new deals or if its dividend time. You decide, which channel to choose.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContentTwo
