import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'
import Background from '../../../media/images/background.png'

const ThingsLearn = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={classNames("section", styles.thingslearn)}>
        <img src={Background} alt="Thins you'll learn" className={styles.thingslearn__bg} />
        <div className={classNames("center", styles.thingslearn__center)}>
          <h2 className={classNames("h3 text-center", styles.thingslearn__title)}>
            {t('Things you’ll learn')}
          </h2>

          <div className={styles.thingslearn__text}>
            {t('We believe that you should never stop learning.')}
          </div>

          <div className={styles.thingslearn__list}>
            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('Setup a wallet')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to research on a opportunity')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to get accredited')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to Setup an account')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to make KYC / KYB')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('FIAT / Crypto on - off ramp')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to Invest in a opportunity')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to use wallet messaging')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to distribute dividends')}
              </span>
            </span>

            <span className={styles.thingslearn__item}>
              <span className={styles.thingslearn__item_icon}>
                &nbsp;
              </span>
              <span>
                {t('How to receive dividends/interest payments')}
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThingsLearn
