import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

const Goals = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames('section', styles.goals)}>
      <div className={classNames('center', styles.goals__center)}>

        <div className={styles.goals__box}>
          <div className={styles.goals__inner}>
            <div className={classNames('h3', styles.goals__title, styles.goals__title_gold)}>
              2000
            </div>

            <div className={styles.goals__text}>
              {t('Token issuances average ticket 500k')}
            </div>
          </div>
        </div>

        <div className={styles.goals__box}>
          <div className={styles.goals__inner}>
            <div className={classNames('h3', styles.goals__title, styles.goals__title_gold)}>
              1bn
            </div>

            <div className={styles.goals__text}>
              {t('Deployed capital')}
            </div>
          </div>
        </div>

        <div className={styles.goals__box}>
          <div className={styles.goals__inner}>
            <div className={classNames('h3', styles.goals__title, styles.goals__title_gold)}>
              {t('Our goal by 2030')}
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Goals
