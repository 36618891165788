import React from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Icon from '../../Icon'

import styles from './styles.module.scss'

const OffsetCta = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.footer__cta}>
      <div className={classNames('center', styles.footer__cta_center)}>
        <div className={styles.footer__cta_bgwrapper}>
          <h2 className={classNames('h2 text-center', styles.footer__cta_title)}>{t("Its time to start.")}</h2>
          <div className={styles.footer__cta_text}>{t("Create an Opportunity, Signup for the Community or just learn.")}</div>

          <div className={styles.footer__cta_btn}>
            <NavLink to="/learn" className="button-white">
              {t("Learn")}
            </NavLink>

            <NavLink to="/signup" className="button-dark button-has--icon">
              {t("Create Opportunity")}
              <Icon name="arrow-bottom" className={classNames("arrow-bottom")} size={20} />
            </NavLink>

            <a href="https://t.me/investhubDAO" target="_blank" rel="noreferrer" className="button-dark button-has--icon">
              {t("Signup for the Community")}
              <Icon name="arrow-bottom" className={classNames("arrow-bottom")} size={20} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OffsetCta
