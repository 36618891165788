import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const CheckoutStatus = ({ match }) => {
  const { t } = useTranslation()
  const isSuccess = match.params.status === 'success'
  const navigateToDashboard = () => {
    window.location.href = `${window.location.origin}/dashboard`
  }

  return (
    <>
      <div className="section result successfully-subscribed">
        <div className="result__center center">
          <div className="result__wrap">
            { isSuccess ? (
              <>
                {/* Credit to https://codepen.io/Hellwinkel/pen/KKaNNKb */}
                <div className="successfully-subscribed__icon">
                  <div className="successfully-subscribed__wrapper">
                    <div className="successfully-subscribed__background">
                      <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 25L27.3077 44L58.5 7" stroke="white" strokeWidth="13" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div>
                  <div className="successfully-subscribed__shadow">{' '}</div>
                </div>
                <div className="result__title h2">
                  {t('You&apos;ve been successfully subscribed for your tenant')}
                </div>
              </>
            ) : (
              <>
                <div className="result__title h2">
                  {t('Something went wrong with the payment. Please try again later.')}
                </div>
              </>
            )}

            <button
              type="button"
              className="button successfully-subscribed__button"
              onClick={() => navigateToDashboard()}
            >
              {t('Back to dashboard')}
            </button>
          </div>
        </div>
      </div>
    </>

  )
}

CheckoutStatus.propTypes = {
  match: PropTypes.object,
}

export default CheckoutStatus
