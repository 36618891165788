import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import SlideToggle from "react-slide-toggle"

import SiteLogoDark from '../../media/images/dark-mode-investhub-logo.svg'
import Icon from '../Icon'
import OffsetCta from './OffsetCta/OffsetCta'
import SubscribeForm from './SubbscribeForm/SubscribeForm'

import styles from './styles.module.scss'

const SiteFooter = () => {
  const { t } = useTranslation()

  return (
    <footer className={classNames(styles.footer)}>
      <OffsetCta />

      <div className={styles.footer__inner}>
        <div className={classNames('center', styles.footer__center)}>

          <div className={classNames(styles.footer__row)}>
            <div className={classNames(styles.footer__col__one)}>
              <NavLink to="/" className={classNames(styles.footer__logo)}>
                <img src={SiteLogoDark} alt="Investhub.io" />
              </NavLink>

              <div className={styles.footer__info}>
                {t("Cross-chain capital made easy. We are a legal and compliant capital allocation engine. Join a Community of future Web3 Investors and Entrepreneurs.")}
              </div>

              <ul className={styles.footer__socials}>
                <li className={styles.social__item}>
                  <a href="https://www.youtube.com/channel/UCQNrwM5JnYzwxG_RkFk4zQg" target="_blank" rel="noreferrer">
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1328_5429)">
                        <path d="M16.4907 5.18395C16.3931 4.85031 16.2069 4.54632 15.9502 4.30138C15.6933 4.05643 15.3746 3.87882 15.0249 3.7857C13.7121 3.4502 8.42866 3.4502 8.42866 3.4502C8.42866 3.4502 3.15856 3.44351 1.83244 3.7857C1.48266 3.87882 1.16398 4.05643 0.907202 4.30138C0.650423 4.54632 0.464225 4.85031 0.366609 5.18395C0.117755 6.46603 -0.00493064 7.76748 0.000151632 9.07134C-0.00396986 10.3703 0.118712 11.6668 0.366609 12.944C0.464225 13.2776 0.650423 13.5816 0.907202 13.8266C1.16398 14.0715 1.48266 14.2491 1.83244 14.3422C3.14382 14.6784 8.42866 14.6784 8.42866 14.6784C8.42866 14.6784 13.6981 14.6784 15.0249 14.3422C15.3746 14.2491 15.6933 14.0715 15.9502 13.8266C16.2069 13.5816 16.3931 13.2776 16.4907 12.944C16.7334 11.6663 16.8509 10.3698 16.8418 9.07134C16.8519 7.76795 16.7344 6.4665 16.4907 5.18395ZM6.7424 11.4761V6.65922L11.1399 9.07134L6.7424 11.4761Z" fill="#808080" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1328_5429">
                          <rect width="16.8423" height="16.8423" fill="white" transform="translate(0 0.643555)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>

                <li className={styles.social__item}>
                  <a href="https://twitter.com/investhubdao" target="_blank" rel="noreferrer">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1328_5426)">
                        <path d="M17.064 3.84992C16.4429 4.12291 15.7797 4.30888 15.0815 4.39379C15.7931 3.96501 16.3404 3.28922 16.5994 2.48289C15.9321 2.87237 15.1924 3.15588 14.405 3.31378C13.7762 2.64079 12.88 2.21973 11.8849 2.21973C9.9782 2.21973 8.43226 3.76571 8.43226 5.6703C8.43226 5.94399 8.46384 6.20715 8.52138 6.45908C5.65117 6.32364 3.10728 4.94538 1.4048 2.86184C1.10515 3.36851 0.937424 3.95729 0.937424 4.59871C0.937424 5.79872 1.54796 6.85347 2.47288 7.47313C1.90656 7.45488 1.37392 7.29909 0.909354 7.04084V7.08365C0.909354 8.75736 2.09744 10.1532 3.67851 10.4711C3.38868 10.549 3.08272 10.5911 2.76903 10.5911C2.54867 10.5911 2.33744 10.57 2.12621 10.5307C2.56902 11.9012 3.84202 12.9006 5.35713 12.9286C4.17817 13.8543 2.68411 14.4059 1.07497 14.4059C0.801282 14.4059 0.528296 14.3897 0.253906 14.3588C1.79007 15.3371 3.59992 15.9091 5.55713 15.9091C11.9109 15.9091 15.3811 10.6486 15.3811 6.09417C15.3811 5.9475 15.3811 5.79942 15.3706 5.65205C16.045 5.16854 16.6337 4.5573 17.0969 3.86396L17.064 3.84992Z" fill="#808080" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1328_5426">
                          <rect width="16.8423" height="16.8423" fill="white" transform="translate(0.253906 0.643555)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>

                <li className={styles.social__item}>
                  <a href="https://t.me/investhubDAO" target="_blank" rel="noreferrer">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1328_5425)">
                        <path d="M16.1531 2.92928L13.7597 14.7165C13.5939 15.553 13.1099 15.7536 12.4337 15.3664L8.78708 12.5526L7.02347 14.3294C6.82457 14.5368 6.65882 14.7165 6.29416 14.7165C5.81679 14.7165 5.89636 14.5299 5.73723 14.0598L4.47751 9.78042L0.864104 8.60514C0.0817519 8.36317 0.0751217 7.80319 1.03649 7.3953L15.1321 1.72635C15.7752 1.42908 16.3918 1.89227 16.1465 2.92237L16.1531 2.92928Z" fill="#808080" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1328_5425">
                          <rect width="15.9066" height="15.9066" fill="white" transform="translate(0.294922 0.643555)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
              </ul>

            </div>

            <div className={styles.footer__col__two}>

              <div className={classNames('tablet-hide', styles.footer__group)}>
                <div className={styles.footer__head}>
                  {t("Marketplace")}
                  <Icon name="arrow-bottom" className={classNames("arrow-bottom")} size={20} />
                </div>

                <div className={styles.footer__body}>
                  <div className={styles.footer__menu}>
                    <NavLink to="/explore">{t("Utility Tokens")}</NavLink>
                    <NavLink to="/explore">{t("Security Tokens")}</NavLink>
                    <NavLink to="/explore">{t("Private Deals")}</NavLink>
                    <NavLink to="/opportunity">{t("Opportunity")}</NavLink>
                    <NavLink to="/issuer">{t("Issuer")}</NavLink>
                    <span>{t("Opportunity API (soon)")}</span>
                    <span>{t("Commitment API (soon)")}</span>
                    <span>{t("Tokenization as a Service API (soon)")}</span>
                    <span>{t("DAOs (soon)")}</span>
                  </div>
                </div>
              </div>

              <div className={classNames('desktop-hide tablet-show', styles.footer__group)}>
                <SlideToggle
                  collapsed
                  render={({
                    toggle,
                    setCollapsibleElement,
                    toggleState,
                  }) => (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div
                        className={
                          classNames(
                            "footer-head__custom",
                            styles.footer__head,
                            (toggleState || "").toLowerCase(),
                          )
                        }
                        onClick={toggle}
                      >
                        {t("Marketplace")}
                        <Icon name="arrow-bottom" className={classNames("arrow-bottom")} size={20} />
                      </div>

                      <div className={classNames(styles.footer__body, styles.footer__body_rwd)} ref={setCollapsibleElement}>
                        <div className={styles.footer__menu}>
                          <NavLink to="/explore">{t("Utility Tokens")}</NavLink>
                          <NavLink to="/explore">{t("Security Tokens")}</NavLink>
                          <NavLink to="/explore">{t("Private Deals")}</NavLink>
                          <NavLink to="/opportunity">{t("Opportunity")}</NavLink>
                          <NavLink to="/issuer">{t("Issuer")}</NavLink>
                          <span>{t("Opportunity API (soon)")}</span>
                          <span>{t("Commitment API (soon)")}</span>
                          <span>{t("Tokenization as a Service API (soon)")}</span>
                          <span>{t("DAOs (soon)")}</span>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>

              <div className={classNames('tablet-hide', styles.footer__group)}>
                <div className={styles.footer__head}>
                  {t("Help")}
                  <Icon name="arrow-bottom" className={classNames("arrow-bottom")} size={20} />
                </div>

                <div className={styles.footer__body}>
                  <div className={styles.footer__menu}>
                    <a href="https://docs.investhub.io/" target="_blank" rel="noreferrer">{t("Documentation")}</a>
                  </div>
                </div>
              </div>

              <div className={classNames('desktop-hide tablet-show', styles.footer__group)}>
                <SlideToggle
                  collapsed
                  render={({
                    toggle,
                    setCollapsibleElement,
                    toggleState,
                  }) => (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                      <div
                        className={
                          classNames(
                            "footer-head__custom",
                            styles.footer__head,
                            (toggleState || "").toLowerCase(),
                          )
                        }
                        onClick={toggle}
                      >
                        {t("Help")}
                        <Icon name="arrow-bottom" className={classNames("arrow-bottom")} size={20} />
                      </div>

                      <div className={classNames(styles.footer__body, styles.footer__body_rwd)} ref={setCollapsibleElement}>
                        <div className={styles.footer__menu}>
                          <a href="https://docs.investhub.io/" target="_blank" rel="noreferrer">{t("Documentation")}</a>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className={styles.footer__col__three}>
              <SubscribeForm />
            </div>
          </div>

          <div className={classNames(styles.footer__foot)}>

            <div className={classNames(styles.footer__copyright)}>
              {t("© 2023 Investhub DAO. - All Rights Reserved.")}
            </div>

            <div className={classNames(styles.footer__note)}>
              {t('Made with love by Investhub DAO')}
            </div>

          </div>
        </div>
      </div>
    </footer>
  )
}

export default SiteFooter
