import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { omit } from 'lodash'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Checkbox,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'

import {
  CHECK_SUBDOMAIN_AVAILABILITY,
  CHECK_DOMAIN_AVAILABILITY,
  PUBLIC_SETTINGS,
  EASY_REGISTER_TENANT,
} from '../queriesAndMutations'
import { toaster } from '../utils'
import ValidationMessage from '../components/common/ValidationMessage'

const TenantEasySetup = () => {
  const { t } = useTranslation()
  const [checkSubdomain, checkSubdomainStatus] = useMutation(CHECK_SUBDOMAIN_AVAILABILITY)
  const [checkDomain, checkDomainStatus] = useMutation(CHECK_DOMAIN_AVAILABILITY)
  const [easyRegisterTenant, easyRegisterTenantStatus] = useMutation(EASY_REGISTER_TENANT)
  const { data } = useQuery(PUBLIC_SETTINGS)
  const tenantBaseDomain = (data && data.publicSettings && data.publicSettings.cloudflare && data.publicSettings.cloudflare.baseDomain) || ''
  const prefixOwnDomain = window.location.host === 'dev.investhub.io' ? 'test-' : ''

  const [stepEasySetup, setStepEasySetup] = useState(1)
  const [hasManualUpdateTokenSymbol, setHasManualUpdateTokenSymbol] = useState(false)
  const [values, setValues] = useState({
    subdomainType: '',
    ownSubdomain: '',
    customDomain: localStorage.getItem('desiredUrl') || '',

    tokenType: '',
    baseCurrency: 'eur',
    minimumInvestment: 1,
    acceptedCurrencies: ['eur', 'usd', 'chf', 'usdt', 'usdc', 'eure'],
    tokenName: '',
    tokenSymbol: '',
    blockchainType: 'MATIC_SIDE_CHAIN',
    acceptedWallets: {
      eth: true,
      cosmos: false,
      ixo_id: false,
      polygon: true,
      evm_compatible_wallet: true,
      btc: false,
      other: false,
    },
  })
  const [stepErrors, setStepErrors] = useState({})

  const setTextField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  const generateTokenSymbol = (tokenName) => {
    if (!tokenName) {
      return ''
    }

    const words = tokenName.replace(/[^a-zA-Z0-9]/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .trim()
      .split(' ')

    if (words.length === 0) {
      return ''
    }

    if (words.length === 1) {
      return words[0].substr(0, 3).toUpperCase()
    }

    return words.map((word) => word.substr(0, 1)).join('').substr(0, 3).toUpperCase()
  }

  const setTokenName = ({ target: { value } }) => {
    if (hasManualUpdateTokenSymbol) {
      setValues({
        ...values,
        tokenName: value,
      })
    } else {
      setValues({
        ...values,
        tokenName: value,
        tokenSymbol: generateTokenSymbol(value),
      })
    }
  }

  const setTokenSymbol = ({ target: { value } }) => {
    setValues({
      ...values,
      tokenSymbol: value,
    })
    setHasManualUpdateTokenSymbol(true)
  }

  const setNumberField = (fieldName) => ({ target: { value } }) => {
    setValues({
      ...values,
      [fieldName]: +value,
    })
  }

  const setButtonSelectField = (fieldName, value) => () => {
    setValues({
      ...values,
      [fieldName]: value,
    })
  }

  const setCheckBox = (field) => ({ target: { value, checked } }) => {
    if (checked) {
      setValues({
        ...values,
        [field]: [
          ...values[field],
          value,
        ],
      })
    } else {
      setValues({
        ...values,
        [field]: values[field].filter((c) => c !== value),
      })
    }
  }

  const setAcceptedWallets = (currency) => ({ target: { checked } }) => {
    setValues({
      ...values,
      acceptedWallets: {
        ...values.acceptedWallets,
        [currency]: checked,
      },
    })
  }

  const validateDomain = () => {
    const errors = {}
    if (!values.subdomainType) {
      errors.subdomainType = 'You need to select a type of domain.'
    } else if (values.subdomainType === 'ownSubdomain' && !values.ownSubdomain) {
      errors.ownSubdomain = 'You need to provide your subdomain.'
    } else if (values.subdomainType === 'investhubSubdomain') {
      if (!values.customDomain) {
        errors.customDomain = 'You need to select a subdomain.'
      }
    }

    setStepErrors({
      ...stepErrors,
      domain: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateTokenType = () => {
    const errors = {}
    if (!values.tokenType) {
      errors.tokenType = 'You need to select a token type.'
    }

    setStepErrors({
      ...stepErrors,
      tokenType: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateCurrency = () => {
    const errors = {}
    if (!values.baseCurrency) {
      errors.baseCurrency = 'You need to select a base currency.'
    }

    if (+values.minimumInvestment <= 0) {
      errors.minimumInvestment = 'Minimum investment must be greater than 0.'
    }

    if (values.acceptedCurrencies.length === 0) {
      errors.acceptedCurrencies = 'You must select at least one accepted currency.'
    }

    setStepErrors({
      ...stepErrors,
      currency: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateBlockchain = () => {
    const errors = {}
    if (!values.tokenName) {
      errors.tokenName = 'Token name must not be empty.'
    }

    if (!values.tokenSymbol) {
      errors.tokenSymbol = 'Token symbol must not be empty.'
    }

    if (!values.blockchainType) {
      errors.blockchainType = 'You must select a blockchain type.'
    }

    if (Object.values(values.acceptedWallets).reduce((all, isAccepted) => all || isAccepted, false) === false) {
      errors.acceptedWallets = 'You must select at least one accepted wallet.'
    }

    setStepErrors({
      ...stepErrors,
      blockchain: errors,
    })

    return Object.keys(errors).length === 0
  }

  const validateStep = (step) => {
    switch (step) {
      case 1:
        return validateDomain()

      case 2:
        return validateTokenType()

      case 3:
        return validateCurrency()

      case 4:
        return validateBlockchain()

      default:
        return true
    }
  }
  const stepListEasySetup = [
    'Setup Domain',
    'Financial Instrument',
    'Financials',
    'Blockchain',
  ]

  const prevStepEasySetup = () => {
    if (stepEasySetup > 1) {
      setStepEasySetup(stepEasySetup - 1)
    }
  }

  const nextStepEasySetup = () => {
    const formViewPort = document.getElementById('tenant_register_form')

    if (stepEasySetup < 4) {
      if (validateStep(stepEasySetup)) {
        setStepEasySetup(stepEasySetup + 1)
        window.scrollBy(0, formViewPort.getClientRects()[0].y - 100)
      } else {
        setTimeout(() => {
          const firstErrorMessage = formViewPort.querySelector('.error-message')

          if (firstErrorMessage) {
            window.scrollBy(0, firstErrorMessage.getClientRects()[0].y - 100)
          }
        }, 0)
      }
    } else if (validateStep(stepEasySetup)) {
      easyRegisterTenant({
        variables: {
          input: omit(values, [
            'blockchainType',
          ]),
        },
      }).then(() => {
        window.location.href = `${window.location.origin}/dashboard`
      }).catch(() => {})
    }
  }

  const getErrorText = (step, field) => (stepErrors && stepErrors[step] && stepErrors[step][field]) || ''

  const verifyDomain = () => {
    checkDomain({
      variables: {
        host: `${prefixOwnDomain}${values.ownSubdomain}`,
      },
    }).then((response) => {
      if (response.data.checkDomainAvailability) {
        toaster.success(t('Domain "OWNSUBDOMAIN" is available', { OWNSUBDOMAIN: prefixOwnDomain + values.ownSubdomain }))
      } else {
        toaster.error(t('Domain "OWNSUBDOMAIN" is not available', { OWNSUBDOMAIN: prefixOwnDomain + values.ownSubdomain }))
      }
    }).catch(() => {})
  }

  const checkSubdomainAvailable = () => {
    checkSubdomain({
      variables: {
        host: values.customDomain,
      },
    }).then((response) => {
      if (response.data.checkSubdomainAvailability) {
        toaster.success(t('Domain "OWNDOMAIN" is available', { OWNDOMAIN: values.customDomain + tenantBaseDomain }))
      } else {
        toaster.error(t('Domain "OWNDOMAIN" is not available', { OWNDOMAIN: values.customDomain + tenantBaseDomain }))
      }
    }).catch(() => {})
  }

  return (
    <>
      <div className="section-pt80 faq new-tenant">
        <div className="faq__center center new-tenant__center">
          <div className="faq__top new-tenant__center">
            <div className="faq__stage">{t('4 Steps - 60 Seconds')}</div>
            <div className="faq__title h2 new-tenant__title">{t('Easy Setup')}</div>
            <div className="faq__info new-tenant__info">{t('Backing the future of Web3 Entrepreneurs & helping Web2 Entrepreneurs transition to Web3.')}</div>
          </div>

          <div className="faq__row new-tenant__row">
            <div className="faq__col new-tenant__col">
              <div className="new-tenant__stepper-nav tablet-hide mobile-hide">
                <Stepper
                  activeStep={stepEasySetup - 1}
                  orientation="vertical"
                >
                  { stepListEasySetup.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        {t(label)}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
            </div>

            <div className="faq__col new-tenant__col" id="tenant_register_form">
              <div className="new-tenant__stepper-content">
                {stepEasySetup === 1 && (
                  <>
                    <div className="upload__item">
                      <div className="upload__category">{t('Setup domain')}</div>

                      <div className="upload__fieldset details__fieldset">
                        <div className="field">
                          <div className="field__label">{t('Do you want to have your own Subdomain?')}</div>

                          <RadioGroup
                            row
                            aria-label="subdomaintype"
                            name="subdomaintype"
                            className="field__radio"
                            value={values.subdomainType}
                            onChange={setTextField('subdomainType')}
                          >
                            <FormControlLabel
                              value="ownSubdomain"
                              label={t('Yes - I have my own Subdomain')}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              value="investhubSubdomain"
                              label={t('No - Continue with TENANTBASEDOMAIN subdomain and verify my own Domain later.')}
                              control={<Radio />}
                            />
                          </RadioGroup>

                          <ValidationMessage error={getErrorText('domain', 'subdomainType')} />
                        </div>

                        {values.subdomainType === 'ownSubdomain' && (
                          <>
                            <TextField
                              value={values.ownSubdomain}
                              onChange={setTextField('ownSubdomain')}
                              className="investhub__field field field-has__button"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Cname')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {checkDomainStatus.loading && (
                                      <CircularProgress />
                                    )}
                                    {!checkDomainStatus.loading && (
                                      <button
                                        type="button"
                                        className="button button-small"
                                        onClick={verifyDomain}
                                      >
                                        {t('Verify')}
                                      </button>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <ValidationMessage error={getErrorText('domain', 'ownSubdomain')} />

                            <div className="field">
                              <div className="box-note box-note__orange">
                                {t('Please setup a CNAME DNS record point to the address app.investhub.io (without proxy enabled. We recommend to use CloudFlare). If you wish to set up your subdomain, please make sure to follow the video.')}
                              </div>
                            </div>
                          </>
                        )}

                        {values.subdomainType === 'investhubSubdomain' && (
                          <>
                            <TextField
                              value={values.customDomain}
                              onChange={setTextField('customDomain')}
                              className="investhub__field field field-has__button"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={t('Cname')}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <span className="button-stroke button-small button-transparent">
                                      {tenantBaseDomain}
                                    </span>
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <ValidationMessage error={getErrorText('domain', 'customDomain')} />

                            <div className="field">
                              {checkSubdomainStatus.loading && (
                                <CircularProgress />
                              )}
                              {!checkSubdomainStatus.loading && (
                                <button
                                  className="button-stroke"
                                  type="button"
                                  onClick={checkSubdomainAvailable}
                                >
                                  {t('Check Availability')}
                                </button>
                              )}
                            </div>

                            <div className="field">
                              <div className="box-note box-note__orange">
                                {t('You can also set up your cname later in case you want to simply start with a subdomain so you can start configuring your KYC SaaS right away.')}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="field field__video">
                          <div className="video__item video__item-9-16">
                            <iframe
                              src="https://www.youtube.com/embed/6eUCbY5oIuU"
                              title={t('YouTube video player')}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              frameBorder="0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {stepEasySetup === 2 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Financial Instrument')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field">
                        <div className="field__label">
                          {t('Please tell us what financial instrument you are going to be issuing')}
                          :
                        </div>

                        <ValidationMessage error={getErrorText('tokenType', 'tokenType')} />

                        <div className="investhub-table__wrapper">
                          <div className="investhub-table__box investhub-table__box-styled new-tenant__table">
                            <div className="investhub-table__row">{' '}</div>
                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('EQUITY')}</strong>
                                {t('Equity - can tokenize your existing or newly issued equity.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'EQUITY' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'EQUITY')}
                                >
                                  {values.tokenType === 'EQUITY' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('BOND')}</strong>
                                {t('Bond - can tokenize a complete bond including interest payments and initial bond principal.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'BOND' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'BOND')}
                                >
                                  {values.tokenType === 'BOND' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('UTILITY')}</strong>
                                {t('Utility - can issue a utility token with or without whitelisting feature.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'UTILITY' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'UTILITY')}
                                >
                                  {values.tokenType === 'UTILITY' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('NONE')}</strong>
                                {t('None - can be used for simple invest onboarding without any token function.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.tokenType === 'NONE' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('tokenType', 'NONE')}
                                >
                                  {values.tokenType === 'NONE' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            src="https://www.youtube.com/embed/d-fz5JoE_I4"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder="0"
                          />
                        </div>
                      </div>

                      <div className="field single-page">
                        <p>
                          {t('The difference between each token type is primarily which token contract has to be deployed as the smart contract for an equity offering is different than compared to a bond or a utility token.')}
                        </p>
                        <p>
                          {t('If you wish to not have any token-related parameters in your KYC SaaS you can simply choose &quot;None&quot; which enables you to use the KYC SaaS as a simple onboarding tool for your investors, either for equity or tokens.')}
                        </p>
                        <p>
                          {t('Many of our customers also use this in connection with our Referral feature, where you can assign certain investors to certain projects so you can use for example 1 system for several thousand investors with just separating them through their referral source.')}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {stepEasySetup === 3 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Financials')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field">
                        <div className="field__label">{t('Whats your base currency?')}</div>

                        <TextField
                          select
                          className="select investhub-select select-trans"
                          value={values.baseCurrency}
                          onChange={setTextField('baseCurrency')}
                        >
                          {['eur', 'usd', 'chf'].map((currency) => (
                            <MenuItem value={currency} key={currency}>{currency.toUpperCase()}</MenuItem>
                          ))}
                        </TextField>

                        <ValidationMessage error={getErrorText('currency', 'baseCurrency')} />
                      </div>

                      <TextField
                        className="investhub__field field"
                        label={t('Whats your minimum investment?')}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="number"
                        step={1}
                        value={values.minimumInvestment}
                        onChange={setNumberField('minimumInvestment')}
                      />
                      <ValidationMessage error={getErrorText('currency', 'minimumInvestment')} />

                      <div className="field">
                        <div className="field__label">
                          {t('Which currencies do you want that investors can invest?')}
                        </div>

                        <div className="field__checkbox-wrapper field__checkbox-vertical">
                          {['eth', 'eur', 'usd', 'chf', 'usdt', 'usdc', 'eure', 'xdc', 'polygon', 'dai', 'busd'].map((currency) => (
                            <FormControlLabel
                              key={currency}
                              className="field field__checkbox field__checkbox-group no-margin__left"
                              label={currency.toUpperCase()}
                              control={(
                                <Checkbox
                                  color="primary"
                                  value={currency}
                                  checked={values.acceptedCurrencies.includes(currency)}
                                  onChange={setCheckBox('acceptedCurrencies')}
                                />
                              )}
                            />
                          ))}
                        </div>
                        <ValidationMessage error={getErrorText('currency', 'acceptedCurrencies')} />
                      </div>

                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            src="https://www.youtube.com/embed/TtQykxBfnM8"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="video-tenant"
                            allowFullScreen
                            frameBorder="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {stepEasySetup === 4 && (
                  <div className="upload__item">
                    <div className="upload__category">{t('Blockchain')}</div>

                    <div className="upload__fieldset details__fieldset">
                      <div className="field__group">
                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          step={1}
                          label={t('Whats your token name?')}
                          value={values.tokenName}
                          onChange={setTokenName}
                          error={!!getErrorText('blockchain', 'tokenName')}
                          helperText={(
                            <ValidationMessage error={getErrorText('blockchain', 'tokenName')} />
                          )}
                        />

                        <TextField
                          className="investhub__field field"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          step={1}
                          label={t('Your Token Symbol?')}
                          value={values.tokenSymbol}
                          onChange={setTokenSymbol}
                          error={!!getErrorText('blockchain', 'tokenSymbol')}
                          helperText={(
                            <ValidationMessage error={getErrorText('blockchain', 'tokenSymbol')} />
                          )}
                        />
                      </div>

                      <div className="field">
                        <div className="field__label">{t('Which crypto wallets will you accepted from investors?')}</div>

                        <div className="field__checkbox-wrapper field__checkbox-vertical">
                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label="ETH"
                            control={(
                              <Checkbox
                                color="primary"
                                value="eth"
                                checked={values.acceptedWallets.eth}
                                onChange={setAcceptedWallets('eth')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('ixo Account ID')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="ixo_id"
                                checked={values.acceptedWallets.ixo_id}
                                onChange={setAcceptedWallets('ixo_id')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('ixo Account Address')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="cosmos"
                                checked={values.acceptedWallets.cosmos}
                                onChange={setAcceptedWallets('cosmos')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('Polygon Address')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="cosmos"
                                checked={values.acceptedWallets.polygon}
                                onChange={setAcceptedWallets('cosmos')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label="BTC"
                            control={(
                              <Checkbox
                                color="primary"
                                value="btc"
                                checked={values.acceptedWallets.btc}
                                onChange={setAcceptedWallets('btc')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('EVM Compatible Wallet')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="cosmos"
                                checked={values.acceptedWallets.evm_compatible_wallet}
                                onChange={setAcceptedWallets('cosmos')}
                              />
                            )}
                          />

                          <FormControlLabel
                            className="field field__checkbox field__checkbox-group no-margin__left"
                            label={t('Others')}
                            control={(
                              <Checkbox
                                color="primary"
                                value="other"
                                checked={values.acceptedWallets.other}
                                onChange={setAcceptedWallets('other')}
                              />
                            )}
                          />
                        </div>

                        <ValidationMessage error={getErrorText('blockchain', 'acceptedWallets')} />
                      </div>

                      <div className="field">
                        <div className="field__label">{t('Which Blockchain should be used for the financial instrument deployed?')}</div>

                        <ValidationMessage error={getErrorText('blockchain', 'blockchainType')} />

                        <div className="investhub-table__wrapper">
                          <div className="investhub-table__box investhub-table__box-styled new-tenant__table">
                            <div className="investhub-table__row">{' '}</div>
                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Ethereum Blockchain')}</strong>
                                {t('Ethereum is the community-run technology powering the cryptocurrency ether (ETH) and thousands of decentralized applications')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.blockchainType === 'ETHEREUM' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('blockchainType', 'ETHEREUM')}
                                >
                                  {values.blockchainType === 'ETHEREUM' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Stellar Blockchain')}</strong>
                                {t('Stellar, or Stellar Lumens, is an open source, decentralized protocol for digital currency to fiat money low-cost transfers which allows cross-border transactions between any pair of currencies.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.blockchainType === 'STELLAR' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('blockchainType', 'STELLAR')}
                                >
                                  {values.blockchainType === 'STELLAR' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>

                            <div className="investhub-table__row new-tenant__row-table">
                              <div className="investhub-table__col">
                                <strong>{t('Polygon Blockchain')}</strong>
                                {t('Polygon is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks. Aggregating scalable solutions on Ethereum supporting a multi-chain Ethereum ecosystem.')}
                              </div>

                              <div className="investhub-table__col">
                                <button
                                  type="button"
                                  className={`${values.blockchainType === 'MATIC_SIDE_CHAIN' ? 'button' : 'button-stroke'} button-small`}
                                  onClick={setButtonSelectField('blockchainType', 'MATIC_SIDE_CHAIN')}
                                >
                                  {values.blockchainType === 'MATIC_SIDE_CHAIN' ? t('Selected') : t('Select')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="field field__video">
                        <div className="video__item video__item-9-16">
                          <iframe
                            src="https://www.youtube.com/embed/478n1h7k6tM"
                            title={t('YouTube video player')}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            className="video-tenant"
                            allowFullScreen
                            frameBorder="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="upload__foot details__foot new-tenant__foot">
                  <div className="details__btns details__btns_flex">
                    { stepEasySetup > 1 ? (
                      <button type="button" className="button-stroke details__button" onClick={prevStepEasySetup}>
                        {t('Back')}
                      </button>
                    ) : (
                      <></>
                    )}

                    { stepEasySetup > 1 ? (
                      <div className="new-tenant__stepper-nav new-tenant__stepper-mobile desktop-hide tablet-show">
                        <Stepper
                          activeStep={stepEasySetup - 1}
                          orientation="vertical"
                        >
                          { stepListEasySetup.map((label) => (
                            <Step key={label}>
                              <StepLabel>{t(label)}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </div>
                    ) : (
                      <></>
                    )}

                    { stepEasySetup < 4 && (
                      <button type="button" className="button details__button" onClick={nextStepEasySetup}>
                        {t('Next')}
                      </button>
                    )}
                    { stepEasySetup >= 4 && easyRegisterTenantStatus.loading && (
                      <CircularProgress />
                    )}
                    { stepEasySetup >= 4 && !easyRegisterTenantStatus.loading && (
                      <button type="button" className="button details__button" onClick={nextStepEasySetup}>
                        {t('Complete')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TenantEasySetup
