import React from 'react'
import classNames from 'classnames'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import {
  CircularProgress,
} from '@material-ui/core'

import {
  GET_STATISTIC_DATA,
} from '../../../queriesAndMutations'
import {
  formatPrintNumber,
} from '../../../utils'
import HeroImage from '../../../media/images/hero-image-human.png'
import Partners from '../../../media/images/new-partners/new-partners'

import styles from './styles.module.scss'

const Hero = () => {
  const { t } = useTranslation()
  const { data: { getStatisticData: statisticData } = {}, loading: statisticLoading } = useQuery(GET_STATISTIC_DATA)

  const printStatisticNumber = (field, precise = 0) => {
    if (statisticLoading) {
      return <CircularProgress />
    }

    if (statisticData) {
      return formatPrintNumber(precise ? +statisticData[field] : parseInt(statisticData[field], 10), precise)
    }

    return '-'
  }
  return (
    <div className={classNames("section", styles.hero)}>
      <div className={classNames('center', styles.hero__center)}>
        <div className={styles.hero__content}>
          <h1 className={styles.hero__title}>{t("don't trust, verify.")}</h1>

          <h2 className={styles.hero__subtitle}>{t("Cross-chain capital made easy.")}</h2>
          <p className={styles.hero__text}>{t("We are a legal and compliant capital allocation engine. Join a Community of future Web3 Investors and Entrepreneurs.")}</p>

          <div className={styles.hero__buttons}>
            <NavLink to="/explore" className="button">
              {t("Explore Opporturnities")}
            </NavLink>

            <NavLink to="/issuer" className="button-white">
              {t("For Fundraising Entrepreneurs")}
            </NavLink>
          </div>

          <div className={styles.hero__statistic}>
            <div className={styles.hero__item}>
              <div className={styles.hero__number}>
                {printStatisticNumber('allUser')}
              </div>
              <span className={styles.hero__total}>
                {t("Total Users")}
              </span>
            </div>

            <div className={styles.hero__item}>
              <div className={styles.hero__number}>
                {printStatisticNumber('totalValue', 0)}
                {!statisticLoading && 'USDC'}
              </div>
              <span className={styles.hero__total}>
                {t("Total Allocated")}
              </span>
            </div>
          </div>

          <div className={classNames(styles.hero__statistic, styles.hero__statistic_group)}>
            <div className={classNames(styles.hero__statistic_top)}>
              <div className={styles.hero__number}>
                {printStatisticNumber('deployableCapitalEth', 0)}
                {' '}
                ETH
              </div>
              <div className={styles.hero__number}>
                {printStatisticNumber('deployableCapitalEure', 0)}
                {' '}
                EURE
              </div>
              <div className={styles.hero__number}>
                {printStatisticNumber('deployableCapitalBusd', 0)}
                {' '}
                BUSD
              </div>

              <div className={styles.hero__number}>
                {printStatisticNumber('deployableCapitalUsdc', 0)}
                {' '}
                USDC
              </div>
              <div className={styles.hero__number}>
                {printStatisticNumber('deployableCapitalUsdt', 0)}
                {' '}
                USDT
              </div>
              <div className={styles.hero__number}>
                {printStatisticNumber('deployableCapitalDai', 0)}
                {' '}
                DAI
              </div>
            </div>
            <div className={classNames(styles.hero__statistic_bottom)}>
              <span className={styles.hero__total}>
                {t("Deployable Capital")}
              </span>
            </div>
          </div>
        </div>

        <div className={styles.hero__partner}>
          <div className={styles.hero__partner_item}>
            <img src={Partners.etherium} alt="Etherium" />
          </div>

          <div className={styles.hero__partner_item}>
            <img src={Partners.polygon} alt="Polygin" />
          </div>

          <div className={styles.hero__partner_item}>
            <img src={Partners.solana} alt="Solana" />
          </div>

          <div className={styles.hero__partner_item}>
            <img src={Partners.avalanche} alt="Avalanche" />
          </div>

          <div className={styles.hero__partner_item}>
            <img src={Partners.xdc} alt="XDC" />
          </div>

          <div className={styles.hero__partner_item}>
            <img src={Partners.cosmos} alt="Cosmos" />
          </div>

          <div className={styles.hero__partner_item}>
            <img src={Partners.chainlink} alt="Chainlink" />
          </div>
        </div>

        <div className={styles.hero__img}>
          <img src={HeroImage} alt="Investhub" />
        </div>
      </div>
    </div>
  )
}

export default Hero
