import opportunities from './opportunities.png'
import topcontributor from './top-contributor.png'
import web3community from './web3-community.png'
import allocatecapital from './allocatecapital.png'
import governance from './governance.png'
import thenewformofemail from './thenewformofemail.png'

export default {
  opportunities,
  topcontributor,
  web3community,
  allocatecapital,
  governance,
  thenewformofemail
}