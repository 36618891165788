import React, { useState } from 'react'
import classNames from 'classnames'
import {
  TextField,
  TablePagination,
  Dialog,
  CircularProgress,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom"

import {
  OPPORTUNITY_LIST,
} from '../../queriesAndMutations'
import Icon from '../../components/Icon'
import TablePaginationActions from '../../components/common/TablePaginationActions'
import ModalInnerMaterialDialog from '../../components/ModalInnerMaterialDialog'

import styles from './styles.module.scss'

const OpportunityList = () => {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const rowsPerPage = 12
  const [keyword, setKeyword] = useState('')
  const [openModalVideo, setOpenModalVideo] = useState(false)
  const [isClickedModalVideo, setIsClickedModalVideo] = useState(null)

  const handleOpenModal = (index) => {
    setIsClickedModalVideo(index)
    setOpenModalVideo(true)
  }
  const handleCloseModal = () => {
    setOpenModalVideo(false)
    setIsClickedModalVideo(null)
  }

  const { data: { opportunityList: data } = {}, loading } = useQuery(OPPORTUNITY_LIST, {
    variables: {
      search: keyword,
      page,
      rowsPerPage,
    },
  })

  const pageCount = data ? data.count : 0

  const changePage = (event, nextPage) => {
    setPage(nextPage)
  }

  return (
    <div className={classNames('section', styles.opportunitylist)}>
      <div className={classNames('center', styles.opportunitylist__center)}>
        <div className={classNames(styles.opportunitylist__top)}>
          <h1 className={classNames('h2', styles.opportunitylist__title)}>
            {t('Opportunity')}
          </h1>

          <form className={styles.opportunitylist__form}>
            <TextField
              placeholder={t('Search by title...')}
              name="search"
              type="text"
              required
              className="investhub__field catalog__input"
              onChange={({ target: { value } }) => { setKeyword(value) }}
            />
            <button className={styles.opportunitylist__form_button} type="submit">
              <Icon
                name="searchh"
                className={classNames('searchh')}
                size={16}
              />
            </button>
          </form>
        </div>

        <div className={styles.opportunitylist__row}>
          <div className={styles.opportunitylist__wrapper}>
            <div className={styles.opportunitylist__list}>
              {loading && (
                <div className="text-center">
                  <CircularProgress />
                </div>
              )}
              {data && data.opportunities && data.opportunities.length && data.opportunities.map((opportunity, index) => (
                <div className={styles.opportunitylist__card} key={opportunity.id}>
                  <div className={classNames(styles.opportunitylist__card_preview, styles.opportunitylist__card_preview_video)}>
                    <iframe
                      src={opportunity.video_embed_url}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="item__preview-video"
                    />

                    <div className={styles.opportunitylist__card_control}>
                      <button
                        type="button"
                        className={classNames('button button-small', styles.opportunitylist__card_btn)}
                        style={{ bottom: "68px" }}
                        onClick={() => handleOpenModal(index)}
                      >
                        {t('View Video')}
                      </button>
                      <NavLink
                        to={`/opportunity/${opportunity.slug}`}
                        className={classNames('button button-small', styles.opportunitylist__card_btn)}
                      >
                        {t('Check more')}
                      </NavLink>
                    </div>
                  </div>

                  <div className={styles.opportunitylist__card_wrapper}>
                    <div className={styles.opportunitylist__card_body}>
                      <div className={styles.opportunitylist__card_title}>
                        {opportunity.name}
                      </div>
                    </div>
                  </div>

                  {opportunity.video_embed_url && (
                    <Dialog
                      open={openModalVideo && index === isClickedModalVideo}
                      className="investhub-modal investhub-modal__m"
                      onClose={handleCloseModal}
                      key={`${opportunity.id}-modal`}
                    >
                      <ModalInnerMaterialDialog
                        modalTitle={opportunity.name}
                        modalOnClickClose={handleCloseModal}
                      >
                        <div className="field field__video">
                          <div className="video__item video__item-9-16">
                            <iframe
                              src={opportunity.video_embed_url}
                              title="video"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            />
                          </div>
                        </div>
                      </ModalInnerMaterialDialog>
                    </Dialog>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="investhub-table__pagination">
          <TablePagination
            colSpan={6}
            count={pageCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={changePage}
            rowsPerPageOptions={[]}
            ActionsComponent={TablePaginationActions}
            component="div"
          />
        </div>
      </div>
    </div>
  )
}

export default OpportunityList
