import React from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment-timezone'
import {
  CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
  GET_USER_TENANT,
} from '../../queriesAndMutations'
import { TENANT_STATUS } from '../../constants/tenant'

import TenantStatusChip from '../common/TenantStatusChip'

const UserTenantTable = () => {
  const { t } = useTranslation()
  const { data, loading } = useQuery(GET_USER_TENANT)
  const allTenants = data ? data.getUserTenant : []
  const formatDate = (timestamp) => moment(+timestamp).tz('Europe/Vaduz').format('YYYY-MM-DD HH:mm z')

  return (
    <>
      <div className="investhub-table__wrapper">
        <div className="investhub-table__box investhub-table__box-styled">
          <div className="investhub-table__row">
            <div className="investhub-table__col">
              {t('Domain')}
            </div>
            <div className="investhub-table__col">
              {t('Created At')}
            </div>
            <div className="investhub-table__col">
              {t('Status')}
            </div>
            <div className="investhub-table__col">
              {t('Action')}
            </div>
          </div>

          { loading && (
            <div className="investhub-table__row investhub-table__row-empty">
              <div className="investhub-table__col">
                <CircularProgress />
              </div>
            </div>
          )}

          { allTenants.map((tenant) => (
            <div className="investhub-table__row" key={tenant.id}>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Domain')}</div>
                {tenant.host}
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Created At')}</div>
                {formatDate(tenant.createdAt)}
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Status')}</div>
                <TenantStatusChip status={tenant.status} />
              </div>
              <div className="investhub-table__col">
                <div className="investhub-table__label">{t('Action')}</div>

                <div className="investhub-table__btns">
                  {tenant.status === TENANT_STATUS.AWAIT_PURCHASE && (
                    <NavLink
                      to={`/dashboard/tenants/${tenant.id}/subscribe`}
                      className="button-stroke button-small investhub-table__btn"
                    >
                      {t('Subscribe')}
                    </NavLink>
                  )}
                  {tenant.status === TENANT_STATUS.AWAIT_DNS_SETUP && (
                    <NavLink
                      to={`/dashboard/tenants/${tenant.id}/setup-dns`}
                      className="button-stroke button-small investhub-table__btn"
                    >
                      {t('Setup DNS')}
                    </NavLink>
                  )}
                  <NavLink
                    to={`/dashboard/tenants/${tenant.id}`}
                    className="button-stroke button-small investhub-table__btn"
                  >
                    {t('Details')}
                  </NavLink>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default UserTenantTable
