import React from 'react'
import PropTypes from 'prop-types'
import { generateUuid } from '../../utils'
import BreadcrumbConnector from '../common/BreadcrumbConnector'

const BreadcrumbsSimple = ({ children }) => {
  let inner
  if (children.length) {
    inner = children.reduce((all, element) => [...all, element, <BreadcrumbConnector key={generateUuid()} />], [])
    inner.pop()
  } else {
    inner = children
  }

  return (
    <div className="control tablet-hide">
      <div className="control__center center">
        <div className="breadcrumbs">
          <ul className="breadcrumbs">
            {inner}
          </ul>
        </div>
      </div>
    </div>
  )
}

BreadcrumbsSimple.prototypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

export default BreadcrumbsSimple
