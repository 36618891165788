import caag from './crypto-advisory.png'
import calidrisColor from './calidris-color.png'
import calidrisWhite from './calidris-white.png'
import cll from './cll.svg'
import enercom from './enercom.png'
import equanimity from './equanimity.svg'
import equanimityDark from './equanimity-dark.svg'
import ixo from './ixo.png'
import xdcteq from './xdcteq.svg'

export default {
  caag,
  calidrisColor,
  calidrisWhite,
  cll,
  enercom,
  equanimity,
  equanimityDark,
  ixo,
  xdcteq
}