import React from 'react'
import { useTranslation } from 'react-i18next'
import LoginForm from '../components/LoginForm'

const Login = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="section-pt80 variants signinup">
        <div className="variants__center signinup__center center">
          <div className="variants__top signinup__top">
            <div className="variants__title h2 signinup__title">
              {t('Please Sign In')}
            </div>
            <div className="variants__info signinup__info">
              {t('Signin to manage your account')}
            </div>
          </div>

          <LoginForm onSuccess={() => { window.location.href = `${window.location.origin}/dashboard` }} />
        </div>
      </div>
    </>
  )
}

export default Login
