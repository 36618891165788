import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SignUpForm from '../components/SignUpForm'

const SignUp = () => {
  const { t } = useTranslation()
  const [showRegistrationCompleteStatus, setShowRegistrationCompleteStatus] = useState(false)
  const showRegistrationComplete = () => {
    setShowRegistrationCompleteStatus(true)
  }

  return (
    <>

      <div className="section-pt80 variants signinup">
        <div className="variants__center signinup__center center">
          { showRegistrationCompleteStatus ? (
            <div className="variants__top signinup__top">
              <div className="variants__title h2 signinup__title">
                {t('Verify your signup process')}
              </div>
              <div className="variants__info signinup__info">
                {t('You received an email, please confirm this email and continue from there.')}
              </div>
            </div>
          ) : (
            <>
              <div className="variants__top signinup__top">
                <div className="variants__title h2 signinup__title">
                  {t('Please Sign Up')}
                </div>
                <div className="variants__info signinup__info">
                  {t('Once you are signed up, you can start creating tenants.')}
                </div>
              </div>

              <SignUpForm onRegistrationComplete={showRegistrationComplete} />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default SignUp
