import React from 'react'

import Hero from '../components/newHome/Hero/Hero'
import Achievement from '../components/newHome/Achievement/Achievement'
import WeAreActive from '../components/newHome/WeAreActive/WeAreActive'
import Investors from '../components/newHome/Investors/Investors'
import AdoptionRates from '../components/newHome/AdoptionRates/AdoptionRates'
import CapitalDeployment from '../components/newHome/CapitalDeployment/CapitalDeployment'
import ContentOne from '../components/newHome/ContentOne/ContentOne'
import ContentTwo from '../components/newHome/ContentTwo/ContentTwo'
import CommunityRightNow from '../components/newHome/CommunityRightNow/CommunityRightNow'
import Goals from '../components/newHome/Goals/Goals'
import CustomerSays from '../components/newHome/CustomerSays/CustomerSays'
import ThingsLearn from '../components/newHome/ThingsLearn/ThngsLearn'
import TheCommunity from '../components/newHome/TheCommunity/TheCommunity'

const NewHome = () => (
  <>

    <Hero />
    <Achievement />
    <AdoptionRates />
    <ContentOne />
    <CommunityRightNow />
    <Goals />
    <CapitalDeployment />
    <ContentTwo />
    <TheCommunity />
    <ThingsLearn />
    <CustomerSays />
    <Investors />
    <WeAreActive />

  </>
)

export default NewHome
