import React from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { animated } from 'react-spring'
import { use3dEffect } from 'use-3d-effect'

import ContentImage from '../../../media/images/feature/feature'

import styles from './styles.module.scss'

const ContentOne = () => {
  const { t } = useTranslation()

  const ref = React.useRef(null)
  const { style, ...mouseHandlers } = use3dEffect(ref)

  const ref2 = React.useRef(null)
  const { style2, ...mouseHandlers2 } = use3dEffect(ref2)

  const ref3 = React.useRef(null)
  const { style3, ...mouseHandlers3 } = use3dEffect(ref3)

  return (
    <>
      <div className={classNames('section', styles.content, styles.content__right, styles.content__first)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref}
              style={{
                ...style,
              }}
              {...mouseHandlers}
            >
              <img src={ContentImage.topcontributor} alt={t('Contribute value to the Community, get rewarded')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Contribute value to the Community, get rewarded')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('Digital Collectibles or “NFTs” are a great way to incentivize our Community. Join us on our Journey to 1bn deployable capital and 2m wallets.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__left)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref2}
              style={{
                ...style2,
              }}
              {...mouseHandlers2}
            >
              <img src={ContentImage.web3community} alt={t('The entry ticket to the Web3 Community')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('The entry ticket to the Web3 Community')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('With the initial creation of the Digital Collectibles from Investhub, we show our appreciation to our partners and existing community members for the last several years. As these collectibles are issued on chain they will always be with you.')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames('section', styles.content, styles.content__right)}>
        <div className={classNames('center', styles.content__center)}>

          <div className={styles.content__image}>
            <animated.div
              ref={ref3}
              style={{
                ...style3,
              }}
              {...mouseHandlers3}
            >
              <img src={ContentImage.opportunities} alt={t('Access Opportunities with your Collectible')} />
            </animated.div>
          </div>

          <div className={styles.content__description}>
            <h2 className={classNames("h3", styles.content__title)}>
              {t('Access Opportunities with your Collectible')}
            </h2>

            <div className={styles.content__text}>
              <p>
                {t('You will be able to access opportunities with a simple signature with your Wallet.')}
              </p>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default ContentOne
